
import React from "react";
import { NavLink } from "react-router-dom";
import { Schemes, Utils } from "../../../utils";
import { PlatformPopoverItem, i18nManager } from "../../../i18n";
import { Button, Column, Popover, PopoverManageRef, Table } from "@ronuse/noseur";
import { ApiResponseData, BaseService, IPlatform, IProfile, PlatformService, SanitizedResponse } from "../../../services";

export interface ProfileProps {
    profile: IProfile;
}

function Profile(props: ProfileProps) {
    const wordRef = React.useRef<HTMLSpanElement>(null);
    const platformService = PlatformService.getInstance();
    const labels = i18nManager.Labels.dashboard.account.profile;
    const platformPopoverManageRef = React.useRef<PopoverManageRef>(null);
    const [activePlatform, setActivePlatform] = React.useState<IPlatform>();
    const [platformData, setPlatformData] = React.useState<ApiResponseData<IPlatform>>({});

    React.useEffect(() => {
        fetchPlatforms();
        const interval = animateWords();
        return () => {
            clearInterval(interval);
        };
    }, []);

    return (<div className="profile">
        <div className="top">
            <div className="words">
                <span ref={wordRef}></span>
            </div>
            <div className="control">
                <img src={props.profile?.profile_picture_location || i18nManager.Labels.logo} />
                <div>
                    <div>
                        <span className="t">{props.profile?.first_name} {props.profile?.last_name}</span>
                        <span>{props.profile?.email}</span>
                        <span>{props.profile?.title}</span>
                        <span className="loc">
                            <img src={`https://cdn.countryflags.com/thumbs/${(props.profile?.country ?? 'nigeria').toLowerCase()}/flag-3d-250.png`} />
                            {props.profile?.full_address}</span>
                    </div>
                    <Button scheme={Schemes.RIVTN_QUIRINUS} text={labels.edit_profile.label} link={labels.edit_profile.location}
                        style={{ color: "white", width: 120 }} raised />
                </div>
            </div>
        </div>
        <div className="bottom">
            <span className="t">{labels.platforms.title}</span>
            <Table className="table" data={platformData.content} totalRecords={platformData.total_elements} paginate>
                <Column template={(icon_location: any) => (<img className="icon rounded" src={icon_location} />)} dataKey="{bacuator_data.icon_location}" />
                <Column dataKey="name" header={labels.platforms.table.name} />
                <Column dataKey="platform_id" header={labels.platforms.table.platform_id} />
                <Column template={(platform: IPlatform) => (<React.Fragment>
                    <i className="fa fa-ellipsis-h" style={{ cursor: "pointer" }}
                        onClick={(e) => {
                            setActivePlatform(platform);
                            platformPopoverManageRef.current?.show(e as any);
                        }} />
                </React.Fragment>)} />
            </Table>
        </div>

        <Popover className="dashboard-popover" manageRef={platformPopoverManageRef} style={{ padding: "10px 0px" }}>
            {labels.platforms.table.popover.map((item: PlatformPopoverItem, index: number) => (<NavLink key={index} className="popover-link"
                to={`${item.location}${activePlatform?.platform_id}`}>
                {item.label}
            </NavLink>))}
        </Popover>
    </div>);

    function animateWords() {
        return setInterval(() => {
            wordRef.current!.classList.add("fade-in");
            wordRef.current!.innerText = (labels.words[Utils.randomNumber(0, labels.words.length - 1)]);
            const subInterval = setInterval(() => {
                wordRef.current && wordRef.current.classList.remove("fade-in");
                clearInterval(subInterval);
            }, 1000);
        }, 2000);
    }

    function fetchPlatforms() {
        platformService.queryPlatforms(undefined, true).then(({ apiResponse }: SanitizedResponse<IPlatform>) => {
            setPlatformData(apiResponse.data!);
        }).catch(BaseService.reportError);
    }

}

export default Profile;
