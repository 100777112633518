
import { BaseService } from "../BaseService";
import { NoseurObject } from "@ronuse/noseur";

export class Isp {
    name?: string;
    carrier?: string;
}

export class Device {
    os_name?: string;
    os_version?: string;
    engine_name?: string;
    device_name?: string;
    device_type?: string;
    device_class?: string;
    browser_name?: string;
    engine_version?: string;
    browser_version?: string;
}

export class Location {
    latitude?: number;
    longitude?: number;
    state?: string;
    country?: string;
    address?: string;
}

export class GroupListed {
    reason!: string;
    device!: Device;
    events!: string[];
    expiry_date!: string;
    creation_date!: string;
    data?: NoseurObject<any>;
}

export interface Domain {
    ip?: string;
    name?: string;
    created?: string;
    expires?: string;
    changed?: string;
    idnName?: string;
    askWhois?: string;
    nameservers?: string[];
    contacts?: {
        owner?: {
            name?: string;
            email?: string;
            country?: string;
            address?: string;
            organization?: string;
        };
        admin?: {
            name?: string;
            email?: string;
            country?: string;
            address?: string;
            organization?: string;
        };
        tech?: {
            name?: string;
            email?: string;
            country?: string;
            address?: string;
            organization?: string;
        };
    };
    registrar?: {
        url?: string;
        name?: string;
        email?: string;
    };
}

export class GroupSuspicion {
    device!: Device;
    reason!: string;
    strike_count!: number;
}

export class SoteriaBaseService extends BaseService {

    constructor() {
        super(process.env.REACT_APP_SOTERIA_BASE_API_URL ?? "http://127.0.0.1:9304");
    }

}

