
import React from "react";
import Platforms from "./Platforms";
import Management from "./Management";
import { i18nManager } from "../../../i18n";
import { Route, Routes } from "react-router-dom";
import ApprovalRequests from "./ApprovalRequests";
import RolesPermissions from "./RolesPermissions";
import { IProfile, ISecondaryData } from "../../../services";
import { DashboardContainer } from "../../shared/DashboardContainer";

export interface AdministrationProps {
    search: string;
    profile: IProfile;
    secondaryData: ISecondaryData;
}

function Administration(props: AdministrationProps) {
    const labels = i18nManager.Labels.dashboard.administration;

    return DashboardContainer({
        ...labels,
        search: props.search,
        secondaryData: props.secondaryData,
        content: (<Routes>
            <Route path="/platforms" element={<Platforms/>} />
            <Route path="/management" element={<Management/>} />
            <Route path="/roles-and-permissions" element={<RolesPermissions/>} />
            <Route path="/approvals-and-request" element={<ApprovalRequests/>} />
        </Routes>),
    });
}

export default Administration;
