
import React from "react";
import { ConfirmDialog, Elements } from "../../../shared";
import { i18nManager } from "../../../../i18n";
import { LzEncryptor, Schemes, Utils } from "../../../../utils";
import { NavLink, Path, useSearchParams } from "react-router-dom";
import { Button, ButtonManageRef, Checkbox, Classname, Column, DataManageRef, DateTimePicker, DateTimePickerEvent, DateTimePickerManageRef, DateTimePickerSelectionMode, DateTimePickerType, FormControl, InputManageRef, NoseurObject, Scheme, Table, TextInput, TypeChecker } from "@ronuse/noseur";
import { ActorType, ApiResponseData, AuditService, BaseService, IAudit, IPermission, IPlatform, SanitizedResponse } from "../../../../services";
import { permission } from "process";

export interface AuditProps {
    search: string;
    platform?: IPlatform;
}

function Audit(props: AuditProps) {
    const auditService = AuditService.getInstance();
    const tableManageRef = React.useRef<DataManageRef>(null);
    const dialogErrorRef = React.useRef<HTMLDivElement>(null);
    const dateFilterSpan = React.useRef<HTMLSpanElement>(null);
    const [activeAudit, setActiveAudit] = React.useState<IAudit>();
    const queryInputManageRef = React.useRef<InputManageRef>(null);
    const queryButtonManageRef = React.useRef<ButtonManageRef>(null);
    const labels = i18nManager.Labels.dashboard.platform_management.audit;
    const [audits, setAudits] = React.useState<ApiResponseData<IAudit>>({});
    const dateFilterManageRef = React.useRef<DateTimePickerManageRef>(null);
    const [deletingAudits, setDeletingAudits] = React.useState<boolean>(false);
    const [exportingAudits, setExportingAudits] = React.useState<boolean>(false);
    const [selectedAuditsIds, setSelectedAuditsIds] = React.useState<number[]>([]);
    const [searchParams, setSearchParams] = useSearchParams(window.location.search);

    React.useEffect(() => {
        let query;
        if (searchParams.has("platform_id") && !searchParams.has("query")) {
            const currentQuery = LzEncryptor.decrypt(searchParams.get("query") ?? "", true);
            query = (currentQuery ? (currentQuery + " AND ") : "") + "group CONTAINS " + searchParams.get("platform_id");
            searchParams.set("query", LzEncryptor.encrypt(query, true));
            setSearchParams(searchParams);
        }
        fetchAudits(query);
    }, []);

    return (<div className="panel">
        <div className="left">
            <div className="header" style={{ padding: "12px 20px", alignItems: "center", overflow: "hidden" }}>
                <span>{labels.title}</span>
                <FormControl className="audit-search-form-control" leftContent={<i className="fas fa-search" style={{ color: "#0FA883" }} />}
                    rightContent={<Button className="audit-query-button" manageRef={queryButtonManageRef} scheme={Schemes.RIVTN_QUIRINUS}
                        text={labels.query} onClick={() => fetchAudits()} loadingProps={{
                            disabled: true,
                            leftIcon: "fas fa-spinner fa-pulse"
                        }} />} borderless>
                    <TextInput defaultValue={searchParams.has("query") ? LzEncryptor.decrypt(searchParams.get("query"), true) : ""}
                        manageRef={queryInputManageRef} placeholder={labels.query_placeholder} style={{ marginRight: 10 }} />
                </FormControl>
                <div className="audit-controls">
                    <i className="fa fa-calendar" ref={dateFilterSpan} onClick={(e: any) => dateFilterManageRef.current?.toggle(e, dateFilterSpan.current as any)} />
                    <i className={Classname.build(exportingAudits ? "fa fa-spinner fa-spin" : "fa fa-download")} onClick={() => fetchAudits(undefined, true)} />
                    <i className={Classname.build((deletingAudits ? "fa fa-spinner fa-spin del" : "fa fa-trash"), (!selectedAuditsIds.length ? "noseur-disabled" : null))} onClick={() => deleteAudits()} />
                </div>
            </div>
            <div className="content">
                <div className="content management-links">
                    <Table className="table" rowProps={{ className: "audit" }} data={audits.content} totalRecords={audits.total_elements} dataRefreshKeys={["id"]} manageRef={tableManageRef}
                        loadingState={Elements.LOADING_STATE} rowsPerPage={audits.size ?? 100} valuedRowProps={(audit: IAudit) => audit.id === activeAudit?.id ? { className: "audit active" } : {}}
                        onRowSelection={(audit: IAudit) => {
                            const rowNumber = audits.content?.findIndex(e => e.id === audit.id) ?? 0;
                            if (audit.id === activeAudit?.id) {
                                setActiveAudit(undefined);
                                tableManageRef.current?.collapseContent(rowNumber + 1);
                                Utils.updateSearchParams("active_audit_id", null, setSearchParams);
                                return false;
                            }
                            setActiveAudit(audit);
                            Utils.updateSearchParams("active_audit_id", audit.id, setSearchParams);
                            tableManageRef.current?.expandContent(rowNumber + 1);
                            return true;
                        }} rowExpansionTemplate={(audit: IAudit) => {
                            return (<div className="audit-content">
                                <div><span className="title">{labels.audit.audit_id}:</span> <span>{audit.audit_id}</span></div>
                                <div><span className="title">{labels.audit.type}:</span> <span>{audit.type}</span></div>
                                <div><span className="title">{labels.audit.group}:</span> <span>{audit.group}</span></div>
                                <div><span className="title">{labels.audit.status}:</span> <span>{audit.status}</span></div>
                                <div style={{ display: "inline-flex" }}><span className="title">{labels.audit.actor}:</span><span style={{ marginLeft: 3 }}>{buildActorLink(audit, false)}</span></div>
                                <div><span className="title">{labels.audit.actor_type}:</span> <span>{audit.actor_type}</span></div>
                                <div><span className="title">{labels.audit.environment}:</span> <span>{audit.environment}</span></div>
                                <div><span className="title">{labels.audit.source}:</span> <span>{audit.source}</span></div>
                                <div><span className="title">{labels.audit.action}:</span> <span>{audit.action}</span></div>
                                <div><span className="title">{labels.audit.extra_data}:</span> <span>{JSON.stringify(audit.extra_data)}</span></div>
                                <div><span className="title">{labels.audit.auditable}:</span> <span>{JSON.stringify(audit.auditable ?? "")}</span></div>
                                <div><span className="title">{labels.audit.ip_address}:</span> <a target="_blank" href={`https://whatismyipaddress.com/ip/${audit.ip_address}`}>{audit.ip_address}</a><span></span></div>
                                <div><span className="title">{labels.audit.isp}:</span> <span>{buildFineObjectString(audit.isp)}</span></div>
                                <div><span className="title">{labels.audit.location}:</span> <span>{buildFineObjectString(audit.location)}</span></div>
                                <div><span className="title">{labels.audit.device}:</span> <span>{buildFineObjectString(audit.device)}</span></div>
                                <div><span className="title">{labels.audit.date_created}:</span> <span>{audit.created_at}</span></div>
                            </div>)
                        }} rowsContent={{}}>
                        <Column header={() => <Checkbox scheme={Schemes.RIVTN_QUIRINUS} checked={selectedAuditsIds?.length === audits?.content?.length} onChange={() => {
                            if (selectedAuditsIds?.length === audits.content?.length) {
                                setSelectedAuditsIds([]);
                            } else {
                                setSelectedAuditsIds(audits.content?.map(m => m.id)!);
                            }
                        }} />} template={(audit: IAudit) => {
                            return (<Checkbox scheme={Schemes.RIVTN_QUIRINUS} checked={selectedAuditsIds.includes(audit.id)} onChange={(e: any) => {
                                if (e.checked && !selectedAuditsIds.includes(audit.id)) {
                                    setSelectedAuditsIds([...selectedAuditsIds, audit.id]);
                                } else if (!e.checked && selectedAuditsIds.includes(audit.id)) {
                                    selectedAuditsIds.splice(selectedAuditsIds.indexOf(audit.id), 1);
                                    setSelectedAuditsIds([...selectedAuditsIds]);
                                }
                            }} />);
                        }} />
                        <Column dataKey="type" header={labels.table.type} template={(type: string) => (<span className="lined-limit">{type}</span>)} />
                        <Column dataKey="group" header={labels.table.group} template={(group: string) => (<span className="lined-limit">{group}</span>)} />
                        <Column header={labels.table.actor} template={(a) => buildActorLink(a)} />
                        <Column dataKey="source" header={labels.table.source} template={(source: string) => (<span className="lined-limit">{source}</span>)} />
                        <Column dataKey="action" header={labels.table.action} template={(action: string) => (<span className="lined-limit">{action}</span>)} />
                    </Table>
                    {Elements.buildPaginator(audits ?? {}, ({ currentPage }, size) => Utils.updateSearchParamses({ "page": currentPage, id: null, size }, setSearchParams, fetchAudits), parseInt(searchParams.get("page") ?? "1"))}
                </div>
            </div>
        </div>

        <DateTimePicker scheme={Schemes.RIVTN_QUIRINUS} type={DateTimePickerType.POPOVER} sticky selectionMode={DateTimePickerSelectionMode.RANGE}
            manageRef={dateFilterManageRef} reportOnSelectClickOnly highlightDatesInRange onSelectDate={(options: DateTimePickerEvent) => {
                Utils.updateSearchParams(`created_at_to`, (options.toDate ? Utils.formatToApiDate(options.toDate) : undefined), setSearchParams, () => {
                    Utils.updateSearchParams(`created_at_from`, (options.fromDate ? Utils.formatToApiDate(options.fromDate) : undefined), setSearchParams, fetchAudits);
                });
            }} />
    </div>);

    function fetchAudits(queryString?: string, download?: boolean) {
        setSelectedAuditsIds([]);
        if (!download) {
            tableManageRef.current?.setLoadingState(true);
            queryButtonManageRef.current?.setLoadingState(true);
        } else {
            setExportingAudits(true);
        }
        const query: NoseurObject<any> = Utils.normalizeUrlParams(searchParams, undefined, undefined, undefined, ["page", "size", "sort", "created_at_to", "created_at_from"]);
        const value = (queryString ?? queryInputManageRef.current?.value() ?? "");
        const valueParts = value.toLowerCase().split("and");
        for (const valuePart of valueParts) {
            let operator;
            let leftOperand;
            let rightOperand;
            let subValueParts: string[] = [];
            if (valuePart.includes(" contains ")) {
                operator = "__like";
                subValueParts = valuePart.trim().split(" contains ");
            } else if (valuePart.includes(" eq ") || valuePart.includes(" = ") || valuePart.includes(" equals ")) {
                operator = "__eq";
                let seperator = " = ";
                if (valuePart.includes(" eq ")) seperator = " eq ";
                if (valuePart.includes(" equals ")) seperator = " equals ";
                subValueParts = valuePart.trim().split(seperator);
            } else {
                operator = "__like";
                subValueParts = ["source", valuePart.trim()];
            }
            leftOperand = subValueParts[0];
            rightOperand = subValueParts.length > 1 ? subValueParts[1] : "";
            if (rightOperand.startsWith("'") || rightOperand.startsWith('"')) rightOperand = rightOperand.substring(1);
            if (rightOperand.endsWith("'") || rightOperand.endsWith('"')) rightOperand = rightOperand.substring(0, rightOperand.length - 1);
            const queryKey = `${leftOperand}${operator}`;
            if (queryKey in query) {
                query[queryKey] = ([] as string[]).concat(query[queryKey]);
                query[queryKey].push(rightOperand);
            } else {
                query[queryKey] = rightOperand;
            }
        }
        Utils.updateSearchParams("query", (value ? LzEncryptor.encrypt(value, true) : undefined), setSearchParams);

        let fetchedQueryParamsAudit = !searchParams.get("active_audit_id");
        (download ? auditService.getAuditExportKey : auditService.queryAudits).bind(auditService)(query, true).then(({ apiResponse }: SanitizedResponse<IAudit>) => {
            if (download) {
                window.open(`${auditService.BASE_URL}/thoth/api/v1/audit/export?key=${apiResponse.data}`, '_blank');
                setExportingAudits(false);
                return;
            }
            setAudits(apiResponse.data!);
            if (!fetchedQueryParamsAudit) {
                apiResponse.data!.content!.forEach(a => {
                    if (`${a.id}` === searchParams.get("active_audit_id")) {
                        fetchedQueryParamsAudit = true;
                        selectActiveAudit(apiResponse.data!.content!, { ...a });
                    }
                });
            }
            if (!fetchedQueryParamsAudit) {
                const content = apiResponse.data!.content!;
                auditService.getSingleAudit(parseInt(searchParams.get("active_audit_id") ?? "0")).then(({ apiResponse }: SanitizedResponse<IAudit>) => {
                    content.unshift(apiResponse.data as IAudit);
                    selectActiveAudit(content, { ...apiResponse.data as IAudit });
                }).catch(BaseService.reportError);
            }
        }).catch(BaseService.reportError).finally(() => {
            setExportingAudits(false);
            tableManageRef.current?.setLoadingState(false);
            queryButtonManageRef.current?.setLoadingState(false);
        });
    }

    function selectActiveAudit(content: IAudit[], audit: IAudit) {
        setActiveAudit(audit);
        try {
            setTimeout(() => {
                const rowNumber = content?.findIndex(e => e.id === audit.id) ?? 0;
                tableManageRef.current?.expandContent(rowNumber + 1);
            }, 500);
        } catch (err) {
            console.error(err);
        }
    }

    function buildActorLink(audit: IAudit, includeUserType: boolean = true) {
        let userType = "";
        let to: Partial<Path> = {
            search: `active_admin_id=${audit.actor_id}`,
            pathname: "/dashboard/administration/management"
        };
        if (audit.actor_type === ActorType.PLATFORM) {
            userType = `${labels.user_type.platform}: `;
            to.search = `active_platform_id=${audit.actor_id}`;
            to.pathname = "/dashboard/administration/platforms";
        } else if (audit.actor_type === ActorType.USER) {
            userType = `${labels.user_type.user}: `;
            to.pathname = "/dashboard/users-and-businesses/users";
            to.search = `active_user_id=${audit.actor_id}&fragment=profile`;
        } else if (audit.actor_type === ActorType.BUSINESS) {
            userType = `${labels.user_type.business}: `;
            to.search = `active_business_id=${audit.actor_id}`;
            to.pathname = "/dashboard/users-and-businesses/businesses";
        } else if (audit.actor_type === ActorType.ADMINISTRATOR) {
            userType = `${labels.user_type.admin}: `;
        }
        return (<NavLink className="lined-limit" style={{ width: "fit-content" }} to={to}>{includeUserType ? userType : " "}{audit.actor_id}</NavLink>)
    }

    function deleteAudits() {
        setDeletingAudits(true);
        const dialog = ConfirmDialog({
            confirmScheme: Scheme.DANGER,
            title: labels.dialog.delete.title,
            cancelLabel: i18nManager.Labels.common.cancel,
            desc: labels.dialog.delete.desc.replace("${count}", `${selectedAuditsIds.length}`),
            content: (<div ref={dialogErrorRef} className="error"></div>),
            onConfirm: (button?: ButtonManageRef) => {
                button?.setLoadingState(true);
                auditService.deleteAudits(selectedAuditsIds).then(() => {
                    Utils.updateSearchParams("active_audit_id", null, setSearchParams, () => {
                        const rowNumber = audits.content?.findIndex(e => e.id === activeAudit?.id) ?? 0;
                        tableManageRef.current?.collapseContent(rowNumber+1);
                        dialog.hide(fetchAudits);
                    });
                }).catch(BaseService.reportError).finally(() => setDeletingAudits(false));
                return false;
            },
            onCancel: () => setDeletingAudits(false)
        });
        dialog.show();
    }

    function buildFineObjectString(obj: NoseurObject<any>) {
        if (!obj) return "";
        if (TypeChecker.isArray(obj)) return JSON.stringify(obj)
        const objKeys = Object.keys(obj);
        const elements: React.ReactNode[] = [];
        for (let index = 0; index < objKeys.length; index++) {
            const key = objKeys[index];
            let value = JSON.stringify(obj[key]);
            const name = Utils.toSaneSentenceFormat(key);
            const separator = (index === (objKeys.length - 1)) ? "" : ", ";
            if (value.startsWith('"') && value.endsWith('"')) value = value.substring(1, value.length - 1);
            elements.push(<span className="audit-fos" key={key}><span className="t">{name}: </span><span>{value}{separator}</span></span>);
        }
        return (<span>{elements}</span>)
    }

}

export default Audit;
