
import { NoseurObject } from "@ronuse/noseur";
import { CharonBaseService, TemplateType } from "./CharonBaseService";

export interface ITemplate {
    id: number;
    name: string;
    content: string;
    type: TemplateType;
    updated_at: string;
    template_id: string;
    external_id: string;
    description: string;
    extra_data: NoseurObject<any>;
    required_parameters: string[];
}

export class TemplateService extends CharonBaseService {

    protected static instance: TemplateService;

    private constructor() {
        super();
    }

    static getInstance() {
        if (!TemplateService.instance) TemplateService.instance = new TemplateService();
        return TemplateService.instance;
    }

    queryTemplate(params: NoseurObject<string | number> = { page: 1, size: 10 }, refresh: boolean = false): Promise<any> {
        return this.report(this.transport.get("/charon/api/v1/template", { params: this.normalizeParams(params) } as any));
    }

    getSingleByTemplateId(templateId: string): Promise<any> {
        return this.report(this.transport.get(`/charon/api/v1/template/template_id/${templateId!}`, this.config));
    }

    createTemplate(template: ITemplate): Promise<any> {
        return this.report(this.transport.post(`/charon/api/v1/template`, template, this.config));
    }

    updateTemplate(template: ITemplate): Promise<any> {
        return this.report(this.transport.patch(`/charon/api/v1/template/${template.id!}`, template, this.config));
    }

    enableDisableTemplate(template: ITemplate, enabled: boolean): Promise<any> {
        return this.report(this.transport.patch(`/charon/api/v1/template/${template.id!}`, { enabled }, this.config));
    }

    deleteTemplate(template: ITemplate): Promise<any> {
        return this.report(this.transport.delete(`/charon/api/v1/template/${template.id!}`, this.config));
    }

}

