
import { NoseurObject } from "@ronuse/noseur";
import { GroupListed, Isp, Location, SoteriaBaseService } from "./SoteriaBaseService";

export interface IListedIp {
    isp: Isp;
    extra_data: any;
    location: Location;
    created_at: string;
    updated_at: string;
    ip_address: string;
    external_id: string;
    platforms_blacklisted: NoseurObject<GroupListed>;
    platforms_whitelisted: NoseurObject<GroupListed>;
}

export class ListedIpService extends SoteriaBaseService {

    protected static instance: ListedIpService;

    private constructor() {
        super();
    }

    static getInstance() {
        if (!ListedIpService.instance) ListedIpService.instance = new ListedIpService();
        return ListedIpService.instance;
    }

    queryListedIps(params: NoseurObject<string | number> = { page: 1, size: 10 }, refresh: boolean = false): Promise<any> {
        return this.report(this.transport.get("/soteria/api/v1/listed/ip", { params: this.normalizeParams(params) } as any));
    }

    getSingleListedIp(externalId: string): Promise<any> {
        return this.report(this.transport.get(`/soteria/api/v1/listed/ip/${externalId!}`, this.config));
    }

    createListedIp(payload: any): Promise<any> {
        return this.report(this.transport.post(`/soteria/api/v1/listed/ip`, payload, this.config));
    }

    deleteListedIp(externalId: string): Promise<any> {
        return this.report(this.transport.delete(`/soteria/api/v1/listed/ip/${externalId!}`, this.config));
    }

    addListedIpPlatform(payload: any): Promise<any> {
        return this.report(this.transport.put(`/soteria/api/v1/listed/ip`, payload, this.config));
    }

    deleteListedIpPlatform(externalId: string, listingType: string, platform: string): Promise<any> {
        return this.report(this.transport.patch(`/soteria/api/v1/listed/ip/${externalId!}`, {
            platforms: [ platform ],
            listing_type: listingType,
            reason: "Removed by admin from the UI",
        }, this.config));
    }

}

