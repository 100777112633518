
import React from "react";
import { i18nManager } from "../../../../i18n";
import { IPlatform } from "../../../../services";
import { NavLink } from "react-router-dom";

export interface LinksProps {
    loading: boolean;
    platform?: IPlatform;
}

function Links(props: LinksProps) {
    const labels = i18nManager.Labels.dashboard.platform_management.management.links;
    const auditUiUrl = `${window.location.origin}/dashboard/audit`;
    const financeUrl = `${window.location.origin}/dashboard/finance`;
    const debugUrl = `${window.location.origin}/dashboard/service-debugging`;
    const editPlatformUrl = `${window.location.origin}/dashboard/administration/platforms`;
    const logUiUrl = process.env.REACT_APP_LOG_UI_URL ?? "http://172.20.10.4:3002/dashboard";

    return <div className="content management-links">
        {props.loading
            ? (<i className="fa fa-spinner fa-spin" />)
            : (<React.Fragment>
                <a target="__blank" href={props.platform?.bacuator_data?.location}>{labels.visit}</a>
                <a target="__blank" href={`${logUiUrl}?group=${props.platform?.platform_id}`}>{labels.view_service_logs}</a>
                <NavLink to={`${auditUiUrl}?platform_id=${props.platform?.platform_id}`}>{labels.view_audit_logs}</NavLink>
                <NavLink to={`${editPlatformUrl}?active_platform_id=${props.platform?.platform_id}&edit=true`}>{labels.edit_platform}</NavLink>
                <NavLink to={`${financeUrl}?platform_id=${props.platform?.platform_id}`}>{labels.view_financial_trends}</NavLink>
                <NavLink to={`${debugUrl}?platform_id=${props.platform?.platform_id}`}>{labels.debug_service}</NavLink>
            </React.Fragment>)}
    </div>;
}

export default Links;
