
import React from "react";
import { Schemes, Utils } from "../../../utils";
import { useSearchParams } from "react-router-dom";
import { CSSTransition } from 'react-transition-group';
import { FormControls, i18nManager } from "../../../i18n";
import { ConfirmDialog, Elements, Siren } from "../../shared";
import ManageUser, { ManageUserFragment } from "./ManageUser";
import { ApiResponseData, BaseService, IUser, SanitizedResponse, UserService, UserStatus } from "../../../services";
import { Button, ButtonManageRef, Classname, Column, DataManageRef, MessageSchemesIcons, MessageSpinnerIcons, NoseurObject, Popover, PopoverManageRef, Scheme, Table, Toaster, Transition } from "@ronuse/noseur";

function Users() {
    let processing = false;
    const userService = UserService.getInstance();
    const tableManageRef = React.useRef<DataManageRef>(null);
    const dialogErrorRef = React.useRef<HTMLDivElement>(null);
    const [activeUser, setActiveUser] = React.useState<IUser>();
    const [showFilters, setShowFilters] = React.useState<boolean>();
    const userPopoverManageRef = React.useRef<PopoverManageRef>(null);
    const [fragment, setFragment] = React.useState<ManageUserFragment>();
    const [users, setUsers] = React.useState<ApiResponseData<IUser>>({});
    const labels = i18nManager.Labels.dashboard.users_and_businesses.users;
    const [searchParams, setSearchParams] = useSearchParams(window.location.search);

    React.useEffect(() => {
        if (!activeUser && !searchParams.has("active_user_id")) {
            fetchUsers();
        } else {
            fetchActiveUser(searchParams.get("active_user_id") ?? "");
        }
    }, []);
    React.useEffect(() => {
        if (window.location.search.includes("active_user_id")) {
            if (!activeUser) {
                fetchActiveUser(searchParams.get("active_user_id") ?? "");
            }
            return;
        }
        searchParams.delete("active_user_id");
        setActiveUser(undefined);
        if (!users.content) fetchUsers();
    }, [searchParams]);

    return (<div className="charon-messages">
        <div className="management">
            <div className="controls">
                <div>
                    <Button scheme={Schemes.RIVTN_QUIRINUS} text={labels.filter} leftIcon="fa fa-filter" style={{ marginLeft: 0 }} raised outlined={!showFilters}
                        onClick={() => setShowFilters(!showFilters)} />
                </div>
            </div>
            <CSSTransition classNames={Transition.CURTAIN} timeout={500} in={showFilters} unmountOnExit>
                <div className="filters">
                    {labels.filters.form?.map((control: FormControls, index: number) => {
                        return (<div key={index} className={Classname.build("filter", (control.type === "checkbox" ? "ckb" : null))}>
                            <span>{control.title}</span>
                            {Elements.buildConfirmDialogForm([control], (_, __) => {
                            }, searchParams, undefined, { marginTop: 0 }, undefined, { marginTop: 7 }, setSearchParams, undefined, undefined, { platform_id: "users_platform_id" })}
                        </div>);
                    })}
                    <Button scheme={Schemes.RIVTN_QUIRINUS} text={labels.filters.reset_filter} outlined onClick={() => {
                        labels.filters.form.map((control: FormControls) => {
                            Utils.updateSearchParams((control.name === "platform_id" ? "users_platform_id" : control.name), undefined, setSearchParams);
                        });
                    }} />
                    <Button scheme={Schemes.RIVTN_QUIRINUS} text={labels.filters.apply} style={{ padding: "10px 40px" }} onClick={fetchUsers} />
                </div>
            </CSSTransition>
            <Table className="table" data={users.content} rowsPerPage={users.size ?? 10} totalRecords={users.total_elements} dataRefreshKeys={["id", "status"]} manageRef={tableManageRef} loadingState={Elements.LOADING_STATE}>
                <Column dataKey="email" header={labels.table.email} />
                <Column dataKey="first_name" header={labels.table.first_name} />
                <Column dataKey="last_name" header={labels.table.last_name} />
                <Column dataKey="dob" header={labels.table.dob} template={(dob: string) => Utils.formatToFineDate4(dob)} />
                <Column dataKey="gender" header={labels.table.gender} />
                <Column dataKey="email_verified" header={labels.table.email_verified} template={(emailVerified: boolean) => labels.table.verified[`${emailVerified}`]} />
                <Column dataKey="status" header={labels.table.status} template={selectStyledStatus} />
                <Column template={(user: IUser) => (<React.Fragment>
                    <i className="fa fa-ellipsis-h" style={{ margin: "5px 10px", cursor: "pointer" }}
                        onClick={(e) => {
                            setActiveUser(user);
                            setFragment(undefined);
                            userPopoverManageRef.current?.show(e as any);
                        }} />
                </React.Fragment>)} />
            </Table>
            {Elements.buildPaginator(users ?? {}, ({ currentPage }, size) => Utils.updateSearchParamses({ "page": currentPage, id: null, size }, setSearchParams, fetchUsers), parseInt(searchParams.get("page") ?? "1"))}
        </div>

        {activeUser && fragment ? <ManageUser user={activeUser} fragment={fragment} sendPasswordReset={sendPasswordResetEmail}/> : null}

        <Popover className="dashboard-popover" manageRef={userPopoverManageRef} style={{ padding: "10px 0px" }} dismissOnClick>
            <span className="popover-link" onClick={() => manageUser(ManageUserFragment.PROFILE)}>{labels.table.popover.update_profile}</span>
            <span className="popover-link" onClick={() => manageUser(ManageUserFragment.SESSIONS)}>{labels.table.popover.view_sessions}</span>
            <span className="popover-link" onClick={() => sendPasswordResetEmail()}>{labels.table.popover.send_password_reset_email}</span>
            <span className={Classname.build("popover-link", (activeUser?.status === UserStatus.DEACTIVATED ? "g" : "r"))} onClick={() => activateDeactivateUser()}>{activeUser?.status === UserStatus.DEACTIVATED ? labels.table.popover.activate : labels.table.popover.deactivate}</span>
            <span className="popover-link" onClick={() => { }}>{labels.table.popover.audit_logs}</span>
        </Popover>
    </div>);

    function fetchUsers() {
        if (processing) return; processing = true;
        if (searchParams.has("active_user_id")) return;
        tableManageRef.current?.setLoadingState(true);
        userService.queryUsers(Utils.normalizeUrlParams(searchParams, { platform_id: "users_platform_id" }, undefined, { users_platform_id: "platform_id" }), true).then(({ apiResponse }: SanitizedResponse<IUser>) => {
            processing = false;
            setUsers(apiResponse.data!);
        }).catch(BaseService.reportError).finally(() => {
            processing = false;
            tableManageRef.current?.setLoadingState(false);
        });
    }

    function fetchActiveUser(id: string) {
        if (processing) return; processing = true;
        const fragment = (searchParams.get("fragment")) as ManageUserFragment;
        if (!fragment) {
            processing = false;
            Utils.updateSearchParams("active_user_id", undefined, setSearchParams, fetchUsers);
            return;
        }
        userService.getSingleUser(id).then(({ apiResponse }: SanitizedResponse<IUser>) => {
            processing = false;
            setActiveUser(apiResponse.data as any as IUser);
            setFragment(fragment);
        }).catch((err) => {
            BaseService.reportError(err);
            Utils.updateSearchParams("active_user_id", undefined, setSearchParams, fetchUsers);
        }).finally(() => processing = false);
    }

    function selectStyledStatus(status: string, style: any = {}) {
        if (status === UserStatus.DEACTIVATED) return (<span className="pill" style={{ "--backgroundColor": "#EB5959", ...style } as any}>{UserStatus.DEACTIVATED}</span>);
        return (<span className="pill" style={{ "--backgroundColor": "#0FA883", ...style } as any}>{UserStatus.ACTIVE}</span>);
    }

    function manageUser(section: ManageUserFragment) {
        if (!activeUser) return;
        Utils.updateSearchParams("active_user_id", activeUser.external_id, setSearchParams, () => setFragment(section));
    }

    function sendPasswordResetEmail(onDone?: () => void) {
        if (!activeUser) return;
        Toaster.toast({
            sticky: true,
            foreScheme: true,
            showProgressbar: true,
            scheme: Scheme.WARNING,
            pauseDelayOnHover: true,
            style: { background: "white" },
            icon: MessageSpinnerIcons.NOTCH,
            content: labels.password_reset_processing.replace("${email}", activeUser.email),
        }, "send-password-email");
        userService.sendPasswordResetEmail(activeUser.external_id).then((_: SanitizedResponse<IUser>) => {
            Toaster.update("send-password-email", {
                sticky: false,
                scheme: Scheme.SUCCESS,
                icon: MessageSchemesIcons.SUCCESS,
                content: labels.password_reset_success.replace("${email}", activeUser.email),
            });
        }).catch((err) => {
            Toaster.update("send-password-email", {
                sticky: false,
                scheme: Scheme.DANGER,
                icon: MessageSchemesIcons.INFO,
                content: labels.password_reset_fail.replace("${email}", activeUser.email),
            });
            BaseService.reportError(err);
        }).finally(onDone);
    }

    function activateDeactivateUser() {
        if (!activeUser) return;
        const activate = activeUser.status === UserStatus.DEACTIVATED;
        const dialogLabel = labels.dialogs[activate ? "activate" : "deactivate"];
        const updateUserStatusPayload: NoseurObject<string> = {
            status: (activate ? UserStatus.ACTIVE : UserStatus.DEACTIVATED)
        };
        const dialog = ConfirmDialog({
            closable: true,
            title: dialogLabel.title,
            confirmLabel: dialogLabel.confirm_label,
            confirmScheme: !activate ? Scheme.DANGER : undefined,
            desc: dialogLabel.desc.replace("${email}", activeUser.email),
            content: (<div style={{ marginTop: 10, marginBottom: 10 }}>
                <div style={{ marginTop: 15 }}>
                    {Elements.buildConfirmDialogForm(dialogLabel.form, (name: string, value: string) => {
                        updateUserStatusPayload[name] = value;
                    })}
                </div>
                <div ref={dialogErrorRef} className="error"> </div>
            </div>),
            onConfirm: (button?: ButtonManageRef) => {
                let errorMessage;
                dialogErrorRef.current!.innerText = " ";
                if (!updateUserStatusPayload.reason) errorMessage = labels.reason_required;
                if (!updateUserStatusPayload.code) errorMessage = labels.auth_code_required;
                if (errorMessage) {
                    dialogErrorRef.current!.innerText = errorMessage;
                    return false;
                }
                button?.setLoadingState(true);
                userService.updateUserStatus(activeUser.external_id, updateUserStatusPayload).then((_: SanitizedResponse<IUser>) => {
                    dialog.hide(fetchUsers);
                    Siren.alert(dialogLabel.success);
                }).catch(({ errorMessage }: SanitizedResponse<any>) => {
                    dialogErrorRef.current!.innerText = errorMessage;
                }).finally(() => {
                    button?.setLoadingState(false);
                });
                return false;
            },
        });
        dialog.show();
    }

}

export default Users;
