
import React from "react";
import { Elements } from "../shared";
import Account from "./account/Account";
import Security from "./security/Security";
import Messaging from "./messaging/Messaging";
import Audit from "./platform_management/audit/Audit";
import Administration from "./administration/Administration";
import { DashboardPopoverItem, i18nManager } from "../../i18n";
import { Dropdown, ObjectHelper, Popover } from "@ronuse/noseur";
import Management from "./platform_management/management/Management";
import { CacheManager, Schemes, Subscriber, Utils } from "../../utils";
import UsersAndBusinesses from "./users_and_businesses/UsersAndBusinesses";
import ServiceDebugging from "./platform_management/service_debugging/ServiceDebugging";
import { useNavigate, NavLink, Routes, Route, Navigate, useSearchParams } from "react-router-dom";
import { AccountService, PlatformService, IProfile, SanitizedResponse, IPlatform, ApiResponseData, ISecondaryData, BaseService } from "../../services";

function Dashboard() {
    const navigate = useNavigate();
    const labels = i18nManager.Labels.dashboard;
    const accountService = AccountService.getInstance();
    const profilePopOverManageRef = React.useRef<any>();
    const platformService = PlatformService.getInstance();
    const profileToggleDiv = React.useRef<HTMLDivElement>(null);
    const [sidebarVisible, setSidebarVisible] = React.useState<boolean>(true);
    const [searchParams, setSearchParams] = useSearchParams(window.location.search);
    const [platforms, setPlatforms] = React.useState<ApiResponseData<IPlatform>>({});
    const [secondaryData, setSecondaryData] = React.useState<ISecondaryData>({ unreads: {} });
    const [profile, setProfile] = React.useState<IProfile>(CacheManager.get(CacheManager.PROFILE_KEY));
    const searchString = Utils.urlParamsToSearch(searchParams, ["platform_id", "platform_page", "include_column_names"]);

    React.useEffect(() => {
        if (!profile) return navigate("/");
        if (!profile.first_name) {
            accountService.getProfile().then(({ sanitized }: any) => setProfile(sanitized)).catch(BaseService.reportError);
        }
        platformService.queryPlatforms().then(({ apiResponse }: SanitizedResponse<IPlatform>) => {
            setPlatforms(apiResponse.data!);
            if (!searchParams.get("platform_id") && apiResponse.data?.content?.length) {
                Utils.updateSearchParams("platform_id", apiResponse.data.content[0].platform_id, setSearchParams);
            }
        }).catch(BaseService.reportError);

        Subscriber.subscribe(Subscriber.KEYS.SECONDARY_DATA, (data) => {
            secondaryData.unreads = ObjectHelper.merge(secondaryData.unreads, data.unreads);
            setSecondaryData(secondaryData);
        });
        Subscriber.subscribe(Subscriber.KEYS.TOGGLE_SIDEBAR_VISIBILITY, (visible: boolean) => {
            setSidebarVisible(visible);
        });
        return () => {
            Subscriber.clearSubscriptions(Subscriber.KEYS.TOGGLE_SIDEBAR_VISIBILITY);
            Subscriber.unSubscribe(Subscriber.KEYS.SECONDARY_DATA, setSecondaryData);
        };
    }, []);

    // TODO: use the long loader in the platform dropdown to load next page, store page in search params
    return (<div className="dashboard">
        {sidebarVisible ? (<div className="sidebar">
            <div className="top">
                <div className="header">
                    <img className="i" src={i18nManager.Labels.logo} alt={i18nManager.Labels.display_name} />
                    <span className="t">{i18nManager.Labels.display_name}</span>
                </div>
                <div className="primary-menu">
                    {labels.primary_menu.map((menu) => (<NavLink key={menu.slug} to={{ pathname: menu.location, search: Utils.urlParamsToSearch(searchParams, ["platform_id", "platform_page"]) }}>
                        {Elements.DASHBOARD_MENU_ICONS_MAP[menu.slug]}
                        <span>{menu.label}</span>
                    </NavLink>))}
                </div>
                <div className="secondary-menu">
                    <Dropdown scheme={Schemes.RIVTN_QUIRINUS} options={platforms.content} optionMap={{
                        label: "{name}",
                        value: "{platform_id}",
                        icon: "{bacuator_data.icon_location}",
                    }} dontMatchTargetSize selectedOptionIndex={Math.max(platforms.content?.findIndex((value) => value.platform_id === searchParams.get("platform_id")) ?? -1, 0)}
                        onSelectOption={(platform: IPlatform) => {
                            Utils.updateSearchParams("platform_id", platform.platform_id, setSearchParams);
                            return true;
                        }} editable />
                    {labels.secondary_menu.map((menu) => (<NavLink key={menu.slug} to={{ pathname: menu.location, search: Utils.urlParamsToSearch(searchParams, ["platform_id", "platform_page"]) }}>
                        {Elements.DASHBOARD_MENU_ICONS_MAP[menu.slug]}
                        <span>{menu.label}</span>
                    </NavLink>))}
                </div>
            </div>
            <div className="bottom">
                <div className="com rivtn-quirinus-bd-3px-bx-sw-fc"
                    tabIndex={1} ref={profileToggleDiv} onClick={(e) => profilePopOverManageRef.current?.toggle(e, profileToggleDiv.current)}>
                    <img className="i" src={profile.profile_picture_location || i18nManager.Labels.logo} alt={profile.first_name} />
                    <div className="con">
                        <span className="t">{profile.first_name} {profile.last_name}</span>
                        <span className="d">{labels.rivtn_admin}</span>
                    </div>
                    <i className="fa fa-ellipsis" />
                </div>
            </div>
        </div>) : null}
        <Routes>
            <Route path="/account/*" element={<Account profile={profile} secondaryData={secondaryData} />} />
            <Route path="/security/*" element={<Security profile={profile} secondaryData={secondaryData} search={searchString} />} />
            <Route path="/messaging/*" element={<Messaging profile={profile} secondaryData={secondaryData} search={searchString} />} />
            <Route path="/administration/*" element={<Administration profile={profile} secondaryData={secondaryData} search={searchString} />} />
            <Route path="/users-and-businesses/*" element={<UsersAndBusinesses profile={profile} secondaryData={secondaryData} search={searchString} />} />
            <Route path="/audit/*" element={<Audit platform={platforms.content?.find((value) => value.platform_id === searchParams.get("platform_id"))} search={searchString} />} />
            <Route path="/management/*" element={<Management platform={platforms.content?.find((value) => value.platform_id === searchParams.get("platform_id"))} search={searchString} />} />
            <Route path="/service-debugging/*" element={<ServiceDebugging platform={platforms.content?.find((value) => value.platform_id === searchParams.get("platform_id"))} search={searchString} />} />
            <Route path="/account" element={<Navigate to={{ pathname: "/dashboard/account/profile", search: searchString }} />} />
            <Route path="/management" element={<Navigate to={{ pathname: "/dashboard/management/links", search: searchString }} />} />
            <Route path="/messaging" element={<Navigate to={{ pathname: "/dashboard/messaging/messages", search: searchString }} />} />
            <Route path="/security" element={<Navigate to={{ pathname: "/dashboard/security/suspicious-ips", search: searchString }} />} />
            <Route path="/administration" element={<Navigate to={{ pathname: "/dashboard/administration/management", search: searchString }} />} />
            <Route path="/users-and-businesses" element={<Navigate to={{ pathname: "/dashboard/users-and-businesses/users", search: searchString }} />} />
            <Route path="/service-debugging" element={<Navigate to={{ pathname: "/dashboard/service-debugging/check-health", search: searchString }} />} />
        </Routes>

        <Popover className="dashboard-popover" manageRef={profilePopOverManageRef} pointingArrowClassName="" dismissOnClick>
            {Object.keys(labels.account_popover).map((key: string, index: number) => {
                const items = labels.account_popover[key];
                return (<div key={index} className={key} style={key === "accounts" ? { paddingBottom: 8 } : { borderTop: "1px solid #F0F0F0", marginTop: 8, paddingTop: 13, paddingBottom: 8 }}>
                    {items.map((item: DashboardPopoverItem, sindex: number) => (<NavLink key={sindex} to={{ pathname: item.location, search: Utils.urlParamsToSearch(searchParams, ["platform_id", "platform_page"]) }}>
                        {item.label} {item.badge_key && secondaryData.unreads[item.badge_key] > 0 ? <span className="badge">{secondaryData.unreads[item.badge_key]}</span> : null}
                    </NavLink>))}
                </div>);
            })}
        </Popover>
    </div>);
}

export default Dashboard;
