
import { FormControls, i18nManager } from "../i18n";
import { SetURLSearchParams } from "react-router-dom";
import { Checkbox, ComposedPassword, DateHelper, DateInput, DateTimeInput, DateTimePickerEvent, DateTimePickerLayout, DateTimePickerSelectionMode, Dropdown, EmailInput, NoseurObject, NumberInput, Scheme, TextAreaInput, TextInput, DropdownManageRef, Position } from "@ronuse/noseur";

export type URLSearchParamsValue = string | string[] | number | number[] | undefined | null;

export const Utils = {

    __formatedDateMap: null,
    __timeKeeper: i18nManager.Labels.time_keeping,

    randomNumber(min: number = 1, max: number = 1000) {
        return Math.floor(Math.random() * (max - min + 1)) + min;
    },

    formatedDate(): { year: string } {
        if (!Utils.__formatedDateMap) {
            Utils.__formatedDateMap = {
                year: "2023"
            } as any;
        }
        return Utils.__formatedDateMap as any;
    },

    dateIsToday(date: Date) {
        return (new Date(date.valueOf()).setHours(0, 0, 0, 0) === new Date().setHours(0, 0, 0, 0));
    },

    dateIsYesterday(date: Date) {
        const yesterDate = new Date();
        yesterDate.setDate(yesterDate.getDate() - 1);
        return (new Date(date.valueOf()).setHours(0, 0, 0, 0) === yesterDate.setHours(0, 0, 0, 0));
    },

    dateToFineValue(_date: Date) {
        const date = new Date(_date.valueOf());
        const seconds = Math.floor(((new Date() as any) - (date as any)) / 1000);
        var interval = seconds / 31536000;

        if (interval > 1) return Math.floor(interval) + ` ${Utils.__timeKeeper.years} ${Utils.__timeKeeper.ago}`;
        interval = seconds / 2592000;
        if (interval > 1) return Math.floor(interval) + ` ${Utils.__timeKeeper.months} ${Utils.__timeKeeper.ago}`;
        interval = seconds / 86400;
        if (interval > 1) return Math.floor(interval) + ` ${Utils.__timeKeeper.days} ${Utils.__timeKeeper.ago}`;
        interval = seconds / 3600;
        if (interval > 1) return Math.floor(interval) + ` ${Utils.__timeKeeper.hours} ${Utils.__timeKeeper.ago}`;
        interval = seconds / 60;
        if (interval > 1) return Math.floor(interval) + ` ${Utils.__timeKeeper.minutes} ${Utils.__timeKeeper.ago}`;
        return Math.floor(seconds) + ` ${Utils.__timeKeeper.seconds} ${Utils.__timeKeeper.ago}`;
    },

    dateDiffInSeconds(startDate: Date, endDate: Date = new Date()) {
        return (endDate.getTime() - startDate.getTime()) / 1000;
    },

    buildSelectNoseurComponentProps({ disabled, title, type, name, placeholder, editable, options, value }: FormControls, urlSearchParams?: URLSearchParams | NoseurObject<any>, setSearchParams?: SetURLSearchParams | URLSearchParams, cb?: Function, queryKeyName?: string, onSearch?: Function, onFormInput?: (name: string, value: any, search?: boolean) => any) {
        if (queryKeyName === undefined) queryKeyName = name;

        let defaultValue: any;
        const isSearchParams = !!urlSearchParams?.get;
        if (type === "date_range") {
            const fromDate = (isSearchParams
                ? defaultValue = urlSearchParams.get(`${queryKeyName!}_from`) ?? value
                : urlSearchParams && (`${queryKeyName!}_from` in urlSearchParams) && !!(urlSearchParams as any)[`${queryKeyName!}_from`] ? (urlSearchParams as any)[`${queryKeyName!}_from`] : value);
            const toDate = (isSearchParams
                ? defaultValue = urlSearchParams.get(`${queryKeyName!}_to`) ?? value
                : urlSearchParams && (`${queryKeyName!}_to` in urlSearchParams) && !!(urlSearchParams as any)[`${queryKeyName!}_to`] ? (urlSearchParams as any)[`${queryKeyName!}_to`] : value);
            defaultValue = [];
            if (!!fromDate) defaultValue.push(new Date(fromDate));
            if (!!toDate) defaultValue.push(new Date(toDate));
        } else {
            defaultValue = (isSearchParams
                ? defaultValue = urlSearchParams.get(queryKeyName) ?? value
                : urlSearchParams && (queryKeyName in urlSearchParams) && !!(urlSearchParams as any)[queryKeyName] ? (urlSearchParams as any)[queryKeyName] : value);
        }

        const inputProps: any = {
            name,
            fill: true,
            placeholder,
            defaultValue,
            noStyle: true,
            borderless: true,
            style: { borderRadius: 0 },
            disabled: (disabled === true),
            onInputComplete: (e: string) => {
                onSearch && onSearch();
                onFormInput && onFormInput(queryKeyName!, e);
                if (!setSearchParams) return;
                Utils.updateSearchParams(queryKeyName!, e || undefined, setSearchParams, cb);
            },
        };
        if (type === "date" || type === "datetime" || type === "date_range") {
            inputProps.highlightToday = inputProps.highlightDatesInRange = true;
            if (type === "datetime") inputProps.disableToDate = DateHelper.addDays(new Date(), -1);
            if (type === "date_range") {
                inputProps.sticky = true;
                inputProps.selectionMode = DateTimePickerSelectionMode.RANGE;
                inputProps.leftLayout = DateTimePickerLayout.FINE_LEFT_LAYOUT;
                inputProps.selectedDates = defaultValue;
            } else {
                inputProps.selectedDates = defaultValue ? [(typeof defaultValue === "string" ? new Date(defaultValue) : defaultValue)] : undefined;
            }
            inputProps.onSelectDate = (options: DateTimePickerEvent) => {
                onSearch && onSearch();
                onFormInput && onFormInput(queryKeyName!, options);
                if (!setSearchParams) return;
                if (type === "date_range") {
                    Utils.updateSearchParams(`${queryKeyName!}_to`, (options.toDate ? Utils.formatToApiDate(options.toDate) : undefined), setSearchParams, cb);
                    Utils.updateSearchParams(`${queryKeyName!}_from`, (options.fromDate ? Utils.formatToApiDate(options.fromDate) : undefined), setSearchParams, cb);
                    return;
                }
                Utils.updateSearchParams(queryKeyName!, `${options.selectedDate}` ?? undefined, setSearchParams, cb);
            };
            inputProps.onClear = () => {
                if (!setSearchParams) return;
                if (type === "date_range") {
                    Utils.updateSearchParams(`${queryKeyName!}_to`, undefined, setSearchParams, cb);
                    Utils.updateSearchParams(`${queryKeyName!}_from`, undefined, setSearchParams, cb);
                    return;
                }
                Utils.updateSearchParams(queryKeyName!, undefined, setSearchParams, cb);
            };
        }
        if (type === "dropdown") {
            inputProps.options = options;
            inputProps.editable = editable;
            inputProps.onInputComplete = undefined;
            inputProps.togglePosition = Position.LEFT;
            if (editable) {
                inputProps.cleareable = true;
                inputProps.onDeSelectOption = (_: any) => {
                    if (!setSearchParams) return;
                    Utils.updateSearchParams(queryKeyName!, undefined, setSearchParams, cb);
                };
                inputProps.onSearch = (e: any, dropdownManageRef?: DropdownManageRef) => {
                    onSearch && onSearch();
                    onFormInput && onFormInput(queryKeyName!, { manageRef: dropdownManageRef, value: e.target.value }, true);
                    dropdownManageRef?.showDropDown(e);
                };
            }
            inputProps.onSelectOption = (option: any) => {
                onSearch && onSearch();
                if (onFormInput && onFormInput(queryKeyName!, option.value) === false) {
                    return false;
                }
                if (setSearchParams) Utils.updateSearchParams(queryKeyName!, option.value || undefined, setSearchParams, cb);
                return true;
            };
            inputProps.selectedOptionIndex = options?.findIndex(c => c.value === defaultValue || c.label === defaultValue);
            if (inputProps.selectedOptionIndex === -1 || inputProps.selectedOptionIndex === undefined) inputProps.defaultInputValue = defaultValue;
        }
        if (type === "checkbox") {
            delete inputProps.onInputComplete;
            inputProps.label = title ?? placeholder;
            inputProps.scheme = Schemes.RIVTN_QUIRINUS;
            inputProps.checked = defaultValue === true;
            inputProps.onChange = (e: any) => {
                onSearch && onSearch();
                onFormInput && onFormInput(queryKeyName!, e.checked);
            }
        }
        return inputProps;
    },

    selectNoseurComponent(name?: string) {
        if (name === "email") return EmailInput;
        if (name === "checkbox") return Checkbox;
        if (name === "dropdown") return Dropdown;
        if (name === "phone") return NumberInput;
        if (name === "number") return NumberInput;
        if (name === "datetime") return DateTimeInput;
        if (name === "textarea") return TextAreaInput;
        if (name?.startsWith("date")) return DateInput;
        if (name === "password") return ComposedPassword;
        return TextInput;
    },

    //March 21st 2023 04:91 PM WAT
    formatToFineDate(dateStr: string) {
        const date = new Date(dateStr);
        const month = date.toLocaleString('default', { month: 'long' });
        const day = date.toLocaleString('default', { day: '2-digit' }).padStart(2, "0");
        const time = date.toLocaleTimeString('default', { hour12: true, hour: '2-digit', minute: '2-digit', timeZoneName: 'short' });
        return `${month} ${day} ${date.getFullYear()} ${time}`;
    },

    //Saturday April 8th, 2023
    formatToFineDate2(dateStr: string) {
        const date = new Date(dateStr);
        const month = date.toLocaleString('default', { month: 'long' });
        const weekday = date.toLocaleString('default', { weekday: 'long' });
        const day = date.toLocaleString('default', { day: '2-digit' }).padStart(2, "0");
        return `${weekday} ${month} ${day}, ${date.getFullYear()}`;
    },

    //Saturday April 8th, 2023
    formatToFineDate3(dateStr: string) {
        const date = new Date(dateStr);
        const month = date.toLocaleString('default', { month: 'short' });
        const day = date.toLocaleString('default', { day: '2-digit' }).padStart(2, "0");
        const time = date.toLocaleTimeString('default', { hour12: true, hour: '2-digit', minute: '2-digit', timeZoneName: 'short' });
        return `${day} ${month} ${date.getFullYear()} | ${time}`;
    },

    //April 8th, 2023
    formatToFineDate4(dateStr: string) {
        if (!dateStr) return "";
        const date = new Date(dateStr);
        const month = date.toLocaleString('default', { month: 'long' });
        return `${month} ${date.toLocaleString('default', { day: 'numeric' })} ${date.getFullYear()}`;
    },

    formatToFineDateOnly(dateStr: string) {
        const date = new Date(dateStr);
        return date.toLocaleString('en', { month: "long", day: "2-digit", year: "numeric" });
    },

    formatToFineTime(dateStr: string) {
        const date = new Date(dateStr);
        return date.toLocaleString('en', { hour: "2-digit", minute: "2-digit", hour12: true });
    },

    formatToFineDay(dateStr: string) {
        const date = new Date(dateStr);
        if (Utils.dateIsToday(date)) return Utils.dateToFineValue(date);
        if (Utils.dateIsYesterday(date)) return date.toLocaleString('en', { weekday: 'short' });
        return date.toLocaleString('en', { dateStyle: 'short' });
    },

    dateToUTCDate(date: Date) {
        return new Date(date.getUTCFullYear(),
            date.getUTCMonth(),
            date.getUTCDate(),
            date.getUTCHours(),
            date.getUTCMinutes(),
            date.getUTCSeconds());
    },

    formatToApiDate(date: Date, noTime: boolean = false) {
        if (!date) return;
        date = Utils.dateToUTCDate(date);
        const year = date.getFullYear();
        const day = ("" + date.getDate()).padStart(2, "0");
        const month = ("" + (date.getMonth() + 1)).padStart(2, "0");
        if (noTime) return `${year}-${month}-${day}`;
        const hours = ("" + date.getHours()).padStart(2, "0");
        const minutes = ("" + date.getMinutes()).padStart(2, "0");
        const seconds = ("" + date.getSeconds()).padStart(2, "0");
        return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    },

    updateSearchParams(key: string, value: URLSearchParamsValue, setSearchParams: SetURLSearchParams | URLSearchParams, cb?: Function | undefined) {
        const fun = (prev: URLSearchParams) => {
            if (value === undefined || value === null) {
                prev.delete(key);
            } else if (Array.isArray(value)) {
                prev.delete(key);
                for (const v of value) prev.append(key, `${v}`);
            } else {
                prev.set(key, `${value}`);
            }
            return prev;
        };
        if (setSearchParams instanceof URLSearchParams) fun(setSearchParams);
        else setSearchParams(fun);
        cb && cb();
    },

    updateSearchParamses(values: NoseurObject<URLSearchParamsValue>, setSearchParams: SetURLSearchParams | URLSearchParams, cb?: () => void) {
        const keys = Object.keys(values);
        let reportedValueSet = 1;
        const valuesLength = keys.length;
        let __cb__: Function;
        if (cb) __cb__ = () => {
            if (reportedValueSet === valuesLength) return cb();
            reportedValueSet++;
        }
        keys.forEach((key: string) => Utils.updateSearchParams(key, values[key], setSearchParams, __cb__));
    },

    normalizeUrlParams(urlSearchParams: URLSearchParams, keyLookupMap: NoseurObject<string> = {}, extraParams: NoseurObject<string | number> = {},
        reverseKeyLookupMap: NoseurObject<string> = {}, only?: string[], exclude?: string[]) {
        const params: NoseurObject<any> = {};
        Object.keys(Object.fromEntries(urlSearchParams)).forEach((key: string) => {
            const lookupKey = keyLookupMap[key] ?? key;
            if (only && !only.includes(lookupKey)) return;
            if (exclude && exclude.includes(lookupKey)) return;
            const values = urlSearchParams.getAll(reverseKeyLookupMap[lookupKey] ? lookupKey : key);
            if (values.length) {
                params[reverseKeyLookupMap[lookupKey] ?? lookupKey] = values.length > 1 ? values : values[0];
            }
        });
        Object.keys(extraParams).forEach((key) => params[key] = extraParams[key])
        return params;
    },

    urlParamsToSearch(urlSearchParams: URLSearchParams, only?: string[], includes?: NoseurObject<URLSearchParamsValue>) {
        only && only.length && Object.keys(Object.fromEntries(urlSearchParams)).forEach((key: string) => {
            if (!only.includes(key)) urlSearchParams.delete(key);
        });
        if (includes) {
            Object.keys(includes).forEach((key: string) => Utils.updateSearchParams(key, includes[key], urlSearchParams));
        }
        return urlSearchParams.toString();
    },

    removeObjectFromArray<T>(arr: T[], obj: T | number, comparator?: (a: T, b: T) => boolean) {
        if (typeof obj === "number") {
            if (obj > -1) arr.splice(obj, 1);
            return;
        }
        const index = arr.findIndex(a => comparator && comparator(a, obj));
        if (index > -1) arr.splice(index, 1);
    },

    toSaneSentenceFormat(value: string, ignore?: boolean) {
        if (ignore || !value) return value;
        return value.split("_").map(e => e[0].toUpperCase() + e.substring(1).toLowerCase()).join(" ");
    },

    reverseMapOfString(mapOfmap: NoseurObject<NoseurObject<string>>) {
        return Object.keys(mapOfmap).reduce((acc: any, key) => {
            const lookupMap = mapOfmap[key], newLookupMap: any = {};
            Object.keys(lookupMap).forEach((entry) => newLookupMap[lookupMap[entry]] = entry);
            acc[key] = newLookupMap;
            return acc;
        }, {});
    },

    joinUrlWithBase(senior: string, sibling: string) {
        let prefix = "";

        if (!sibling || sibling.startsWith("http")) return prefix + sibling;
        const padding = sibling.startsWith("/") ? 0 : 1;
        if (senior.startsWith("https://")) prefix = senior.substring(0, senior.indexOf("/", 8) + padding);
        else if (senior.startsWith("http://")) prefix = senior.substring(0, senior.indexOf("/", 7) + padding);
        else prefix = senior.substring(0, senior.indexOf("/") + padding);
        return prefix + sibling;
    },

    hasNoValidValue(value: any, extraCheck: any = "") {
        return (value === undefined || value === null || Number.isNaN(value) || value === extraCheck);
    },

    fileToBas64: (file: File) => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = reject;
    }),

    downloadBlob(content: string, filename: string, contentType: string = "text/csv;charset=utf-8;") {
        const blob = new Blob([content], { type: contentType });
        const url = URL.createObjectURL(blob);
        const pom = document.createElement('a');
        pom.href = url;
        pom.setAttribute('download', filename);
        pom.click();
    },

    copyToClipboard(text: string, cb: (err?: any) => void) {
        if (navigator.clipboard) {
            navigator.clipboard.writeText(text).then(() => cb(), (err: any) => cb(err));
            return;
        }
        if ((window as any).clipboardData && (window as any).clipboardData.setData) {
            return (window as any).clipboardData.setData("Text", text);

        } else if (document.queryCommandSupported && document.queryCommandSupported("copy")) {
            var textarea = document.createElement("textarea");
            textarea.textContent = text;
            textarea.style.position = "fixed";
            document.body.appendChild(textarea);
            textarea.select();
            try {
                document.execCommand("copy");
                cb();
            } catch (ex) {
                cb(ex);
                return prompt("Copy to clipboard: Ctrl+C, Enter", text);
            } finally {
                document.body.removeChild(textarea);
            }
        }
    },

}

export * from "./Subscriber";
export * from "./Encryptor";
export type { Encryptor } from "./Encryptor";
export { CacheManager } from "./CacheManager";
export const Schemes: {
    RIVTN_QUIRINUS: Scheme;
} = {
    RIVTN_QUIRINUS: "rivtn-quirinus" as Scheme
}

export enum Status {
    ACTIVE = "ACTIVE",
    KICKED = "KICKED",
    LOCKED = "LOCKED",
    EXPIRED = "EXPIRED",
    APPROVED = "APPROVED",
    REJECTED = "REJECTED",
    CANCELLED = "CANCELLED",
    DEACTIVATED = "DEACTIVATED",
    PENDING_APPROVAL = "PENDING_APPROVAL",
}

