
import { NoseurObject } from "@ronuse/noseur";
import { GroupListed, SoteriaBaseService } from "./SoteriaBaseService";

export interface IListedValue {
    value: string;
    updated_at: string;
    external_id: string;
    platforms_blacklisted: NoseurObject<GroupListed>;
    platforms_whitelisted: NoseurObject<GroupListed>;
}

export class ListedValueService extends SoteriaBaseService {

    protected static instance: ListedValueService;

    private constructor() {
        super();
    }

    static getInstance() {
        if (!ListedValueService.instance) ListedValueService.instance = new ListedValueService();
        return ListedValueService.instance;
    }

    queryListedValues(params: NoseurObject<string | number> = { page: 1, size: 10 }, refresh: boolean = false): Promise<any> {
        return this.report(this.transport.get("/soteria/api/v1/listed/value", { params: this.normalizeParams(params) } as any));
    }

    getSingleListedValue(externalId: string): Promise<any> {
        return this.report(this.transport.get(`/soteria/api/v1/listed/value/${externalId!}`, this.config));
    }

    createListedValue(payload: any): Promise<any> {
        return this.report(this.transport.post(`/soteria/api/v1/listed/value`, payload, this.config));
    }

    deleteListedValue(externalId: string): Promise<any> {
        return this.report(this.transport.delete(`/soteria/api/v1/listed/value/${externalId!}`, this.config));
    }

    addListedValuePlatform(payload: any): Promise<any> {
        return this.report(this.transport.put(`/soteria/api/v1/listed/value`, payload, this.config));
    }

    deleteListedValuePlatform(externalId: string, listingType: string, platform: string): Promise<any> {
        return this.report(this.transport.patch(`/soteria/api/v1/listed/value/${externalId!}`, {
            platforms: [ platform ],
            listing_type: listingType,
            reason: "Removed by admin from the UI",
        }, this.config));
    }

}

