
import React from "react";
import ReactDOM from "react-dom/client";
import { Schemes, Utils } from "../../../utils";
import { useSearchParams } from "react-router-dom";
import { ConfirmDialog, Elements } from "../../shared";
import { FormControls, i18nManager } from "../../../i18n";
import { AdministrationService, ApiResponse, BaseService, IAdministrator, IPermission, IRole, IRoleAdministrator, RolePermissionService, SanitizedResponse } from "../../../services";
import { AlertInterface, Button, ButtonManageRef, Checkbox, FormControl, FormGroup, List, NoseurObject, ObjectHelper, Scheme, ViewportSensor, ViewportSensorManageRef } from "@ronuse/noseur";

function RolesPermissions() {
    const dialogErrorRef = React.useRef<HTMLDivElement>(null);
    const [roles, setRoles] = React.useState<ApiResponse<IRole>>();
    const administrationService = AdministrationService.getInstance();
    const rolePermissionService = RolePermissionService.getInstance();
    const activeDialogMutableRoot = React.useRef<ReactDOM.Root>(null);
    const activeDialogMutableRootSec = React.useRef<ReactDOM.Root>(null);
    const administratorsDialogListView = React.useRef<ReactDOM.Root>(null);
    const labels = i18nManager.Labels.dashboard.administration.roles_permissions;
    const [searchParams, setSearchParams] = useSearchParams(window.location.search);
    const [permissions, setPermissions] = React.useState<ApiResponse<IPermission>>();
    const pageNumerRef = React.useRef<NoseurObject<number>>({ ROLES: parseInt(searchParams.get("role_page") ?? "1"), PERMISSIONS: 1 });
    const queryParameterKeyLookupMap: NoseurObject<NoseurObject<string>> = {
        ROLES: {
            page: "role_page",
            name: "role_name",
            permissions: "role_permissions",

        },
        PERMISSIONS: {
            value: "permission_value",
        }
    };
    const queryParameterKeyLookupReverseMap: NoseurObject<NoseurObject<string>> = Utils.reverseMapOfString(queryParameterKeyLookupMap);

    React.useEffect(() => {
        fetchRoles();
    }, []);

    return (<div className="roles-permissions">
        <div style={{ display: "flex", flexDirection: "column" }}>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
                <span>{labels.roles.title}</span>
                <div style={{ fontSize: 12 }}>
                    <span className="link" onClick={() => addNewRole()}>{labels.roles.create_role}</span>
                    <span className="link" style={{ marginLeft: 10 }} onClick={manageAdministratorRolesSearch}>{labels.roles.manage_admin_roles}</span>
                </div>
            </div>
            <FormGroup scheme={Schemes.RIVTN_QUIRINUS} className="form" style={{ display: "grid", gridTemplateColumns: "50% 50%", columnGap: 10, rowGap: 10, borderBottom: "1px solid #F0F0F0", paddingBottom: 15 }}>
                {labels.roles.form.map((formControl: FormControls, index: number) => {
                    const queryKeyName = queryParameterKeyLookupMap.ROLES[formControl.name];
                    const inputProps: any = Utils.buildSelectNoseurComponentProps(formControl, searchParams, setSearchParams, () => { pageNumerRef.current.ROLES = 1; fetchRoles(); }, queryKeyName, setRoles);
                    return (<FormControl key={index} leftContent={formControl.icon}
                        validStyle={{ borderColor: "transparent" }} contentStyle={{ borderRadius: 0 }} >
                        {React.createElement(Utils.selectNoseurComponent(formControl.type!) as any, inputProps)}
                    </FormControl>);
                })}
                <Checkbox label={labels.roles.format_value}
                    defaultChecked={searchParams.get("format_permissions_value") === "true"}
                    onChange={(e: any) => Utils.updateSearchParams("format_permissions_value", e.checked ? "true" : undefined, setSearchParams, () => {
                        pageNumerRef.current.ROLES--;
                        fetchRoles();
                    })} />
            </FormGroup>
            <div style={{ flex: 1, marginTop: 15, display: "flex", flexWrap: "wrap", overflow: "auto" }}>
                {!roles?.data?.content || !roles.data?.content?.length ? (<div className="loader" style={{ alignItems: "center", width: "100%" }}>
                    <i className="fa fa-spinner fa-spin" />
                </div>) : null}
                {roles?.data?.content?.map((role, index) => {
                    return (<div className="role" key={index}>
                        <span className="title">{role.name}</span>
                        <div className="permissions">
                            {role.permissions.map((permission, sindex) => (<span key={sindex}>{Utils.toSaneSentenceFormat(permission, searchParams.get("format_permissions_value") !== "true")}</span>))}
                        </div>
                        <div className="controls">
                            <i className="fa fa-users" onClick={() => updateRoleAdministrators(role)} />
                            <i className="fa fa-edit" onClick={() => addNewRole({ ...role, permissions: new Set<string>(role.permissions) })} />
                            <i className="fa fa-trash" onClick={() => deleteRole(role)} />
                        </div>
                    </div>);
                })}
            </div>
            {Elements.buildPaginator(roles?.data || {}, ({ currentPage }, size) => {
                pageNumerRef.current.ROLES = currentPage;
                Utils.updateSearchParams("role_page", currentPage, setSearchParams, () => {
                    Utils.updateSearchParams("role_size", size, setSearchParams, fetchRoles);
                });
            }, pageNumerRef.current.ROLES)}
        </div>
        <div>
            <span>{labels.permissions.title}</span>
            <FormGroup scheme={Schemes.RIVTN_QUIRINUS} className="form">
                {labels.permissions.form.map((formControl: FormControls, index: number) => {
                    const queryKeyName = queryParameterKeyLookupMap.PERMISSIONS[formControl.name];
                    const inputProps: any = Utils.buildSelectNoseurComponentProps(formControl, searchParams, setSearchParams, () => { pageNumerRef.current.PERMISSIONS = 1; fetchPermissions(); }, queryKeyName, setPermissions);
                    return (<FormControl key={index} leftContent={formControl.icon}
                        validStyle={{ borderColor: "transparent" }} contentStyle={{ borderRadius: 0 }} >
                        {React.createElement(Utils.selectNoseurComponent(formControl.type!) as any, inputProps)}
                    </FormControl>);
                })}
                <Button type="button" text={labels.permissions.add_new} style={{ width: "100%", marginTop: 10 }} onClick={addNewPermission} fill />
            </FormGroup>
            <List rowsPerPage={9999999} style={{ marginTop: 10 }} data={permissions?.data?.content} loadingState={Elements.LOADING_STATE}
                emptyState={<div className="es" style={{ marginTop: 20 }}>
                    <i className="fa fa-bullseye" />
                    <span>{labels.permissions.no_permission_found} <span onClick={addNewPermission}>{i18nManager.Labels.common.create}</span> {labels.permissions.a_permission}</span>
                </div>}
                template={(permission: IPermission) => (<div>
                    <i className="del-times fa fa-times" onClick={() => deletePermission(permission)} />
                    <span>{permission.value}</span>
                </div>)} />
            <ViewportSensor className="loader" onEnterViewport={(viewportSensorManageRef?: ViewportSensorManageRef) => {
                const currentPage = (permissions?.data?.pageable?.page_number! + 1);
                if (currentPage === permissions?.data?.total_pages) return false;
                fetchPermissions((apiResponse: ApiResponse<IPermission>) => {
                    viewportSensorManageRef?.unmountChildren();
                    if (apiResponse.data) apiResponse.data.content = [...(permissions?.data?.content || []), ...(apiResponse.data.content || [])];
                });
                return true;
            }} onExitViewport={() => false} preRenderChildren>
                <i className="fa fa-spinner fa-spin" />
            </ViewportSensor>
        </div>
    </div>);

    function fetchRoles(cb?: (apiResponse: ApiResponse<IRole>) => void) {
        rolePermissionService.queryRoles(Utils.normalizeUrlParams(searchParams, queryParameterKeyLookupReverseMap.ROLES, { page: pageNumerRef.current.ROLES }), cb).then(({ apiResponse }: SanitizedResponse<IRole>) => {
            setRoles(apiResponse);
            pageNumerRef.current.ROLES++;
        }).catch(BaseService.reportError);
    }

    function fetchPermissions(cb?: (apiResponse: ApiResponse<IPermission>) => void) {
        rolePermissionService.queryPermissions(Utils.normalizeUrlParams(searchParams, queryParameterKeyLookupReverseMap.PERMISSIONS, { page: pageNumerRef.current.PERMISSIONS }), cb).then(({ apiResponse }: SanitizedResponse<IPermission>) => {
            setPermissions(apiResponse);
            pageNumerRef.current.PERMISSIONS++;
        }).catch(BaseService.reportError);
    }

    function updateRoleDialogSelectedPermissions(role: NoseurObject<any>) {
        if (!role.permissions || !activeDialogMutableRoot.current) return;
        const permissions = [...role.permissions].map((permission: string, index: number) => {
            return (<div key={index} ref={(r: any) => r?.scrollIntoView()} onClick={() => {
                role.permissions.delete(permission);
                updateRoleDialogSelectedPermissions(role);
            }} >
                <i className="fa fa-minus" />
                <span>{Utils.toSaneSentenceFormat(permission, searchParams.get("format_permissions_value") !== "true")}</span>
            </div>);
        });
        activeDialogMutableRoot.current.render(permissions);
    }

    function updateRoleDialogSearchedPermissions(iPermissions: IPermission[] | undefined, role: NoseurObject<any>) {
        if (!iPermissions || !activeDialogMutableRootSec.current) return;
        const permissions = iPermissions?.map((iPermission, index) => {
            return (<div key={index} ref={(r: any) => r?.scrollIntoView()} onClick={() => {
                role.permissions.add(iPermission.value);
                updateRoleDialogSelectedPermissions(role);
            }} >
                <i className="fa fa-plus" />
                <span>{Utils.toSaneSentenceFormat(iPermission.value, searchParams.get("format_permissions_value") !== "true")}</span>
            </div>);
        });
        activeDialogMutableRootSec.current.render(permissions);
    }

    function addNewRole(role: NoseurObject<any> = { permissions: new Set<string>() }) {
        const dialog = ConfirmDialog({
            closable: true,
            style: { maxWidth: 650 },
            ...labels.roles.dialog.add,
            title: (!!role.external_id) ? labels.roles.dialog.add.edit_title : labels.roles.dialog.add.title,
            confirmLabel: (!!role.external_id ? i18nManager.Labels.common.save : i18nManager.Labels.common.continue),
            content: (<div className="add-new-role">
                {Elements.buildConfirmDialogForm(labels.roles.dialog.add.form, (name: string, value: string) => {
                    dialogErrorRef.current!.innerText = " ";
                    role[name] = value;
                }, role)}
                <div className="permissions" style={{ flex: 1, maxHeight: 200 }} ref={(r: any) => {
                    if (!r) return;
                    ObjectHelper.resolveRef(activeDialogMutableRoot, ReactDOM.createRoot(r));
                    updateRoleDialogSelectedPermissions(role);
                }}>
                </div>
                <div ref={dialogErrorRef} className="error"> </div>
            </div>),
            rightContent: (<div style={{ flex: 1 }} className="add-new-role-permission">
                {Elements.buildConfirmDialogForm(labels.roles.dialog.add.search_form, (_: string, value: string) => {
                    dialogErrorRef.current!.innerText = " ";
                    rolePermissionService.queryPermissions({ page: 1, value }).then(({ apiResponse }: SanitizedResponse<IPermission>) => {
                        updateRoleDialogSearchedPermissions(apiResponse?.data?.content, role);
                    }).catch(BaseService.reportError);
                })}
                <div className="permissions" ref={(r: any) => {
                    if (!r) return;
                    ObjectHelper.resolveRef(activeDialogMutableRootSec, ReactDOM.createRoot(r));
                    updateRoleDialogSearchedPermissions(permissions?.data?.content?.slice(0, 10), role);
                }}>
                </div>
            </div>),
            onConfirm: (button?: ButtonManageRef) => {
                let errorMessage;
                dialogErrorRef.current!.innerText = " ";
                if (!role.name) errorMessage = labels.roles.dialog.add.role_name_required;
                if (!role.permissions.size) errorMessage = labels.roles.dialog.add.select_a_permission;
                if (errorMessage) {
                    dialogErrorRef.current!.innerText = errorMessage;
                    return false;
                }
                button?.setLoadingState(true);
                const resourceMethod = ((!!role.external_id) ? rolePermissionService.updateRole : rolePermissionService.createRole).bind(rolePermissionService);
                resourceMethod({ external_id: role.external_id, name: role.name, permissions: [...role.permissions] })
                    .then(({ }: SanitizedResponse<IRole>) => {
                        pageNumerRef.current.ROLES = 1;
                        fetchRoles();
                        dialog.destroy();
                    }).catch(({ errorMessage }: SanitizedResponse<any>) => {
                        dialogErrorRef.current!.innerText = errorMessage;
                    }).finally(() => {
                        button?.setLoadingState(false);
                    });
                return false;
            }
        });
        dialog.show();
    }

    function deleteRole(role: IRole) {
        const dialog = ConfirmDialog({
            confirmScheme: Scheme.DANGER,
            title: labels.roles.dialog.delete.title,
            cancelLabel: i18nManager.Labels.common.cancel,
            desc: labels.roles.dialog.delete.desc.replace("${value}", role.name),
            content: (<div ref={dialogErrorRef} className="error"></div>),
            onConfirm: (button?: ButtonManageRef) => {
                button?.setLoadingState(true);
                rolePermissionService.deleteRole(role.external_id!)
                    .then(({ }: SanitizedResponse<IPermission>) => {
                        pageNumerRef.current.ROLE--;
                        fetchRoles();
                        dialog.destroy();
                    }).catch(({ errorMessage }: SanitizedResponse<any>) => {
                        dialogErrorRef.current!.innerText = errorMessage;
                    }).finally(() => {
                        button?.setLoadingState(false);
                    });
                return false;
            }
        });
        dialog.show();
    }

    function updateRoleAdministrators(role: IRole) {
        const selectedAdministrators: IAdministrator[] = [];
        let immutableAdministratorList: IAdministrator[] = [];
        const dialog = ConfirmDialog({
            closable: true,
            title: labels.roles.dialog.administrators.title.replace("${name}", role.name),
            content: Elements.administratorModifierDialog(labels.roles.dialog.administrators, administrationService, administratorsDialogListView,
                dialogErrorRef, selectedAdministrators as any[], {}),
            onLoading: async (alert: AlertInterface) => {
                rolePermissionService.queryRoleAdministrators({ "role.external_id": role.external_id! }).then(({ apiResponse }: SanitizedResponse<IRoleAdministrator>) => {
                    apiResponse.data?.content?.forEach(a => selectedAdministrators.push(a.administrator));
                    immutableAdministratorList = ObjectHelper.clone(selectedAdministrators);
                    alert.doneLoading();
                }).catch(({ errorMessage }: SanitizedResponse<any>) => {
                    alert.doneLoading(() => {
                        dialogErrorRef.current!.innerText = errorMessage;
                    });
                });
                return false;
            },
            onConfirm: (button?: ButtonManageRef) => {
                dialogErrorRef.current!.innerText = " ";
                button?.setLoadingState(true);
                const revokedAdministrators: IAdministrator[] = [];
                const assignedAdministrators: IAdministrator[] = [];
                for (const administrator of selectedAdministrators) {
                    if (immutableAdministratorList.findIndex(e => administrator.external_id === e.external_id) < 0) {
                        assignedAdministrators.push(administrator);
                        continue;
                    }
                }
                for (const administrator of immutableAdministratorList) {
                    if (selectedAdministrators.findIndex(e => administrator.external_id === e.external_id) < 0) {
                        revokedAdministrators.push(administrator);
                        continue;
                    }
                }
                rolePermissionService.updateRoleAdministrators(role.external_id!, assignedAdministrators.map(a => a.external_id))
                    .then(() => {
                        rolePermissionService.updateRoleAdministrators(role.external_id!, revokedAdministrators.map(a => a.external_id), false)
                            .then(() => {
                                dialog.destroy();
                            }).catch(({ errorMessage }: SanitizedResponse<any>) => {
                                dialogErrorRef.current!.innerText = errorMessage;
                            }).finally(() => {
                                button?.setLoadingState(false);
                            });
                    }).catch(({ errorMessage }: SanitizedResponse<any>) => {
                        dialogErrorRef.current!.innerText = errorMessage;
                    }).finally(() => {
                        button?.setLoadingState(false);
                    });
                return false;
            }
        });
        dialog.show();
    }

    function updateNewPermissionControls(newPermissionsValues: string[]) {
        if (!activeDialogMutableRoot.current) return;
        const newPermissions = newPermissionsValues.map((value: string, index: number) => {
            const formControl = {
                value: value,
                name: `permissions_${index}`,
                placeholder: labels.permissions.dialog.add.text_field_placeholder,
                icon: (<i className="fas fa-circle" style={{ color: "#0FA883", padding: "13.3px", background: "rgba(217, 217, 217, 0.2)" }} />),
            };
            const inputProps: any = Utils.buildSelectNoseurComponentProps(formControl, undefined, undefined, undefined, undefined, undefined, (e: any) => {
                dialogErrorRef.current!.innerText = " ";
                newPermissionsValues[index] = e.target.value;
            });
            inputProps.style = { background: "rgba(217, 217, 217, 0.2)", borderRadius: 0 };
            return (<FormControl key={index} leftContent={formControl.icon} style={{ marginTop: 0, marginBottom: 10, background: "transparent" }}
                validStyle={{ borderColor: "transparent" }} contentStyle={{ borderRadius: 0 }} ref={(r: any) => r?.scrollIntoView()}
                rightContent={<i className="fa fa-trash" style={{ color: "#EB5959", cursor: "pointer" }} onClick={() => {
                    dialogErrorRef.current!.innerText = " ";
                    delete newPermissionsValues[index]; updateNewPermissionControls(newPermissionsValues);
                }} />}>
                {React.createElement(Utils.selectNoseurComponent() as any, inputProps)}
            </FormControl>);
        });
        activeDialogMutableRoot.current.render(newPermissions);
    }

    function addNewPermission() {
        const newPermissions: string[] = [""];
        const dialog = ConfirmDialog({
            closable: true,
            ...labels.permissions.dialog.add,
            content: (<div className="add-new-permission" style={{ marginTop: 15 }}>
                <FormGroup scheme={Schemes.RIVTN_QUIRINUS} style={{ maxHeight: 200, overflow: "auto" }} className="form" ref={(r: any) => {
                    if (!r) return;
                    ObjectHelper.resolveRef(activeDialogMutableRoot, ReactDOM.createRoot(r));
                    updateNewPermissionControls(newPermissions);
                }}>
                </FormGroup>
                <div style={{ fontSize: 13, marginTop: 15, cursor: "pointer", width: "fit-content" }} onClick={() => {
                    newPermissions.push("");
                    updateNewPermissionControls(newPermissions);
                }}>
                    <i className="fa fa-plus" style={{ marginRight: 7, color: "#0FA883" }} />
                    <span>{labels.permissions.dialog.add.add_text_field}</span>
                </div>
                <div ref={dialogErrorRef} className="error"> </div>
            </div>),
            onConfirm: (button?: ButtonManageRef) => {
                dialogErrorRef.current!.innerText = " ";
                let errorMessage;
                const permissionsValues = newPermissions.reduce((acc: { value: string; }[], permission: string) => {
                    if (!permission) errorMessage = labels.permissions.dialog.add.missing_a_value;
                    acc.push({ value: permission });
                    return acc;
                }, []);
                if (errorMessage) {
                    dialogErrorRef.current!.innerText = errorMessage;
                    return false;
                }
                button?.setLoadingState(true);
                rolePermissionService.createPermissions(permissionsValues)
                    .then(({ }: SanitizedResponse<IPermission>) => {
                        pageNumerRef.current.PERMISSIONS = 1;
                        fetchPermissions();
                        dialog.destroy();
                    }).catch(({ errorMessage }: SanitizedResponse<any>) => {
                        dialogErrorRef.current!.innerText = errorMessage;
                    }).finally(() => {
                        button?.setLoadingState(false);
                    });
                return false;
            }
        });
        dialog.show();
    }

    function deletePermission(permission: IPermission) {
        const dialog = ConfirmDialog({
            confirmScheme: Scheme.DANGER,
            title: labels.permissions.dialog.delete.title,
            cancelLabel: i18nManager.Labels.common.cancel,
            desc: labels.permissions.dialog.delete.desc.replace("${value}", permission.value),
            content: (<div ref={dialogErrorRef} className="error"></div>),
            onConfirm: (button?: ButtonManageRef) => {
                button?.setLoadingState(true);
                rolePermissionService.deletePermission(permission.external_id!)
                    .then(({ }: SanitizedResponse<IPermission>) => {
                        pageNumerRef.current.PERMISSIONS = 1;
                        fetchPermissions();
                        dialog.destroy();
                    }).catch(({ errorMessage }: SanitizedResponse<any>) => {
                        dialogErrorRef.current!.innerText = errorMessage;
                    }).finally(() => {
                        button?.setLoadingState(false);
                    });
                return false;
            }
        });
        dialog.show();
    }

    function manageAdministratorRolesSearch() {
        const selectedAdministrators: IAdministrator[] = [];
        const dialog = ConfirmDialog({
            closable: true,
            title: labels.roles.dialog.administrators_role_manage.title,
            content: Elements.administratorModifierDialog(labels.roles.dialog.administrators_role_manage, administrationService, administratorsDialogListView,
                dialogErrorRef, selectedAdministrators as any[], {}, undefined, true),
            onConfirm: (button?: ButtonManageRef) => {
                if (!selectedAdministrators.length) {
                    dialogErrorRef.current!.innerText = labels.roles.dialog.administrators_role_manage.select_an_admin;
                    return false;
                }
                dialogErrorRef.current!.innerText = " ";
                button?.setLoadingState(true);
                dialog.destroy(() => manageAdministratorRolesManage(selectedAdministrators[0]));
                return false;
            }
        });
        dialog.show();
    }

    function updateAdministratorDialogSelectedRoles(roles: IRole[]) {
        if (!roles || !activeDialogMutableRoot.current) return;
        const roleEntries: any[] = [];
        roles.forEach((role, index) => roleEntries.push(<div key={role.external_id} ref={(r: any) => r?.scrollIntoView()} onClick={() => {
            const a = roles.splice(index, 1);
            updateAdministratorDialogSelectedRoles(roles);
        }} >
            <i className="fa fa-minus" />
            <span>{role.name}</span>
        </div>));
        activeDialogMutableRoot.current.render(roleEntries);
    }

    function updateAdministratorDialogSearchedRoles(iRoles: IRole[] | undefined, roles: IRole[]) {
        if (!iRoles || !activeDialogMutableRootSec.current) return;
        const roleElements = iRoles?.map((role, index) => {
            return (<div key={index} ref={(r: any) => r?.scrollIntoView()} onClick={() => {
                if (roles.findIndex(r => r.external_id === role.external_id) > -1) return;
                roles.push(role);
                updateAdministratorDialogSelectedRoles(roles);
            }} >
                <i className="fa fa-plus" />
                <span>{role.name}</span>
            </div>);
        });
        activeDialogMutableRootSec.current.render(roleElements);
    }

    function manageAdministratorRolesManage(administrator: IAdministrator) {
        let immutableRoles: IRole[] = [];
        const selectedRoles: IRole[] = [];
        const dialog = ConfirmDialog({
            closable: true,
            style: { maxWidth: 650 },
            title: labels.roles.dialog.administrators_role_manage.title,
            desc: labels.roles.dialog.administrators_role_manage.desc_manage,
            content: (<div className="add-new-role">
                <div className="participant" style={{ marginTop: 20 }}>
                    {Elements.buildAdministratorPreview(administrator)}
                </div>
                <div className="permissions" style={{ flex: 1, maxHeight: 200 }} ref={(r: any) => {
                    if (!r) return;
                    ObjectHelper.resolveRef(activeDialogMutableRoot, ReactDOM.createRoot(r));
                    updateAdministratorDialogSelectedRoles(selectedRoles);
                }}>
                </div>
                <div ref={dialogErrorRef} className="error"> </div>
            </div>),
            rightContent: (<div style={{ flex: 1 }} className="add-new-role-permission">
                {Elements.buildConfirmDialogForm(labels.roles.dialog.administrators_role_manage.search_form, (_: string, value: string) => {
                    dialogErrorRef.current!.innerText = " ";
                    rolePermissionService.queryRoles({ page: 1, name: value }).then(({ apiResponse }: SanitizedResponse<IRole>) => {
                        updateAdministratorDialogSearchedRoles(apiResponse?.data?.content, selectedRoles);
                    }).catch(BaseService.reportError);
                })}
                <div className="permissions" ref={(r: any) => {
                    if (!r) return;
                    ObjectHelper.resolveRef(activeDialogMutableRootSec, ReactDOM.createRoot(r));
                    updateAdministratorDialogSearchedRoles(roles?.data?.content?.slice(0, 10), selectedRoles);
                }}>
                </div>
            </div>),
            onLoading: async (alert: AlertInterface) => {
                rolePermissionService.queryRoleAdministrators({ "administrator.external_id": administrator.external_id! }).then(({ apiResponse }: SanitizedResponse<IRoleAdministrator>) => {
                    apiResponse.data?.content?.forEach(a => selectedRoles.push(a.role));
                    immutableRoles = ObjectHelper.clone(selectedRoles);
                    alert.doneLoading();
                }).catch(({ errorMessage }: SanitizedResponse<any>) => {
                    alert.doneLoading(() => {
                        dialogErrorRef.current!.innerText = errorMessage;
                    });
                });
                return false;
            },
            onConfirm: (button?: ButtonManageRef) => {
                dialogErrorRef.current!.innerText = " ";
                button?.setLoadingState(true);
                const revokedRoles: IRole[] = [];
                const assignedRoles: IRole[] = [];
                for (const role of selectedRoles) {
                    if (immutableRoles.findIndex(e => role.external_id === e.external_id) < 0) {
                        assignedRoles.push(role);
                        continue;
                    }
                }
                for (const role of immutableRoles) {
                    if (selectedRoles.findIndex(e => role.external_id === e.external_id) < 0) {
                        revokedRoles.push(role);
                        continue;
                    }
                }
                rolePermissionService.assignAdministratorRoles(administrator.external_id!, assignedRoles.map(a => a.external_id!), revokedRoles.map(a => a.external_id!))
                    .then(() => {
                        dialog.destroy();
                    }).catch(({ errorMessage }: SanitizedResponse<any>) => {
                        dialogErrorRef.current!.innerText = errorMessage;
                    }).finally(() => {
                        button?.setLoadingState(false);
                    });
                return false;
            }
        });
        dialog.show();
    }

}

export default RolesPermissions;
