
import { NoseurObject } from "@ronuse/noseur";
import { CharonBaseService, MessageType } from "./CharonBaseService";

export interface IChannel {
    data: any;
    id: number;
    name: string;
    enabled: boolean;
    type: MessageType;
    updated_at: string;
    channel_id: string;
    external_id: string;
    description: string;
}

export class ChannelService extends CharonBaseService {

    protected static instance: ChannelService;

    private constructor() {
        super();
    }

    static getInstance() {
        if (!ChannelService.instance) ChannelService.instance = new ChannelService();
        return ChannelService.instance;
    }

    queryChannel(params: NoseurObject<string | number> = { page: 1, size: 10 }, refresh: boolean = false): Promise<any> {
        return this.report(this.transport.get("/charon/api/v1/channel", { params: this.normalizeParams(params) } as any));
    }

    createChannel(channel: IChannel): Promise<any> {
        return this.report(this.transport.post(`/charon/api/v1/channel`, channel, this.config));
    }

    updateChannel(channel: IChannel): Promise<any> {
        return this.report(this.transport.patch(`/charon/api/v1/channel/${channel.id!}`, channel, this.config));
    }

    enableDisableChannel(channel: IChannel, enabled: boolean): Promise<any> {
        return this.report(this.transport.patch(`/charon/api/v1/channel/${channel.id!}`, { enabled }, this.config));
    }

    deleteChannel(channel: IChannel): Promise<any> {
        return this.report(this.transport.delete(`/charon/api/v1/channel/${channel.id!}`, this.config));
    }

}

