
import { NoseurObject } from "@ronuse/noseur";
import { IContact } from "./ContactListService";
import { CharonBaseService, MessageType, MessageStatus, MessageSource, ContentType } from "./CharonBaseService";

export interface Attachment {
    cid: string;
    href?: string;
    path?: string;
    filename: string;
    content?: string;
    encoding?: string;
    headers: NoseurObject<any>;
    httpHeaders?: NoseurObject<any>;
}

export interface IMessage {
    id: number;
    from: string;
    async: boolean;
    subject: string;
    content: string;
    ccs: IContact[];
    bccs: IContact[];
    actor_id: string;
    max_retry: number;
    type: MessageType;
    channel_id: string;
    created_at: string;
    ip_address: string;
    updated_at: string;
    message_id: string;
    external_id: string;
    platform_id: string;
    template_id: string;
    schedule_id: string;
    retry_count: number;
    callback_url: string;
    event_logs: string[];
    status: MessageStatus;
    source: MessageSource;
    recipients: IContact[];
    channel_used_id: string;
    content_type: ContentType;
    attachments: Attachment[];
    contact_list_ids: string[];
    headers: NoseurObject<any>;
    list_headers: NoseurObject<any>;
    rotate_channel_on_retry: boolean;
}

export class MessageService extends CharonBaseService {

    protected static instance: MessageService;

    private constructor() {
        super();
    }

    static getInstance() {
        if (!MessageService.instance) MessageService.instance = new MessageService();
        return MessageService.instance;
    }

    queryMessage(params: NoseurObject<string | number> = { page: 1, size: 10 }, refresh: boolean = false): Promise<any> {
        return this.report(this.transport.get("/charon/api/v1/message", { params: this.normalizeParams(params) } as any));
    }

    sendMessage(message: IMessage): Promise<any> {
        return this.report(this.transport.post(`/charon/api/v1/message`, message, this.config));
    }

    getSingleMessage(id: number): Promise<any> {
        return this.report(this.transport.get(`/charon/api/v1/message/${id!}`, this.config));
    }

    updateMessage(message: IMessage): Promise<any> {
        return this.report(this.transport.patch(`/charon/api/v1/message/${message.id!}`, message, this.config));
    }

    enableDisableMessage(message: IMessage, enabled: boolean): Promise<any> {
        return this.report(this.transport.patch(`/charon/api/v1/message/${message.id!}`, { enabled }, this.config));
    }

    deleteMessage(message: IMessage): Promise<any> {
        return this.report(this.transport.delete(`/charon/api/v1/message/${message.id!}`, this.config));
    }

    resendMessages(messageIds: number[]): Promise<any> {
        return this.report(this.transport.post(`/charon/api/v1/message/resend`, { message_ids: messageIds }, this.config));
    }

    deleteMessages(messageIds: number[]): Promise<any> {
        return this.report(this.transport.delete(`/charon/api/v1/message`, {
            ...this.config,
            data: { message_ids: messageIds },
        }));
    }

}

