
import React from "react";
import ReactDOM from "react-dom/client";
import { Schemes, Utils } from "../../../utils";
import { CSSTransition } from 'react-transition-group';
import { FormControls, i18nManager } from "../../../i18n";
import { NavLink, useSearchParams } from "react-router-dom";
import { ConfirmDialog, CreateMessage, Elements, Siren } from "../../shared";
import { MessageStatus, TemplateType } from "../../../services/charon/CharonBaseService";
import { ApiResponseData, SanitizedResponse, IMessage, MessageService, BaseService, ChannelService, IChannel, ContactListService, IContactList, TemplateService, ITemplate } from "../../../services";
import { AlertInterface, Button, ButtonManageRef, Checkbox, Classname, Column, DOMHelper, DataManageRef, FileInputPreviewType, FormControl, FormGroup, NoseurObject, ObjectHelper, Scheme, Table, Transition, fileInputBuildFileInputPreview } from "@ronuse/noseur";

function Messages() {
    let processing = false;
    let lastAttachmentTarget: any;
    const channelService = ChannelService.getInstance();
    const messageService = MessageService.getInstance();
    const templateService = TemplateService.getInstance();
    const tableManageRef = React.useRef<DataManageRef>(null);
    const contactListService = ContactListService.getInstance();
    const refMapCollection = React.useRef<NoseurObject<any>>({});
    const labels = i18nManager.Labels.dashboard.messaging.messages;
    const [showFilters, setShowFilters] = React.useState<boolean>();
    const resendButtonManageRef = React.useRef<ButtonManageRef>(null);
    const deleteButtonManageRef = React.useRef<ButtonManageRef>(null);
    const activeDialogMutableRoot = React.useRef<ReactDOM.Root>(null);
    const [activeMessage, setActiveMessage] = React.useState<IMessage>();
    const [messages, setMessages] = React.useState<ApiResponseData<IMessage>>({});
    const [searchParams, setSearchParams] = useSearchParams(window.location.search);
    const [selectedMessagesIds, setSelectedMessagesIds] = React.useState<number[]>([]);

    React.useEffect(() => {
        if (!activeMessage && !searchParams.has("active_message_id")) {
            fetchMessages();
        } else {
            fetchActiveMessage(parseInt(searchParams.get("active_message_id") ?? "0"));
        }
    }, []);
    React.useEffect(() => {
        if (window.location.search.includes("active_message_id")) {
            if (!activeMessage) {
                fetchActiveMessage(parseInt(searchParams.get("active_message_id") ?? "0"));
            }
            return;
        }
        searchParams.delete("active_message_id");
        setActiveMessage(undefined);
        if (!messages.content) fetchMessages();
    }, [searchParams]);

    return (<div className="charon-messages">
        {!activeMessage && !searchParams.has("active_message_id")
            ? (<div className="management">
                <div className="controls">
                    <div>
                        <Button scheme={Schemes.RIVTN_QUIRINUS} text={labels.send_new_message} raised onClick={() => {
                            CreateMessage({
                                refMap: refMapCollection,
                                title: labels.create_message.title,
                                onComplete: (message: IMessage, cb: (error?: string) => void) => {
                                    messageService.sendMessage(message).then((_: SanitizedResponse<IMessage>) => {
                                        cb(); fetchMessages();
                                        Siren.alert(labels.create_message.message_sent);
                                    }).catch((err) => {
                                        cb(err?.errorMessage ?? `${err}`);
                                        BaseService.reportError(err);
                                    });
                                }
                            }).show();
                        }} />
                        <Button scheme={Schemes.RIVTN_QUIRINUS} text={labels.filter} leftIcon="fa fa-filter" raised outlined={!showFilters}
                            onClick={() => setShowFilters(!showFilters)} />
                    </div>
                    {selectedMessagesIds?.length
                        ? (<div>
                            <Button scheme={Schemes.RIVTN_QUIRINUS} text={labels.resend} manageRef={resendButtonManageRef} raised loadingProps={{
                                disabled: true,
                                leftIcon: "fas fa-spinner fa-pulse"
                            }} onClick={() => {
                                resendButtonManageRef.current?.setLoadingState(true);
                                resendMessages(selectedMessagesIds, () => resendButtonManageRef.current?.setLoadingState(false));
                            }} />
                            <Button scheme={Scheme.DANGER} text={labels.delete} manageRef={deleteButtonManageRef} raised loadingProps={{
                                disabled: true,
                                leftIcon: "fas fa-spinner fa-pulse"
                            }} onClick={() => {
                                deleteButtonManageRef.current?.setLoadingState(true);
                                deleteMessages(selectedMessagesIds, () => {
                                    deleteButtonManageRef.current?.setLoadingState(false);
                                    fetchMessages();
                                });
                            }} />
                        </div>) : null}
                </div>
                <CSSTransition classNames={Transition.CURTAIN} timeout={500} in={showFilters} unmountOnExit>
                    <div className="filters">
                        {labels.filters.form?.map((control: FormControls, index: number) => {
                            return (<div key={index} className="filter">
                                <span>{control.title}</span>
                                {Elements.buildConfirmDialogForm([control], (name, value) => {
                                    if (name === "channel_id") {
                                        channelService.queryChannel({ name: value.value }, true).then(({ apiResponse }: SanitizedResponse<IChannel>) => {
                                            value.manageRef?.changeOptions(apiResponse.data!.content?.map((channel: IChannel) => {
                                                return {
                                                    "label": channel.name,
                                                    "value": channel.channel_id
                                                };
                                            }));
                                        }).catch(BaseService.reportError);
                                    } else if (name === "contact_list_ids") {
                                        contactListService.queryContactList({ name: value.value }, true).then(({ apiResponse }: SanitizedResponse<IContactList>) => {
                                            value.manageRef?.changeOptions(apiResponse.data!.content?.map((contactList: IContactList) => {
                                                return {
                                                    "label": contactList.name,
                                                    "value": contactList.contact_list_id
                                                };
                                            }));
                                        }).catch(BaseService.reportError);
                                    } else if (name === "template_id") {
                                        templateService.queryTemplate({ name: value.value }, true).then(({ apiResponse }: SanitizedResponse<ITemplate>) => {
                                            value.manageRef?.changeOptions(apiResponse.data!.content?.map((template: ITemplate) => {
                                                return {
                                                    "label": template.name,
                                                    "value": template.template_id
                                                };
                                            }));
                                        }).catch(BaseService.reportError);
                                    }
                                }, searchParams, undefined, { marginTop: 0 }, undefined, { marginTop: 7 }, setSearchParams, undefined, undefined, { platform_id: "messages_platform_id" })}
                            </div>);
                        })}
                        <Button scheme={Schemes.RIVTN_QUIRINUS} text={labels.filters.reset_filter} outlined onClick={() => {
                            labels.filters.form.map((control: FormControls) => {
                                Utils.updateSearchParams((control.name === "platform_id" ? "messages_platform_id" : control.name), undefined, setSearchParams);
                            });
                        }} />
                        <Button scheme={Schemes.RIVTN_QUIRINUS} text={labels.filters.apply} style={{ padding: "10px 40px" }} onClick={fetchMessages} />
                    </div>
                </CSSTransition>
                <Table className="table" data={messages.content} rowsPerPage={messages.size ?? 10} totalRecords={messages.total_elements} dataRefreshKeys={["id"]} manageRef={tableManageRef} loadingState={Elements.LOADING_STATE}>
                    <Column header={() => <Checkbox scheme={Schemes.RIVTN_QUIRINUS} checked={selectedMessagesIds?.length === messages?.content?.length} onChange={(e: any) => {
                        if (selectedMessagesIds?.length === messages.content?.length) {
                            setSelectedMessagesIds([]);
                        } else {
                            setSelectedMessagesIds(messages.content?.map(m => m.id)!);
                        }
                    }} />} template={(message: IMessage) => {
                        return (<Checkbox scheme={Schemes.RIVTN_QUIRINUS} checked={selectedMessagesIds.includes(message.id)} onChange={(e: any) => {
                            if (e.checked && !selectedMessagesIds.includes(message.id)) {
                                setSelectedMessagesIds([...selectedMessagesIds, message.id]);
                            } else if (!e.checked && selectedMessagesIds.includes(message.id)) {
                                selectedMessagesIds.splice(selectedMessagesIds.indexOf(message.id), 1);
                                setSelectedMessagesIds([...selectedMessagesIds]);
                            }
                        }} />);
                    }} />
                    <Column dataKey="platform_id" header={labels.table.platform_id} />
                    <Column dataKey="type" header={labels.table.type} />
                    <Column header={labels.table.date_created} template={(message: IMessage) => Utils.formatToFineDate3(message.created_at)} />
                    <Column header={labels.table.subject_and_content} template={(message: IMessage) => {
                        const isTemplate = !message.content;
                        return (<span className="lined-limit"><b>{labels.table.subject}:</b> {message.subject}.
                            <b> {isTemplate ? labels.table.template : labels.table.content}:</b>
                            {isTemplate ? <NavLink to={{
                                pathname: "/dashboard/messaging/templating",
                                search: "active_data_id=" + message.template_id
                            }}> {message.template_id}</NavLink> : <span> {message.content.substring(0, 10)}</span>}
                        </span>);
                    }} />
                    <Column header={labels.table.events} template={(message: IMessage) => `${message.event_logs?.length ?? 0} ${labels.table.events}`} />
                    <Column header={labels.table.status} template={(message: IMessage) => selectStyledStatus(message.status)} />
                    <Column template={(message: IMessage) => {
                        return (<React.Fragment>
                            <i className={`fa fa-eye noseur-cursor-pointer ${Schemes.RIVTN_QUIRINUS}-tx link`} onClick={() => {
                                processing = true;
                                renderContentPreview(message, setActiveMessage);
                                Utils.updateSearchParams("active_message_id", message.id, setSearchParams);
                            }} />
                            {message.status === MessageStatus.FAILED
                                ? (<i style={{ marginLeft: 15 }} className={`fa fa-refresh noseur-cursor-pointer ${Schemes.RIVTN_QUIRINUS}-tx link fa`} onClick={(e: any) => {
                                    DOMHelper.addClass(e.target, "fa-spin");
                                    resendMessages([message.id], () => DOMHelper.removeClass(e.target, "fa-spin"));
                                }} />) : null}
                        </React.Fragment>)
                    }} />
                </Table>
                {Elements.buildPaginator(messages ?? {}, ({ currentPage }, size) => Utils.updateSearchParamses({ "page": currentPage, id: null, size }, setSearchParams, fetchMessages), parseInt(searchParams.get("page") ?? "1"))}
            </div>)
            : (<div className="messaging-sided">
                <div className="second override">
                    {!activeMessage ? Elements.LOADING_STATE : (activeMessage as any).__borrowed_contentPreview}
                </div>
                <div className="third active-data active-message">
                    {!activeMessage ? Elements.LOADING_STATE : (<React.Fragment>
                        <div className="header">
                            <i className="fa fa-times" onClick={() => {
                                Utils.updateSearchParams("active_message_id", undefined, setSearchParams, fetchMessages);
                            }} />
                            <span className="name">{activeMessage?.subject}</span>
                            <span className="id">{activeMessage?.platform_id}</span>
                            {selectStyledStatus(activeMessage?.status ?? "unset", { marginTop: 5 })}
                        </div>
                        <div className="middle">
                            <div><b>{labels.active_message.info.type}:</b> {activeMessage?.type}</div>
                            <div><b>{labels.active_message.info.source}:</b> {activeMessage?.source}</div>
                            <div><b>{labels.active_message.info.asynchronous}?</b> {`${activeMessage?.async}`}</div>
                            <div><b>{labels.active_message.info.rotate_on_retry}:</b> {`${activeMessage?.rotate_channel_on_retry}`}</div>
                            <div><b>{labels.active_message.info.max_retry}:</b> {activeMessage?.max_retry}, <b>{labels.active_message.info.retry_count}:</b> {activeMessage?.retry_count}</div>
                            <div><b>{labels.active_message.info.ip_address}:</b> <a href={`https://whatismyipaddress.com/ip/${activeMessage?.ip_address}`} target="_blank">{activeMessage?.ip_address}</a></div>
                            <div><b>{labels.active_message.info.channel_requested}:</b> {activeMessage?.channel_id ? (<a href={`/dashboard/messaging/channels?active_data_id=${activeMessage?.channel_id}`}>{activeMessage?.channel_id}</a>) : null}</div>
                            <div><b>{labels.active_message.info.channel_used}:</b> {activeMessage?.channel_id ? (<a href={`/dashboard/messaging/channels?active_data_id=${activeMessage?.channel_used_id}`}>{activeMessage?.channel_used_id}</a>) : null}</div>
                            <div><b>{labels.active_message.info.actor_id}:</b> {activeMessage?.actor_id}</div>
                            <div><b>{labels.active_message.info.schedule}:</b> {activeMessage?.channel_id ? (<a href={`/dashboard/messaging/scheduling?active_schedule_id=${activeMessage?.schedule_id}`}>{activeMessage?.schedule_id}</a>) : null}</div>
                            <div><b>{labels.active_message.info.callback_url}:</b> <a href={activeMessage?.callback_url} target="_blank">{activeMessage?.callback_url}</a></div>
                            <div><b>{labels.active_message.info.content_type}:</b> {activeMessage?.content_type}</div>
                            <div><b>{labels.active_message.info.template}:</b> {activeMessage?.channel_id ? (<a href={`/dashboard/messaging/templating?active_data_id=${activeMessage?.template_id}`}>{activeMessage?.template_id}</a>) : null}</div>
                        </div>
                        <div className="actions">
                            <span className="link" onClick={() => resendMessage(activeMessage)}>{labels.active_message.actions.resend_message}</span>
                            <span className="link" onClick={() => showEventLogs(activeMessage)}>{labels.active_message.actions.view_event_logs}</span>
                            <span className="link" onClick={() => showRecipients(activeMessage)}>{labels.active_message.actions.view_recipients}</span>
                            <span className="link" onClick={() => showContactLists(activeMessage)}>{labels.active_message.actions.show_contact_lists}</span>
                            <span className="link" onClick={() => showAttachments(activeMessage)}>{labels.active_message.actions.view_attachments}</span>
                            <span className="link" onClick={() => showCustomHeaders(activeMessage)}>{labels.active_message.actions.show_custom_headers}</span>
                            <span className="link" onClick={() => showCcsAndBccs(activeMessage)}>{labels.active_message.actions.view_ccs_bccs}</span>
                            <span className="link" onClick={() => showListHeaders(activeMessage)}>{labels.active_message.actions.view_list_headers}</span>
                            <span className="link danger" onClick={() => deleteMessage(activeMessage)}>{labels.active_message.actions.delete_message}</span>
                        </div>
                    </React.Fragment>)}
                </div>
            </div>)}
    </div>);

    function fetchMessages() {
        if (processing) return; processing = true;
        if (searchParams.has("active_message_id")) return;
        setSelectedMessagesIds([]);
        tableManageRef.current?.setLoadingState(true);
        messageService.queryMessage(Utils.normalizeUrlParams(searchParams, { platform_id: "messages_platform_id" }, undefined, { messages_platform_id: "platform_id" }), true).then(({ apiResponse }: SanitizedResponse<IMessage>) => {
            processing = false;
            setMessages(apiResponse.data!);
        }).catch(BaseService.reportError).finally(() => {
            processing = false;
            tableManageRef.current?.setLoadingState(false);
        });
    }

    function fetchActiveMessage(id: number) {
        if (processing) return; processing = true;
        messageService.getSingleMessage(id).then(({ apiResponse }: SanitizedResponse<IMessage>) => {
            processing = false;
            renderContentPreview(apiResponse.data as any as IMessage, (message) => {
                setActiveMessage(message);
                showDialog(message);
            });
        }).catch((err) => {
            BaseService.reportError(err);
            Utils.updateSearchParams("active_message_id", undefined, setSearchParams, fetchMessages);
        }).finally(() => processing = false);
    }

    function showDialog(message: IMessage) {
        if (searchParams.get("popup") === "event_logs") showEventLogs(message as any as IMessage);
        else if (searchParams.get("popup") === "recipients") showRecipients(message as any as IMessage);
        else if (searchParams.get("popup") === "ccs_and_bcc") showCcsAndBccs(message as any as IMessage);
        else if (searchParams.get("popup") === "attachments") showAttachments(message as any as IMessage);
        else if (searchParams.get("popup") === "list_headers") showListHeaders(message as any as IMessage);
        else if (searchParams.get("popup") === "contact_list") showContactLists(message as any as IMessage);
        else if (searchParams.get("popup") === "custom_headers") showCustomHeaders(message as any as IMessage);
    }

    function selectStyledStatus(status: string, style: any = {}) {
        if (status === MessageStatus.FAILED) return (<span className="pill" style={{ "--backgroundColor": "#EB5959", ...style } as any}>{labels.table.statuses.failed}</span>);
        if (status === MessageStatus.PENDING) return (<span className="pill" style={{ "--backgroundColor": "#0F68A8", ...style } as any}>{labels.table.statuses.pending}</span>);
        if (status === MessageStatus.RETRYING) return (<span className="pill" style={{ "--backgroundColor": "#A5A80F", ...style } as any}>{labels.table.statuses.retrying}</span>);
        if (status === MessageStatus.CANCELLED) return (<span className="pill" style={{ "--backgroundColor": "#000000, ...style" } as any}>{labels.table.statuses.cancelled}</span>);
        return (<span className="pill" style={{ "--backgroundColor": "#0FA883", ...style } as any}>{labels.table.statuses.success}</span>);
    }

    function renderContentPreview(message: IMessage, onComplete: (message: IMessage) => void) {
        if (processing) return;
        (message.content
            ? new Promise((resolve, _) => resolve({ apiResponse: { data: { type: TemplateType.HTML, content: message.content } } }))
            : templateService.getSingleByTemplateId(message.template_id)).then(({ apiResponse }: SanitizedResponse<ITemplate> | any) => {
                (message as any).__borrowed_contentPreview = Elements.buildTemplateView(apiResponse.data as any as ITemplate, TemplateType, {}, {}, message.attachments);
            }).catch(BaseService.reportError).finally(() => onComplete(message));
    }

    function resendMessages(mesagesId: number[], onComplete?: () => void) {
        messageService.resendMessages(mesagesId).then(({ }: SanitizedResponse<IMessage>) => {
            Siren.alert(labels.messages_resend_initiated);
        }).catch(BaseService.reportError).finally(onComplete);
    }

    function deleteMessages(mesagesId: number[], onComplete: () => void) {
        messageService.deleteMessages(mesagesId).then(({ }: SanitizedResponse<IMessage>) => {
            Siren.alert(labels.messages_deleted);
        }).catch(BaseService.reportError).finally(onComplete);
    }

    function resendMessage(message: IMessage) {
        const dialog = ConfirmDialog({
            onLoading: async (alert: AlertInterface) => {
                resendMessages([message.id ?? 0], () => {
                    alert.hide();
                });
                return false;
            },
        });
        dialog.show();
    }

    function showEventLogs(message: IMessage) {
        Utils.updateSearchParams("popup", "event_logs", setSearchParams, () => {
            const dialog = ConfirmDialog({
                closable: true,
                confirmLabel: null,
                style: { maxWidth: "850px" },
                title: labels.active_message.dialog.event_logs,
                onCancel: () => Utils.updateSearchParams("popup", undefined, setSearchParams),
                content: (<div className="message-event-logs">
                    {(message?.event_logs ?? []).map((eventLog, index) => <span key={index}>{eventLog} </span>)}
                </div>)
            });
            dialog.show();
        });
    }

    function showRecipients(message: IMessage) {
        Utils.updateSearchParams("popup", "recipients", setSearchParams, () => {
            const dialog = ConfirmDialog({
                closable: true,
                confirmLabel: null,
                style: { maxWidth: "850px" },
                title: labels.active_message.dialog.recipients,
                onCancel: () => Utils.updateSearchParams("popup", undefined, setSearchParams),
                content: (<div className="message-event-logs">
                    {(message?.recipients ?? []).map((recipient, index) => <span key={index}>{recipient.address}
                        <span style={{ color: "rgb(62, 61, 61, 0.5)" }}> ({JSON.stringify(recipient.parameters)})</span></span>)}
                </div>)
            });
            dialog.show();
        });
    }

    function showContactLists(message: IMessage) {
        Utils.updateSearchParams("popup", "contact_list", setSearchParams, () => {
            const dialog = ConfirmDialog({
                closable: true,
                confirmLabel: null,
                style: { maxWidth: "650px" },
                title: labels.active_message.dialog.contact_lists,
                onCancel: () => Utils.updateSearchParams("popup", undefined, setSearchParams),
                content: (<div className="message-event-logs" style={{ maxWidth: 600 }}>
                    {(message?.contact_list_ids ?? []).map((contact_list_id, index) => <a key={index} href={`/dashboard/messaging/contact-management?active_data_id=${contact_list_id}`}>{contact_list_id}</a>)}
                </div>),
            });
            dialog.show();
        });
    }

    async function previewSelectedAttachment(message: IMessage, index: number) {
        if (!message.attachments?.length || !activeDialogMutableRoot.current) return;
        const attachment = message.attachments[index];
        if (!attachment) return;
        const isHtmlPreview = true;//!!attachment.path;
        //const blobParts = !isPath ? [await (await fetch(`"data:;base64,${attachment.content}`)).blob()] : [];
        const blobParts = !attachment.path ? [atob(attachment.content ?? "")] : [];
        //console.log("BOOYAH", blobParts);
        const file = attachment.path ? undefined : new File(blobParts, (attachment.filename), {
            type: "text"
        });
        let preview = fileInputBuildFileInputPreview({
            file,
            url: attachment.path,
            style: { maxWidth: 400, height: "100%", minHeight: 300 },
            previewType: (attachment.path ? FileInputPreviewType.PDF : FileInputPreviewType.AUTO),
        });
        if (isHtmlPreview) {
            let info = fileInputBuildFileInputPreview({
                file,
                url: attachment.path,
                previewType: FileInputPreviewType.BINARY,
                style: { maxWidth: 300, wordWrap: "break-word" },
            });
            preview = (<div>{preview}{info}</div>)
        }
        //console.log("ATATCHEMENNRR", attachment);
        activeDialogMutableRoot.current.render(preview);
    }

    function showAttachments(message: IMessage) {
        Utils.updateSearchParams("popup", "attachments", setSearchParams, () => {
            const dialog = ConfirmDialog({
                closable: true,
                confirmLabel: null,
                style: { maxWidth: "1050px", minWidth: "60%", minHeight: "50%" },
                title: labels.active_message.dialog.attachments,
                onCancel: () => Utils.updateSearchParams("popup", undefined, setSearchParams),
                content: (<div style={{ maxWidth: 1000, display: "flex", height: "100%" }}>
                    <div className="message-event-logs" style={{ flex: 1 }}>
                        {(message?.attachments ?? []).map((attachment, index) => {
                            const encoding = attachment.encoding;
                            attachment.cid = attachment.cid ?? index;

                            return (<React.Fragment key={index}>
                                <span className={Classname.build("link-hv", { "active": index === 0 })} onClick={(e) => {
                                    // setSelectedAttachement(attachment); TODO
                                    if (lastAttachmentTarget) DOMHelper.removeClass(lastAttachmentTarget, "active");
                                    DOMHelper.addClass(e.currentTarget as any, "active");
                                    lastAttachmentTarget = e.currentTarget;
                                    previewSelectedAttachment(message, index);
                                }} ref={(r) => {
                                    if (r && index === 0) lastAttachmentTarget = r;
                                }}>{attachment.cid}:{attachment.filename} <span style={{ color: "rgba(62,61,61,.4)" }}>(
                                        {!encoding ? labels.active_message.dialog.url
                                            : `${labels.active_message.dialog.encoding}:${encoding}`})</span></span></React.Fragment>);
                        })}
                    </div>
                    <div className="message-attachment-preview" style={{ flex: 0.9, maxWidth: 500, borderLeft: "1px solid #E2E6E4", marginLeft: 20, padding: 20 }} ref={(r) => {
                        if (!r) return;
                        ObjectHelper.resolveRef(activeDialogMutableRoot, ReactDOM.createRoot(r));
                        previewSelectedAttachment(message, 0);
                    }}>
                    </div>
                </div>),
            });
            dialog.show();
        });
    }

    function showCustomHeaders(message: IMessage) {
        Utils.updateSearchParams("popup", "custom_headers", setSearchParams, () => {
            const dialog = ConfirmDialog({
                closable: true,
                confirmLabel: null,
                style: { maxWidth: "650px", minWidth: "50%" },
                title: labels.active_message.dialog.custom_headers,
                onCancel: () => Utils.updateSearchParams("popup", undefined, setSearchParams),
                content: (<FormGroup scheme={Schemes.RIVTN_QUIRINUS} style={{ maxWidth: 600, marginTop: 20, maxHeight: 260, overflow: "auto" }} className="message-event-logs form">
                    {Object.keys(message?.headers ?? {}).map((header, index) => {
                        const keyProps: any = Utils.buildSelectNoseurComponentProps({
                            value: header,
                            name: "header",
                        });
                        const valueProps: any = Utils.buildSelectNoseurComponentProps({
                            name: "value",
                            value: JSON.stringify(message?.headers[header]),
                        });

                        keyProps.readOnly = true; valueProps.readOnly = true;
                        valueProps.style = { background: "rgba(217, 217, 217, 0.2)", borderRadius: 0, flex: 1.2, height: 100 };
                        keyProps.style = { background: "rgba(217, 217, 217, 0.2)", borderRadius: 0, flex: 0.8, marginRight: 10, alignSelf: "flex-start" };
                        return (<FormControl key={index} style={{ marginTop: 0, marginBottom: 10, background: "transparent" }}
                            validStyle={{ borderColor: "transparent" }} contentStyle={{ borderRadius: 0 }}>
                            {React.createElement(Utils.selectNoseurComponent() as any, keyProps)}
                            {React.createElement(Utils.selectNoseurComponent("textarea") as any, valueProps)}
                        </FormControl>);
                    })}
                </FormGroup>),
            });
            dialog.show();
        });
    }

    function showCcsAndBccs(message: IMessage) {
        Utils.updateSearchParams("popup", "ccs_and_bcc", setSearchParams, () => {
            const dialog = ConfirmDialog({
                closable: true,
                confirmLabel: null,
                style: { maxWidth: "1050px", minWidth: "50%" },
                title: labels.active_message.dialog.ccs_bccs.title,
                onCancel: () => Utils.updateSearchParams("popup", undefined, setSearchParams),
                content: (<div style={{ maxWidth: 1000, display: "flex", marginTop: 5 }}>
                    <div className="message-event-logs" style={{ flex: 1 }}>
                        <span className="title">{labels.active_message.dialog.ccs_bccs.ccs}</span>
                        {(message?.ccs ?? []).map((recipient, index) => <span key={index}>{recipient.address}
                            <span style={{ color: "rgb(62, 61, 61, 0.5)" }}> ({JSON.stringify(recipient.parameters)})</span></span>)}
                    </div>
                    <div className="message-event-logs" style={{ flex: 1, borderLeft: "1px solid #E2E6E4", marginLeft: 15, paddingLeft: 15 }}>
                        <span className="title">{labels.active_message.dialog.ccs_bccs.bccs}</span>
                        {(message?.bccs ?? []).map((recipient, index) => <span key={index}>{recipient.address}
                            <span style={{ color: "rgb(62, 61, 61, 0.5)" }}> ({JSON.stringify(recipient.parameters)})</span></span>)}
                    </div>
                </div>),
            });
            dialog.show();
        });
    }

    function showListHeaders(message: IMessage) {
        Utils.updateSearchParams("popup", "list_headers", setSearchParams, () => {
            const dialog = ConfirmDialog({
                closable: true,
                confirmLabel: null,
                style: { maxWidth: "650px", minWidth: "50%" },
                title: labels.active_message.dialog.list_headers,
                onCancel: () => Utils.updateSearchParams("popup", undefined, setSearchParams),
                content: (<FormGroup scheme={Schemes.RIVTN_QUIRINUS} style={{ maxWidth: 600, marginTop: 20, maxHeight: 260, overflow: "auto" }} className="message-event-logs form">
                    {Object.keys(message?.list_headers ?? {}).map((header, index) => {
                        const keyProps: any = Utils.buildSelectNoseurComponentProps({
                            value: header,
                            name: "header",
                        });
                        const valueProps: any = Utils.buildSelectNoseurComponentProps({
                            name: "value",
                            value: JSON.stringify(message?.list_headers[header]),
                        });

                        keyProps.readOnly = true; valueProps.readOnly = true;
                        valueProps.style = { background: "rgba(217, 217, 217, 0.2)", borderRadius: 0, flex: 1.2, height: 100 };
                        keyProps.style = { background: "rgba(217, 217, 217, 0.2)", borderRadius: 0, flex: 0.8, marginRight: 10, alignSelf: "flex-start" };
                        return (<FormControl key={index} style={{ marginTop: 0, marginBottom: 10, background: "transparent" }}
                            validStyle={{ borderColor: "transparent" }} contentStyle={{ borderRadius: 0 }}>
                            {React.createElement(Utils.selectNoseurComponent() as any, keyProps)}
                            {React.createElement(Utils.selectNoseurComponent("textarea") as any, valueProps)}
                        </FormControl>);
                    })}
                </FormGroup>),
            });
            dialog.show();
        });
    }

    function deleteMessage(message: IMessage) {
        const dialog = ConfirmDialog({
            closable: true,
            confirmScheme: Scheme.DANGER,
            ...labels.active_message.dialog.delete,
            confirmLabel: i18nManager.Labels.common.continue,
            onCancel: () => Utils.updateSearchParams("popup", undefined, setSearchParams),
            onConfirm: (button?: ButtonManageRef) => {
                button?.setLoadingState(true);
                messageService.deleteMessage(message).then(({ }: SanitizedResponse<IMessage>) => {
                    Siren.alert(labels.active_message.dialog.delete.success);
                    Utils.updateSearchParams("active_message_id", undefined, setSearchParams, fetchMessages);
                }).catch(BaseService.reportError).finally(() => dialog.hide());
                return false;
            }
        });
        dialog.show();
    }

}

export default Messages;
