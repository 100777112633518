
import { NoseurObject } from "@ronuse/noseur";
import { ThothBaseService } from "./ThothBaseService";
import { Device, Isp } from "../soteria/SoteriaBaseService";

export interface IAudit {
    isp: Isp;
    id: number;
    type: string;
    group: string;
    status: string;
    source: string;
    device: Device;
    action: string;
    auditable: any;
    audit_id: string;
    actor_id: string;
    location: Location;
    created_at: string;
    actor_type: string;
    ip_address: string;
    environment: string;
    extra_data: NoseurObject<any>;
}

export class AuditService extends ThothBaseService {

    protected static instance: AuditService;

    private constructor() {
        super();
    }

    static getInstance() {
        if (!AuditService.instance) AuditService.instance = new AuditService();
        return AuditService.instance;
    }

    queryAudits(params: NoseurObject<string | number> = { page: 1, size: 10 }, refresh: boolean = false): Promise<any> {
        return this.report(this.transport.get("/thoth/api/v1/audit", { params: this.normalizeParams(params) } as any));
    }

    getAuditExportKey(params: NoseurObject<string | number> = { page: 1, size: 10 }): Promise<any> {
        return this.report(this.transport.get("/thoth/api/v1/audit/export/key", { params: this.normalizeParams(params) } as any));
    }

    getSingleAudit(id: number): Promise<any> {
        return this.report(this.transport.get(`/thoth/api/v1/audit/${id!}`, this.config));
    }

    deleteAudits(data: number[]): Promise<any> {
        return this.report(this.transport.delete(`/thoth/api/v1/audit/multiple`, {
            ...this.config,
            data
        }));
    }

}

