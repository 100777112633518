
import React from "react";
import { ConfirmDialog, Elements, SidedContainer, Siren } from "../../shared";
import { NavLink, useSearchParams } from "react-router-dom";
import { ApiResponseData, BaseService, ISuspiciousIp, SuspiciousIpService, SanitizedResponse } from "../../../services";
import { Schemes, Subscriber, Utils } from "../../../utils";
import { i18nManager } from "../../../i18n";
import { Button, ButtonManageRef, Column, NoseurElement, NoseurObject, Scheme, TabPane, TabPanel, Table } from "@ronuse/noseur";

function SuspiciousIps() {

    const suspiciousIpsService = SuspiciousIpService.getInstance();
    const labels = i18nManager.Labels.dashboard.security.suspicious_ips;
    const [searchParams, setSearchParams] = useSearchParams(window.location.search);
    const [suspiciousIps, setSuspiciousIps] = React.useState<ApiResponseData<ISuspiciousIp>>({});
    const [activeSuspiciousIp, setActiveSuspiciousIp] = React.useState<{ listing?: ISuspiciousIp, activeData?: NoseurObject<any>, mainContent?: () => NoseurElement }>({});

    React.useEffect(() => {
        if (!activeSuspiciousIp && !searchParams.has("active_data_id")) {
            fetchSuspiciousIps();
        } else {
            fetchSuspiciousIp(searchParams.get("active_data_id"));
        }
    }, []);
    React.useEffect(() => {
        if (window.location.search.includes("active_data_id")) {
            if (!activeSuspiciousIp.listing) {
                fetchSuspiciousIp(searchParams.get("active_data_id"));
            }
            return;
        }
        searchParams.delete("active_data_id");
        setActiveSuspiciousIp({});
        if (!suspiciousIps.content) fetchSuspiciousIps();
    }, [searchParams]);

    return SidedContainer({
        searchParams,
        setSearchParams,
        labels: labels.sided_labels,
        queryData: fetchSuspiciousIps,
        activeData: activeSuspiciousIp.activeData,
        mainContent: activeSuspiciousIp.mainContent ?? buildListingTable,
    } as any);

    function fetchSuspiciousIps() {
        suspiciousIpsService.querySuspiciousIps(Utils.normalizeUrlParams(searchParams), true).then(({ apiResponse }: SanitizedResponse<ISuspiciousIp>) => {
            setSuspiciousIps(apiResponse.data!);
        }).catch(BaseService.reportError).finally(() => Subscriber.report(Subscriber.KEYS.QUERY_REQUEST_PROMISE_COMPLETED));
    }

    function fetchSuspiciousIp(externalId: string | null) {
        if (!externalId) return;
        suspiciousIpsService.getSingleSuspiciousIp(externalId).then(({ apiResponse }: SanitizedResponse<ISuspiciousIp>) => {
            setActiveSuspiciousIpProps(apiResponse.data as any as ISuspiciousIp);
        }).catch((err) => {
            BaseService.reportError(err);
            Utils.updateSearchParams("active_data_id", undefined, setSearchParams, fetchSuspiciousIps);
        }).finally(() => Subscriber.report(Subscriber.KEYS.QUERY_REQUEST_PROMISE_COMPLETED));
    }

    function setActiveSuspiciousIpProps(suspiciousIp: ISuspiciousIp) {
        setActiveSuspiciousIp({
            listing: suspiciousIp,
            mainContent: () => Elements.buildSuspiciousMainContent(suspiciousIp, {
                type: labels.type,
                id: suspiciousIp.external_id,
                value: suspiciousIp.ip_address,
                onDelete: suspiciousIpsService.deleteSuspiciousIpPlatform.bind(suspiciousIpsService),
                onComplete: () => {
                    fetchSuspiciousIp(suspiciousIp.external_id);
                }
            }),
            activeData: {
                element: buildActiveData(suspiciousIp)
            },
        });
    }

    function buildListingTable() {
        return (<React.Fragment>
            <Table rowProps={{ className: "listing-table-row" }} rowsPerPage={suspiciousIps.size ?? 10} className="table" style={{ marginTop: 0 }} data={suspiciousIps.content} totalRecords={suspiciousIps.total_elements} dataRefreshKeys={["external_id"]} loadingState={Elements.LOADING_STATE} emptyState={<div className="es" style={{ marginTop: 20 }}>
                <i className="fa fa-bullseye" />
                <span style={{ maxWidth: 150 }}>{i18nManager.Labels.common.no} {labels.sided_labels.fine_name} {labels.table.reported}</span>
            </div>} onRowSelection={(suspiciousIp: ISuspiciousIp) => {
                setActiveSuspiciousIpProps(suspiciousIp);
                Utils.updateSearchParams("active_data_id", suspiciousIp.external_id, setSearchParams);
                return false;
            }}>
                <Column header={labels.table.ip_address} dataKey="ip_address" />
                <Column header={labels.table.platform_no} template={(suspiciousIp: ISuspiciousIp) => `${Object.keys(suspiciousIp.platforms_suspicious ?? {}).length} ${labels.table.platforms}`} />
                <Column header={labels.table.first_reported_on} template={(suspiciousIp: ISuspiciousIp) => Utils.formatToFineDate(suspiciousIp.created_at)} />
                <Column header={labels.table.last_reported_on} template={(suspiciousIp: ISuspiciousIp) => Utils.formatToFineDate(suspiciousIp.updated_at)} />
            </Table>
            {Elements.buildPaginator(suspiciousIps ?? {}, ({ currentPage }, size) => Utils.updateSearchParamses({ "page": currentPage, id: null, size }, setSearchParams, fetchSuspiciousIps), parseInt(searchParams.get("page") ?? "1"))}
        </React.Fragment>);
    }

    function buildActiveData(suspiciousIp: ISuspiciousIp) {
        return (<React.Fragment>
            <div className="header">
                <i className="fa fa-times" onClick={() => {
                    setActiveSuspiciousIp({});
                    Utils.updateSearchParams("active_data_id", undefined, setSearchParams, fetchSuspiciousIps);
                }} />
                <span className="name">{suspiciousIp?.ip_address}</span>
            </div>
            <div className="middle" style={{ borderTop: "none", marginTop: 0 }}>
            </div>
            <div className="actions">
                <a target="_blank" href={`https://whatismyipaddress.com/ip/${suspiciousIp.ip_address}`}>{labels.active_listing.view_ip_info}</a>
                <span className="link" onClick={() => showTrails(suspiciousIp)}>{labels.active_listing.show_trails}</span>
                <span className="link danger" onClick={() => deleteListing(suspiciousIp)}>{labels.active_listing.delete_suspicious_ip}</span>
            </div>
        </React.Fragment>);
    }

    function showTrails(suspiciousIp: ISuspiciousIp) {
        const dialog = ConfirmDialog({
            closable: true,
            confirmLabel: null,
            style: { maxWidth: "850px" },
            title: labels.active_listing.trails,
            onCancel: () => Utils.updateSearchParams("popup", undefined, setSearchParams),
            content: (<div className="message-event-logs">
                {(suspiciousIp?.trails ?? []).map((trail, index) => <span key={index}>• {trail} </span>)}
            </div>)
        });
        dialog.show();
    }

    function deleteListing(suspiciousIp: ISuspiciousIp) {
        const deleteLabels = labels.delete;
        const dialog = ConfirmDialog({
            closable: true,
            confirmScheme: Scheme.DANGER,
            desc: deleteLabels.desc.replace("${ipAddress}", suspiciousIp.ip_address),
            title: deleteLabels.title.replace("${ipAddress}", suspiciousIp.ip_address),
            onConfirm: (button?: ButtonManageRef) => {
                button?.setLoadingState(true);
                suspiciousIpsService.deleteSuspiciousIp(suspiciousIp.external_id).then(() => {
                    Siren.alert(deleteLabels.successful);
                    dialog.destroy(() => Utils.updateSearchParams("active_data_id", undefined, setSearchParams, fetchSuspiciousIps));
                }).catch(BaseService.reportError).finally(() => button?.setLoadingState(false));
                return false;
            }
        });
        dialog.show();
    }

}

export default SuspiciousIps;