
import React from "react";
import { Schemes, Utils } from "../../../utils";
import { FormControls, i18nManager } from "../../../i18n";
import { NavLink, useSearchParams } from "react-router-dom";
import { ApiResponse, BaseService, INotification, NotificationService, SanitizedResponse } from "../../../services";
import { Checkbox, DateInput, DateTimePickerSelectionMode, FormControl, FormGroup, TextInput, ViewportSensor } from "@ronuse/noseur";

function Notifications() {
    const notificationService = NotificationService.getInstance();
    const labels = i18nManager.Labels.dashboard.account.notifications;
    const [searchParams, setSearchParams] = useSearchParams(window.location.search);
    const [notifications, setNotifications] = React.useState<ApiResponse<INotification>>();

    React.useEffect(fetchNotifications, []);

    return (<div className="notifications">
        <div className="left">
            <span className="t">{labels.title}</span>
            <FormGroup scheme={Schemes.RIVTN_QUIRINUS} className="form">
                <FormControl leftContent={"fa fa-search"} validStyle={{ borderColor: "transparent" }} contentStyle={{ borderRadius: 0 }} fill>
                    <TextInput placeholder={labels.query.search_placeholder} defaultValue={searchParams.get("message") || undefined}
                        onInputComplete={(e: string) => Utils.updateSearchParams("message", e || undefined, setSearchParams, fetchNotifications)} noStyle />
                </FormControl>
                <div style={{ marginTop: 10, fontSize: 14 }}>{labels.query.date_range}</div>
                <DateInput leftContent={"fa fa-calendar-alt"} placeholder={labels.query.date_range_placeholder} selectionMode={DateTimePickerSelectionMode.RANGE}
                    formControlProps={{ validStyle: { borderColor: "transparent" }, contentStyle: { borderRadius: 0 } }} highlightDatesInRange noStyle fill />
                <div style={{ marginTop: 20, marginBottom: 15, fontSize: 14 }}>{labels.query.status.label}</div>
                {labels.query.status.form.map((input: FormControls, index: number) => (<Checkbox key={index} label={input.placeholder}
                    style={{ marginBottom: 10 }} defaultChecked={(input.name === "true") ? searchParams.get("read") === "true" : searchParams.get("read") === "false"}
                    onChange={(option: any) => {
                        let readValue;
                        if (input.name === "true") {
                            if (option.checked && searchParams.get("read") !== "false") readValue = "true";
                        }
                        if (input.name === "false") {
                            if (option.checked && searchParams.get("read") !== "true") readValue = "false";
                        }
                        Utils.updateSearchParams("read", readValue, setSearchParams, fetchNotifications);
                    }} />))}
                <div style={{ marginTop: 20, marginBottom: 15, fontSize: 14 }}>{labels.query.group.label}</div>
                {labels.query.group.form.map((input: FormControls, index: number) => (<Checkbox key={index} label={input.placeholder} style={{ marginBottom: 10 }}
                    defaultChecked={searchParams.getAll("group").includes(input.name)}
                    onChange={(option: any) => {
                        const groups = searchParams.getAll("group");
                        const groupIndex = groups.indexOf(input.name);
                        if (option.checked && groupIndex < 0) groups.push(input.name);
                        else if (!option.checked && groupIndex > -1) groups.splice(groupIndex, 1);
                        Utils.updateSearchParams("group", groups, setSearchParams, fetchNotifications);
                    }} />))}
            </FormGroup>
        </div>
        <div className="right">
            {Object.keys(notifications?.data?.transformed || {}).map((group: string, index: number) => {
                if (!notifications?.data?.transformed[group].length) return;
                return (<div className="grp" key={index}>
                    <div className="t">{group}</div>
                    {notifications?.data?.transformed[group].map((notification: INotification, sindex: number) => (<div className={"notification" + (notification.read === false ? "" : ` r`)} key={sindex}>
                        <NavLink className="l" to={{
                            pathname: getnotificationActionRoute(notification.group), search: Utils.urlParamsToSearch(new URLSearchParams(searchParams), ["platform_id", "platform_page"], {
                                id: notification.extra_data?.id
                            })
                        }}>
                            <img src={notification.logo || labels.notification.default_logo} />
                            <div>
                                <span className="t">{notification.title}</span>
                                <span className="m">{notification.message}</span>
                                <span className="c">{Utils.dateToFineValue(new Date(notification.created_at))}{group === labels.notification.earlier
                                    ? " - " + Utils.formatToFineDate(notification.created_at) : ""}</span>
                            </div>
                        </NavLink>
                        <div className="r">
                            <span className={notification.read === false ? undefined : "r"}
                                onClick={(e) => { e.stopPropagation(); notificationService.readNotification(notification.external_id).then(fetchNotifications).catch(BaseService.reportError); }}>{labels.notification.mark_as_read}</span>
                            <span className="d"
                                onClick={(e) => notificationService.deleteNotification(notification.external_id).then(fetchNotifications).catch(BaseService.reportError).finally(() => e.stopPropagation())}>{labels.notification.delete}</span>
                        </div>
                    </div>))}
                </div>);
            })}
            <ViewportSensor className="loader" onEnterViewport={() => {
                if (!notifications?.data?.total_pages) return false;
                const currentPage = (notifications?.data?.pageable?.page_number! + 1);
                if (currentPage === notifications?.data?.total_pages) return false;
                Utils.updateSearchParams("page", currentPage + 1, setSearchParams, () => {
                    fetchNotifications((apiResponse: ApiResponse<INotification>) => {
                        if (apiResponse.data) apiResponse.data.content = [...(notifications?.data?.content || []), ...(apiResponse.data.content || [])];
                        Utils.updateSearchParams("page", null, setSearchParams);
                    })
                });
                return true;
            }} onExitViewport={() => false} preRenderChildren>
                <i className="fa fa-spinner fa-spin" />
            </ViewportSensor>
        </div>
    </div>);

    function fetchNotifications(cb?: (apiResponse: ApiResponse<INotification>) => void) {
        notificationService.queryNotification(Utils.normalizeUrlParams(searchParams), labels.notification, cb)
            .then(({ apiResponse }: SanitizedResponse<INotification>) => setNotifications(apiResponse))
            .catch(BaseService.reportError);
    }

    function getnotificationActionRoute(group: string) {
        if (group === "MESSAGE") return "/dashboard/account/messages";
    }

}

export default Notifications;
