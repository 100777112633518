
import { NoseurObject } from "@ronuse/noseur";
import { CacheManager } from "../../../utils";
import { BaseService, SanitizedResponse } from "../../BaseService";

export interface IParticipant {
    name: string;
    email: string;
    last_name: string;
    first_name: string;
    external_id: string;
    profile_picture_location: string;
};

export interface IMsg {
    sn: number;
    value: string;
    sent_at: string;
    sender_id: string;
    sender: IParticipant;
}

export interface IAdministratorMessage {
    title: string;
    closed: boolean;
    messages: IMsg[];
    created_at: string;
    updated_at: string;
    external_id: string;
    initiator: IParticipant;
    participants: IParticipant[];
}

export class AdministratorMessageService extends BaseService {

    protected static instance: AdministratorMessageService;

    private constructor() {
        super();
    }

    static getInstance() {
        if (!AdministratorMessageService.instance) AdministratorMessageService.instance = new AdministratorMessageService();
        return AdministratorMessageService.instance;
    }

    queryAdministratorMessages(params: NoseurObject<any> = { page: 1, size: 10 }): Promise<any> {
        params.sort = "updated_at,DESC";
        return this.report(this.transport.get("/janus/geminus/api/v1/administrator/message", { params: this.normalizeParams(params) } as any));
    }

    updateAdministratorMessage(externalId: string, participants: { participant_ids: string[] }): Promise<any> {
        return this.report(this.transport.patch(`/janus/geminus/api/v1/administrator/message/${externalId}`, participants, this.config));
    }

    sendAdministratorMessage(externalId: string, administratorMessage: string): Promise<any> {
        return this.report(this.transport.put(`/janus/geminus/api/v1/administrator/message/${externalId}`, { administratorMessage }, this.config));
    }

    getSingleAdministratorMessage(id: string): Promise<any> {
        return this.report(this.transport.get(`/janus/geminus/api/v1/administrator/message/${id}`));
    }

    closeAdministratorMessage(externalId: string): Promise<any> {
        return this.report(this.transport.delete(`/janus/geminus/api/v1/administrator/message/${externalId}`, this.config));
    }

    createAdministratorMessage(administratorMessage: { message: string, participant_ids: string[] }): Promise<any> {
        return this.report(this.transport.post(`/janus/geminus/api/v1/administrator/message`, administratorMessage, this.config));
    }

}

