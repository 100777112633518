
import { BaseService } from "../../BaseService";

export enum UserStatus {
    ACTIVE = "ACTIVE",
    DEACTIVATED = "DEACTIVATED"
}

export enum EmailChangeRequestStatus {
    PENDING = "PENDING",
    APPROVED = "APPROVED",
    REJECTED = "REJECTED",
}

export class LanuariusBaseService extends BaseService {

    constructor() {
        super(process.env.REACT_APP_LANUARIUS_BASE_API_URL ?? "http://127.0.0.1:9001");
    }

}

