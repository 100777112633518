
import React from "react";
import { i18nManager } from "../../i18n";
import { BaseService } from "../../services";

export interface RequestCodeControlsProps<T extends BaseService> {
    service: T;
    params?: any;
    errorRef: React.RefObject<HTMLDivElement>;
    requestCode: (service: T, params: any, onFail: (errorMessage: string) => void) => boolean;
}

function RequestCodeControls<T extends BaseService>(props: RequestCodeControlsProps<T>) {
    const labels = i18nManager.Labels.common.request_fa_code;
    const faCodeTimerLabel = React.useRef<HTMLSpanElement>(null);
    const faCodeRequestButton = React.useRef<HTMLAnchorElement>(null);
    const faCodeRequesAgainButton = React.useRef<HTMLAnchorElement>(null);

    return (<div style={{ marginTop: 10, fontSize: 12, display: "flex" }}>
        <a ref={faCodeRequestButton} style={{ cursor: "pointer" }} onClick={requestFaToken}>{labels.get_auth_code}</a>
        <span ref={faCodeTimerLabel} style={{ marginRight: 5, display: "none", minWidth: 30 }}>02:00</span>
        <a ref={faCodeRequesAgainButton} style={{ cursor: "pointer", display: "none" }} onClick={requestFaToken}>{labels.request_new_codes}</a>
    </div>);

    function requestFaToken() {
        let interval: NodeJS.Timeout;
        props.errorRef.current!.innerText = " ";
        if (props.requestCode && props.requestCode(props.service, props.params, (errorMessage: string) => {
            clearInterval(interval);
            faCodeTimerLabel.current!.innerText = "01:00";
            props.errorRef.current!.innerText = errorMessage;
            faCodeTimerLabel.current!.style.display = "none";
            faCodeRequestButton.current!.style.display = "block";
            faCodeRequesAgainButton.current!.style.display = "none";
        })) {
            if (!(!faCodeRequestButton.current || !faCodeTimerLabel.current || !faCodeRequesAgainButton.current)) {
                faCodeTimerLabel.current.innerText = "01:00";
                faCodeTimerLabel.current.style.display = "block";
                faCodeRequestButton.current.style.display = "none";
                faCodeRequesAgainButton.current.style.color = "gray";
                faCodeRequesAgainButton.current.style.display = "block";
                faCodeRequesAgainButton.current.style.pointerEvents = "none";
            }
            let minutesToWait = 2;
            let timeCounter = 60 * minutesToWait;
            interval = setInterval(() => {
                if (timeCounter <= 0) {
                    clearInterval(interval);
                    if (faCodeRequesAgainButton.current) {
                        faCodeRequesAgainButton.current.style.color = "#0FA883";
                        faCodeRequesAgainButton.current.style.pointerEvents = "all";
                    }
                    return;
                }
                if (faCodeTimerLabel.current) {
                    let minutes = Math.ceil(timeCounter / 60) - 1;
                    let seconds = Math.floor(timeCounter / (minutes + 1)) - 1;
                    faCodeTimerLabel.current.innerText = `0${minutes}:${seconds}`;
                    timeCounter--;
                }
            }, 800);
        }
    }

}

export default RequestCodeControls;
