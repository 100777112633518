
import React from "react";
import { ConfirmDialog, Elements } from "../../shared";
import { useSearchParams } from "react-router-dom";
import { CacheManager, Schemes, Status, Utils } from "../../../utils";
import { FormControls, i18nManager } from "../../../i18n";
import { AccountService, AdministrationService, ApiResponseData, BaseService, IAdministrator, IPlatform, IPlatformAdministrator, ISession, PlatformService, SanitizedResponse, SessionService } from "../../../services";
import { Alignment, Button, ButtonManageRef, Checkbox, Classname, Column, Dialog, FileInput, NoseurObject, Scheme, Table } from "@ronuse/noseur";

const Toggle = Checkbox; // create toggle on noseur

enum Fragment {
    PROFILE = "profile",
    SESSIONS = "sessions",
    PLATFORMS = "platforms",
    ACTIVITIES = "activities",
    ACCOUNT_SETTINGS = "account_settings",
}

interface ManageAdminProps {
    administrator: IAdministrator;
}

function ManageAdmin(props: ManageAdminProps) {
    const sessionService = SessionService.getInstance();
    const platformService = PlatformService.getInstance();
    const dialogErrorRef = React.useRef<HTMLDivElement>(null);
    const [visible, setVisible] = React.useState<boolean>(true);
    const countries = [{ label: "Nigeria" }, { label: "Ghana" },];
    const administrationService = AdministrationService.getInstance();
    const [actionOngoing, setActionOngoing] = React.useState<boolean>(false);
    const platformLabels = i18nManager.Labels.dashboard.account.profile.platforms;
    const [sessions, setSessions] = React.useState<ApiResponseData<ISession>>({});
    const actionButtonManageRef = React.useRef<NoseurObject<ButtonManageRef>>({});
    const [searchParams, setSearchParams] = useSearchParams(window.location.search);
    const accountSettingsLabels = i18nManager.Labels.dashboard.account.account_settings;
    const labels = i18nManager.Labels.dashboard.administration.management.dialog.manage_account;
    const [fragment, setFragment] = React.useState<Fragment>((searchParams.get("fragment") ?? Fragment.PROFILE) as Fragment);
    const [administratorPlatformData, setAdministratorPlatformData] = React.useState<ApiResponseData<IPlatformAdministrator>>({});
    const fragmentLabels = (labels.fragments as any)[fragment as any];
    const fragmentQueryMap: NoseurObject<Function> = {
        "sessions": fetchSession,
        "platforms": fetchPlatforms,
    }

    React.useEffect(() => {
        if (!(fragment in fragmentQueryMap)) return;
        fragmentQueryMap[fragment]();
    }, [fragment]);

    return (<Dialog contentProps={{ className: "manage-admin" }} style={{ minWidth: "85%", height: "90%" }} visible={visible} onHide={onClose} notClosable dismissableModal={false}>
        <div className="left">
            <span className="title">{labels.title}</span>
            <div className="participant">
                {Elements.buildAdministratorPreview(props.administrator)}
            </div>
            <div className="actions">
                <span className={Classname.build(fragment === Fragment.PROFILE ? "active" : null)} onClick={() => switchFragment(Fragment.PROFILE)}>{labels.actions.profile}</span>
                <span className={Classname.build(fragment === Fragment.ACCOUNT_SETTINGS ? "active" : null)} onClick={() => switchFragment(Fragment.ACCOUNT_SETTINGS)}>{labels.actions.account_settings}</span>
                <span className={Classname.build(fragment === Fragment.PLATFORMS ? "active" : null)} onClick={() => switchFragment(Fragment.PLATFORMS)}>{labels.actions.platforms}</span>
                <span className={Classname.build(fragment === Fragment.SESSIONS ? "active" : null)} onClick={() => switchFragment(Fragment.SESSIONS)}>{labels.actions.sessions}</span>
                <span className={Classname.build(fragment === Fragment.ACTIVITIES ? "active" : null)} onClick={() => switchFragment(Fragment.ACTIVITIES)}>{labels.actions.activities}</span>
            </div>
            <Button text={i18nManager.Labels.common.close} scheme={Schemes.RIVTN_QUIRINUS} onClick={onClose} textOnly />
        </div>
        <div className="right">
            <span>{fragmentLabels.title}</span>
            <div className="fragment">
                {fragment === Fragment.PROFILE ? profileView() : null}
                {fragment === Fragment.PLATFORMS ? platformsView() : null}
                {fragment === Fragment.ACTIVITIES ? activitiesView() : null}
                {fragment === Fragment.ACCOUNT_SETTINGS ? accountSettingsView() : null}
                {fragment === Fragment.SESSIONS ? Elements.buildSessionView(sessions, fragmentLabels.desc, searchParams, setSearchParams, fetchSession, (session: ISession, onDone: () => void) => {
                    sessionService.superRevokeSession(session.id).then(fetchSession).catch(BaseService.reportError).finally(onDone);;
                }) : null}
            </div>
        </div>
    </Dialog>);

    function switchFragment(fragmentation: Fragment) {
        if (fragmentation === fragment) return;
        setFragment(fragmentation);
        Utils.updateSearchParams("fragment", fragmentation, setSearchParams);
    }

    function profileView() {
        return (<div className="profile-view">
            <FileInput stickyPreview className="pic" rounded scheme={Schemes.RIVTN_QUIRINUS} accepts="image/*"
                label={<i className='fa fa-pen' />} attrsRelay={{ label: { style: { borderRadius: "50%" }, alignment: Alignment.TOP_LEFT } }}
                emptyTemplate={(select) => <img onClick={select} style={{ width: "100%", height: "100%" }} src={props.administrator.profile_picture_location} />} />
            <div className="inputs">
                {Elements.buildConfirmDialogForm(labels.fragments.profile.form, (name: string, value: any) => {
                    dialogErrorRef.current!.innerText = " ";
                    if (name === "dob") value = Utils.formatToApiDate(value.selectedDate, true);
                    (props.administrator as any)[name] = value;
                }, props.administrator, undefined, { marginTop: 0 }, { country: countries })}
                <div ref={dialogErrorRef} className="error"> </div>
                <div style={{ flex: 0.6 }} />
                <Button manageRef={(m) => { if (m) actionButtonManageRef.current["change-email"] = m }} scheme={Schemes.RIVTN_QUIRINUS} text={i18nManager.Labels.common.save} loadingProps={{
                    disabled: true,
                    leftIcon: "fas fa-spinner fa-pulse"
                }} onClick={() => {
                    actionButtonManageRef.current["change-email"]?.setLoadingState(true);
                    administrationService.updateAdminProfile(props.administrator).then(({ }: SanitizedResponse<any>) => {
                        ConfirmDialog({
                            centerIcon: Elements.DIALOG_CHECK_ICON,
                            confirmLabel: i18nManager.Labels.common.continue,
                            title: accountSettingsLabels.change_email.dialog!.title,
                            desc: accountSettingsLabels.change_email.dialog!.successful,
                        }).show();// TODO: use the toast
                    }).catch(({ errorMessage }: SanitizedResponse<any>) => {
                        dialogErrorRef.current!.innerText = errorMessage;
                    }).finally(() => actionButtonManageRef.current["change-email"]?.setLoadingState(false));
                }} style={{ alignSelf: "flex-end", minWidth: 120 }} />
            </div>
        </div>);
    }

    function accountSettingsView() {
        const formValues: NoseurObject<string> = { reason: "Not specifiable" };
        return (<div className="acc-settings">
            <div className="acc-toggles-modifiers" style={{ padding: 0, marginTop: 30, fontSize: 13, height: "auto" }}>
                <div className="tp">
                    {accountSettingsLabels.notifications.map((control: FormControls, index: number) => (<div key={index}>
                        <div style={{ marginRight: 50 }}>
                            <span className="ti">{control.title}</span>
                            <span className="sti">{control.sub_title}</span>
                        </div>
                        <Toggle scheme={Schemes.RIVTN_QUIRINUS} />
                    </div>))}
                </div>
            </div>
            <p className={Classname.build("link", (actionOngoing ? "noseur-disabled" : null))} onClick={sendPasswordReset} style={{ width: "fit-content" }}>{accountSettingsLabels.send_password.replace("${email}", props.administrator.email)}</p>
            <div className="change-email">
                <span className="title">{accountSettingsLabels.change_email.title}</span>
                {Elements.buildConfirmDialogForm(accountSettingsLabels.change_email.dialog!.sub_form!, (name: string, value: string) => {
                    formValues[name] = value;
                    dialogErrorRef.current!.innerText = " ";
                }, undefined, undefined, { marginTop: 10 })}
                <div ref={dialogErrorRef} className="error"> </div>
                <Button manageRef={(m) => { if (m) actionButtonManageRef.current["change-email"] = m }} scheme={Schemes.RIVTN_QUIRINUS} text={accountSettingsLabels.change_email.short_title} loadingProps={{
                    disabled: true,
                    leftIcon: "fas fa-spinner fa-pulse"
                }} onClick={() => {
                    dialogErrorRef.current!.innerText = " ";
                    if (!("code" in formValues) || !("email" in formValues)) {
                        dialogErrorRef.current!.innerText = accountSettingsLabels.change_email.dialog!.form_error!;
                        return;
                    }
                    actionButtonManageRef.current["change-email"]?.setLoadingState(true);
                    administrationService.changeAdminEmail(props.administrator.external_id, formValues as any).then(({ }: SanitizedResponse<any>) => {
                        ConfirmDialog({
                            centerIcon: Elements.DIALOG_CHECK_ICON,
                            confirmLabel: i18nManager.Labels.common.continue,
                            title: accountSettingsLabels.change_email.dialog!.title,
                            desc: accountSettingsLabels.change_email.dialog!.successful,
                            onConfirm: () => {
                                window.location.reload();
                                return true;
                            },
                        }).show();// TODO: use the toast
                    }).catch(({ errorMessage }: SanitizedResponse<any>) => {
                        dialogErrorRef.current!.innerText = errorMessage;
                    }).finally(() => actionButtonManageRef.current["change-email"]?.setLoadingState(false));
                }} />
            </div>
        </div>);
    }

    function platformsView() {
        return (<div className="platform-view">
            <Table className="table" data={administratorPlatformData.content} totalRecords={administratorPlatformData.total_elements} paginate>
                <Column template={(icon_location: any) => (<img className="icon rounded" src={icon_location} />)} dataKey="{platform.bacuator_data.icon_location}" />
                <Column dataKey="{platform.name}" header={platformLabels.table.name} />
                <Column dataKey="{platform.platform_id}" header={platformLabels.table.platform_id} />
                <Column dataKey="platform" template={(platform: IPlatform) => (<React.Fragment>
                    <Button manageRef={(m) => { if (m) actionButtonManageRef.current[platform.platform_id] = m }} text={i18nManager.Labels.common.remove} scheme={Scheme.DANGER} textOnly loadingProps={{
                        disabled: true,
                        leftIcon: "fas fa-spinner fa-pulse"
                    }} style={{ width: 120 }} onClick={() => {
                        actionButtonManageRef.current[platform.platform_id]?.setLoadingState(true);
                        platformService.assignAdministratorPlatforms(platform.external_id!, [], [props.administrator.external_id])
                            .then(() => {
                                fetchPlatforms();
                            }).catch(BaseService.reportError).finally(() => {
                                actionButtonManageRef.current[platform.platform_id]?.setLoadingState(false)
                            });
                    }} className="link danger" />
                </React.Fragment>)} />
            </Table>
        </div>);
    }

    function activitiesView() {
        return ""
    }


    function fetchSession() {
        sessionService.queryAllSession({
            actor_id: props.administrator.email,
            page: parseInt(searchParams.get("sessions_page") ?? "1")
        }).then(({ apiResponse }: SanitizedResponse<ISession>) => {
            setSessions(apiResponse.data!);
        }).catch(BaseService.reportError);
    }

    function fetchPlatforms() {
        platformService.queryPlatformAdministrators({
            page: parseInt(searchParams.get("platforms_page") ?? "1"),
            "administrator.external_id": props.administrator.external_id
        }).then(({ apiResponse }: SanitizedResponse<IPlatformAdministrator>) => {
            setAdministratorPlatformData(apiResponse.data!);
        }).catch(BaseService.reportError);
    }

    function sendPasswordReset() {
        setActionOngoing(true);
        administrationService.sendPasswordResetEmail(props.administrator.external_id, { prefix_url: `${window.location.host}/reset-password` }).then(({ }: SanitizedResponse<any>) => {
            ConfirmDialog({
                centerIcon: Elements.DIALOG_CHECK_ICON,
                ...i18nManager.Labels.auths.forgot_password.success,
                confirmLabel: i18nManager.Labels.common.continue,
            }).show();// TODO use toast
        }).catch(BaseService.reportError).finally(() => setActionOngoing(false));
    }

    function onClose() {
        setVisible(false);
        Utils.updateSearchParams("fragment", undefined, setSearchParams);
        Utils.updateSearchParams("active_admin_id", undefined, setSearchParams);
        Utils.updateSearchParams("sessions_page", undefined, setSearchParams);
        Utils.updateSearchParams("platforms_page", undefined, setSearchParams);
    }

}

export default ManageAdmin;
