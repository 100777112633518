
import React from "react";
import ManageAdmin from "./ManageAdmin";
import { useSearchParams } from "react-router-dom";
import { Schemes, Utils, Status } from "../../../utils";
import { FormControls, i18nManager } from "../../../i18n";
import { ConfirmDialog, Elements, LockAccount } from "../../shared";
import { AccountService, AdministrationService, ApiResponseData, BaseService, IAdministrator, SanitizedResponse } from "../../../services";
import { BoolHelper, Button, ButtonManageRef, Classname, Column, FormControl, FormGroup, NoseurObject, Paginator, PaginatorPageElementOption, Popover, Scheme, Table } from "@ronuse/noseur";

function Management() {
    const popoverManageRef = React.useRef<any>();
    const accountService = AccountService.getInstance();
    const dialogErrorRef = React.useRef<HTMLDivElement>(null);
    const subDialogErrorRef = React.useRef<HTMLDivElement>(null);
    const administrationService = AdministrationService.getInstance();
    const labels = i18nManager.Labels.dashboard.administration.management;
    const [searchParams, setSearchParams] = useSearchParams(window.location.search);
    const [activeAdministrator, setActiveAdministrator] = React.useState<IAdministrator>();
    const [adminsitratorsData, setAdminsitratorsData] = React.useState<ApiResponseData<IAdministrator>>({});

    React.useEffect(fetchAdministrators, []);
    React.useEffect(() => {
        if (!!activeAdministrator?.__internal_relay__) Utils.updateSearchParams("active_admin_id", activeAdministrator.external_id, setSearchParams);
    }, [activeAdministrator]);

    return (<div className="management">
        <span>{labels.title}</span>
        <div className="query-inputs">
            <FormGroup scheme={Schemes.RIVTN_QUIRINUS} className="form">
                {labels.adminstrator.actions.form.map(({ disabled, icon, type, name, placeholder, options }: FormControls, index: number) => {
                    const inputProps: any = {
                        name,
                        fill: true,
                        placeholder,
                        noStyle: true,
                        borderless: true,
                        style: { borderRadius: 0 },
                        disabled: (disabled === true),
                        defaultValue: searchParams.get(name),
                        onInputComplete: (e: string) => Utils.updateSearchParams(name, e || undefined, setSearchParams),
                    };
                    if (type === "dropdown") {
                        inputProps.options = options;
                        inputProps.onInputComplete = undefined;
                        inputProps.onSelectOption = (option: any) => {
                            Utils.updateSearchParams(name, option.value || undefined, setSearchParams);
                            return true;
                        };
                        inputProps.selectedOptionIndex = options?.findIndex(c => c.value === (searchParams.get(name) ?? ""));
                    }
                    return (<FormControl key={index} leftContent={icon}
                        validStyle={{ borderColor: "transparent" }} contentStyle={{ borderRadius: 0 }} >
                        {React.createElement(Utils.selectNoseurComponent(type!) as any, inputProps)}
                    </FormControl>);
                })}
                <Button text={labels.adminstrator.actions.apply_filters} style={{ padding: "10px 25px", marginBottom: 5 }} onClick={fetchAdministrators} raised />
                <div style={{ flex: 1 }} />
                <Button text={labels.adminstrator.actions.add_new_administrator} scheme={Scheme.DANGER} style={{ marginBottom: 5 }} onClick={() => addNewAdministrator()} raised />
            </FormGroup>
        </div>
        <Table className="table" data={adminsitratorsData.content} rowsPerPage={adminsitratorsData.size ?? 10} totalRecords={adminsitratorsData.total_elements} loadingState={Elements.LOADING_STATE}>
            <Column template={(profile_picture_location: any) => (<img className="icon rounded" src={profile_picture_location} />)} dataKey="profile_picture_location" />
            <Column sortable dataKey="{first_name} {last_name}" header={labels.adminstrator.table.name} />
            <Column sortable dataKey="email" header={labels.adminstrator.table.email} />
            <Column sortable dataKey="post_title" header={labels.adminstrator.table.post_title} />
            <Column sortable dataKey="status" header={labels.adminstrator.table.status} template={(status) => selectStyledStatus(status)} />
            <Column template={(administrator: IAdministrator) => (<i className={Classname.build("fa fa-ellipsis-h", (administrator.status === Status.KICKED ? "noseur-disabled" : null))} style={{ cursor: "pointer" }}
                onClick={(e: any) => {
                    setActiveAdministrator(administrator);
                    popoverManageRef.current?.toggle(e);
                }} />)} />
        </Table>
        {Elements.buildPaginator(adminsitratorsData ?? {}, ({ currentPage }, size) => Utils.updateSearchParamses({ "page": currentPage, id: null, size }, setSearchParams, fetchAdministrators), parseInt(searchParams.get("page") ?? "1"))}

        {activeAdministrator && !!activeAdministrator.__internal_relay__ ? <ManageAdmin administrator={activeAdministrator} /> : null}

        <Popover className="dashboard-popover" manageRef={popoverManageRef} style={{ padding: "10px 0px" }} dismissOnClick>
            <span className="popover-link" onClick={() => setActiveAdministrator({ ...(activeAdministrator! || {}), __internal_relay__: true })}>{labels.adminstrator.popover.manage_account}</span>
            {!BoolHelper.equalsAny(activeAdministrator?.status, [Status.PENDING_APPROVAL, Status.REJECTED])
                ? (<span className="popover-link" onClick={() => activateDeactivateAdmin(activeAdministrator!, activeAdministrator?.status !== Status.DEACTIVATED)}>{activeAdministrator?.status === Status.DEACTIVATED
                    ? labels.adminstrator.popover.reactivate_account : labels.adminstrator.popover.deactivate_account}</span>) : null}
            {BoolHelper.equalsAny(activeAdministrator?.status, [Status.APPROVED, Status.LOCKED])
                ? <span className="popover-link"
                    onClick={() => LockAccount.lockUnlockAccount(activeAdministrator?.status !== Status.LOCKED, (activeAdministrator?.status === Status.LOCKED ? (labels.dialog.unlock as any) : labels.dialog.lock), accountService, dialogErrorRef, { external_id: activeAdministrator!.external_id }, activeAdministrator!, fetchAdministrators)}>
                    {activeAdministrator?.status === Status.LOCKED
                        ? labels.adminstrator.popover.unlock_account
                        : labels.adminstrator.popover.lock_account}</span> : null}
            <span className="popover-link" onClick={() => kickAdmin(activeAdministrator!)}>{labels.adminstrator.popover.kick}</span>
        </Popover>
    </div>);

    function selectStyledStatus(status: string) {
        if (status === Status.KICKED) return (<span style={{ color: "#000000" }}>{labels.adminstrator.table.statuses.kicked}</span>);
        if (status === Status.LOCKED) return (<span style={{ color: "#0F68A8" }}>{labels.adminstrator.table.statuses.locked}</span>);
        if (status === Status.REJECTED) return (<span style={{ color: "#400FA8" }}>{labels.adminstrator.table.statuses.rejected}</span>);
        if (status === Status.DEACTIVATED) return (<span style={{ color: "#EB5959" }}>{labels.adminstrator.table.statuses.deactivated}</span>);
        if (status === Status.PENDING_APPROVAL) return (<span style={{ color: "#A5A80F" }}>{labels.adminstrator.table.statuses.pending_pproval}</span>);
        return (<span style={{ color: "#0FA883" }}>{labels.adminstrator.table.statuses.approved}</span>);
    }

    function fetchAdministrators() {
        let fetchedQueryParamsAdministrator = !searchParams.get("active_admin_id");
        administrationService.queryAdministrator(Utils.normalizeUrlParams(searchParams)).then(({ apiResponse }: SanitizedResponse<IAdministrator>) => {
            setAdminsitratorsData(apiResponse.data!);
            if (!fetchedQueryParamsAdministrator) {
                apiResponse.data!.content!.forEach(a => {
                    if (a.external_id === searchParams.get("active_admin_id")) {
                        setActiveAdministrator({ ...a, __internal_relay__: true });
                        fetchedQueryParamsAdministrator = true;
                    }
                });
            }
            if (!fetchedQueryParamsAdministrator) {
                administrationService.getSingleAdministrator(searchParams.get("active_admin_id")!).then(({ apiResponse }: SanitizedResponse<IAdministrator>) => {
                    setActiveAdministrator({ ...apiResponse.data as IAdministrator, __internal_relay__: true });
                }).catch(BaseService.reportError);
            }
        }).catch(BaseService.reportError);
    }

    function addNewAdministrator(formValues: NoseurObject<any> = {}) {
        const dialog = ConfirmDialog({
            closable: true,
            ...labels.add_new_addministrator_dialog,
            content: (<div className="add-new-admin">
                <span className="pass-hint">{labels.add_new_addministrator_dialog.password_reset_hint}</span>
                {Elements.buildConfirmDialogForm(labels.add_new_addministrator_dialog.form, (name: string, value: string) => {
                    formValues[name] = value;
                    dialogErrorRef.current!.innerText = " ";
                }, formValues)}
                <div ref={dialogErrorRef} className="error"> </div>
            </div>),
            onConfirm: () => {
                dialogErrorRef.current!.innerText = " ";
                let errorText = "";
                labels.add_new_addministrator_dialog.error_map.forEach((errorer) => {
                    if (!formValues[errorer.key]) errorText += `${errorer.value}\n`;
                })
                if (errorText) {
                    dialogErrorRef.current!.innerText = errorText;
                    return false;
                }
                dialog.destroy();
                const faDialog = ConfirmDialog({
                    closable: true,
                    ...labels.add_new_addministrator_dialog,
                    content: (<div className="add-new-admin">
                        <span className="fa-hint">{labels.add_new_addministrator_dialog.fa_desc}</span>
                        {Elements.buildConfirmDialogForm(labels.add_new_addministrator_dialog.fa_form, (name: string, value: string) => {
                            formValues[name] = value;
                            subDialogErrorRef.current!.innerText = " ";
                        }, formValues)}
                        <div ref={subDialogErrorRef} className="error"> </div>
                    </div>),
                    cancelLabel: i18nManager.Labels.common.back,
                    onCancel: () => addNewAdministrator(formValues),
                    onConfirm: (button?: ButtonManageRef) => {
                        button?.setLoadingState(true);
                        subDialogErrorRef.current!.innerText = " ";
                        administrationService.createAdministrator(formValues as IAdministrator)
                            .then(({ }: SanitizedResponse<any>) => {
                                faDialog.destroy(); fetchAdministrators();
                            }).catch(({ errorMessage }: SanitizedResponse<any>) => {
                                subDialogErrorRef.current!.innerText = errorMessage;
                            }).finally(() => {
                                button?.setLoadingState(false);
                            });
                        return false;
                    }
                });
                faDialog.show();
                return false;
            }
        });
        dialog.show();
    }

    function activateDeactivateAdmin(administrator: IAdministrator, deactivate: boolean = true) {
        const label = labels.dialog.activate_deactivate[deactivate ? "deactivate" : "reactivate"];
        const dialog = ConfirmDialog({
            ...label,
            closable: true,
            confirmLabel: label.confirm,
            cancelLabel: i18nManager.Labels.common.cancel,
            confirmScheme: (deactivate ? Scheme.DANGER : undefined),
            content: (<div style={{ marginTop: 20 }}>
                <div className="participant">
                    {Elements.buildAdministratorPreview(administrator)}
                </div>
                <div ref={dialogErrorRef} className="error"> </div>
            </div>),
            onConfirm: (button?: ButtonManageRef) => {
                dialogErrorRef.current!.innerText = " ";
                button?.setLoadingState(true);
                administrationService.activateDeactivateAdministrator(administrator.external_id, deactivate)
                    .then(({ }: SanitizedResponse<any>) => {
                        dialog.destroy(fetchAdministrators);
                    }).catch(({ errorMessage }: SanitizedResponse<any>) => {
                        dialogErrorRef.current!.innerText = errorMessage;
                    }).finally(() => {
                        button?.setLoadingState(false);
                    });
                return false;
            }
        });
        dialog.show();
    }

    function kickAdmin(administrator: IAdministrator) {
        const formValues: NoseurObject<string> = { code: "" };
        const dialog = ConfirmDialog({
            closable: true,
            ...labels.dialog.kick,
            confirmScheme: Scheme.DARK,
            confirmLabel: labels.dialog.kick.confirm,
            cancelLabel: i18nManager.Labels.common.cancel,
            content: (<div style={{ marginTop: 20 }}>
                <div className="participant" style={{ marginBottom: 20 }}>
                    {Elements.buildAdministratorPreview(administrator)}
                </div>
                {Elements.buildConfirmDialogForm(labels.dialog.kick.form, (name: string, value: string) => {
                    dialogErrorRef.current!.innerText = " "; formValues[name] = value;
                })}
                <div ref={dialogErrorRef} className="error"> </div>
            </div>),
            onConfirm: (button?: ButtonManageRef) => {
                dialogErrorRef.current!.innerText = " ";
                button?.setLoadingState(true);
                administrationService.kickAdministrator(administrator.external_id, formValues.code)
                    .then(({ }: SanitizedResponse<any>) => {
                        dialog.destroy(fetchAdministrators);
                    }).catch(({ errorMessage }: SanitizedResponse<any>) => {
                        dialogErrorRef.current!.innerText = errorMessage;
                    }).finally(() => {
                        button?.setLoadingState(false);
                    });
                return false;
            }
        });
        dialog.show();
    }

}

export default Management;
