
import React, { } from "react";
import { i18nManager } from "../../../../i18n";
import { useSearchParams } from "react-router-dom";
import { CacheManager, Schemes, Utils } from "../../../../utils";
import { BaseService, IPlatform, PlatformService, SanitizedResponse } from "../../../../services";
import { Checkbox, Classname, Column, FileInput, FileInputManageRef, NoseurObject, Popover, PopoverManageRef, Table, TextAreaInput, Transition, TypeChecker } from "@ronuse/noseur";

export interface DatabaseQueryProps {
    loading: boolean;
    platform?: IPlatform;
}

function DatabaseQuery(props: DatabaseQueryProps) {
    const platformService = PlatformService.getInstance();
    const queryInputRef = React.useRef<HTMLInputElement>(null);
    const resultInputRef = React.useRef<HTMLInputElement>(null);
    const selectFileManageRef = React.useRef<FileInputManageRef>(null);
    const loadResultManageRef = React.useRef<FileInputManageRef>(null);
    const queryCopiedPopOverManageRef = React.useRef<PopoverManageRef>(null);
    const [queryInProgress, setQueryInProgress] = React.useState<boolean>(false);
    const [selectedResultTab, setSelectedResultTab] = React.useState<string>("");
    const [searchParams, setSearchParams] = useSearchParams(window.location.search);
    const [selectedTopQueryIndex, setSelectedTopQueryIndex] = React.useState<number>();
    const [queryExecutionResult, setQueryExecutionResult] = React.useState<NoseurObject<any[]>>({});
    const labels = i18nManager.Labels.dashboard.platform_management.service_debugging.database_query;
    const [last10Queries, setLast10Queries] = React.useState<string[]>(CacheManager.get("rivtn.quirinus.l10q", []) as string[]);
    const [queryEndpoint, setQueryEndpoint] = React.useState<string>(props.platform?.bacuator_data?.database_multiple_query_route ?? "");

    React.useEffect(() => {
        if (!props.platform) return;
        let protocol = "http://";
        const noProtocolBaseServiceUrl = props.platform.bacuator_route;
        if (noProtocolBaseServiceUrl.startsWith("https")) {
            protocol = "https://";
        }
        const baseServiceUrl = noProtocolBaseServiceUrl.replace(protocol, "")?.substring(0, noProtocolBaseServiceUrl.replace(protocol, "").indexOf("/"));
        setQueryEndpoint(protocol + baseServiceUrl + props.platform.bacuator_data?.database_multiple_query_route);
    }, [props.platform]);

    return (<div className="content management-links db-query">
        {props.loading ? (<i className="fa fa-spinner fa-spin" />) : (<React.Fragment>
            <div className="request">
                <div className="menubar">
                    <div className="left">
                        <i className={Classname.build((queryInProgress ? "fa fa-spinner fa-spin" : "fa fa-bolt"), searchParams.get("last_10_queries") === "true" || queryInProgress ? "noseur-disabled" : null)}
                            onClick={() => executeQueries()} />
                        <i className={Classname.build("fa fa-folder", searchParams.get("last_10_queries") === "true" || queryInProgress ? "noseur-disabled" : null)}
                            onClick={() => loadQueryFromFileSystem()} />
                        <i className={Classname.build("fa fa-save", searchParams.get("last_10_queries") === "true" || queryInProgress ? "noseur-disabled" : null)}
                            onClick={() => saveQueryToFileSystem()} />
                        {searchParams.get("last_10_queries") === "true" && selectedTopQueryIndex !== undefined && selectedTopQueryIndex < last10Queries.length ? <i className="fa fa-copy" onClick={(e: any) => copySelectedQuery(e)} /> : null}
                    </div>
                    <div className="right">
                        <Checkbox scheme={Schemes.RIVTN_QUIRINUS} label={labels.show_last_10_queries}
                            checked={searchParams.get("last_10_queries") === "true"} onChange={(e: any) => toggleOption("last_10_queries", e.checked)} />
                        <Checkbox scheme={Schemes.RIVTN_QUIRINUS} label={labels.include_column_names}
                            checked={searchParams.get("include_column_names") === "true"} onChange={(e: any) => toggleOption("include_column_names", e.checked)} />
                    </div>
                </div>
                {searchParams.get("last_10_queries") !== "true"
                    ? (<TextAreaInput ref={queryInputRef} style={{ flex: 1 }} borderless />)
                    : (<div className="last-10-queries">
                        {last10Queries.map((query, index) => (<span key={query} className={Classname.build(selectedTopQueryIndex === index ? "active" : null)}
                            onClick={() => setSelectedTopQueryIndex(index)}>{query}</span>))}
                    </div>)}
            </div>
            <div className="response">
                <div className="menubar">
                    <div className="left">
                        <i className="fa fa-download" onClick={() => downloadResultToFileSystem()} />
                        <i className="fa fa-upload" onClick={() => loadResultFromFileSystem()} />
                        <i className={Classname.build("fa fa-refresh", queryInProgress ? "fa-spin" : null)} onClick={() => executeQueries()} />
                    </div>
                    <div className="right">
                        <Checkbox scheme={Schemes.RIVTN_QUIRINUS} label={labels.plain_text}
                            checked={searchParams.get("plain_text") === "true"} onChange={(e: any) => toggleOption("plain_text", e.checked)} />
                    </div>
                </div>
                {searchParams.get("plain_text") !== "true"
                    ? (<Table hideHeaders={searchParams.get("include_column_names") !== "true"} rowsPerPage={99999999}
                        dataRefreshKeys={buildResultTableColumns(selectedResultTab).map((column) => column.dataKey)} data={buildResultTableData(selectedResultTab)} stripedRows>
                        {buildResultTableColumns(selectedResultTab).map((column) => (<Column key={column.dataKey} header={column.header} dataKey={column.dataKey} />)) as any}
                    </Table>)
                    : (<TextAreaInput defaultValue={queryExecutionResult[selectedResultTab]?.join("\n")} ref={resultInputRef} style={{ flex: 1 }} borderless readOnly />)}
            </div>
            <div className="result-toggler">
                {Object.keys(queryExecutionResult).map((name) => (<span key={name} className={Classname.build(selectedResultTab === name ? "active" : null)} onClick={() => {
                    if (selectedResultTab === name) return;
                    setSelectedResultTab(name);
                    if (resultInputRef.current) resultInputRef.current.value = queryExecutionResult[name]?.join("\n");
                }}>{name?.substring(0, 20)}</span>))}
            </div>
        </React.Fragment>)}

        <FileInput style={{ display: "none" }} manageRef={selectFileManageRef} accepts=".sql" onSelectFiles={loadQueryFromFileSystem} selectOnly />
        <FileInput style={{ display: "none" }} manageRef={loadResultManageRef} accepts=".csv" onSelectFiles={loadResultFromFileSystem} selectOnly />
        <Popover transition={Transition.FADE} manageRef={queryCopiedPopOverManageRef} className="query-copied-popover" pointingArrowClassName="">
            <i className="fa fa-link" /> {labels.query_copied}
        </Popover>
    </div>);

    function executeQueries() {
        if (!queryInputRef.current) return;
        setQueryExecutionResult({});
        setQueryInProgress(true);
        const queries = (queryInputRef.current!.value ?? "").split(";").filter(query => !!query).map(query => query.trim());
        const payload: NoseurObject<any> = {
            queries,
            include_column_names: (searchParams.get("include_column_names") === "true"),
        };
        const startTime = new Date();
        const newLast10Queries = [...last10Queries];
        for (const query of queries) {
            if (newLast10Queries.includes(query)) continue;
            newLast10Queries.unshift(query);
        }
        setLast10Queries(newLast10Queries.slice(0, 10));
        CacheManager.put("rivtn.quirinus.l10q", newLast10Queries.slice(0, 10));
        platformService.executeDatabaseQueries(queryEndpoint, payload).then(({ apiResponse }: SanitizedResponse<NoseurObject<any>>) => {
            const result: NoseurObject<any[]> = apiResponse.data as any;
            setQueryExecutionResult(result);
            if (Object.keys(result).length) setSelectedResultTab(Object.keys(result)[0]);
        }).catch((err) => {
            const secs = Utils.dateDiffInSeconds(startTime);
            console.error("failed", err);
            const newResult: NoseurObject<any> = {};
            newResult[labels.result] = [[(labels.query_failed + secs + " " + i18nManager.Labels.time_keeping.secs)]];
            setQueryExecutionResult(newResult);
            setSelectedResultTab(labels.result);
            BaseService.reportError(err);
        }).finally(() => setQueryInProgress(false));
    }

    async function loadQueryFromFileSystem(files?: File[]) {
        if (!files || !files.length) {
            selectFileManageRef.current?.select();
            return;
        }
        if (!queryInputRef.current) return;
        const file = files[0];
        queryInputRef.current!.value = (await file.text());
    }

    function saveQueryToFileSystem() {
        if (!queryInputRef.current) return;
        const value = queryInputRef.current!.value;
        Utils.downloadBlob(value, value?.substring(0, 20) + ".sql", "text/text;charset=utf-8;");
    }

    function copySelectedQuery(e: any) {
        if (selectedTopQueryIndex === undefined) return;
        const selectedQuery = last10Queries[selectedTopQueryIndex];
        Utils.copyToClipboard(selectedQuery, (err) => {
            if (!err) {
                queryCopiedPopOverManageRef.current?.show(e);
                setTimeout(() => queryCopiedPopOverManageRef.current?.hide(), 500);
            } else {
                console.error(err);
            }
        });
    }

    function downloadResultToFileSystem() {
        const csv = queryExecutionResult[selectedResultTab]?.map(row => row.map(String)
            .map((v: any) => v.replaceAll('"', '""'))
            .map((v: any) => `"${v}"`)
            .join(',')
        ).join('\r\n');
        Utils.downloadBlob(csv, csv?.substring(0, 20) + ".csv");
    }

    async function loadResultFromFileSystem(files?: File[]) {
        if (!files || !files.length) {
            loadResultManageRef.current?.select();
            return;
        }
        if (!queryInputRef.current) return;
        const file = files[0];
        const loadedResult: NoseurObject<any> = {};
        const lines = (await file.text()).replaceAll('""', '"').split("\n");
        loadedResult[labels.loaded_result] = [];
        for (const line of lines) {
            loadedResult[labels.loaded_result].push(line.split(",").map(e => e.trim()).map((e) => (e.startsWith('"') && e.endsWith('"')) ? e.substring(1, e.length-2) : e));
        }
        setQueryExecutionResult(loadedResult);
        setSelectedResultTab(labels.loaded_result)
    }

    function toggleOption(key: string, active: boolean) {
        if (!active) {
            Utils.updateSearchParams(key, null, setSearchParams);
            return;
        }
        Utils.updateSearchParams(key, "true", setSearchParams);
    }

    function buildResultTableData(name: string) {
        const queryExecutionKeys = Object.keys(queryExecutionResult);
        if (!name || !queryExecutionKeys.includes(name)) return [];
        const includeColumnNames = (searchParams.get("include_column_names") === "true" && queryExecutionKeys.length > 1);
        const rows = queryExecutionResult[name];
        const rowsData: NoseurObject<any>[] = [];
        const firstRow = rows[0];
        for (let findex = (includeColumnNames ? 1 : 0); findex < rows.length; findex++) {
            const columns = TypeChecker.isArray(rows[findex]) ? rows[findex] : [rows[findex]];
            const rowData: NoseurObject<string> = {};
            for (let index = 0; index < columns.length; index++) {
                const column = columns[index];
                rowData[(includeColumnNames ? firstRow[index] : `${index}`)] = `${column}`;
            }
            rowsData.push(rowData);
        }
        return rowsData;
    }

    function buildResultTableColumns(name: string): { dataKey: string; header: string; }[] {
        const queryExecutionKeys = Object.keys(queryExecutionResult);
        if (!name || !queryExecutionKeys.includes(name)) return [];
        const rows: any[][] = queryExecutionResult[name];
        const includeColumnNames = (searchParams.get("include_column_names") === "true" && queryExecutionKeys.length > 1);
        return rows.length ? (TypeChecker.isArray(rows[0]) ? rows[0] : [rows[0]]).map((value, index) => {
            const dataKey = (includeColumnNames ? value : `${index}`);
            return { dataKey, header: (includeColumnNames ? dataKey : undefined) }
        }) : [];
    }

}

export default DatabaseQuery;
