
import { NoseurObject } from "@ronuse/noseur";
import { ISession } from "../geminus/SessionService";
import { LanuariusBaseService } from "./LanuariusBaseService";

export type IUserSession = ISession;

export class UserSessionService extends LanuariusBaseService {

    protected static instance: UserSessionService;

    private constructor() {
        super();
    }

    static getInstance() {
        if (!UserSessionService.instance) UserSessionService.instance = new UserSessionService();
        return UserSessionService.instance;
    }

    queryAllSession(params: NoseurObject<string | number> = { page: 1, size: 10 }): Promise<any> {
        return this.report(this.transport.get("/janus/lanuarius/api/v1/session", { params: this.normalizeParams(params) } as any));
    }

    revokeSession(id: number | string): Promise<any> {
        return this.report(this.transport.delete(`/janus/lanuarius/api/v1/session/${id}`, this.config));
    }

}

