
import React from "react";
import Profile from "./Profile";
import Sessions from "./Sessions";
import Messages from "./Messages";
import Notifications from "./Notifications";
import { i18nManager } from "../../../i18n";
import AccountSettings from "./AccountSettings";
import { Route, Routes } from "react-router-dom";
import { IProfile, ISecondaryData } from "../../../services";
import { DashboardContainer } from "../../shared/DashboardContainer";

export interface AccountProps {
    profile: IProfile;
    secondaryData: ISecondaryData;
}

function Account(props: AccountProps) {
    const labels = i18nManager.Labels.dashboard.account;

    return DashboardContainer({
        ...labels,
        secondaryData: props.secondaryData,
        content: (<Routes>
            <Route path="/sessions" element={<Sessions />} />
            <Route path="/notifications" element={<Notifications />} />
            <Route path="/profile" element={<Profile profile={props.profile} />} />
            <Route path="/messages" element={<Messages profile={props.profile} />} />
            <Route path="/account-settings" element={<AccountSettings profile={props.profile} />} />
        </Routes>),
        rightComponent: (<div>Messages</div>)
    });
}

export default Account;
