
import React from "react";
import ReactDOM from "react-dom/client";
import { i18nManager } from "../../../i18n";
import { Schemes, Utils } from "../../../utils";
import { ConfirmDialog, Elements } from "../../shared";
import { NavLink, useSearchParams } from "react-router-dom";
import { AdministrationService, AdministratorMessageService, ApiResponse, BaseService, IAdministrator, IAdministratorMessage, IMsg, IParticipant, IProfile, SanitizedResponse } from "../../../services";
import { Button, ButtonManageRef, Classname, Dropdown, FileInput, FileInputManageRef, FileInputMode, FormControl, FormGroup, InputManageRef, ObjectHelper, Orientation, Paginator, PaginatorPageChangeOption, Scheme, TextAreaInput } from "@ronuse/noseur";

export interface MessagesProps {
    profile: IProfile;
}

function Messages(props: MessagesProps) {
    let activelyFetching = false;
    const activeMessageFetchInterval = 10 * 1000;
    const [msg, setMsg] = React.useState<string>();
    const messageInputContainerRef = React.useRef<any>(null);
    const dialogErrorRef = React.useRef<HTMLDivElement>(null);
    const labels = i18nManager.Labels.dashboard.account.messages;
    const sendButtonManageRef = React.useRef<ButtonManageRef>(null);
    const messageInputManageRef = React.useRef<InputManageRef>(null);
    const administrationService = AdministrationService.getInstance();
    const fileInpuManagetRef = React.useRef<FileInputManageRef>(null);
    const participantsDialogListView = React.useRef<ReactDOM.Root>(null);
    const [idMessage, setIdMessage] = React.useState<IAdministratorMessage>();
    const administratorMessageService = AdministratorMessageService.getInstance();
    const [searchParams, setSearchParams] = useSearchParams(window.location.search);
    const [activeMessage, setActiveMessage] = React.useState<IAdministratorMessage>();
    const [messages, setMessages] = React.useState<ApiResponse<IAdministratorMessage>>();
    const [lastMessageRefetchDate, setLastMessageRefetchDate] = React.useState<Date | null>(null);
    const [currentPage, setCurrentPage] = React.useState<number>(parseInt(searchParams.get("page") || "1"));
    const [searchField, setSearchField] = React.useState<string>(searchParams.get("search_field") || labels.search_options[0].value);

    React.useEffect(fetchMessages, []);

    // TODO use the list data from noseur
    return (<div className="messages">
        <div className="left">
            <div className="h">
                <span className="t">{labels.title} <i className="fa fa-refresh" onClick={fetchMessages} /></span>
                <span className="c-m" onClick={(e: any) => {
                    let value;
                    if (e.target.innerText === labels.all_mgs) value = "false";
                    if (e.target.innerText === labels.opened_mgs) value = "true";
                    Utils.updateSearchParamses({ closed: value, page: 1 }, setSearchParams, fetchMessages);
                }}>{!searchParams.has("closed") ? labels.all_mgs : (searchParams.get("closed") === "false" ? labels.opened_mgs : labels.closed_mgs)}</span>
            </div>
            <Dropdown scheme={Schemes.RIVTN_QUIRINUS} options={labels.search_options} selectedOptionIndex={labels.search_options.findIndex(e => e.value === searchField)}
                formControlProps={{
                    leftContent: (<i className="fa fa-search" style={{ marginLeft: 15, color: "#0FA883" }} />),
                    style: { boxShadow: "none", border: "none", background: "rgba(217, 217, 217, 0.2)" }
                }} defaultInputValue={searchParams.get(searchField) || undefined}
                onSelectOption={(option: any) => {
                    Utils.updateSearchParams(searchField, null, setSearchParams);
                    setSearchField(option.value);
                    Utils.updateSearchParams("search_field", option.value, setSearchParams);
                    return true;
                }}
                onInputComplete={(v: string) => {
                    if (searchParams.get(searchField) === v) return;
                    Utils.updateSearchParams(searchField, v || undefined, setSearchParams, fetchMessages);
                }} editable borderless renderOptionAsPlaceholder />
            <div />
            <div className="ms">
                {idMessage ? buildMessage(idMessage, true) : undefined}
                {messages?.data?.content?.map((m) => buildMessage(m))}
            </div>
            <Paginator scheme={Schemes.RIVTN_QUIRINUS} rowsPerPage={parseInt(searchParams.get("size") || "10")}
                totalRecords={messages?.data?.total_elements} initialPage={currentPage}
                onPageChange={(e: PaginatorPageChangeOption) => {
                    if (currentPage === e.currentPage) return;
                    setCurrentPage(e.currentPage);
                    Utils.updateSearchParamses({ "page": e.currentPage, id: null }, setSearchParams, fetchMessages);
                }} template={{
                    layout: "FirstPageElement PreviousPageElement ActivePageLabel NextPageElement LastPageElement"
                }} />
            <Button scheme={Schemes.RIVTN_QUIRINUS} text={labels.new_message} onClick={initiateNewMessage} fill />
        </div>
        {activeMessage
            ? <div className="right" onMouseOver={fetchActiveMessageLatest}>
                <div className="h">
                    <span className="t lined-limit">{activeMessage?.title}</span>
                    <span className="i-d">{labels.initiated_by} {activeMessage.initiator.first_name} {activeMessage.initiator.last_name} - {Utils.formatToFineDate2(activeMessage.created_at)}</span>
                    <div className="c">
                        <span className={Classname.build("a", (activeMessage.closed ? "not-clickable" : null))} onClick={addParticipants}>{labels.add_participants}</span>
                        <span className={Classname.build("c", (activeMessage.closed || activeMessage.initiator.external_id !== props.profile.external_id ? "not-clickable" : null))} onClick={closeMessage}>{labels.close_message}</span>
                    </div>
                </div>
                <div className="mc">
                    <div className="p">
                        {activeMessage?.messages?.map((msg: IMsg, index: number) => {
                            const isNotification = msg.value.startsWith("___NOTIFICATION__");
                            const isSender = msg.sender.external_id === props.profile.external_id;
                            const msgRef = lastMessageRefetchDate === null && index === activeMessage?.messages.length - 1 ? (r?: any) => r?.scrollIntoView() : null;
                            let messageValue = msg.value.replace("___NOTIFICATION__", "");

                            if (isNotification) {
                                const messageParts = messageValue.split("<::>");
                                const messagePartslength = messageParts.length;
                                if (messagePartslength > 0) {
                                    switch (messageParts[0]) {
                                        case "ADD_USER":
                                            messageValue = `${messagePartslength > 1 ? messageParts[1] : "Unknwown User"} ${labels.add} ${messagePartslength > 2 ? messageParts[2] : "Unknwown User"} ${labels.to_the_conversation}`;
                                            break;
                                        case "REMOVE_USER":
                                            messageValue = `${messagePartslength > 1 ? messageParts[1] : "Unknwown User"} ${labels.remove} ${messagePartslength > 2 ? messageParts[2] : "Unknwown User"} ${labels.from_the_conversation}`;
                                            break;
                                        default:
                                            messageValue = messageParts[0];
                                    }
                                } else {
                                    messageValue = "Unknown/invalid notification: " + messageValue;
                                }
                            }

                            return (isNotification
                                ? (<div ref={msgRef} key={index} className="dv">
                                    <div className="v"></div>
                                    <div className="d2">{messageValue}</div>
                                </div>)
                                : (<div ref={msgRef} key={index} className={Classname.build("msg", (isSender ? "s" : null))}>
                                    <img src={msg.sender.profile_picture_location} />
                                    <div>
                                        <span className="i">{msg.sender.name} - {Utils.formatToFineDay(msg.sent_at)} - {Utils.formatToFineTime(msg.sent_at)}</span>
                                        <div>{Elements.formattedValueToHtml(msg.value)}</div>
                                    </div>
                                </div>))
                        })}
                    </div>
                    <div className="m" style={{ display: (activeMessage.closed ? "none" : "flex") }} ref={messageInputContainerRef} >
                        <FileInput noDragAndDrop dragAndDropRefs={[messageInputContainerRef]} orientation={Orientation.HORIZONTAL} concatNewFile
                            mode={FileInputMode.PREVIEW} manageRef={fileInpuManagetRef} emptyTemplate={() => null} className="shadow-file-input" multiple
                            onDragBegin={() => messageInputContainerRef.current.style.border = "2px solid #0FA883"} onDragComplete={() => messageInputContainerRef.current.style.border = "none"} />
                        <div className="s">
                            <TextAreaInput manageRef={messageInputManageRef} placeholder="Type here..." onInput={(e: any) => setMsg(e.target.value)}
                                fill borderless noStyle autoGrowHeight />
                            <Button scheme={Schemes.RIVTN_QUIRINUS} className="fa fa-paper-plane" manageRef={sendButtonManageRef} onClick={() => sendMessage(msg)}
                                loadingProps={{ disabled: true, className: "fa fa-spinner fa-spin" }} textOnly />
                            <Button scheme={Schemes.RIVTN_QUIRINUS} className="fa fa-paperclip" textOnly onClick={() => fileInpuManagetRef.current?.select()} />
                        </div>
                    </div>
                </div>
            </div>
            : <div className="right">
                <div className="es">
                    <i className="fa fa-message" />
                    <span>{labels.select_message} <span onClick={initiateNewMessage}>{i18nManager.Labels.common.initiate}</span> {labels.a_message}</span>
                </div>
            </div>}

    </div>);

    function fetchMessages() {
        const messageId = searchParams.get("id");
        if (messageId) {
            administratorMessageService.getSingleAdministratorMessage(messageId).then(({ apiResponse }: SanitizedResponse<IAdministratorMessage>) => setIdMessage(apiResponse.data as IAdministratorMessage)).catch(BaseService.reportError);
        } else if (idMessage !== undefined) {
            setIdMessage(undefined)
        }
        administratorMessageService.queryAdministratorMessages(Utils.normalizeUrlParams(searchParams))
            .then(({ apiResponse }: SanitizedResponse<IAdministratorMessage>) => setMessages(apiResponse))
            .catch(BaseService.reportError);
    }

    function sendMessage(content: string | null | undefined = msg) {
        if (!content || !activeMessage) return;
        sendButtonManageRef.current?.setLoadingState(true);
        administratorMessageService.sendAdministratorMessage(activeMessage?.external_id, content)
            .then(() => {
                setMsg(undefined);
                messageInputManageRef.current?.clear();
                administratorMessageService.getSingleAdministratorMessage(activeMessage?.external_id)
                    .then(({ apiResponse }: SanitizedResponse<IAdministratorMessage>) => setActiveMessage(apiResponse.data as IAdministratorMessage))
                    .catch(BaseService.reportError);
            }).finally(() => {
                sendButtonManageRef.current?.setLoadingState(false);
            }).catch(BaseService.reportError);
    }

    function selectPictures(message: IAdministratorMessage) {
        let count = 0;
        const profilePictures: React.ReactElement[] = [];
        for (const particitant of message.participants) {
            if (count === 2) break;
            profilePictures.push(<img key={count} src={particitant.profile_picture_location} />);
            count++;
        }
        return profilePictures;
    }

    function initiateNewMessage() {
        let messageContent = "";
        const selectedAdministrators: IParticipant[] = [props.profile as any as IParticipant];
        const dialog = ConfirmDialog({
            closable: true,
            style: { maxWidth: 680 },
            title: labels.new_message_dialog.title,
            content: (<div style={{ display: "flex" }}>
                <div className="cd-c" style={{ display: "flex", flexDirection: "column" }}>
                    <span className="desc">{labels.new_message_dialog.desc}</span>
                    <FormGroup className="form" scheme={Schemes.RIVTN_QUIRINUS} >
                        <FormControl validStyle={{ borderColor: "transparent" }} contentStyle={{ borderRadius: 0 }} >
                            <TextAreaInput placeholder={labels.new_message_dialog.placeholder} onInput={(e: any) => messageContent = e.target.value} style={{ height: 280 }} fill />
                        </FormControl>
                    </FormGroup>
                </div>
                <div style={{ height: "100", width: 1, backgroundColor: "#F0F0F0", margin: "0px 25px" }} />
                {Elements.administratorModifierDialog(labels.add_participant_dialog, administrationService, participantsDialogListView,
                    dialogErrorRef, selectedAdministrators as any[], { maxWidth: 300 }, props.profile as IAdministrator)}
            </div>),
            onConfirm: (button?: ButtonManageRef) => {
                dialogErrorRef.current!.innerText = " ";
                if (!messageContent) {
                    dialogErrorRef.current!.innerText = labels.new_message_dialog.empty_message;
                    return false;
                } else if (selectedAdministrators.length < 2) {
                    dialogErrorRef.current!.innerText = labels.add_participant_dialog.missing_participants;
                    return false;
                }
                button?.setLoadingState(true);
                administratorMessageService.createAdministratorMessage({ message: messageContent, participant_ids: selectedAdministrators.map(a => a.external_id) })
                    .then(({ apiResponse }: SanitizedResponse<IAdministratorMessage>) => {
                        setIdMessage(apiResponse.data as IAdministratorMessage);
                        setActiveMessage(apiResponse.data as IAdministratorMessage);
                        dialog.destroy();
                    }).catch(({ errorMessage }: SanitizedResponse<any>) => {
                        dialogErrorRef.current!.innerText = errorMessage;
                    }).finally(() => {
                        button?.setLoadingState(false);
                    });
                return false;
            }
        });
        dialog.show();
    }

    function addParticipants() {
        if (!activeMessage) return;
        const selectedAdministrators: IParticipant[] = ObjectHelper.clone(activeMessage?.participants ?? []);
        const dialog = ConfirmDialog({
            closable: true,
            title: labels.add_participant_dialog.title,
            content: Elements.administratorModifierDialog(labels.add_participant_dialog, administrationService, participantsDialogListView,
                dialogErrorRef, selectedAdministrators as any[], {}, props.profile as IAdministrator),
            onConfirm: (button?: ButtonManageRef) => {
                dialogErrorRef.current!.innerText = " ";
                if (selectedAdministrators.length < 2) {
                    dialogErrorRef.current!.innerText = labels.add_participant_dialog.missing_participants;
                    return false;
                }
                button?.setLoadingState(true);
                administratorMessageService.updateAdministratorMessage(activeMessage.external_id, { participant_ids: selectedAdministrators.map(a => a.external_id) })
                    .then(() => {
                        const messages: string[] = [];
                        selectedAdministrators.forEach((p) => {
                            if (activeMessage.participants.findIndex(a => a.external_id === p.external_id) >= 0) return;
                            messages.push(`___NOTIFICATION__ADD_USER<::>${props.profile.first_name} ${props.profile.last_name}<::>${p.first_name} ${p.last_name}`);
                        });
                        activeMessage.participants.forEach((p) => {
                            if (selectedAdministrators.findIndex(a => a.external_id === p.external_id) >= 0) return;
                            messages.push(`___NOTIFICATION__REMOVE_USER<::>${props.profile.first_name} ${props.profile.last_name}<::>${p.name}`);
                        });
                        dialog.destroy();
                        messages.forEach((message) => {
                            sendMessage(message);
                        });
                    }).catch(({ errorMessage }: SanitizedResponse<any>) => {
                        dialogErrorRef.current!.innerText = errorMessage;
                    }).finally(() => {
                        button?.setLoadingState(false);
                    });
                return false;
            }
        });
        dialog.show();
    }

    function closeMessage() {
        if (!activeMessage) return;
        const dialog = ConfirmDialog({
            closable: true,
            ...labels.delete_dialog,
            confirmScheme: Scheme.DANGER,
            onConfirm: (button?: ButtonManageRef) => {
                button?.setLoadingState(true);
                administratorMessageService.closeAdministratorMessage(activeMessage?.external_id!).then(fetchMessages).catch(BaseService.reportError).finally(() => {
                    button?.setLoadingState(true);
                    dialog.hide();
                });
                return false;
            }
        });
        dialog.show();
    }

    function buildMessage(message: IAdministratorMessage, ided: boolean = false) {
        if (!ided && idMessage && idMessage.external_id === message.external_id) return undefined;
        const isActiveMessage = searchParams.get("id") === message.external_id;
        if (isActiveMessage && activeMessage?.external_id !== message.external_id) setActiveMessage(message);

        return (<NavLink className={Classname.build("message", (isActiveMessage ? "selected" : null))} key={message.external_id} to={{
            pathname: "/dashboard/account/messages", search: Utils.urlParamsToSearch(new URLSearchParams(searchParams), [], {
                id: message.external_id
            })
        }}>
            <div className="ps">
                {selectPictures(message)}
            </div>
            <div className="c">
                <span className="t">{message.title}</span>
                <span className="i">{labels.initiated_by} {message.initiator.first_name} {message.initiator.last_name}</span>
                <span className="i">{Utils.formatToFineDate2(message.created_at)}</span>
            </div>
            {message.closed ? <i className="fa fa-check" /> : null}
            <div className="ovf" />
        </NavLink>);
    }

    function fetchActiveMessageLatest() {
        if (!activeMessage || activelyFetching) return;
        if (lastMessageRefetchDate === null || ((new Date() as any) - (lastMessageRefetchDate as any)) > activeMessageFetchInterval) {
            activelyFetching = true;
            administratorMessageService.getSingleAdministratorMessage(activeMessage?.external_id)
                .then(({ apiResponse }: SanitizedResponse<IAdministratorMessage>) => {
                    activelyFetching = false;
                    setLastMessageRefetchDate(new Date());
                    setActiveMessage(apiResponse.data as IAdministratorMessage);
                }).catch(BaseService.reportError);
        }
    }

}

export default Messages;
