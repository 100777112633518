
import { NoseurIconElement, NoseurObject } from "@ronuse/noseur";
import { AuthProps } from "../components/auths/Auth";
import en_US from "./en.json";

export type DashboardPrimaryMenuItem = {
    slug: string;
    label: string;
    location: string;
};

export type DashboardPopoverItem = {
    label: string;
    search?: string;
    location: string;
    badge_key?: string;
};

export interface FormControls {
    name: string;
    type?: string;
    title?: string;
    value?: string;
    sub_title?: string;
    disabled?: boolean;
    editable?: boolean;
    strengthy?: boolean;
    placeholder?: string;
    icon?: string | NoseurIconElement;
    options?: {
        label: string;
        value: string;
    }[];
};

export type AccountSettingsItem = {
    save?: string;
    label?: string;
    title?: string;
    successful?: string;
    short_title?: string;
    form?: FormControls[];
    new_password_not_match?: string;
    dialog?: {
        desc: string;
        title: string;
        desc2?: string;
        fill_form?: string;
        form_error?: string;
        successful?: string;
        enter_code?: string;
        enter_phone?: string;
        new_request?: string;
        form: FormControls[];
        request_code?: string;
        sub_form?: FormControls[];
        conflict_message?: string;
        old_new_phone_conflict?: string;
    };
};

export interface SidedContainerLabelsSearchControl {
    title: string;
    control: FormControls;
}

export interface SidedContainerLabels {
    title: string;
    create?: string;
    fine_name: string;
    create_label?: string;
    textfield_form: FormControls[];
    dropdown_form: SidedContainerLabelsSearchControl[];
    date_range_form: SidedContainerLabelsSearchControl[];
}

export type AccountMenuItem = DashboardPopoverItem;
export type PlatformPopoverItem = DashboardPopoverItem;

export type JanusLabels = {
    logo: string;
    name: string;
    lang: string;
    rivtn: string;
    short_lang: string;
    short_name: string;
    display_name: string;
    common: {
        no: string;
        yes: string;
        save: string;
        back: string;
        next: string;
        prev: string;
        found: string;
        close: string;
        cancel: string;
        create: string;
        remove: string;
        initiate: string;
        continue: string;
        password_does_not_match: string;
        request_fa_code: {
            get_auth_code: string;
            request_new_codes: string;
        }
    };
    time_keeping: {
        ago: string;
        secs: string;
        days: string;
        hours: string;
        years: string;
        months: string;
        seconds: string;
        minutes: string;
    };
    auths: {
        fa: AuthProps;
        login: AuthProps;
        register: AuthProps;
        reset_password: AuthProps;
        forgot_password: AuthProps;
    };
    dashboard: {
        primary_menu: DashboardPrimaryMenuItem[],
        secondary_menu: DashboardPrimaryMenuItem[],
        rivtn_admin: string,
        refresh_access_token: {
            desc: string;
            title: string;
            cancelLabel: string;
            form: FormControls[];
        },
        account_popover: {
            [key: string]: DashboardPopoverItem[];
        },
        account: {
            title: string;
            menu: AccountMenuItem[];
            profile: {
                words: string[];
                edit_profile: {
                    label: string;
                    location: string;
                };
                platforms: {
                    title: string;
                    table: {
                        name: string;
                        platform_id: string;
                        popover: PlatformPopoverItem[];
                    }
                }
            },
            account_settings: {
                edit_profile: {
                    save: string;
                    title: string;
                    successful: string;
                    form: FormControls[];
                };
                send_password: string;
                new_fa: AccountSettingsItem;
                notifications: FormControls[];
                change_email: AccountSettingsItem;
                lock_account: AccountSettingsItem;
                change_mobile: AccountSettingsItem;
                change_password: AccountSettingsItem;
            },
            sessions: {
                desc: string;
                title: string;
                session: {
                    delete: string;
                    revoke: string;
                    unknown: string;
                    location: string;
                    signed_in: string;
                    last_activity: string;
                    active_session: string;
                }
            },
            notifications: {
                title: string;
                query: {
                    date_range: string;
                    search_placeholder: string;
                    date_range_placeholder: string;
                    status: {
                        label: string;
                        form: FormControls[];
                    };
                    group: {
                        label: string;
                        form: FormControls[];
                    };
                };
                notification: {
                    today: string;
                    delete: string;
                    earlier: string;
                    yesterday: string;
                    mark_as_read: string;
                    default_logo: string;
                };
            };
            messages: {
                add: string;
                title: string;
                remove: string;
                all_mgs: string;
                a_message: string;
                closed_mgs: string;
                opened_mgs: string;
                new_message: string;
                initiated_by: string;
                close_message: string;
                select_message: string;
                add_participants: string;
                to_the_conversation: string;
                from_the_conversation: string;
                delete_dialog: {
                    desc: string;
                    title: string;
                };
                new_message_dialog: {
                    desc: string;
                    title: string;
                    placeholder: string;
                    empty_message: string;
                };
                add_participant_dialog: {
                    desc: string;
                    title: string;
                    placeholder: string;
                    search_admins: string;
                    missing_participants: string;
                };
                search_options: {
                    label: string;
                    value: string;
                }[];
            }
        },
        administration: {
            title: string;
            menu: AccountMenuItem[];
            management: {
                title: string;
                adminstrator: {
                    actions: {
                        form: FormControls[];
                        apply_filters: string;
                        add_new_administrator: string;
                    };
                    table: {
                        name: string;
                        email: string;
                        status: string;
                        post_title: string;
                        statuses: {
                            kicked: string;
                            locked: string;
                            approved: string;
                            rejected: string;
                            deactivated: string;
                            pending_pproval: string;
                        }
                    };
                    popover: {
                        kick: string;
                        lock_account: string;
                        unlock_account: string;
                        manage_account: string;
                        deactivate_account: string;
                        reactivate_account: string;
                    };
                };
                add_new_addministrator_dialog: {
                    desc: string;
                    title: string;
                    fa_desc: string;
                    form: FormControls[];
                    fa_form: FormControls[];
                    password_reset_hint: string;
                    error_map: {
                        key: string;
                        value: string;
                    }[];
                };
                dialog: {
                    activate_deactivate: {
                        reactivate: {
                            desc: string;
                            title: string;
                            confirm: string;
                        };
                        deactivate: {
                            desc: string;
                            title: string;
                            confirm: string;
                        };
                    };
                    kick: {
                        desc: string;
                        title: string;
                        confirm: string;
                        form: FormControls[];
                    };
                    lock: {
                        desc: string;
                        title: string;
                        desc2?: string;
                        fill_form?: string;
                        successful?: string;
                        form_error?: string;
                        form: FormControls[];
                        sub_form?: FormControls[];
                    };
                    unlock: {
                        desc: string;
                        title: string;
                        confirm: string;
                        form: FormControls[];
                    };
                    manage_account: {
                        title: string;
                        actions: {
                            profile: string;
                            sessions: string;
                            platforms: string;
                            activities: string;
                            account_settings: string;
                        };
                        fragments: {
                            profile: {
                                title: string;
                                form: FormControls[];
                            };
                            sessions: {
                                desc: string;
                                title: string;
                            };
                            platforms: {
                                title: string;
                            };
                            activities: {
                                title: string;
                            };
                            account_settings: {
                                title: string;
                            };
                        };
                    };
                };
            };
            approval_requests: {
                old: string;
                new: string;
                groups: {
                    key: string;
                    title: string;
                    search_fields: FormControls[];
                    table_headers: {
                        key: string;
                        header: string | {
                            key: string;
                            value: string;
                        }[];
                    }[];
                }[];
                dialog: {
                    approve: {
                        desc: string;
                        title: string;
                        form: FormControls[];
                    },
                    reject: {
                        desc: string;
                        title: string;
                        form: FormControls[];
                        reason_missing: string;
                    }
                }
            };
            roles_permissions: {
                roles: {
                    title: string;
                    create_role: string;
                    format_value: string;
                    form: FormControls[];
                    manage_admin_roles: string;
                    dialog: {
                        add: {
                            desc: string;
                            title: string;
                            edit_title: string;
                            form: FormControls[];
                            role_name_required: string;
                            search_form: FormControls[];
                            select_a_permission: string;
                            password_placeholder: string;
                        };
                        delete: {
                            desc: string;
                            title: string;
                        };
                        administrators: {
                            desc: string;
                            title: string;
                            placeholder: string;
                            search_admins: string;
                        };
                        administrators_role_manage: {
                            desc: string;
                            title: string;
                            desc_manage: string;
                            placeholder: string;
                            search_admins: string;
                            select_an_admin: string;
                            search_form: FormControls[];
                        };
                    }
                };
                permissions: {
                    title: string;
                    add_new: string;
                    a_permission: string;
                    form: FormControls[];
                    no_permission_found: string;
                    dialog: {
                        add: {
                            desc: string;
                            title: string;
                            add_text_field: string;
                            missing_a_value: string;
                            text_field_placeholder: string;
                        };
                        delete: {
                            desc: string;
                            title: string;
                        };
                    };
                };
            };
            platforms: {
                title: string;
                actions: {
                    form: FormControls[];
                    apply_filters: string;
                    add_new_platform: string;
                };
                platform: {
                    edit: string;
                    debug: string;
                    actions: {
                        icon: string;
                        template: string;
                        location?: string;
                    }[];
                };
                preview: {
                    downloads: string;
                    transactions: string;
                    assumed_profits: string;
                    registered_users: string;
                    unresolved_incidents: string;
                    actions: {
                        visit: string;
                        view_audits: string;
                        edit_platform: string;
                        debug_service: string;
                        view_security_setup: string;
                        view_earnings_trends: string;
                        manage_administrators: string;
                    };
                };
                dialog: {
                    manage_platform: {
                        add: string;
                        name: string;
                        title: string;
                        ui_url: string;
                        update: string;
                        single: string;
                        multiple: string;
                        fetch_info: string;
                        platform_id: string;
                        description: string;
                        api_base_url: string;
                        update_title: string;
                        log_endpoints: string;
                        health_endpoint: string;
                        earning_endpoint: string;
                        url_control: FormControls;
                        db_query_endpoints: string;
                        bacuator_route_required: string;
                    };
                    manage_admin_platform: {
                        desc: string;
                        title: string;
                        search_form: FormControls[];
                    }
                };
            }
        };
        messaging: {
            title: string;
            see_messages: string;
            menu: AccountMenuItem[];
            channels: {
                edit_channel: string;
                enable_channel: string;
                delete_channel: string;
                disable_channel: string;
                sided_labels: SidedContainerLabels;
                dialog: {
                    manage: {
                        key: string;
                        info: string;
                        json: string;
                        primitive: string;
                        missing_value: string;
                        add_json_data: string;
                        add_primitive_data: string;
                        fill_required_fields: string;
                        basic: {
                            create: {
                                desc: string;
                                title: string;
                                successful: string;
                                confirm_label: string;
                            };
                            update: {
                                desc: string;
                                title: string;
                                successful: string;
                                confirm_label: string;
                            };
                        };
                        form: FormControls[];
                    };
                    enable: {
                        desc: string;
                        title: string;
                        successful: string;
                    };
                    disable: {
                        desc: string;
                        title: string;
                        successful: string;
                    };
                    delete: {
                        desc: string;
                        title: string;
                        successful: string;
                    };
                };
            };
            contact_management: {
                contacts: string;
                edit_contact_list: string;
                delete_contact_list: string;
                sided_labels: SidedContainerLabels;
                dialog: {
                    manage: {
                        info: string;
                        add_new_contact: string;
                        download_sample: string;
                        missing_contacts: string;
                        params_placeholder: string;
                        address_placeholder: string;
                        fill_required_fields: string;
                        missing_contact_value: string;
                        add_contacts_from_csv: string;
                        basic: {
                            create: {
                                desc: string;
                                title: string;
                                successful: string;
                                confirm_label: string;
                            };
                            update: {
                                desc: string;
                                title: string;
                                successful: string;
                                confirm_label: string;
                            };
                        };
                        form: FormControls[];
                    };
                    delete: {
                        desc: string;
                        title: string;
                        successful: string;
                    };
                };
            };
            templating: {
                parameters: string;
                edit_template: string;
                delete_template: string;
                sided_labels: SidedContainerLabels;
                dialog: {
                    manage: {
                        desc: string;
                        title: string;
                        select_type: string;
                        form: FormControls[];
                        editor: {
                            title: string;
                            create: string;
                            update: string;
                            preview: string;
                            text_info: string;
                            media_info: string;
                            parameters: string;
                            successful: string;
                            params_info: string;
                            choose_file: string;
                            form: FormControls[];
                            secondary_form: FormControls[];
                            html: {
                                dnd: string;
                                html: string;
                                info: string;
                                dnd_elements: {
                                    key: string;
                                    icon: string;
                                    label: string;
                                }[];
                            };
                        };
                    };
                    delete: {
                        desc: string;
                        title: string;
                        successful: string;
                    };
                };
            };
            messages: {
                filter: string;
                resend: string;
                delete: string;
                send_new_message: string;
                messages_deleted: string;
                messages_resend_initiated: string;
                filters: {
                    apply: string;
                    reset_filter: string;
                    form: FormControls[];
                };
                table: {
                    type: string;
                    events: string;
                    status: string;
                    subject: string;
                    content: string;
                    template: string;
                    platform_id: string;
                    date_created: string;
                    subject_and_content: string;
                    statuses: {
                        failed: string;
                        success: string;
                        pending: string;
                        retrying: string;
                        cancelled: string;
                    }
                };
                active_message: {
                    info: {
                        type: string;
                        source: string;
                        template: string;
                        actor_id: string;
                        schedule: string;
                        max_retry: string;
                        ip_address: string;
                        retry_count: string;
                        asynchronous: string;
                        channel_used: string;
                        callback_url: string;
                        content_type: string;
                        rotate_on_retry: string;
                        channel_requested: string;
                    };
                    actions: {
                        view_ccs_bccs: string;
                        delete_message: string;
                        resend_message: string;
                        view_event_logs: string;
                        view_recipients: string;
                        view_attachments: string;
                        view_list_headers: string;
                        show_contact_lists: string;
                        show_custom_headers: string;
                    };
                    dialog: {
                        url: string;
                        encoding: string;
                        recipients: string;
                        event_logs: string;
                        attachments: string;
                        list_headers: string;
                        contact_lists: string;
                        custom_headers: string;
                        ccs_bccs: {
                            ccs: string;
                            bccs: string;
                            title: string;
                        };
                        delete: {
                            desc: string;
                            title: string;
                            success: string;
                        };
                    };
                };
                create_message: {
                    title: string;
                    message_sent: string;
                    starter: {
                        desc: string;
                        type: string;
                        use_template: string;
                        search_and_select: string;
                        select_content_or_template: string;
                    };
                    main: {
                        subject_required: string;
                        channel_required: string;
                        platform_required: string;
                        a_recipient_required: string;
                        from_address_required: string;
                        message_type_required: string;
                        content_or_template_required: string;
                        props: {
                            form: FormControls[];
                        };
                        contacts: {
                            desc: string;
                            address: string;
                            params_in_json: string;
                            add_new_contact: string;
                            add_list_headers: string;
                            add_ccs_and_bccs: string;
                            add_custom_header: string;
                            search_select_contact_list: FormControls;
                        };
                        custom_headers: {
                            name: string;
                            title: string;
                            no_object: string;
                            missing_name: string;
                            missing_value: string;
                            add_new_header: string;
                            primitive_or_json_array: string;
                        };
                        list_headers: {
                            name: string;
                            title: string;
                            missing_name: string;
                            missing_value: string;
                            value_in_json: string;
                            add_new_list_header: string;
                        };
                        ccs_bcss: {
                            name: string;
                            title: string;
                            address: string;
                            ccs_desc: string;
                            bccs_desc: string;
                            add_new_ccs: string;
                            add_new_bccs: string;
                            missing_value: string;
                        };
                        content: {
                            subject_control: FormControls;
                            controls: {
                                edit: string;
                                change: string;
                                large_preview: string;
                                add_attachements: string;
                            };
                            dialog: {
                                editor: {
                                    title: string;
                                    set_content: string;
                                };
                                large_preview: {
                                    desc: string;
                                    title: string;
                                    remove_preview: string;
                                };
                                add_attachments: {
                                    add: string;
                                    title: string;
                                    update: string;
                                    commit: string;
                                    cid_taken: string;
                                    cid_required: string;
                                    filename_required: string;
                                    add_new_url_attachment: string;
                                    upload_local_attachment: string;
                                    add_new_encoding_attachment: string;
                                    url_attachment: {
                                        title: string;
                                        form: FormControls[];
                                        path_required: string;
                                        enter_http_headers: string;
                                        add_new_http_headers: string;
                                        header_missing_key_or_value: string;
                                        http_header_name_placeholder: string;
                                        http_header_value_placeholder: string;
                                    };
                                    local_attachment: {
                                        title: string;
                                        select_file: string;
                                        form: FormControls[];
                                        file_input_control: FormControls;
                                    };
                                    encoding_attachment: {
                                        title: string;
                                        form: FormControls[];
                                        content_required: string;
                                        encoding_required: string;
                                    };
                                };
                            };
                        };
                    };
                };
            };
            schedules: {
                filter: string;
                delete: string;
                trigger: string;
                schedules_deleted: string;
                create_new_schedule: string;
                schedule_trigger_initiated: string;
                filters: {
                    apply: string;
                    reset_filter: string;
                    form: FormControls[];
                };
                table: {
                    name: string;
                    type: string;
                    status: string;
                    subject: string;
                    content: string;
                    triggers: string;
                    template: string;
                    platform_id: string;
                    no_of_triggers: string;
                    last_execution_date: string;
                    subject_and_content: string;
                    statuses: {
                        ended: string;
                        paused: string;
                        failed: string;
                        retrying: string;
                        scheduled: string;
                        cancelled: string;
                        message_sent: string;
                        sending_message: string;
                    }
                };
                active_schedule: {
                    info: {
                        type: string;
                        subject: string;
                        actor_id: string;
                        end_date: string;
                        max_retry: string;
                        start_date: string;
                        ip_address: string;
                        from_address: string;
                        creation_date: string;
                        last_execution: string;
                        total_triggers: string;
                        failed_triggers: string;
                        last_retry_count: string;
                        channel_requested: string;
                        last_channel_used: string;
                        successful_triggers: string;
                        send_every_x_interval: string;
                        rotate_channel_on_retry: string;
                        sent_first_message_immediately: string;
                    };
                    actions: {
                        edit_schedule: string;
                        pause_schedule: string;
                        resume_schedule: string;
                        cancel_schedule: string;
                        delete_schedule: string;
                        trigger_schedule: string;
                    };
                    dialog: {
                        pause: {
                            desc: string;
                            title: string;
                            success: string;
                        };
                        resume: {
                            desc: string;
                            title: string;
                            success: string;
                        };
                        cancel: {
                            desc: string;
                            title: string;
                            success: string;
                        };
                        delete: {
                            desc: string;
                            title: string;
                            success: string;
                        };
                    };
                };
                create_schedule: {
                    desc: string;
                    title: string;
                    send_every: string;
                    form: FormControls[];
                    name_required: string;
                    type_required: string;
                    setup_message: string;
                    update_message: string;
                    update_schedule: string;
                    create_schedule: string;
                    schedule_created: string;
                    schedule_updated: string;
                    description_required: string;
                    start_end_date_required: string;
                    interval_unit_control: FormControls;
                    interval_interval_control: FormControls;
                    interval_unit_and_value_required: string;
                    send_first_message_immediately: FormControls;
                };
            }
        };
        security: {
            title: string;
            menu: AccountMenuItem[];
            platforms: {
                add: string;
                blacklist: string;
                whitelist: string;
                view_data: string;
                blacklisted: string;
                whitelisted: string;
                dialog: {
                    add: {
                        save: string;
                        desc: string;
                        title: string;
                        successful: string;
                        form: FormControls[];
                        data_required: string;
                        reason_required: string;
                        platform_required: string;
                        expiry_date_required: string;
                    };
                    delete: {
                        desc: string;
                        title: string;
                        remove: string;
                        successful: string;
                    };
                    view_data: {
                        desc: string;
                        title: string;
                    };
                }
            };
            listed_ips: {
                type: string;
                sided_labels: SidedContainerLabels;
                table: {
                    isp: string;
                    location: string;
                    platforms: string;
                    ip_address: string;
                    last_update: string;
                    blacklisted_no: string;
                    whitelisted_no: string;
                };
                active_listing: {
                    name: string;
                    state: string;
                    carrier: string;
                    country: string;
                    address: string;
                    latitude: string;
                    longitude: string;
                    delete_listing: string;
                    location_on_map: string;
                };
                create: {
                    title: string;
                    successful: string;
                    form: FormControls[];
                    list_ip_address: string;
                    ip_address_required: string;
                };
                delete: {
                    desc: string;
                    title: string;
                    successful: string;
                };
            };
            listed_domains: {
                type: string;
                sided_labels: SidedContainerLabels;
                table: {
                    registrar: string;
                    platforms: string;
                    domain_name: string;
                    last_update: string;
                    blacklisted_no: string;
                    whitelisted_no: string;
                };
                active_listing: {
                    expires_on: string;
                    owner_name: string;
                    owner_email: string;
                    visit_domain: string;
                    owner_address: string;
                    delete_listing: string;
                    domain_registrar: string;
                    view_domain_info: string;
                };
                create: {
                    title: string;
                    successful: string;
                    list_domain: string;
                    form: FormControls[];
                    domain_required: string;
                };
                delete: {
                    desc: string;
                    title: string;
                    successful: string;
                };
            };
            listed_values: {
                type: string;
                sided_labels: SidedContainerLabels;
                table: {
                    value: string;
                    platforms: string;
                    last_update: string;
                    blacklisted_no: string;
                    whitelisted_no: string;
                };
                active_listing: {
                    delete_listing: string;
                };
                create: {
                    title: string;
                    successful: string;
                    list_value: string;
                    form: FormControls[];
                    value_required: string;
                };
                delete: {
                    desc: string;
                    title: string;
                    successful: string;
                };
            };
            suspicious_ips: {
                type: string;
                sided_labels: SidedContainerLabels;
                table: {
                    reported: string;
                    platforms: string;
                    ip_address: string;
                    platform_no: string;
                    last_reported_on: string;
                    first_reported_on: string;
                };
                active_listing: {
                    type: string;
                    trails: string;
                    strike: string;
                    show_trails: string;
                    view_ip_info: string;
                    reported_platforms: string;
                    delete_suspicious_ip: string;
                };
                delete: {
                    desc: string;
                    title: string;
                    successful: string;
                };
            };
        }
        users_and_businesses: {
            title: string;
            menu: AccountMenuItem[];
            users: {
                filter: string;
                reason_required: string;
                auth_code_required: string;
                password_reset_fail: string;
                password_reset_success: string;
                password_reset_processing: string;
                filters: {
                    apply: string;
                    reset_filter: string;
                    form: FormControls[];
                };
                table: {
                    dob: string;
                    email: string;
                    gender: string;
                    status: string;
                    last_name: string;
                    first_name: string;
                    email_verified: string;
                    verified: {
                        true: string;
                        false: string;
                    };
                    popover: {
                        activate: string;
                        audit_logs: string;
                        deactivate: string;
                        view_sessions: string;
                        update_profile: string;
                        send_password_reset_email: string;
                    };
                };
                dialogs: {
                    activate: {
                        desc: string;
                        title: string;
                        success: string;
                        form: FormControls[];
                        confirm_label: string;
                    };
                    deactivate: {
                        desc: string;
                        title: string;
                        success: string;
                        form: FormControls[];
                        confirm_label: string;
                    };
                    manage_account: {
                        title: string;
                        status: string;
                        email_verified: string;
                        actions: {
                            profile: string;
                            sessions: string;
                            addresses: string;
                            phone_numbers: string;
                            security_settings: string;
                            connected_businesses: string;
                        };
                        fragments: {
                            default: string;
                            mark_as_default: string;
                            profile: {
                                title: string;
                                success: string;
                                form: FormControls[];
                            };
                            sessions: {
                                desc: string;
                                title: string;
                            };
                            phone_numbers: {
                                title: string;
                                verified_on: string;
                            };
                            addresses: {
                                city: string;
                                title: string;
                                state: string;
                                country: string;
                            };
                            security_settings: {
                                title: string;
                                settings: FormControls[];
                                send_password_reset: string;
                            };
                            connected_businesses: {
                                title: string;
                            };
                        };
                    };
                };
            };
            email_change_requests: {
                filters: {
                    form: FormControls[];
                };
                table: {
                    name: string;
                    status: string;
                    old_email: string;
                    new_email: string;
                    date_requested: string;
                    reason_for_change: string;
                    reason_for_rejection: string;
                    popover: {
                        reject: string;
                        approve: string;
                        reason_required: string;
                        auth_code_required: string;
                        dialogs: {
                            approve: {
                                desc: string;
                                title: string;
                                success: string;
                                form: FormControls[];
                                confirm_label: string;
                            };
                            reject: {
                                desc: string;
                                title: string;
                                success: string;
                                form: FormControls[];
                                confirm_label: string;
                            };
                        };
                    };
                };
            };
        };
        platform_management: {
            audit: {
                title: string;
                query: string;
                query_placeholder: string;
                user_type: {
                    user: string;
                    admin: string;
                    platform: string;
                    business: string;
                };
                table: {
                    type: string;
                    group: string;
                    actor: string;
                    source: string;
                    action: string;
                    no_audit_logs: string;
                };
                audit: {
                    isp: string;
                    type: string;
                    group: string;
                    actor: string;
                    status: string;
                    source: string;
                    action: string;
                    device: string;
                    audit_id: string;
                    auditable: string;
                    extra_data: string;
                    actor_type: string;
                    location: string;
                    ip_address: string;
                    environment: string;
                    date_created: string;
                };
                dialog: {
                    delete: {
                        desc: string;
                        title: string;
                    };
                };
            };
            service_debugging: {
                title: string;
                menu: AccountMenuItem[];
                database_query: {
                    result: string;
                    plain_text: string;
                    query_copied: string;
                    query_failed: string;
                    loaded_result: string;
                    show_last_10_queries: string;
                    include_column_names: string;
                };
            };
            management: {
                title: string;
                menu: AccountMenuItem[];
                links: {
                    visit: string;
                    debug_service: string;
                    edit_platform: string;
                    view_audit_logs: string;
                    view_service_logs: string;
                    view_financial_trends: string;
                };
            };
        };
    };
};

export type LanguageMap = { [key: string]: JanusLabels };

const languages: LanguageMap = {
    en_US,
};

let i18nData: JanusLabels;
let defaultLanguage: string;

export const i18nManager = {

    Labels: i18nData!,
    LabeslLoaded: false,
    ActiveLanguage: defaultLanguage!,

    switchLanguage(lang: string) {
        localStorage.setItem("rivtn.language", lang);
        window.location.reload();
    }

};

if (!i18nManager.LabeslLoaded) {
    //localStorage.setItem("rivtn.language", "es") // uncomment to test spanish
    //localStorage.setItem("rivtn.language", "en") // uncomment to test english

    defaultLanguage = navigator.language;
    if (localStorage.getItem("rivtn.language") !== null && localStorage.getItem("rivtn.language") !== "") {
        defaultLanguage = localStorage.getItem("rivtn.language") || "en";
    }
    i18nData = languages[defaultLanguage.replace('-', '_')] || languages["en_US"];
    i18nData.short_lang = defaultLanguage;
    i18nManager.Labels = i18nData;
    i18nManager.LabeslLoaded = true;
    i18nManager.ActiveLanguage = defaultLanguage;
}