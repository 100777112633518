
import React from "react";
import ReactDOM from "react-dom/client";
import { Schemes, Utils } from "../../../utils";
import { ConfirmDialog, Elements } from "../../shared";
import { FormControls, i18nManager } from "../../../i18n";
import { NavLink, useSearchParams } from "react-router-dom";
import { AlertInterface, Button, ButtonManageRef, Classname, DateTimePickerSelectionMode, FormControl, FormGroup, ObjectHelper, Scheme } from "@ronuse/noseur";
import { AdministrationService, ApiResponseData, BaseService, IAdministrator, IPlatform, IPlatformAdministrator, PlatformService, SanitizedResponse } from "../../../services";

function Platforms() {
    const platformService = PlatformService.getInstance();
    const dialogErrorRef = React.useRef<HTMLDivElement>(null);
    const subDialogErrorRef = React.useRef<HTMLDivElement>(null);
    const activeDialogMutableRoot = React.useRef<ReactDOM.Root>(null);
    const administrationService = AdministrationService.getInstance();
    const activeDialogMutableRootSec = React.useRef<ReactDOM.Root>(null);
    const labels = i18nManager.Labels.dashboard.administration.platforms;
    const [activePlatform, setActivePlatform] = React.useState<IPlatform>();
    const [searchParams, setSearchParams] = useSearchParams(window.location.search);
    const [platforms, setPlatforms] = React.useState<ApiResponseData<IPlatform>>({});

    React.useEffect(fetchPlatforms, []);
    React.useEffect(() => {
        if (!!activePlatform?.__internal_relay__) Utils.updateSearchParams("active_platform_id", activePlatform.platform_id, setSearchParams);
    }, [activePlatform]);

    return (<div className="platform-page">
        <div className="management">
            <span>{labels.title}</span>
            <div className="query-inputs">
                <FormGroup scheme={Schemes.RIVTN_QUIRINUS} className="form">
                    {labels.actions.form.map(({ disabled, icon, type, name, placeholder, options }: FormControls, index: number) => {
                        const inputProps: any = {
                            name,
                            fill: true,
                            placeholder,
                            noStyle: true,
                            borderless: true,
                            style: { borderRadius: 0 },
                            disabled: (disabled === true),
                            defaultValue: searchParams.get(name),
                            onInputComplete: (e: string) => Utils.updateSearchParams(name, e || undefined, setSearchParams),
                        };
                        if (type === "dropdown") {
                            inputProps.options = options;
                            inputProps.onInputComplete = undefined;
                            inputProps.onSelectOption = (option: any) => {
                                Utils.updateSearchParams(name, option.value || undefined, setSearchParams);
                                return true;
                            };
                            inputProps.selectedOptionIndex = options?.findIndex(c => c.value === (searchParams.get(name) ?? ""));
                        }
                        if (type === "date" || type === "datetime") {
                            inputProps.selectionMode = DateTimePickerSelectionMode.RANGE;
                        }
                        return (<FormControl key={index} leftContent={icon}
                            validStyle={{ borderColor: "transparent" }} contentStyle={{ borderRadius: 0 }} >
                            {React.createElement(Utils.selectNoseurComponent(type!) as any, inputProps)}
                        </FormControl>);
                    })}
                    <Button text={labels.actions.apply_filters} style={{ padding: "10px 25px", marginBottom: 5 }} onClick={fetchPlatforms} raised />
                    <div style={{ flex: 1 }} />
                    <Button text={labels.actions.add_new_platform} scheme={Scheme.DANGER} style={{ marginBottom: 5 }} onClick={() => addNewPlatform()} raised />
                </FormGroup>
            </div>
            <div className="platforms">
                {!platforms.content || !platforms.content.length ? (<div className="loader" style={{ alignItems: "center", width: "100%" }}>
                    <i className="fa fa-spinner fa-spin" />
                </div>) : null}
                {platforms.content?.map((platform, index) => (<div className={Classname.build("platform", (activePlatform?.external_id === platform.external_id ? "active" : null))} key={index} onClick={() => {
                    setActivePlatform(platform);
                }}>
                    <p className="content">
                        <img alt={platform.name} src={platform.bacuator_data.icon_location} className="rounded icon" />
                        <span className="name">{platform.name}</span> <br />
                        <span className="id">{platform.platform_id}</span> <br />
                        {platform.bacuator_data.description}
                    </p>
                    <div className="controls">
                        <span className="link" style={{ width: "fit-content" }} onClick={() => addNewPlatform(platform)}>{labels.platform.edit}</span>
                        <a href={`/dashboard/service-debugging/platform_id=${platform.platform_id}`} style={{ marginTop: 5, width: "fit-content" }}>{labels.platform.debug}</a>
                    </div>
                    <div className="actions">
                        {labels.platform.actions.map((action, index) => {
                            if (!platform.bacuator_data?.incidents) platform.bacuator_data.incidents = {};
                            const label = ObjectHelper.expandStringTemplate(action.template, platform) || "0";
                            return (<span key={index} className="link"><i className={action.icon} /><span style={{ color: "var(--textColor)" }}> {label}</span></span>);
                        })}
                    </div>
                </div>))}
            </div>
            {Elements.buildPaginator(platforms || {}, ({ currentPage }, size) => Utils.updateSearchParamses({ "page": currentPage, id: null, size }, setSearchParams, fetchPlatforms))}
        </div>
        {activePlatform ? (<div className="active-data">
            <div className="header">
                <i className="fa fa-times" onClick={() => setActivePlatform(undefined)} />
                <img className="icon rounded" src={activePlatform.bacuator_data.icon_location} />
                <span className="name">{activePlatform.name}</span>
                <span className="id">{activePlatform.platform_id}</span>
                <span className="desc">{activePlatform.bacuator_data.description}</span>
            </div>
            <div className="middle">
                <div><i className="fa fa-users" /> <b>{activePlatform.bacuator_data?.users?.total ?? 0}</b> {labels.preview.registered_users}</div>
                <div><i className="fa fa-dollar" /> <b>{activePlatform.bacuator_data?.earnings?.total ?? 0}</b> {labels.preview.transactions}, <b>{activePlatform.bacuator_data.earnings.profit ?? 0}</b> {labels.preview.assumed_profits}</div>
                <div><i className="fa fa-download" /> <b>{activePlatform.bacuator_data?.download_counts ?? 0}</b> {labels.preview.downloads}</div>
                <div><i className="fa fa-car-crash" /> <b>{activePlatform.bacuator_data.incidents?.total ?? 0}</b> {labels.preview.unresolved_incidents}</div>
            </div>
            <div className="actions">
                <NavLink to={activePlatform.bacuator_data.location || "#"} target="_blank">{labels.preview.actions.visit}</NavLink>
                <span className="link" onClick={() => managePlatformAdministrators(activePlatform)}>{labels.preview.actions.manage_administrators}</span>
                <span className="link" onClick={() => addNewPlatform(activePlatform)}>{labels.preview.actions.edit_platform}</span>
                <NavLink to={`/dashboard/earnings?platform_id=${activePlatform.platform_id}`}>{labels.preview.actions.view_earnings_trends}</NavLink>
                <NavLink to={`/dashboard/audit?platform_id=${activePlatform.platform_id}`}>{labels.preview.actions.view_audits}</NavLink>
                <NavLink to={`/dashboard/service-debugging?platform_id=${activePlatform.platform_id}`}>{labels.preview.actions.debug_service}</NavLink>
                <NavLink to={`/dashboard/security?platform_id=${activePlatform.platform_id}`}>{labels.preview.actions.view_security_setup}</NavLink>
            </div>
        </div>) : null}
    </div>);

    function fetchPlatforms() {
        let fetchedQueryParamsPlatform = !searchParams.get("active_platform_id");
        platformService.queryPlatforms(Utils.normalizeUrlParams(searchParams), true).then(({ apiResponse }: SanitizedResponse<IPlatform>) => {
            setPlatforms(apiResponse.data!);
            if (!fetchedQueryParamsPlatform) {
                apiResponse.data!.content!.forEach(a => {
                    if (a.external_id === searchParams.get("active_platform_id")) {
                        fetchedQueryParamsPlatform = true;
                        setActivePlatform({ ...a, __internal_relay__: true });
                        if (searchParams.get("edit") === "true") addNewPlatform(a);
                    }
                });
            }
            if (!fetchedQueryParamsPlatform) {
                platformService.getSinglePlatform(searchParams.get("active_platform_id")!).then(({ apiResponse }: SanitizedResponse<IPlatform>) => {
                    setActivePlatform({ ...apiResponse.data as IPlatform, __internal_relay__: true });
                    if (searchParams.get("edit") === "true") addNewPlatform(apiResponse.data as IPlatform);
                }).catch(BaseService.reportError);
            }
        }).catch(BaseService.reportError);
    }

    function addNewPlatform(platform: IPlatform = {} as any) {
        const dialog = ConfirmDialog({
            closable: true,
            style: { minWidth: 350 },
            confirmLabel: labels.dialog.manage_platform.fetch_info,
            title: (!!platform.external_id ? labels.dialog.manage_platform.update_title : labels.dialog.manage_platform.title),
            content: (<div style={{ display: "flex", flexDirection: "column" }}>
                {!!platform.external_id ? <div className="platform-brief">
                    <img className="icon rounded" alt={platform.bacuator_route} src={platform.bacuator_data.icon_location} />
                    <div style={{ display: "flex", flexDirection: "column" }}>
                        <span><b>{labels.dialog.manage_platform.name}</b>: {platform.bacuator_data.name}</span>
                        <span><b>{labels.dialog.manage_platform.platform_id}</b>: {platform.bacuator_data.service_api_name}</span>
                    </div>
                </div> : null}
                {Elements.buildConfirmDialogForm([labels.dialog.manage_platform.url_control], (_: string, value: string) => {
                    dialogErrorRef.current!.innerText = " ";
                    platform.bacuator_route = value;
                }, platform, undefined, { marginTop: 0 })}
                <div ref={dialogErrorRef} className="error"> </div>
            </div>),
            onConfirm: (button?: ButtonManageRef) => {
                dialogErrorRef.current!.innerText = " ";
                if (!platform.bacuator_route) {
                    dialogErrorRef.current!.innerText = labels.dialog.manage_platform.bacuator_route_required;
                    return false;
                }
                button?.setLoadingState(true);
                platformService.transport.get(platform.bacuator_route).then((response) => {
                    platform.bacuator_data = response.data.data;
                    dialog.destroy(() => addNewPlatformPreview(platform));
                }).catch((response: any) => {
                    dialogErrorRef.current!.innerText = `${response}`;
                }).finally(() => {
                    button?.setLoadingState(false);
                });
                return false;
            }
        });
        dialog.show();
    }

    function addNewPlatformPreview(platform: IPlatform) {
        platform.name = platform.bacuator_data.name;
        platform.platform_id = platform.bacuator_data.service_api_name;
        const dialog = ConfirmDialog({
            closable: true,
            cancelLabel: i18nManager.Labels.common.back,
            title: (!!platform.external_id ? labels.dialog.manage_platform.update_title : labels.dialog.manage_platform.title),
            confirmLabel: (!!platform.external_id ? labels.dialog.manage_platform.update : labels.dialog.manage_platform.add),
            content: (<div className="new-platform-preview">
                <span className="route">{platform.bacuator_route}</span>
                <div className="media">
                    <img className="icon rounded" alt={platform.bacuator_route} src={platform.bacuator_data.icon_location} />
                    <div style={{ display: "flex", flexDirection: "column" }}>
                        <span><b>{labels.dialog.manage_platform.name}</b>: {platform.bacuator_data.name}</span>
                        <span><b>{labels.dialog.manage_platform.platform_id}</b>: {platform.bacuator_data.service_api_name}</span>
                    </div>
                </div>
                <span className="desc"><b>{labels.dialog.manage_platform.description}</b>: {platform.bacuator_data.description}</span>
                <div className="section">
                    <span><b>{labels.dialog.manage_platform.api_base_url}</b>: {Utils.joinUrlWithBase(platform.bacuator_route, platform.bacuator_data.api_base_url)}</span>
                    <span><b>{labels.dialog.manage_platform.ui_url}</b>: {Utils.joinUrlWithBase(platform.bacuator_route, platform.bacuator_data.location)}</span>
                    <span><b>{labels.dialog.manage_platform.health_endpoint}</b>: {Utils.joinUrlWithBase(platform.bacuator_route, platform.bacuator_data.health_endpoint)}</span>
                    <span><b>{labels.dialog.manage_platform.earning_endpoint}</b>: {Utils.joinUrlWithBase(platform.bacuator_route, platform.bacuator_data.location)}</span>
                </div>
                <div className="section">
                    <b>{labels.dialog.manage_platform.log_endpoints}</b>
                    {platform.bacuator_data.log_urls?.map((log_url, index) => <span key={index}>{Utils.joinUrlWithBase(platform.bacuator_route, log_url)}</span>)}
                </div>
                <div className="section">
                    <b>{labels.dialog.manage_platform.db_query_endpoints}</b>
                    <span><b>{labels.dialog.manage_platform.single}</b>: {Utils.joinUrlWithBase(platform.bacuator_route, platform.bacuator_data.database_query_route)}</span>
                    <span><b>{labels.dialog.manage_platform.multiple}</b>: {Utils.joinUrlWithBase(platform.bacuator_route, platform.bacuator_data.database_multiple_query_route)}</span>
                </div>
                <div ref={subDialogErrorRef} className="error"> </div>
            </div>),
            onCancel: () => addNewPlatform(platform),
            onConfirm: (button?: ButtonManageRef) => {
                subDialogErrorRef.current!.innerText = " ";
                button?.setLoadingState(true);
                const resourceMethod = ((!!platform.external_id) ? platformService.updatePlatform : platformService.createPlatform).bind(platformService);
                resourceMethod(platform)
                    .then(({ }: SanitizedResponse<IPlatform>) => {
                        dialog.destroy(() => Utils.updateSearchParamses({ "page": 1 }, setSearchParams, fetchPlatforms));
                    }).catch(({ errorMessage }: SanitizedResponse<any>) => {
                        subDialogErrorRef.current!.innerText = errorMessage;
                    }).finally(() => {
                        button?.setLoadingState(false);
                    });
                return false;
            }
        });
        dialog.show();
    }

    function managePlatformAdministrators(platform: IPlatform) {
        let immutableAdmins: IAdministrator[] = [];
        const selectedAdmins: IAdministrator[] = [];
        const dialog = ConfirmDialog({
            closable: true,
            style: { maxWidth: 650 },
            cancelLabel: i18nManager.Labels.common.cancel,
            title: labels.dialog.manage_admin_platform.title,
            content: (<div className="manage-platform-admins" style={{ marginTop: 10 }}>
                <div className="participant">
                    <div className={"noseur-dropdown-popover-li-item"}>
                        <img style={{ width: 35, height: 35, maxWidth: 35, maxHeight: 35 }} src={platform.bacuator_data?.icon_location} />
                        <div style={{ display: "flex", flexDirection: "column" }}>
                            <span style={{ fontWeight: 550 }}>{platform.name}</span>
                            <span style={{ fontSize: 12 }}>{platform.platform_id}</span>
                        </div>
                    </div>
                </div>
                <p style={{ marginTop: 15, fontSize: 13 }}>{labels.dialog.manage_admin_platform.desc}</p>
                <div className="message-participants" ref={(r) => {
                    if (!r) return;
                    ObjectHelper.resolveRef(activeDialogMutableRoot, ReactDOM.createRoot(r));
                    Elements.updateAdministratorsListView(activeDialogMutableRoot, selectedAdmins, undefined, "fa fa-minus");
                }}>
                </div>
                <div ref={dialogErrorRef} className="error"> </div>
            </div>),
            rightContent: (<div style={{ flex: 1 }} className="add-new-role-permission">
                {Elements.buildConfirmDialogForm(labels.dialog.manage_admin_platform.search_form, (_: string, value: string) => {
                    dialogErrorRef.current!.innerText = " ";
                    administrationService.queryAdministrator({ page: 1, email: value }).then(({ apiResponse }: SanitizedResponse<IAdministrator>) => {
                        Elements.updateAdministratorsListView(activeDialogMutableRootSec, apiResponse?.data?.content!, undefined, "fa fa-plus", (administrator: IAdministrator) => {
                            if (selectedAdmins.findIndex(r => r.external_id === administrator.external_id) > -1) return;
                            selectedAdmins.push(administrator);
                            Elements.updateAdministratorsListView(activeDialogMutableRoot, selectedAdmins, undefined, "fa fa-minus");
                        });
                    }).catch(BaseService.reportError);
                })}
                <div className="message-participants" ref={(r: any) => {
                    if (!r) return;
                    ObjectHelper.resolveRef(activeDialogMutableRootSec, ReactDOM.createRoot(r));
                }}>
                </div>
            </div>),
            onLoading: async (alert: AlertInterface) => {
                platformService.queryPlatformAdministrators({ "platform.external_id": platform.external_id }).then(({ apiResponse }: SanitizedResponse<IPlatformAdministrator>) => {
                    apiResponse.data?.content?.forEach(a => selectedAdmins.push(a.administrator));
                    immutableAdmins = ObjectHelper.clone(selectedAdmins);
                    alert.doneLoading();
                }).catch(({ errorMessage }: SanitizedResponse<any>) => {
                    alert.doneLoading(() => {
                        dialogErrorRef.current!.innerText = errorMessage;
                    });
                });
                return false;
            },
            onConfirm: (button?: ButtonManageRef) => {
                dialogErrorRef.current!.innerText = " ";
                button?.setLoadingState(true);
                const addedAdmins: IAdministrator[] = [];
                const removedAdmins: IAdministrator[] = [];
                for (const administrator of selectedAdmins) {
                    if (immutableAdmins.findIndex(e => administrator.external_id === e.external_id) < 0) {
                        addedAdmins.push(administrator);
                        continue;
                    }
                }
                for (const administrator of immutableAdmins) {
                    if (selectedAdmins.findIndex(e => administrator.external_id === e.external_id) < 0) {
                        removedAdmins.push(administrator);
                        continue;
                    }
                }
                platformService.assignAdministratorPlatforms(platform.external_id!, addedAdmins.map(a => a.external_id!), removedAdmins.map(a => a.external_id!))
                    .then(() => {
                        dialog.destroy();
                    }).catch(({ errorMessage }: SanitizedResponse<any>) => {
                        dialogErrorRef.current!.innerText = errorMessage;
                    }).finally(() => {
                        button?.setLoadingState(false);
                    });
                return false;
            }
        });
        dialog.show();
    }

}

export default Platforms;
