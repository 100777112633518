
import React from "react";
import Auth from "./Auth";
import { i18nManager } from "../../i18n";
import { CacheManager } from "../../utils";
import { NoseurObject } from "@ronuse/noseur";
import { useNavigate } from "react-router-dom";
import { ConfirmDialog, Elements } from "../shared";
import { AuthsService, SanitizedResponse } from "../../services";

function Register() {
    const navigate = useNavigate();
    const labels = i18nManager.Labels.auths.register;
    const [error, setError] = React.useState<string>();
    const [loading, setLoading] = React.useState<boolean>(false);

    return Auth({
        ...(labels),
        error,
        loading,
        onSubmit: (service: AuthsService, formValues: NoseurObject<any>) => {
            setError(""); setLoading(true);
            if (formValues.password !== formValues.confirm_password) {
                setLoading(false);
                setError(i18nManager.Labels.common.password_does_not_match);
                return;
            }
            service.signup(formValues).then(({}: SanitizedResponse<any>) => {
                ConfirmDialog({
                    ...labels.success,
                    centerIcon: Elements.DIALOG_CHECK_ICON,
                    onConfirm: () => {
                        navigate("/");
                        return true;
                    }
                }).show();
            }).catch(({ errorMessage }: SanitizedResponse<any>) => {
                setLoading(false);
                setError(errorMessage);
            });
        }
    });
}

export default Register;
