
import { BaseService } from "../../BaseService";
import { NoseurObject } from "@ronuse/noseur";
import { IAdministrator } from "./AdministrationService";

export interface IPlatform {
    name: string;
    platform_id: string;
    external_id: string;
    bacuator_route: string;
    bacuator_data: {
        name: string;
        location: string;
        log_urls: string[];
        description: string;
        api_base_url: string;
        icon_location: string;
        download_counts: string;
        health_endpoint: string;
        service_api_name: string;
        users: NoseurObject<any>;
        earnings: NoseurObject<any>;
        database_query_route: string;
        database_multiple_query_route: string;
        [key:string]: any;
    };
    __internal_relay__: any;
}

export interface IPlatformAdministrator {
    platform: IPlatform;
    external_id?: string;
    administrator: IAdministrator;
}

export class PlatformService extends BaseService {

    protected static instance: PlatformService;

    private constructor() {
        super();
    }

    static getInstance() {
        if (!PlatformService.instance) PlatformService.instance = new PlatformService();
        return PlatformService.instance;
    }

    queryPlatforms(params: NoseurObject<string | number> = { page: 1, size: 10 }, refresh: boolean = false): Promise<any> {
        return this.report(this.transport.get("/janus/geminus/api/v1/platform", { params: this.normalizeParams(params),/* cache: this.cacheManager,*/ refreshCache: refresh } as any));
    }

    getSinglePlatform(externalId: string): Promise<any> {
        return this.report(this.transport.get(`/janus/geminus/api/v1/platform/platform_id/${externalId}`));
    }

    createPlatform(platform: IPlatform): Promise<any> {
        return this.report(this.transport.post(`/janus/geminus/api/v1/platform`, platform, this.config));
    }

    updatePlatform(platform: IPlatform): Promise<any> {
        return this.report(this.transport.patch(`/janus/geminus/api/v1/platform/${platform.external_id!}`, platform, this.config));
    }

    queryPlatformAdministrators(params: NoseurObject<string | number> = { page: 1, size: 10 }): Promise<any> {
        return this.report(this.transport.get("/janus/geminus/api/v1/platform/administrator", { params: this.normalizeParams(params) } as any));
    }

    assignAdministratorPlatforms(platformId: string, added_administrator_ids: string[], removed_administrator_ids: string[]): Promise<any> {
        return this.report(this.transport.post(`/janus/geminus/api/v1/platform/administrator/multiple`, [
            { assign: true, platform_id: platformId, administrator_ids: added_administrator_ids },
            { assign: false, platform_id: platformId, administrator_ids: removed_administrator_ids },
        ] as any, this.config));
    }

    checkHealth(healthEndpoint: string, refresh: boolean = false): Promise<any> {
        return this.report(this.transport.get(healthEndpoint, { /* cache: this.cacheManager,*/ refreshCache: refresh } as any));
    }

    executeDatabaseQueries(queryEndpoint: string, payload: NoseurObject<any>): Promise<any> {
        return this.report(this.transport.post(queryEndpoint, payload, this.config));
    }

}

