
import React from "react";
import { i18nManager } from "../../../i18n";
import { useSearchParams } from "react-router-dom";
import { Subscriber, Utils } from "../../../utils";
import { ConfirmDialog, Elements, SidedContainer, Siren } from "../../shared";
import { ButtonManageRef, Column, NoseurElement, NoseurObject, Scheme, Table } from "@ronuse/noseur";
import { ApiResponseData, BaseService, IListedIp, ListedIpService, SanitizedResponse } from "../../../services";

function ListedIps() {

    const listedIpsService = ListedIpService.getInstance();
    const labels = i18nManager.Labels.dashboard.security.listed_ips;
    const [searchParams, setSearchParams] = useSearchParams(window.location.search);
    const [listedIps, setListedIps] = React.useState<ApiResponseData<IListedIp>>({});
    const [activeListedIp, setActiveListedIp] = React.useState<{ listing?: IListedIp, activeData?: NoseurObject<any>, mainContent?: () => NoseurElement }>({});

    React.useEffect(() => {
        if (!activeListedIp && !searchParams.has("active_data_id")) {
            fetchListedIps();
        } else {
            fetchListedIp(searchParams.get("active_data_id"));
        }
    }, []);
    React.useEffect(() => {
        if (window.location.search.includes("active_data_id")) {
            if (!activeListedIp.listing) {
                fetchListedIp(searchParams.get("active_data_id"));
            }
            return;
        }
        searchParams.delete("active_data_id");
        setActiveListedIp({});
        if (!listedIps.content) fetchListedIps();
    }, [searchParams]);

    return SidedContainer({
        searchParams,
        setSearchParams,
        onAction: manageListedIp,
        queryData: fetchListedIps,
        labels: labels.sided_labels,
        activeData: activeListedIp.activeData,
        mainContent: activeListedIp.mainContent ?? buildListingTable,
    } as any);

    function fetchListedIps() {
        listedIpsService.queryListedIps(Utils.normalizeUrlParams(searchParams), true).then(({ apiResponse }: SanitizedResponse<IListedIp>) => {
            setListedIps(apiResponse.data!);
        }).catch(BaseService.reportError).finally(() => Subscriber.report(Subscriber.KEYS.QUERY_REQUEST_PROMISE_COMPLETED));
    }

    function fetchListedIp(externalId: string | null) {
        if (!externalId) return;
        listedIpsService.getSingleListedIp(externalId).then(({ apiResponse }: SanitizedResponse<IListedIp>) => {
            setActiveListedIpProps(apiResponse.data as any as IListedIp);
        }).catch((err) => {
            BaseService.reportError(err);
            Utils.updateSearchParams("active_data_id", undefined, setSearchParams, fetchListedIps);
        }).finally(() => Subscriber.report(Subscriber.KEYS.QUERY_REQUEST_PROMISE_COMPLETED));
    }

    function setActiveListedIpProps(listedIp: IListedIp) {
        setActiveListedIp({
            listing: listedIp,
            mainContent: () => Elements.buildListingMainContent(listedIp, {
                type: labels.type,
                id: listedIp.external_id,
                value: listedIp.ip_address,
                onCreate: listedIpsService.addListedIpPlatform.bind(listedIpsService),
                onDelete: listedIpsService.deleteListedIpPlatform.bind(listedIpsService),
                onComplete: () => {
                    fetchListedIp(listedIp.external_id);
                }
            }),
            activeData: {
                element: buildActiveData(listedIp)
            },
        });
    }

    function buildListingTable() {
        return (<React.Fragment>
            <Table rowProps={{ className: "listing-table-row" }} rowsPerPage={listedIps.size ?? 10} className="table" style={{ marginTop: 0 }} data={listedIps.content} totalRecords={listedIps.total_elements} dataRefreshKeys={["external_id"]} loadingState={Elements.LOADING_STATE} emptyState={<div className="es" style={{ marginTop: 20 }}>
                <i className="fa fa-bullseye" />
                <span style={{ maxWidth: 150 }}>{i18nManager.Labels.common.no} {labels.sided_labels.fine_name} {i18nManager.Labels.common.found} <span onClick={() => manageListedIp}>{labels.sided_labels.create ?? i18nManager.Labels.common.create}</span> {labels.sided_labels.fine_name}</span>
            </div>} onRowSelection={(listedIp: IListedIp) => {
                setActiveListedIpProps(listedIp);
                Utils.updateSearchParams("active_data_id", listedIp.external_id, setSearchParams);
                return false;
            }}>
                <Column header={labels.table.ip_address} dataKey="ip_address" />
                <Column header={labels.table.isp} dataKey="{isp.name?}" />
                <Column header={labels.table.location} dataKey='{location.state?}, {location.country?}' />
                <Column header={labels.table.blacklisted_no} template={(listedIp: IListedIp) => `${Object.keys(listedIp.platforms_blacklisted ?? {}).length} ${labels.table.platforms}`} />
                <Column header={labels.table.whitelisted_no} template={(listedIp: IListedIp) => `${Object.keys(listedIp.platforms_whitelisted ?? {}).length} ${labels.table.platforms}`} />
                <Column header={labels.table.last_update} template={(listedIp: IListedIp) => Utils.formatToFineDate(listedIp.updated_at)} />
            </Table>
            {Elements.buildPaginator(listedIps ?? {}, ({ currentPage }, size) => Utils.updateSearchParamses({ "page": currentPage, id: null, size }, setSearchParams, fetchListedIps), parseInt(searchParams.get("page") ?? "1"))}
        </React.Fragment>);
    }

    function buildActiveData(listedIp: IListedIp) {
        return (<React.Fragment>
            <div className="header">
                <i className="fa fa-times" onClick={() => {
                    setActiveListedIp({});
                    Utils.updateSearchParams("active_data_id", undefined, setSearchParams, fetchListedIps);
                }} />
                <span className="name">{listedIp?.ip_address}</span>
            </div>
            <div className="middle">
                <div><b>{labels.active_listing.longitude}:</b> {listedIp.location.longitude ?? ""}</div>
                <div><b>{labels.active_listing.latitude}:</b> {listedIp.location.latitude ?? ""}</div>
                <div><b>{labels.active_listing.state}:</b> {listedIp.location.state ?? ""}</div>
                <div><b>{labels.active_listing.country}:</b> {listedIp.location.country ?? ""}</div>
                <div><b>{labels.active_listing.address}:</b> {listedIp.location.address ?? ""}</div>
            </div>
            <div className="middle" style={{ borderTop: "none", marginTop: 0 }}>
                <div><b>{labels.active_listing.name}:</b> {listedIp.isp.name ?? ""}</div>
                <div><b>{labels.active_listing.carrier}:</b> {listedIp.isp.carrier ?? ""}</div>
            </div>
            <div className="actions">
                <a target="_blank" href={`https://www.google.com/maps/place/${listedIp.location.latitude}+${listedIp.location.longitude}`}>{labels.active_listing.location_on_map}</a>
                <span className="link danger" onClick={() => deleteListing(listedIp)}>{labels.active_listing.delete_listing}</span>
            </div>
        </React.Fragment>);
    }

    function deleteListing(listedIp: IListedIp) {
        const deleteLabels = labels.delete;
        const dialog = ConfirmDialog({
            closable: true,
            confirmScheme: Scheme.DANGER,
            desc: deleteLabels.desc.replace("${ipAddress}", listedIp.ip_address),
            title: deleteLabels.title.replace("${ipAddress}", listedIp.ip_address),
            onConfirm: (button?: ButtonManageRef) => {
                button?.setLoadingState(true);
                listedIpsService.deleteListedIp(listedIp.external_id).then(() => {
                    Siren.alert(deleteLabels.successful);
                    dialog.destroy(() => Utils.updateSearchParams("active_data_id", undefined, setSearchParams, fetchListedIps));
                }).catch(BaseService.reportError).finally(() => button?.setLoadingState(false));
                return false;
            }
        });
        dialog.show();
    }

    function manageListedIp() {
        let dialogErrorRef: any;
        const payload: any = { reason: "", listing_type: "WHITELIST" };
        const dialog = ConfirmDialog({
            closable: true,
            style: { minWidth: 350 },
            title: labels.create.title,
            confirmLabel: labels.create.list_ip_address,
            content: (<div>
                {Elements.buildConfirmDialogForm(labels.create.form, (name: string, value: any) => {
                    dialogErrorRef.innerText = " ";
                    payload[name] = value;
                })}
                <div ref={(r) => dialogErrorRef = r} className="error"> </div>
            </div>),
            onConfirm: (button?: ButtonManageRef) => {
                button?.setLoadingState(true);
                dialogErrorRef.innerText = " ";
                if (!payload.ip_address) {
                    button?.setLoadingState(false);
                    dialogErrorRef.innerText = labels.create.ip_address_required;
                    return false;
                }
                listedIpsService.createListedIp(payload).then(() => {
                    Siren.alert(labels.create.successful);
                    dialog.destroy(fetchListedIps);
                }).catch(BaseService.reportError).finally(() => button?.setLoadingState(false));
                return false;
            }
        });
        dialog.show();
    }

}

export default ListedIps;