
import React from "react";
import { i18nManager } from "../../../i18n";
import { useSearchParams } from "react-router-dom";
import { Subscriber, Utils } from "../../../utils";
import { ConfirmDialog, Elements, SidedContainer, Siren } from "../../shared";
import { ButtonManageRef, Column, NoseurElement, NoseurObject, Scheme, Table } from "@ronuse/noseur";
import { ApiResponseData, BaseService, IListedDomain, ListedDomainService, SanitizedResponse } from "../../../services";

function ListedDomains() {

    const listedDomainsService = ListedDomainService.getInstance();
    const labels = i18nManager.Labels.dashboard.security.listed_domains;
    const [searchParams, setSearchParams] = useSearchParams(window.location.search);
    const [listedDomains, setListedDomains] = React.useState<ApiResponseData<IListedDomain>>({});
    const [activeListedDomain, setActiveListedDomain] = React.useState<{ listing?: IListedDomain, activeData?: NoseurObject<any>, mainContent?: () => NoseurElement }>({});

    React.useEffect(() => {
        if (!activeListedDomain && !searchParams.has("active_data_id")) {
            fetchListedDomains();
        } else {
            fetchListedDomain(searchParams.get("active_data_id"));
        }
    }, []);
    React.useEffect(() => {
        if (window.location.search.includes("active_data_id")) {
            if (!activeListedDomain.listing) {
                fetchListedDomain(searchParams.get("active_data_id"));
            }
            return;
        }
        searchParams.delete("active_data_id");
        setActiveListedDomain({});
        if (!listedDomains.content) fetchListedDomains();
    }, [searchParams]);

    return SidedContainer({
        searchParams,
        setSearchParams,
        onAction: manageListedDomain,
        queryData: fetchListedDomains,
        labels: labels.sided_labels,
        activeData: activeListedDomain.activeData,
        mainContent: activeListedDomain.mainContent ?? buildListingTable,
    } as any);

    function fetchListedDomains() {
        listedDomainsService.queryListedDomains(Utils.normalizeUrlParams(searchParams), true).then(({ apiResponse }: SanitizedResponse<IListedDomain>) => {
            setListedDomains(apiResponse.data!);
        }).catch(BaseService.reportError).finally(() => Subscriber.report(Subscriber.KEYS.QUERY_REQUEST_PROMISE_COMPLETED));
    }

    function fetchListedDomain(externalId: string | null) {
        if (!externalId) return;
        listedDomainsService.getSingleListedDomain(externalId).then(({ apiResponse }: SanitizedResponse<IListedDomain>) => {
            setActiveListedDomainProps(apiResponse.data as any as IListedDomain);
        }).catch((err) => {
            BaseService.reportError(err);
            Utils.updateSearchParams("active_data_id", undefined, setSearchParams, fetchListedDomains);
        }).finally(() => Subscriber.report(Subscriber.KEYS.QUERY_REQUEST_PROMISE_COMPLETED));
    }

    function setActiveListedDomainProps(listedDomain: IListedDomain) {
        setActiveListedDomain({
            listing: listedDomain,
            mainContent: () => Elements.buildListingMainContent(listedDomain, {
                type: labels.type,
                id: listedDomain.external_id,
                value: listedDomain.domain_name,
                onCreate: listedDomainsService.addListedDomainPlatform.bind(listedDomainsService),
                onDelete: listedDomainsService.deleteListedDomainPlatform.bind(listedDomainsService),
                onComplete: () => {
                    fetchListedDomain(listedDomain.external_id);
                }
            }),
            activeData: {
                element: buildActiveData(listedDomain)
            },
        });
    }

    function buildListingTable() {
        return (<React.Fragment>
            <Table rowProps={{ className: "listing-table-row" }} rowsPerPage={listedDomains.size ?? 10} className="table" style={{ marginTop: 0 }} data={listedDomains.content} totalRecords={listedDomains.total_elements} dataRefreshKeys={["external_id"]} loadingState={Elements.LOADING_STATE} emptyState={<div className="es" style={{ marginTop: 20 }}>
                <i className="fa fa-bullseye" />
                <span style={{ maxWidth: 150 }}>{i18nManager.Labels.common.no} {labels.sided_labels.fine_name} {i18nManager.Labels.common.found} <span onClick={() => manageListedDomain}>{labels.sided_labels.create ?? i18nManager.Labels.common.create}</span> {labels.sided_labels.fine_name}</span>
            </div>} onRowSelection={(listedDomain: IListedDomain) => {
                setActiveListedDomainProps(listedDomain);
                Utils.updateSearchParams("active_data_id", listedDomain.external_id, setSearchParams);
                return false;
            }}>
                <Column header={labels.table.domain_name} dataKey="domain_name" />
                <Column header={labels.table.registrar} dataKey="{info?{}.registrar?{}.name?}" />
                <Column header={labels.table.blacklisted_no} template={(listedDomain: IListedDomain) => `${Object.keys(listedDomain.platforms_blacklisted ?? {}).length} ${labels.table.platforms}`} />
                <Column header={labels.table.whitelisted_no} template={(listedDomain: IListedDomain) => `${Object.keys(listedDomain.platforms_whitelisted ?? {}).length} ${labels.table.platforms}`} />
                <Column header={labels.table.last_update} template={(listedDomain: IListedDomain) => Utils.formatToFineDate(listedDomain.updated_at)} />
            </Table>
            {Elements.buildPaginator(listedDomains ?? {}, ({ currentPage }, size) => Utils.updateSearchParamses({ "page": currentPage, id: null, size }, setSearchParams, fetchListedDomains), parseInt(searchParams.get("page") ?? "1"))}
        </React.Fragment>);
    }

    function buildActiveData(listedDomain: IListedDomain) {
        const navigateAbleDomain = listedDomain.domain_name.startsWith("http") ? listedDomain.domain_name : `http://${listedDomain.domain_name}`;
        return (<React.Fragment>
            <div className="header">
                <i className="fa fa-times" onClick={() => {
                    setActiveListedDomain({});
                    Utils.updateSearchParams("active_data_id", undefined, setSearchParams, fetchListedDomains);
                }} />
                <span className="name">{listedDomain?.domain_name}</span>
            </div>
            <div className="middle">
                <div><b>{labels.active_listing.domain_registrar}:</b> {listedDomain.info?.registrar?.name ?? ""}</div>
                <div><b>{labels.active_listing.owner_email}:</b> {listedDomain.info?.contacts?.owner?.email ?? ""}</div>
                <div><b>{labels.active_listing.owner_name}:</b> {listedDomain.info?.contacts?.owner?.name ?? ""}</div>
                <div><b>{labels.active_listing.expires_on}:</b> {listedDomain.info?.expires ?? ""}</div>
                <div><b>{labels.active_listing.owner_address}:</b> {listedDomain.info?.contacts?.owner?.address ?? ""}</div>
            </div>
            <div className="actions">
                <a target="_blank" href={navigateAbleDomain}>{labels.active_listing.visit_domain}</a>
                <a target="_blank" href={`https://www.whois.com/whois/${navigateAbleDomain}`}>{labels.active_listing.view_domain_info}</a>
                <span className="link danger" onClick={() => deleteListing(listedDomain)}>{labels.active_listing.delete_listing}</span>
            </div>
        </React.Fragment>);
    }

    function deleteListing(listedDomain: IListedDomain) {
        const deleteLabels = labels.delete;
        const dialog = ConfirmDialog({
            closable: true,
            confirmScheme: Scheme.DANGER,
            desc: deleteLabels.desc.replace("${domainName}", listedDomain.domain_name),
            title: deleteLabels.title.replace("${domainName}", listedDomain.domain_name),
            onConfirm: (button?: ButtonManageRef) => {
                button?.setLoadingState(true);
                listedDomainsService.deleteListedDomain(listedDomain.external_id).then(() => {
                    Siren.alert(deleteLabels.successful);
                    dialog.destroy(() => Utils.updateSearchParams("active_data_id", undefined, setSearchParams, fetchListedDomains));
                }).catch(BaseService.reportError).finally(() => button?.setLoadingState(false));
                return false;
            }
        });
        dialog.show();
    }

    function manageListedDomain() {
        let dialogErrorRef: any;
        const payload: any = { reason: "", listing_type: "WHITELIST" };
        const dialog = ConfirmDialog({
            closable: true,
            style: { minWidth: 350 },
            title: labels.create.title,
            confirmLabel: labels.create.list_domain,
            content: (<div>
                {Elements.buildConfirmDialogForm(labels.create.form, (name: string, value: any) => {
                    dialogErrorRef.innerText = " ";
                    payload[name] = value;
                })}
                <div ref={(r) => dialogErrorRef = r} className="error"> </div>
            </div>),
            onConfirm: (button?: ButtonManageRef) => {
                button?.setLoadingState(true);
                dialogErrorRef.innerText = " ";
                if (!payload.domain_name) {
                    button?.setLoadingState(false);
                    dialogErrorRef.innerText = labels.create.domain_required;
                    return false;
                }
                listedDomainsService.createListedDomain(payload).then(() => {
                    Siren.alert(labels.create.successful);
                    dialog.destroy(fetchListedDomains);
                }).catch(BaseService.reportError).finally(() => button?.setLoadingState(false));
                return false;
            }
        });
        dialog.show();
    }

}

export default ListedDomains;