
import React from "react";
import { Elements } from "../../shared";
import { i18nManager } from "../../../i18n";
import { Button, Scheme } from "@ronuse/noseur";
import { CacheManager, Utils } from "../../../utils";
import { ApiResponseData, BaseService, ISession, SanitizedResponse, SessionService } from "../../../services";

function Sessions() {
    const sessionService = SessionService.getInstance();
    const labels = i18nManager.Labels.dashboard.account.sessions;
    const sessionToken: string = CacheManager.get(CacheManager.SESSION_TOKEN_KEY);
    const [sessions, setSessions] = React.useState<ApiResponseData<ISession>>({});

    React.useEffect(fetchSession, []);

    // TODO use data list and bottom sensor to load new data
    return (<div className="sessions">
        <div className="left">
            <span className="t">{labels.title}</span>
            <span className="d">{labels.desc}</span>
        </div>
        <div className="right">
            <div className="list">
                {sessions.content?.map((session: ISession, index: number) => {
                    const isActiveSession = sessionToken.startsWith(session.session_token.substring(0, 20));
                    return (<div key={index} className="session">
                        <div>
                            <i className={Elements.selectSessionIcon(session.device.device_class)} />
                            <div>
                                <b>{session.ip_address}</b>
                                <span><b>{session.device.browser_name}</b> on <b>{session.device.os_name}</b></span>
                                <span><b>{labels.session.location}:</b> {session.location?.state ?? labels.session.unknown}, {session.location?.country ?? labels.session.unknown}</span>
                                <span><b>{labels.session.last_activity}:</b> {Utils.formatToFineDate(session.updated_at)}</span>
                                <span><b>{labels.session.signed_in}:</b> {Utils.formatToFineDate(session.created_at)}</span>
                            </div>
                        </div>
                        {isActiveSession
                            ? <Button scheme={Scheme.SECONDARY} text={labels.session.active_session} textOnly disabled />
                            : <Button scheme={Scheme.DANGER} text={labels.session.revoke} textOnly onClick={(e: any) => {
                                sessionService.revokeSession(session.id).then(fetchSession).catch(BaseService.reportError);
                            }} />}
                    </div>);
                })}
            </div>
            {Elements.buildPaginator(sessions)}
        </div>
    </div>);

    function fetchSession() {
        sessionService.querySession().then(({ apiResponse }: SanitizedResponse<ISession>) => {
            setSessions(apiResponse.data!);
        }).catch(BaseService.reportError);
    }

}

export default Sessions;
