
import { NoseurObject } from "@ronuse/noseur";
import { Domain, GroupListed, SoteriaBaseService } from "./SoteriaBaseService";

export interface IListedDomain {
    info: Domain;
    created_at: string;
    updated_at: string;
    domain_name: string;
    external_id: string;
    platforms_blacklisted: NoseurObject<GroupListed>;
    platforms_whitelisted: NoseurObject<GroupListed>;
}

export class ListedDomainService extends SoteriaBaseService {

    protected static instance: ListedDomainService;

    private constructor() {
        super();
    }

    static getInstance() {
        if (!ListedDomainService.instance) ListedDomainService.instance = new ListedDomainService();
        return ListedDomainService.instance;
    }

    queryListedDomains(params: NoseurObject<string | number> = { page: 1, size: 10 }, refresh: boolean = false): Promise<any> {
        return this.report(this.transport.get("/soteria/api/v1/listed/domain", { params: this.normalizeParams(params) } as any));
    }

    getSingleListedDomain(externalId: string): Promise<any> {
        return this.report(this.transport.get(`/soteria/api/v1/listed/domain/${externalId!}`, this.config));
    }

    createListedDomain(payload: any): Promise<any> {
        return this.report(this.transport.post(`/soteria/api/v1/listed/domain`, payload, this.config));
    }

    deleteListedDomain(externalId: string): Promise<any> {
        return this.report(this.transport.delete(`/soteria/api/v1/listed/domain/${externalId!}`, this.config));
    }

    addListedDomainPlatform(payload: any): Promise<any> {
        return this.report(this.transport.put(`/soteria/api/v1/listed/domain`, payload, this.config));
    }

    deleteListedDomainPlatform(externalId: string, listingType: string, platform: string): Promise<any> {
        return this.report(this.transport.patch(`/soteria/api/v1/listed/domain/${externalId!}`, {
            platforms: [ platform ],
            listing_type: listingType,
            reason: "Removed by admin from the UI",
        }, this.config));
    }

}

