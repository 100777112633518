
import ffs, { FFS } from "kyofuuc";
import { BaseService } from "../BaseService";

export enum MessageSource {
    UI = "UI",
    API = "API",
    SCHEDULE = "SCHEDULE"
}

export enum MessageType {
    SMS = "SMS",
    EMAIL = "EMAIL",
    WHATSAPP_MESSAGE = "WHATSAPP_MESSAGE",
}

export enum MessageStatus {
    ENDED = "ENDED",
    PAUSED = "PAUSED",
    FAILED = "FAILED",
    SUCCESS = "SUCCESS",
    PENDING = "PENDING",
    RETRYING = "RETRYING",
    CANCELLED = "CANCELLED",
    SCHEDULED = "SCHEDULED",
    MESSAGE_SENT = "MESSAGE_SENT",
    SENDING_MESSAGE = "SENDING_MESSAGE",
}

export enum TemplateType {
    ANY = "ANY",
    TEXT = "TEXT",
    HTML = "HTML",
    AUDIO = "AUDIO",
    VIDEO = "VIDEO",
    IMAGE = "IMAGE",
}

export enum ContentType {
    ANY = "*/*",
    AUDIO = "audio/*",
    VIDEO = "video/*",
    IMAGE = "image/*",
    TEXT = "text/text; charset=UTF-8",
    HTML = "text/html; charset=UTF-8"
}

export class CharonBaseService extends BaseService {

    constructor() {
        super(process.env.REACT_APP_CHARON_BASE_API_URL ?? "http://127.0.0.1:9302");
    }

}

