
import React from "react";
import { ConfirmDialog, Elements, SidedContainer, Siren } from "../../shared";
import { NavLink, useSearchParams } from "react-router-dom";
import { ApiResponseData, BaseService, IListedValue, ListedValueService, SanitizedResponse } from "../../../services";
import { Schemes, Subscriber, Utils } from "../../../utils";
import { i18nManager } from "../../../i18n";
import { Button, ButtonManageRef, Column, NoseurElement, NoseurObject, Scheme, TabPane, TabPanel, Table } from "@ronuse/noseur";

function ListedValues() {

    const listedValuesService = ListedValueService.getInstance();
    const labels = i18nManager.Labels.dashboard.security.listed_values;
    const [searchParams, setSearchParams] = useSearchParams(window.location.search);
    const [listedValues, setListedValues] = React.useState<ApiResponseData<IListedValue>>({});
    const [activeListedValue, setActiveListedValue] = React.useState<{ listing?: IListedValue, activeData?: NoseurObject<any>, mainContent?: () => NoseurElement }>({});

    React.useEffect(() => {
        if (!activeListedValue && !searchParams.has("active_data_id")) {
            fetchListedValues();
        } else {
            fetchListedValue(searchParams.get("active_data_id"));
        }
    }, []);
    React.useEffect(() => {
        if (window.location.search.includes("active_data_id")) {
            if (!activeListedValue.listing) {
                fetchListedValue(searchParams.get("active_data_id"));
            }
            return;
        }
        searchParams.delete("active_data_id");
        setActiveListedValue({});
        if (!listedValues.content) fetchListedValues();
    }, [searchParams]);

    return SidedContainer({
        searchParams,
        setSearchParams,
        onAction: manageListedValue,
        queryData: fetchListedValues,
        labels: labels.sided_labels,
        activeData: activeListedValue.activeData,
        mainContent: activeListedValue.mainContent ?? buildListingTable,
    } as any);

    function fetchListedValues() {
        listedValuesService.queryListedValues(Utils.normalizeUrlParams(searchParams), true).then(({ apiResponse }: SanitizedResponse<IListedValue>) => {
            setListedValues(apiResponse.data!);
        }).catch(BaseService.reportError).finally(() => Subscriber.report(Subscriber.KEYS.QUERY_REQUEST_PROMISE_COMPLETED));
    }

    function fetchListedValue(externalId: string | null) {
        if (!externalId) return;
        listedValuesService.getSingleListedValue(externalId).then(({ apiResponse }: SanitizedResponse<IListedValue>) => {
            setActiveListedValueProps(apiResponse.data as any as IListedValue);
        }).catch((err) => {
            BaseService.reportError(err);
            Utils.updateSearchParams("active_data_id", undefined, setSearchParams, fetchListedValues);
        }).finally(() => Subscriber.report(Subscriber.KEYS.QUERY_REQUEST_PROMISE_COMPLETED));
    }

    function setActiveListedValueProps(listedValue: IListedValue) {
        setActiveListedValue({
            listing: listedValue,
            mainContent: () => Elements.buildListingMainContent(listedValue, {
                type: labels.type,
                id: listedValue.external_id,
                value: listedValue.value,
                onCreate: listedValuesService.addListedValuePlatform.bind(listedValuesService),
                onDelete: listedValuesService.deleteListedValuePlatform.bind(listedValuesService),
                onComplete: () => {
                    fetchListedValue(listedValue.external_id);
                }
            }),
            activeData: {
                element: buildActiveData(listedValue)
            },
        });
    }

    function buildListingTable() {
        return (<React.Fragment>
            <Table rowProps={{ className: "listing-table-row" }} rowsPerPage={listedValues.size ?? 10} className="table" style={{ marginTop: 0 }} data={listedValues.content} totalRecords={listedValues.total_elements} dataRefreshKeys={["external_id"]} loadingState={Elements.LOADING_STATE} emptyState={<div className="es" style={{ marginTop: 20 }}>
                <i className="fa fa-bullseye" />
                <span style={{ maxWidth: 150 }}>{i18nManager.Labels.common.no} {labels.sided_labels.fine_name} {i18nManager.Labels.common.found} <span onClick={() => manageListedValue}>{labels.sided_labels.create ?? i18nManager.Labels.common.create}</span> {labels.sided_labels.fine_name}</span>
            </div>} onRowSelection={(listedValue: IListedValue) => {
                setActiveListedValueProps(listedValue);
                Utils.updateSearchParams("active_data_id", listedValue.external_id, setSearchParams);
                return false;
            }}>
                <Column header={labels.table.value} dataKey="value" />
                <Column header={labels.table.blacklisted_no} template={(listedValue: IListedValue) => `${Object.keys(listedValue.platforms_blacklisted ?? {}).length} ${labels.table.platforms}`} />
                <Column header={labels.table.whitelisted_no} template={(listedValue: IListedValue) => `${Object.keys(listedValue.platforms_whitelisted ?? {}).length} ${labels.table.platforms}`} />
                <Column header={labels.table.last_update} template={(listedValue: IListedValue) => Utils.formatToFineDate(listedValue.updated_at)} />
            </Table>
            {Elements.buildPaginator(listedValues ?? {}, ({ currentPage }, size) => Utils.updateSearchParamses({ "page": currentPage, id: null, size }, setSearchParams, fetchListedValues), parseInt(searchParams.get("page") ?? "1"))}
        </React.Fragment>);
    }

    function buildActiveData(listedValue: IListedValue) {
        return (<React.Fragment>
            <div className="header">
                <i className="fa fa-times" onClick={() => {
                    setActiveListedValue({});
                    Utils.updateSearchParams("active_data_id", undefined, setSearchParams, fetchListedValues);
                }} />
                <span className="name">{listedValue?.value}</span>
            </div>
            <div className="middle" style={{ borderTop: "none", marginTop: 0 }}>
            </div>
            <div className="actions">
                <span className="link danger" onClick={() => deleteListing(listedValue)}>{labels.active_listing.delete_listing}</span>
            </div>
        </React.Fragment>);
    }

    function deleteListing(listedValue: IListedValue) {
        const deleteLabels = labels.delete;
        const dialog = ConfirmDialog({
            closable: true,
            confirmScheme: Scheme.DANGER,
            desc: deleteLabels.desc.replace("${value}", listedValue.value),
            title: deleteLabels.title.replace("${value}", listedValue.value),
            onConfirm: (button?: ButtonManageRef) => {
                button?.setLoadingState(true);
                listedValuesService.deleteListedValue(listedValue.external_id).then(() => {
                    Siren.alert(deleteLabels.successful);
                    dialog.destroy(() => Utils.updateSearchParams("active_data_id", undefined, setSearchParams, fetchListedValues));
                }).catch(BaseService.reportError).finally(() => button?.setLoadingState(false));
                return false;
            }
        });
        dialog.show();
    }

    function manageListedValue() {
        let dialogErrorRef: any;
        const payload: any = { reason: "", listing_type: "WHITELIST" };
        const dialog = ConfirmDialog({
            closable: true,
            style: { minWidth: 350 },
            title: labels.create.title,
            confirmLabel: labels.create.list_value,
            content: (<div>
                {Elements.buildConfirmDialogForm(labels.create.form, (name: string, value: any) => {
                    dialogErrorRef.innerText = " ";
                    payload[name] = value;
                })}
                <div ref={(r) => dialogErrorRef = r} className="error"> </div>
            </div>),
            onConfirm: (button?: ButtonManageRef) => {
                button?.setLoadingState(true);
                dialogErrorRef.innerText = " ";
                if (!payload.value) {
                    button?.setLoadingState(false);
                    dialogErrorRef.innerText = labels.create.value_required;
                    return false;
                }
                listedValuesService.createListedValue(payload).then(() => {
                    Siren.alert(labels.create.successful);
                    dialog.destroy(fetchListedValues);
                }).catch(BaseService.reportError).finally(() => button?.setLoadingState(false));
                return false;
            }
        });
        dialog.show();
    }

}

export default ListedValues;