
import { NoseurObject } from "@ronuse/noseur";
import { CharonBaseService, MessageType } from "./CharonBaseService";

export interface IContact {
    name?: string;
    address: string;
    parameters: NoseurObject<any>;
}

export interface IContactList {
    id: number;
    name: string;
    type: MessageType;
    updated_at: string;
    external_id: string;
    description: string;
    contacts: IContact[];
    contact_list_id: string;
}

export class ContactListService extends CharonBaseService {

    protected static instance: ContactListService;

    private constructor() {
        super();
    }

    static getInstance() {
        if (!ContactListService.instance) ContactListService.instance = new ContactListService();
        return ContactListService.instance;
    }

    queryContactList(params: NoseurObject<string | number> = { page: 1, size: 10 }, refresh: boolean = false): Promise<any> {
        return this.report(this.transport.get("/charon/api/v1/contact/list", { params: this.normalizeParams(params) } as any));
    }

    getSingleByContactListId(contactListId: string): Promise<any> {
        return this.report(this.transport.get(`/charon/api/v1/contact/list/contact_list_id/${contactListId!}`, this.config));
    }

    createContactList(channel: IContactList): Promise<any> {
        return this.report(this.transport.post(`/charon/api/v1/contact/list`, channel, this.config));
    }

    updateContactList(channel: IContactList): Promise<any> {
        return this.report(this.transport.patch(`/charon/api/v1/contact/list/${channel.id!}`, channel, this.config));
    }

    deleteContactList(channel: IContactList): Promise<any> {
        return this.report(this.transport.delete(`/charon/api/v1/contact/list/${channel.id!}`, this.config));
    }

}

