
import React from "react";
import Auth from "./Auth";
import { i18nManager } from "../../i18n";
import { ConfirmDialog, Elements } from "../shared";
import { NoseurObject, Scheme } from "@ronuse/noseur";
import { useNavigate, useSearchParams } from "react-router-dom";
import { AuthsService, SanitizedResponse } from "../../services";

function ResetPassword() {
    const navigate = useNavigate();
    const [searchParams, _] = useSearchParams();
    const [ error, setError ] = React.useState<string>();
    const labels = i18nManager.Labels.auths.reset_password;
    const [loading, setLoading] = React.useState<boolean>(false);

    React.useEffect(() => {
        if (!(searchParams.has("e") && searchParams.has("f") && searchParams.has("c"))) return navigate("/forgot-password");
    });
    
    return Auth({
        ...(labels),
        error,
        loading,
        onSubmit: (service: AuthsService, formValues: NoseurObject<any>) => {
            setError(""); setLoading(true);
            if (formValues.password !== formValues.confirm_password) {
                setLoading(false);
                setError(i18nManager.Labels.common.password_does_not_match);
                return;
            }
            const payload = {
                email: searchParams.get("e"),
                password: formValues.password,
                email_factor: {
                    value: searchParams.get("c"),
                    factor_id: searchParams.get("f")
                }
            };
            service.resetPassword(payload).then(({}: SanitizedResponse<any>) => {
                ConfirmDialog({
                    ...labels.success,
                    centerIcon: Elements.DIALOG_CHECK_ICON,
                    onConfirm: () => {
                        navigate("/"); return true;
                    }
                }).show();
            }).catch(({ errorMessage }: SanitizedResponse<any>) => {
                setError(errorMessage);
                ConfirmDialog({
                    ...labels.failure,
                    confirmScheme: Scheme.DANGER,
                    centerIcon: Elements.DIALOG_TIMES_ICON,
                    onConfirm: () => true
                }).show();
            }).finally(() => {
                setLoading(false);
            });
        }
    });
}

export default ResetPassword;
