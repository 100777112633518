
import { NoseurObject } from "@ronuse/noseur";
import { ApiResponse, BaseService, SanitizedResponse } from "../../BaseService";
import { IAdministrator } from "./AdministrationService";

export interface IPermission {
    value: string;
    external_id?: string;
}

export interface IRole {
    name: string;
    external_id?: string;
    permissions: string[];
}

export interface IRoleAdministrator {
    role: IRole;
    external_id?: string;
    administrator: IAdministrator;
}

export class RolePermissionService extends BaseService {

    protected static instance: RolePermissionService;

    private constructor() {
        super();
    }

    static getInstance() {
        if (!RolePermissionService.instance) RolePermissionService.instance = new RolePermissionService();
        return RolePermissionService.instance;
    }

    queryPermissions(params: NoseurObject<string | number> = {}, cb?: (apiResponse: ApiResponse<IPermission>) => void): Promise<any> {
        params.size = 30;
        return this.report(this.transport.get("/janus/geminus/api/v1/permission", { params: this.normalizeParams(params) } as any), ({ apiResponse }: SanitizedResponse<IPermission>) => {
            if (cb && cb instanceof Function) cb(apiResponse);
        });
    }

    createPermissions(permissions: IPermission[]): Promise<any> {
        return this.report(this.transport.post(`/janus/geminus/api/v1/permission/multiple`, permissions, this.config));
    }

    deletePermission(externalId: string): Promise<any> {
        return this.report(this.transport.delete(`/janus/geminus/api/v1/permission/${externalId}`, this.config));
    }

    queryRoles(params: NoseurObject<string | number> = {}, cb?: (apiResponse: ApiResponse<IRole>) => void): Promise<any> {
        return this.report(this.transport.get("/janus/geminus/api/v1/role", { params: this.normalizeParams(params) } as any), ({ apiResponse }: SanitizedResponse<IRole>) => {
            if (cb && cb instanceof Function) cb(apiResponse);
        });
    }

    createRole(role: IRole): Promise<any> {
        return this.report(this.transport.post(`/janus/geminus/api/v1/role`, role, this.config));
    }

    updateRole(role: IRole): Promise<any> {
        return this.report(this.transport.patch(`/janus/geminus/api/v1/role/${role.external_id!}`, role, this.config));
    }

    deleteRole(externalId: string): Promise<any> {
        return this.report(this.transport.delete(`/janus/geminus/api/v1/role/${externalId}`, this.config));
    }

    queryRoleAdministrators(params: NoseurObject<string | number> = {}, cb?: (apiResponse: ApiResponse<IRoleAdministrator>) => void): Promise<any> {
        return this.report(this.transport.get("/janus/geminus/api/v1/role/administrator", { params: this.normalizeParams(params) } as any), ({ apiResponse }: SanitizedResponse<IRoleAdministrator>) => {
            if (cb && cb instanceof Function) cb(apiResponse);
        });
    }

    updateRoleAdministrators(roleId: string, administrator_ids: string[], assign: boolean = true): Promise<any> {
        return this.report(this.transport.put(`/janus/geminus/api/v1/role/${roleId}/administrators`, { assign, administrator_ids } as any, this.config));
    }

    assignAdministratorRoles(administratorId: string, assign_role_ids: string[], revoke_role_ids: string[]): Promise<any> {
        return this.report(this.transport.post(`/janus/geminus/api/v1/role/administrator/multiple`, [
            { assign: true, administrator_id: administratorId, role_ids: assign_role_ids },
            { assign: false, administrator_id: administratorId, role_ids: revoke_role_ids },
        ] as any, this.config));
    }

}

