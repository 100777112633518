
import React from "react";
import Auth from "./Auth";
import { i18nManager } from "../../i18n";
import { CacheManager } from "../../utils";
import { NoseurObject } from "@ronuse/noseur";
import { useNavigate } from "react-router-dom";
import { ConfirmDialog, Elements, RequestNewFa } from "../shared";
import { AuthsService, IProfile, SanitizedResponse } from "../../services";

function Fa() {
    const navigate = useNavigate();
    const labels = i18nManager.Labels.auths.fa;
    const [error, setError] = React.useState<string>();
    const [loading, setLoading] = React.useState<boolean>(false);
    const profile: IProfile = CacheManager.get(CacheManager.PROFILE_KEY);
    const sessionToken = CacheManager.get(CacheManager.SESSION_TOKEN_KEY);

    React.useEffect(() => {
        if (!sessionToken || !profile) return navigate("/");
    });

    return Auth({
        ...(labels),
        error,
        loading,
        extra: {
            label: labels.extra!.label,
            link: {
                onClick: onClickRequestNewFa,
                text: labels.extra!.link!.text,
            }
        },
        onSubmit: (service: AuthsService, formValues: NoseurObject<any>) => {
            setError(""); setLoading(true);
            service.fa({ ...formValues, session_token: sessionToken }).then(({ sanitized }: SanitizedResponse<any>) => {
                CacheManager.insecurePut(CacheManager.ACCESS_TOKEN_KEY, sanitized.access_token);
                return navigate("/dashboard");
            }).catch(({ errorMessage }: SanitizedResponse<any>) => {
                setLoading(false); setError(errorMessage);
            });
        }
    });

    function onClickRequestNewFa(service: AuthsService) {
        RequestNewFa({
            profile,
            service,
            reset: true,
            onSuccess: () => {
                ConfirmDialog({
                    ...labels.reset.success,
                    centerIcon: Elements.DIALOG_CHECK_ICON,
                    onConfirm: () => {
                        return true;
                    }
                }).show();
            }
        });
    }

}

export default Fa;

