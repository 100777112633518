
import { NoseurObject } from "@ronuse/noseur";
import { LanuariusBaseService } from "./LanuariusBaseService";

export interface IUserPhoneNumber {
    phone: string;
    primary: boolean;
    created_at: string;
    updated_at: string;
    external_id: string;
}

export class UserPhoneNumberService extends LanuariusBaseService {

    protected static instance: UserPhoneNumberService;

    private constructor() {
        super();
    }

    static getInstance() {
        if (!UserPhoneNumberService.instance) UserPhoneNumberService.instance = new UserPhoneNumberService();
        return UserPhoneNumberService.instance;
    }

    queryUserPhoneNumbers(params: NoseurObject<string | number> = { page: 1, size: 10 }): Promise<any> {
        return this.report(this.transport.get("/janus/lanuarius/api/v1/administration/user/phone", { params: this.normalizeParams(params) } as any));
    }

    markUserPhoneNumberAsDefault(externalId: string): Promise<any> {
        return this.report(this.transport.put("/janus/lanuarius/api/v1/administration/user/phone/primary", { phone_id: externalId } as any, this.config));
    }

    deleteUserPhoneNumber(externalId: string): Promise<any> {
        return this.report(this.transport.delete("/janus/lanuarius/api/v1/administration/user/phone/multiple", {
            ...this.config,
            data: [
                externalId
            ]
        }));
    }

}

