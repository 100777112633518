
import React from "react";
import Auth from "./Auth";
import { i18nManager } from "../../i18n";
import { NoseurObject } from "@ronuse/noseur";
import { useNavigate } from "react-router-dom";
import { ConfirmDialog, Elements } from "../shared";
import { AuthsService, SanitizedResponse } from "../../services";

function ForgotPassword() {
    const navigate = useNavigate();
    const [error, setError] = React.useState<string>();
    const labels = i18nManager.Labels.auths.forgot_password;
    const [loading, setLoading] = React.useState<boolean>(false);

    return Auth({
        ...(labels),
        error,
        loading,
        onSubmit: (service: AuthsService, formValues: NoseurObject<any>) => {
            setError(""); setLoading(true);
            service.requestPasswordResetEmail({ ...formValues, prefix_url: `${window.location.host}/reset-password` }).then(({}: SanitizedResponse<any>) => {
                ConfirmDialog({
                    ...labels.success,
                    centerIcon: Elements.DIALOG_CHECK_ICON,
                    onConfirm: () => {
                        navigate("/"); return true;
                    }
                }).show();
            }).catch(({ errorMessage }: SanitizedResponse<any>) => {
                setError(errorMessage);
            }).finally(() => {
                setLoading(false);
            });
        }
    });
}

export default ForgotPassword;
