
import React from "react";
import Users from "./Users";
import Businesses from "./Businesses";
import { i18nManager } from "../../../i18n";
import { Route, Routes } from "react-router-dom";
import EmailChangeRequest from "./EmailChangeRequest";
import { IProfile, ISecondaryData } from "../../../services";
import { DashboardContainer } from "../../shared/DashboardContainer";

export interface UsersAndBusinessesProps {
    search: string;
    profile: IProfile;
    secondaryData: ISecondaryData;
}

function UsersAndBusinesses(props: UsersAndBusinessesProps) {
    const labels = i18nManager.Labels.dashboard.users_and_businesses;

    return DashboardContainer({
        ...labels,
        search: props.search,
        secondaryData: props.secondaryData,
        content: (<Routes>
            <Route path="/users" element={<Users />} />
            <Route path="/businesses" element={<Businesses />} />
            <Route path="/email-change-requests" element={<EmailChangeRequest />} />
        </Routes>),
    });
}

export default UsersAndBusinesses;
