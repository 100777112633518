
import React from "react";
import { i18nManager } from "../../../i18n";
import { NavLink, useSearchParams } from "react-router-dom";
import { Utils, Subscriber, Schemes } from "../../../utils";
import { TemplateType } from "../../../services/charon/CharonBaseService";
import { ConfirmDialog, Elements, SidedContainer, Siren, TemplateEditor } from "../../shared";
import { ITemplate, ApiResponseData, TemplateService, BaseService, SanitizedResponse } from "../../../services";
import { BoolHelper, Button, ButtonManageRef, Classname, FileInputPreviewType, NoseurElement, NoseurObject, ObjectHelper, Scheme, fileInputBuildFileInputPreview } from "@ronuse/noseur";

function Templating() {
    const templateService = TemplateService.getInstance();
    const iframeRef = React.useRef<HTMLIFrameElement>(null);
    const dialogErrorRef = React.useRef<HTMLDivElement>(null);
    const actionButtonRef = React.useRef<ButtonManageRef>(null);
    const secDialogErrorRef = React.useRef<HTMLDivElement>(null);
    const labels = i18nManager.Labels.dashboard.messaging.templating;
    const htmlTemplateEditorRef = React.useRef<NoseurObject<any>>({});
    const [activeTemplate, setActiveTemplate] = React.useState<ITemplate>();
    const [searchParams, setSearchParams] = useSearchParams(window.location.search);
    const [templates, setTemplates] = React.useState<ApiResponseData<ITemplate>>({});
    const [leftAndMainContent, setLeftAndMainContent] = React.useState<NoseurObject<NoseurElement>>({});

    React.useEffect(() => {
        fetchTemplates();
        return () => {
            Subscriber.report(Subscriber.KEYS.TOGGLE_SIDEBAR_VISIBILITY, true);
        };
    }, []);

    return SidedContainer({
        searchParams,
        data: templates,
        setSearchParams,
        onAction: createTemplate,
        queryData: fetchTemplates,
        labels: labels.sided_labels,
        leftContent: leftAndMainContent?.leftContent,
        mainContent: leftAndMainContent?.mainContent ? () => leftAndMainContent?.mainContent : undefined,
        internalElementProps: {
            className: "template-list"
        },
        activeData: (activeTemplate ? {
            override: true,
            data: activeTemplate,
            preview: Elements.buildTemplateView(activeTemplate, TemplateType),
            element: (<React.Fragment>
                <div className="header">
                    <i className="fa fa-times" onClick={() => {
                        if (leftAndMainContent.preview) {
                            manageTemplate(activeTemplate);
                        }
                        setActiveTemplate(undefined);
                        Utils.updateSearchParams("active_data_id", undefined, setSearchParams);
                    }} />
                    <span className="name">{activeTemplate?.name}</span>
                    <span className="id" style={{ fontWeight: "bold" }}>{activeTemplate?.template_id}</span>
                    <span className="id">{activeTemplate?.type}</span>
                    <span className="desc">{activeTemplate?.description}</span>
                </div>
                <div className="middle">
                    <span style={{ marginTop: 5, marginBottom: 5, fontWeight: "bold" }}>{labels.parameters}</span>
                    {activeTemplate?.required_parameters?.map((parameter, index) => {
                        return (<div key={index}>{parameter}</div>);
                    })}
                </div>
                {!leftAndMainContent.preview ? (<div className="actions">
                    <NavLink to={`/dashboard/messaging/messages?template_id=${activeTemplate?.id}`}>{i18nManager.Labels.dashboard.messaging.see_messages}</NavLink>
                    <span className="link" onClick={() => manageTemplate(activeTemplate)}>{labels.edit_template}</span>
                    <span className="link danger" onClick={() => deleteTemplate(activeTemplate)}>{labels.delete_template}</span>
                </div>) : null}
            </React.Fragment>)
        } : undefined),
        dataTemplate: (template: ITemplate) => {
            return (<div className={Classname.build("template", (activeTemplate?.template_id === template.template_id ? "active" : null))} onClick={() => {
                setActiveTemplate(template);
                Utils.updateSearchParams("active_data_id", template.id, setSearchParams);
            }}>
                {buildTemplatePreview(template)}
                <div className="details">
                    <span className="name lined-limit">{template.name}</span>
                    <span className="type">{template.type}</span>
                    <span className="desc lined-limit">{template.description}</span>
                </div>
                <div className="control">
                    <i className="fa fa-edit link gray" onClick={() => manageTemplate(template)} />
                    <i className="fa fa-trash link danger" onClick={() => deleteTemplate(template)} />
                </div>
            </div>);
        }
    });

    function fetchTemplates() {
        const id = parseInt(searchParams.get("active_data_id") || "0");
        templateService.queryTemplate(Utils.normalizeUrlParams(searchParams), true).then(({ apiResponse }: SanitizedResponse<ITemplate>) => {
            setTemplates(apiResponse.data!);
            if (id > 0) for (const item of apiResponse.data?.content!) {
                if (item.id === id) {
                    setActiveTemplate(item);
                    break;
                }
            }
        }).catch(BaseService.reportError).finally(() => Subscriber.report(Subscriber.KEYS.QUERY_REQUEST_PROMISE_COMPLETED));
    }

    function createTemplate(type?: TemplateType) {
        const dialog = ConfirmDialog({
            closable: true,
            ...labels.dialog.manage,
            content: (<div>
                {Elements.buildConfirmDialogForm(labels.dialog.manage.form, (_: string, value: string) => {
                    dialogErrorRef.current!.innerText = " ";
                    type = value as any;
                }, { type })}
                <div ref={dialogErrorRef} className="error"> </div>
            </div>),
            onConfirm: () => {
                if (!type) {
                    dialogErrorRef.current!.innerText = labels.dialog.manage.select_type;
                    return false;
                }
                manageTemplate(undefined, type);
                return true;
            }
        });
        dialog.show();
    }

    function manageTemplate(template?: ITemplate, type_?: TemplateType) {
        setActiveTemplate(undefined);
        const editorLabel = labels.dialog.manage.editor;
        let formValues: NoseurObject<any> = (template ?? {});
        formValues["type"] = template?.type ?? type_ ?? TemplateType.ANY;
        if (formValues.required_parameters && !formValues.parameters) {
            formValues.parameters = formValues.required_parameters.reduce((acc: any, v: any) => {
                acc[v] = null;
                return acc;
            }, {});
        }
        setLeftAndMainContent({
            leftContent: (<React.Fragment>
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <span className="title">{editorLabel.title}</span>
                    <span className="link" style={{ fontSize: 13 }} onClick={() => {
                        setLeftAndMainContent({ preview: true });
                        setActiveTemplate(formValues as any as ITemplate);
                    }}>{editorLabel.preview}</span>
                </div>
                {Elements.buildConfirmDialogForm(editorLabel.form, (name: string, value: string) => {
                    secDialogErrorRef.current!.innerText = " ";
                    formValues[name] = value;
                }, formValues, undefined, { marginTop: 0 })}
                {!BoolHelper.equalsAny(formValues.type, [TemplateType.IMAGE, TemplateType.AUDIO, TemplateType.VIDEO])
                    ? (<React.Fragment>
                        <div style={{ flex: 0.7, marginBottom: 15 }} />
                        <span>{editorLabel.parameters}</span>
                        <span style={{ fontSize: 12, marginTop: 5, marginBottom: 5 }}>{editorLabel.params_info}</span>
                        {Elements.buildConfirmDialogForm(editorLabel.secondary_form, (_: string, value: string) => {
                            try {
                                if (!formValues.parameters) formValues.parameters = {};
                                formValues.parameters = JSON.parse(value);
                                formValues["required_parameters"] = Object.keys(formValues.parameters);
                                if (iframeRef.current) iframeRef.current.srcdoc = ObjectHelper.expandStringTemplate(formValues.content, formValues.parameters, { chop: "$", relativeExpansion: true });
                                secDialogErrorRef.current!.innerText = " ";
                            } catch (err: any) {
                                secDialogErrorRef.current!.innerText = err;
                            }
                        }, { parameters: JSON.stringify(formValues.parameters, undefined, 4)}, undefined, { marginTop: 0 })}
                    </React.Fragment>) : null}
                <div style={{ flex: 1, marginBottom: 15 }} />
                <div ref={secDialogErrorRef} className="error"> </div>
                <Button manageRef={actionButtonRef} scheme={Schemes.RIVTN_QUIRINUS} text={!!template?.id ? editorLabel.update : editorLabel.create} onClick={() => {
                    actionButtonRef.current?.setLoadingState(true);
                    const method = (!!formValues.id ? templateService.updateTemplate : templateService.createTemplate).bind(templateService);
                    method(formValues as any)
                        .then(({ }: SanitizedResponse<ITemplate>) => {
                            Siren.alert(editorLabel.successful);
                            if (!formValues.id) {
                                setLeftAndMainContent({});
                                setActiveTemplate(undefined);
                                Subscriber.report(Subscriber.KEYS.TOGGLE_SIDEBAR_VISIBILITY, true);
                            }
                        }).catch(({ errorMessage }: SanitizedResponse<any>) => {
                            secDialogErrorRef.current!.innerText = errorMessage;
                        }).finally(() => {
                            actionButtonRef.current?.setLoadingState(false);
                        });
                }} fill raised loadingProps={{
                    disabled: true,
                    leftIcon: "fas fa-spinner fa-pulse"
                }} />
                <Button scheme={Scheme.DANGER} text={i18nManager.Labels.common.back} onClick={() => {
                    setLeftAndMainContent({});
                    if (!formValues.id) createTemplate(formValues.type);
                    else setActiveTemplate(formValues as any as ITemplate);
                    Subscriber.report(Subscriber.KEYS.TOGGLE_SIDEBAR_VISIBILITY, true);
                }} style={{ marginTop: 10 }} fill raised />
            </React.Fragment>),
            mainContent: TemplateEditor.buildCreateTemplateEditor(editorLabel, formValues, htmlTemplateEditorRef),
        });
    }

    function deleteTemplate(template: ITemplate) {
        const dialog = ConfirmDialog({
            closable: true,
            confirmScheme: Scheme.DANGER,
            title: labels.dialog.delete.title,
            desc: labels.dialog.delete.desc.replace("${value}", template.name),
            onConfirm: (button?: ButtonManageRef) => {
                button?.setLoadingState(true);
                templateService.deleteTemplate(template).then(() => {
                    Siren.alert(labels.dialog.delete.successful);
                    setActiveTemplate(undefined);
                    dialog.destroy(fetchTemplates);
                }).catch(BaseService.reportError).finally(() => button?.setLoadingState(false));
                return false;
            }
        });
        dialog.show();
    }

    function buildTemplatePreview(template: ITemplate) {
        let url = template.content;
        let type: string = template.type;

        if (type === TemplateType.ANY) type = "HTML";
        if (type === TemplateType.TEXT) url = URL.createObjectURL(new Blob([template.content], { type: 'text/html' }));

        return fileInputBuildFileInputPreview({
            url,
            style: { flex: 1, width: "auto", maxWidth: "100%", height: 130 },
            previewType: (FileInputPreviewType as any)[type],
            ref: (r: any) => {
                if (!r) return;
                if (r.tagName === "IFRAME") {
                    r.onload = function () {
                        const body = r.contentWindow.document.querySelector('body');
                        body.style.fontSize = '10px';
                    };
                }
            }
        });
    }

    

}

export default Templating;
