
import React from "react";
import Links from "./Links";
import { i18nManager } from "../../../../i18n";
import { Route, Routes } from "react-router-dom";
import { IPlatform } from "../../../../services";
import { DashboardContainer } from "../../../shared/DashboardContainer";

export interface ManagementProps {
    search: string;
    platform?: IPlatform;
}

function Management(props: ManagementProps) {
    const loading = !props.platform;
    const labels = i18nManager.Labels.dashboard.platform_management.management;

    return DashboardContainer({
        ...labels,
        search: props.search,
        title: (<React.Fragment>{labels.title} ({loading ? (<i className="fa fa-spinner fa-spin" />) : (props.platform?.platform_id ?? '')})</React.Fragment>),
        content: (<Routes>
            <Route path="/links" element={<Links platform={props.platform} loading={loading}/>} />
        </Routes>),
    });
}

export default Management;
