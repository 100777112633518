
import React from "react";
import { CacheManager, Schemes, Utils } from "../../utils";
import ReactDOM from "react-dom/client";
import { FormControls, i18nManager } from "../../i18n";
import { ConfirmDialog } from "./ConfirmDialog";
import { AlertInterface, Button, ButtonManageRef, Checkbox, Classname, DOMHelper, Dropdown, DropdownManageRef, FileInput, FormControl, FormGroup, NoseurObject, ObjectHelper, TypeChecker, fileInputFileFromUrl } from "@ronuse/noseur";
import { Attachment, BaseService, ChannelService, ContactListService, IChannel, IContact, IContactList, IMessage, IPlatform, ITemplate, PlatformService, SanitizedResponse, TemplateService, TemplateType } from "../../services";
import { Elements } from "./Elements";
import { TemplateEditor } from "./TemplateEditor";

export interface CreateMessageProps {
    title: string;
    message?: IMessage;
    cancelLabel?: string;
    onCancel?: () => void;
    onComplete: (message: IMessage, cb: (error?: string) => void) => void;
    refMap: React.MutableRefObject<NoseurObject<any>>;
}

export function CreateMessage(props: CreateMessageProps) {
    let template: any = {};
    let dialogErrorRef: any;
    let sdialogErrorRef: any;
    let secDialogErrorRef: any;
    let secDialogErrorRef2: any;
    let startDialog: AlertInterface;
    let flatReceipients: IContact[] = [];
    const channelService = ChannelService.getInstance();
    const platformService = PlatformService.getInstance();
    const templateService = TemplateService.getInstance();
    let activeDialogMutableRoot: ReactDOM.Root | undefined;
    let contentPreviewMutableRoot: ReactDOM.Root | undefined;
    let secActiveDialogMutableRoot: ReactDOM.Root | undefined;
    let secActiveDialogMutableRoot2: ReactDOM.Root | undefined;
    const contactListService = ContactListService.getInstance();
    let largeContentPreviewMutableRoot: ReactDOM.Root | undefined;
    const labels = i18nManager.Labels.dashboard.messaging.messages.create_message;
    let message: IMessage = props.message ?? {
        async: false,
        rotate_channel_on_retry: false,
        from: CacheManager.get(CacheManager.PROFILE_KEY)?.email
    } as any as IMessage;

    startDialog = ConfirmDialog({
        closable: true,
        title: props.title,
        onCancel: props.onCancel,
        desc: labels.starter.desc,
        cancelLabel: props.cancelLabel,
        content: (<div className="create-message starter">
            <FormGroup className="form" scheme={Schemes.RIVTN_QUIRINUS}>
                <Checkbox label={labels.starter.use_template} onChange={(e: any) => {
                    switchStarterMessageType(e.checked);
                    sdialogErrorRef.innerText = " ";
                }} defaultChecked={!!message?.template_id} />
                <div className="switcher" ref={(r) => {
                    if (!r) return;
                    activeDialogMutableRoot = ReactDOM.createRoot(r);
                    switchStarterMessageType(!!message?.template_id);
                }} />
                <div ref={(r) => { if (r) sdialogErrorRef = r; }} className="error"> </div>
            </FormGroup>
        </div>),
        onConfirm: () => {
            if (!message.content_type) {
                sdialogErrorRef.innerText = labels.starter.select_content_or_template;
                return false;
            }
            buildMainDialog().show();
            return true;
        }
    });

    if (!template.type && message.template_id) {
        templateService.getSingleByTemplateId(message.template_id).then(({ apiResponse }: SanitizedResponse<ITemplate>) => {
            template = apiResponse.data as any as ITemplate;
            contentPreviewMutableRoot?.render(Elements.buildTemplateView(template, TemplateType, { borderRadius: 0, margin: 0, flex: 1, height: "unset" }, {}, message.attachments));
        }).catch(BaseService.reportError);
    } else if (!template.type && message.content) {
        template = {
            content: message.content,
            type: TemplateEditor.contentTypeToTemplateType(message.content_type),
        };
        contentPreviewMutableRoot?.render(Elements.buildTemplateView(template, TemplateType, { borderRadius: 0, margin: 0, flex: 1, height: "unset" }, {}, message.attachments));
    }

    return startDialog;

    function buildMainDialog() {
        const mainDialog = ConfirmDialog({
            closable: true,
            title: props.title,
            confirmLabel: props.title,
            cancelLabel: i18nManager.Labels.common.back,
            style: { minWidth: "90%", minHeight: "90%" },
            onCancel: () => startDialog.show(() => {
                switchStarterMessageType(!!message?.template_id);
            }),
            content: (<div style={{ display: "flex", flexDirection: "column", height: "100%" }}>
                <div className="create-message main">
                    <div className="props">
                        {labels.main.props.form?.map((control: FormControls, index: number) => {
                            return (<div key={index} className="labeledd-fc-h">{control.title ? <span>{control.title}</span> : null}
                                {Elements.buildConfirmDialogForm([control], (name, value, searching) => {
                                    dialogErrorRef.innerText = " ";
                                    if (!searching) {
                                        (message as any)[name] = value;
                                        return;
                                    }
                                    if (name === "platform_id") {
                                        platformService.queryPlatforms({ name__like: value.value }, true).then(({ apiResponse }: SanitizedResponse<IPlatform>) => {
                                            value.manageRef?.changeOptions(apiResponse.data!.content?.map((platform: IPlatform) => {
                                                return {
                                                    "label": platform.name,
                                                    "value": platform.platform_id
                                                };
                                            }));
                                        }).catch(BaseService.reportError);
                                    } else if (name === "channel_id") {
                                        channelService.queryChannel({ name: value.value }, true).then(({ apiResponse }: SanitizedResponse<IChannel>) => {
                                            value.manageRef?.changeOptions(apiResponse.data!.content?.map((channel: IChannel) => {
                                                return {
                                                    "label": channel.name,
                                                    "value": channel.channel_id
                                                };
                                            }));
                                        }).catch(BaseService.reportError);
                                    }
                                }, message)}
                            </div>)
                        })}
                    </div>
                    <div className="recepients-extras">
                        <span style={{ fontSize: 13 }}>{labels.main.contacts.desc}</span>
                        <div className="recepients" ref={(r) => {
                            if (!r) return;
                            activeDialogMutableRoot = ReactDOM.createRoot(r);
                            updateRecepientsAndContactLists();
                        }}>
                        </div>
                        <div className="controls">
                            <div className="link-hv" style={{ fontSize: 13, marginTop: 15, cursor: "pointer", width: "fit-content" }} onClick={() => {
                                if (!message.recipients) message.recipients = [];
                                message.recipients.push({} as any);
                                updateRecepientsAndContactLists();
                            }}>
                                <i className="fa fa-plus" style={{ marginRight: 7, color: "#0FA883" }} />
                                <span>{labels.main.contacts.add_new_contact}</span>
                            </div>
                            {Elements.buildConfirmDialogForm([labels.main.contacts.search_select_contact_list], (_, value, search) => {
                                if (!message.contact_list_ids) message.contact_list_ids = [];
                                if (search) {
                                    contactListService.queryContactList({ name: value.value }, true).then(({ apiResponse }: SanitizedResponse<IContactList>) => {
                                        value.manageRef?.changeOptions(apiResponse.data!.content?.map((contactList: IContactList) => {
                                            return { "value": contactList, "label": contactList.name, not_selectable: message.contact_list_ids.includes(contactList.contact_list_id) };
                                        }));
                                    }).catch(BaseService.reportError);
                                    return;
                                }
                                if (message.contact_list_ids.includes(value.contact_list_id)) return false;
                                message.contact_list_ids.push(value.contact_list_id);
                                updateRecepientsAndContactLists();
                                flatReceipients = [];
                                return false;
                            }, message)}
                            <div className="bt">
                                <span className="link" onClick={addCustomHeaders}>{labels.main.contacts.add_custom_header}</span>
                                <span className="link" onClick={addListHeaders}>{labels.main.contacts.add_list_headers}</span>
                                <span className="link" onClick={addCcsAndBccs}>{labels.main.contacts.add_ccs_and_bccs}</span>
                            </div>
                        </div>
                    </div>
                    <div className="content-template">
                        <div className="controls">
                            <span className={Classname.build("link", (!!template.id ? "noseur-disabled" : null))} onClick={editContent}>{labels.main.content.controls.edit}</span>
                            <span className="link" onClick={largePreview}>{labels.main.content.controls.large_preview}</span>
                            <span className="link" onClick={() => {
                                mainDialog.hide(() => startDialog.show(() => {
                                    switchStarterMessageType(!!message?.template_id);
                                }));
                            }}>{labels.main.content.controls.change}</span>
                            <span className="link" onClick={addAttachements}>{labels.main.content.controls.add_attachements}</span>
                        </div>
                        {Elements.buildConfirmDialogForm([labels.main.content.subject_control], (_, value) => {
                            message.subject = value;
                        }, message)}
                        <div style={{ height: "80%", margin: "10px 0px", border: "1px solid #000000", display: "flex" }} ref={(r) => {
                            if (!r) return;
                            contentPreviewMutableRoot = ReactDOM.createRoot(r);
                            if (template.type) contentPreviewMutableRoot.render(Elements.buildTemplateView(template, TemplateType, { borderRadius: 0, margin: 0, flex: 1, height: "unset" }, {}, message.attachments));
                        }} />
                    </div>
                </div>
                <div ref={(r) => dialogErrorRef = r} className="error"> </div>
            </div>),
            onConfirm: (confirmButton?: ButtonManageRef) => {
                dialogErrorRef.innerText = " ";
                if (template.id && template.template_id) {
                    message.template_id = template.template_id;
                } else {
                    message.content = template.content;
                }
                const erroMessages: string[] = [];
                if (!message.subject) erroMessages.push(labels.main.subject_required);
                if (!message.from) erroMessages.push(labels.main.from_address_required);
                if (!message.type) erroMessages.push(labels.main.message_type_required);
                if (!message.channel_id) erroMessages.push(labels.main.channel_required);
                if (!message.content && !message.template_id) erroMessages.push(labels.main.content_or_template_required);
                if ((!message.recipients || !message.recipients.length) && (!message.contact_list_ids || !message.contact_list_ids.length)) erroMessages.push(labels.main.a_recipient_required);
                if (erroMessages.length) {
                    dialogErrorRef.innerText = erroMessages.join(", ");
                    return false;
                }
                confirmButton?.setLoadingState(true);
                props.onComplete && props.onComplete(message, (err) => {
                    confirmButton?.setLoadingState(false);
                    if (!err) {
                        mainDialog.destroy();
                        return;
                    }
                    dialogErrorRef.innerText = err;
                });
                return false;
            }
        });
        return mainDialog;
    }

    function switchStarterMessageType(isTemplate: boolean = false) {
        if (!activeDialogMutableRoot) return;
        if (!isTemplate) {
            const templateTypes = Object.keys(TemplateType);
            const selectedOptionIndex = templateTypes.reduce((acc, tt, i) => {
                if (message?.content_type?.includes(tt?.toLowerCase())) acc = i;
                return acc;
            }, -1);
            activeDialogMutableRoot.render(<Dropdown scheme={Schemes.RIVTN_QUIRINUS} placeholder={labels.starter.type}
                options={templateTypes.map((alignment, index) => ({ label: alignment, value: Object.values(TemplateType)[index] }))}
                formControlProps={{ validStyle: { borderColor: "transparent" }, contentStyle: { borderRadius: 0 } }} selectedOptionIndex={selectedOptionIndex}
                onSelectOption={(option) => {
                    sdialogErrorRef.innerText = " ";
                    delete template.id;
                    delete template.template_id;
                    template.type = option.value;
                    message.content_type = TemplateEditor.templateTypeToContentType(option.value);
                    return true;
                }} />);
            return;
        }
        activeDialogMutableRoot.render(<Dropdown scheme={Schemes.RIVTN_QUIRINUS} placeholder={labels.starter.search_and_select}
            formControlProps={{ validStyle: { borderColor: "transparent" }, contentStyle: { borderRadius: 0 } }} editable options={[]} defaultInputValue={message?.template_id}
            onSearch={(e: any, dropdownManageRef?: DropdownManageRef) => {
                templateService.queryTemplate({ name: e.target.value }, true).then(({ apiResponse }: SanitizedResponse<ITemplate>) => {
                    dropdownManageRef?.changeOptions(apiResponse.data!.content?.map((template: ITemplate) => {
                        return {
                            "value": template,
                            "label": template.name,
                        };
                    }));
                }).catch(BaseService.reportError);
                return undefined;
            }} onSelectOption={(option) => {
                sdialogErrorRef.innerText = " ";
                template = option.value;
                message.content_type = TemplateEditor.templateTypeToContentType(template.type);
                contentPreviewMutableRoot?.render(Elements.buildTemplateView(template, TemplateType, { borderRadius: 0, margin: 0, flex: 1, height: "unset" }, {}, message.attachments));
                return true;
            }} />);
    }

    function updateRecepientsAndContactLists() {
        if (!activeDialogMutableRoot) return;
        const recepients: any[] = [];
        message.recipients?.forEach((contact: IContact, index: number) => {
            const keyProps: any = Utils.buildSelectNoseurComponentProps({
                name: "address",
                value: contact.address,
                placeholder: labels.main.contacts.address,
            }, undefined, undefined, undefined, undefined, undefined, (_: string, value: any) => {
                flatReceipients = [];
                dialogErrorRef.innerText = " ";
                message.recipients[index].address = value;
            });
            const valueProps: any = Utils.buildSelectNoseurComponentProps({
                name: "parameters",
                value: JSON.stringify(contact.parameters),
                placeholder: labels.main.contacts.params_in_json,
            }, undefined, undefined, undefined, undefined, undefined, (_: string, value: any) => {
                flatReceipients = [];
                dialogErrorRef.innerText = " ";
                try {
                    message.recipients[index].parameters = JSON.parse(value);
                } catch (err: any) {
                    dialogErrorRef.innerText = err;
                }
            });
            valueProps.style = { background: "rgba(217, 217, 217, 0.2)", borderRadius: 0, flex: 1.2, height: 70 };
            keyProps.style = { background: "rgba(217, 217, 217, 0.2)", borderRadius: 0, flex: 0.8, marginRight: 10, alignSelf: "flex-start" };
            recepients.push(<FormControl key={'recipeints-' + index} style={{ marginTop: 0, marginBottom: 10, background: "transparent", width: "100%" }}
                validStyle={{ borderColor: "transparent" }} contentStyle={{ borderRadius: 0 }} ref={(r: any) => r?.scrollIntoView()}
                rightContent={<i className="fa fa-trash" style={{ color: "#EB5959", cursor: "pointer" }} onClick={() => {
                    flatReceipients = [];
                    dialogErrorRef.innerText = " ";
                    message.recipients.splice(index, 1); updateRecepientsAndContactLists();
                }} />}>
                {React.createElement(Utils.selectNoseurComponent() as any, keyProps)}
                {React.createElement(Utils.selectNoseurComponent("textarea") as any, valueProps)}
            </FormControl>);
        });
        message.contact_list_ids?.forEach((contactListId: string, index: number) => {
            recepients.push(<div key={'contact-list-' + index} style={{ display: "flex", justifyContent: "flex-end", marginTop: 10 }}>
                <a href="#" style={{ marginRight: 10 }}>{contactListId}</a>
                <i className="fa fa-trash" style={{ color: "#EB5959", cursor: "pointer" }} onClick={() => {
                    flatReceipients = [];
                    dialogErrorRef.innerText = " ";
                    message.contact_list_ids.splice(index, 1); updateRecepientsAndContactLists();
                }} />
            </div>);
        });
        activeDialogMutableRoot.render(recepients);
    }

    function updateKeyValueSecondaryRoot(root: ReactDOM.Root | undefined, entries: NoseurObject<any> | IContact[], labels: { key: string; value: string; }, setErrormessage: (err: string) => void) {
        if (!root) return;
        const entryIsList = TypeChecker.isArray(entries);
        const components = (entryIsList ? entries : Object.keys(entries)).map((entry: any | IContact, index: number) => {
            let entryKey = entry, entryValue = JSON.stringify((entries as any)[entry], null, 2);
            if (entryIsList) {
                entryKey = entry.name;
                entryValue = entry.address;
            }
            const keyProps: any = Utils.buildSelectNoseurComponentProps({
                value: entryKey,
                placeholder: labels.key,
                name: entryIsList ? "name" : "address",
            }, undefined, undefined, undefined, undefined, undefined, (_: string, value: any) => {
                setErrormessage(" ");
                if (entryIsList) {
                    (entries as any)[index].name = value;
                } else {
                    (entries as any)[value] = (entries as any)[entry];
                    delete (entries as any)[entry];
                }
            });
            const valueProps: any = Utils.buildSelectNoseurComponentProps({
                value: entryValue,
                placeholder: labels.value,
                name: entryIsList ? "address" : "parameters",
            }, undefined, undefined, undefined, undefined, undefined, (_: string, value: any) => {
                setErrormessage(" ");
                try {
                    if (entryIsList) {
                        (entries as any)[index].address = value;
                    } else {
                        (entries as any)[entry] = JSON.parse(value);
                    }
                } catch (err: any) {
                    setErrormessage(err);
                }
            });
            valueProps.style = { background: "rgba(217, 217, 217, 0.2)", borderRadius: 0, flex: 1.2, height: (entryIsList ? undefined : 70) };
            keyProps.style = { background: "rgba(217, 217, 217, 0.2)", borderRadius: 0, flex: 0.8, marginRight: 10, alignSelf: "flex-start" };
            return (<FormControl key={entry} style={{ marginTop: 0, marginBottom: 10, background: "transparent", width: "100%" }}
                validStyle={{ borderColor: "transparent" }} contentStyle={{ borderRadius: 0 }} ref={(r: any) => r?.scrollIntoView()}
                rightContent={<i className="fa fa-trash" style={{ color: "#EB5959", cursor: "pointer" }} onClick={() => {
                    setErrormessage(" ");
                    if (entryIsList) (entries as any).splice(index, 1); else delete (entries as any)[entry];
                    updateKeyValueSecondaryRoot(root, entries, labels, setErrormessage);
                }} />}>
                {React.createElement(Utils.selectNoseurComponent() as any, keyProps)}
                {React.createElement(Utils.selectNoseurComponent(entryIsList ? "text" : "textarea") as any, valueProps)}
            </FormControl>);
        });
        root.render(components);
    }

    function addCustomHeaders() {
        const passLabels = {
            key: labels.main.custom_headers.name,
            value: labels.main.custom_headers.primitive_or_json_array,
        }
        const customHeaders = ObjectHelper.clone(message.headers ?? {});
        const dialog = ConfirmDialog({
            closable: true,
            style: { minWidth: 450 },
            title: labels.main.custom_headers.title,
            content: (<div className="cm-custom-headers">
                <div className="recepients" ref={(r) => {
                    if (!r) return;
                    secActiveDialogMutableRoot = ReactDOM.createRoot(r);
                    updateKeyValueSecondaryRoot(secActiveDialogMutableRoot, customHeaders, passLabels, (err) => secDialogErrorRef.innerText = err);
                }}>
                </div>
                <div className="link-hv" style={{ fontSize: 13, marginTop: 15, cursor: "pointer", width: "fit-content" }} onClick={() => {
                    customHeaders[`Header${Object.keys(customHeaders).length + 1}`] = undefined;
                    updateKeyValueSecondaryRoot(secActiveDialogMutableRoot, customHeaders, passLabels, (err) => secDialogErrorRef.innerText = err);
                }}>
                    <i className="fa fa-plus" style={{ marginRight: 7, color: "#0FA883" }} />
                    <span>{labels.main.custom_headers.add_new_header}</span>
                </div>
                <div ref={(r) => secDialogErrorRef = r} className="error" style={{ marginTop: 10 }}> </div>
            </div>),
            onConfirm: () => {
                const keys = Object.keys(customHeaders);
                for (const key of keys) {
                    const value = customHeaders[key];
                    if (!key) {
                        secDialogErrorRef.innerText = labels.main.custom_headers.missing_name;
                        return false;
                    } else if (value === null || value === undefined) {
                        secDialogErrorRef.innerText = labels.main.custom_headers.missing_value.replaceAll("${value}", key);
                        return false;
                    } else if (TypeChecker.isDict(value)) {
                        secDialogErrorRef.innerText = labels.main.custom_headers.no_object.replaceAll("${value}", key);
                        return false;
                    }
                }
                message.headers = customHeaders;
                return true;
            }
        });
        dialog.show();
    }

    function addListHeaders() {
        const passLabels = {
            key: labels.main.list_headers.name,
            value: labels.main.list_headers.value_in_json,
        }
        const customHeaders = ObjectHelper.clone(message.list_headers ?? {});
        const dialog = ConfirmDialog({
            closable: true,
            style: { minWidth: 450 },
            title: labels.main.list_headers.title,
            content: (<div className="cm-custom-headers">
                <div className="recepients" ref={(r) => {
                    if (!r) return;
                    secActiveDialogMutableRoot = ReactDOM.createRoot(r);
                    updateKeyValueSecondaryRoot(secActiveDialogMutableRoot, customHeaders, passLabels, (err) => secDialogErrorRef.innerText = err);
                }}>
                </div>
                <div className="link-hv" style={{ fontSize: 13, marginTop: 15, cursor: "pointer", width: "fit-content" }} onClick={() => {
                    customHeaders[`Header${Object.keys(customHeaders).length + 1}`] = undefined;
                    updateKeyValueSecondaryRoot(secActiveDialogMutableRoot, customHeaders, passLabels, (err) => secDialogErrorRef.innerText = err);
                }}>
                    <i className="fa fa-plus" style={{ marginRight: 7, color: "#0FA883" }} />
                    <span>{labels.main.list_headers.add_new_list_header}</span>
                </div>
                <div ref={(r) => secDialogErrorRef = r} className="error" style={{ marginTop: 10 }}> </div>
            </div>),
            onConfirm: () => {
                const keys = Object.keys(customHeaders);
                for (const key of keys) {
                    const value = customHeaders[key];
                    if (!key) {
                        secDialogErrorRef.innerText = labels.main.list_headers.missing_name;
                        return false;
                    } else if (value === null || value === undefined) {
                        secDialogErrorRef.innerText = labels.main.list_headers.missing_value.replaceAll("${value}", key);
                        return false;
                    }
                }
                message.list_headers = customHeaders;
                return true;
            }
        });
        dialog.show();
    }

    function addCcsAndBccs() {
        const passLabels = {
            key: labels.main.ccs_bcss.name,
            value: labels.main.ccs_bcss.address,
        }
        const ccsContacts = ObjectHelper.clone(message.ccs ?? []);
        const bccsContacts = ObjectHelper.clone(message.bccs ?? []);
        const dialog = ConfirmDialog({
            closable: true,
            style: { minWidth: 750 },
            title: labels.main.ccs_bcss.title,
            content: (<div>
                <div className="send-mgs-ccs-bccs">
                    <div className="cm-custom-headers">
                        <span className="desc">{labels.main.ccs_bcss.ccs_desc}</span>
                        <div className="recepients" ref={(r) => {
                            if (!r) return;
                            secActiveDialogMutableRoot = ReactDOM.createRoot(r);
                            updateKeyValueSecondaryRoot(secActiveDialogMutableRoot, ccsContacts, passLabels, (err) => secDialogErrorRef.innerText = err);
                        }}>
                        </div>
                        <div className="link-hv" style={{ fontSize: 13, marginTop: 15, cursor: "pointer", width: "fit-content" }} onClick={() => {
                            ccsContacts.push({} as any);
                            updateKeyValueSecondaryRoot(secActiveDialogMutableRoot, ccsContacts, passLabels, (err) => secDialogErrorRef.innerText = err);
                        }}>
                            <i className="fa fa-plus" style={{ marginRight: 7, color: "#0FA883" }} />
                            <span>{labels.main.ccs_bcss.add_new_ccs}</span>
                        </div>
                    </div>
                    <div className="cm-custom-headers">
                        <span className="desc">{labels.main.ccs_bcss.bccs_desc}</span>
                        <div className="recepients" ref={(r) => {
                            if (!r) return;
                            secActiveDialogMutableRoot2 = ReactDOM.createRoot(r);
                            updateKeyValueSecondaryRoot(secActiveDialogMutableRoot2, bccsContacts, passLabels, (err) => secDialogErrorRef.innerText = err);
                        }}>
                        </div>
                        <div className="link-hv" style={{ fontSize: 13, marginTop: 15, cursor: "pointer", width: "fit-content" }} onClick={() => {
                            bccsContacts.push({} as any);
                            updateKeyValueSecondaryRoot(secActiveDialogMutableRoot2, bccsContacts, passLabels, (err) => secDialogErrorRef.innerText = err);
                        }}>
                            <i className="fa fa-plus" style={{ marginRight: 7, color: "#0FA883" }} />
                            <span>{labels.main.ccs_bcss.add_new_bccs}</span>
                        </div>
                    </div>
                </div>
                <div ref={(r) => secDialogErrorRef = r} className="error" style={{ marginTop: 20, marginBottom: 0 }}> </div>
            </div>),
            onConfirm: () => {
                for (const contacts of [ccsContacts, bccsContacts]) {
                    for (const contact of contacts) {
                        if (!contact.name || !contact.address) {
                            secDialogErrorRef.innerText = labels.main.ccs_bcss.missing_value;
                            return false;
                        }
                    }
                }
                message.ccs = ccsContacts;
                message.bccs = bccsContacts;
                return true;
            }
        });
        dialog.show();
    }

    function editContent() {
        const dialog = ConfirmDialog({
            closable: true,
            style: { minWidth: "70%", minHeight: "90%" },
            title: labels.main.content.dialog.editor.title,
            confirmLabel: labels.main.content.dialog.editor.set_content,
            content: (<div style={{ height: "100%", position: "relative" }}>
                {TemplateEditor.buildCreateTemplateEditor(i18nManager.Labels.dashboard.messaging.templating.dialog.manage.editor, template, props.refMap, false, message.attachments)}
                <div ref={(r) => secDialogErrorRef = r} className="error" style={{ marginTop: 20, marginBottom: 0 }}> </div>
            </div>),
            onConfirm: () => {
                contentPreviewMutableRoot?.render(Elements.buildTemplateView(template, TemplateType, { borderRadius: 0, margin: 0, flex: 1, height: "unset" }, {}, message.attachments));
                return true;
            }
        });
        dialog.show();
    }

    function largePreview() {
        ConfirmDialog({
            onLoading: async (alert) => {
                if (!flatReceipients.length) {
                    flatReceipients.push({
                        parameters: {},
                        address: labels.main.content.dialog.large_preview.remove_preview,
                    });
                    if (message.contact_list_ids) for (const contactListId of message.contact_list_ids) {
                        const contactList = await contactListService.getSingleByContactListId(contactListId);
                        (contactList.apiResponse.data as any as IContactList).contacts.forEach((contact) => flatReceipients.push(contact));
                    }
                    if (message.recipients) message.recipients.forEach((contact) => flatReceipients.push(contact));
                }
                setTimeout(() => {
                    alert.destroy(() => {
                        const subDialog = ConfirmDialog({
                            noControl: true,
                            style: { minWidth: "100%", minHeight: "100%" },
                            content: (<div className="create-message-large-preview">
                                <div className="recepients">
                                    <span className="title">{labels.main.content.dialog.large_preview.title}</span>
                                    <span className="desc">{labels.main.content.dialog.large_preview.desc}</span>
                                    <div className="contacts">
                                        {flatReceipients.map((contact, index) => {
                                            let params = JSON.stringify(contact.parameters);
                                            return (<span key={index} className="lined-limit link-hv" onClick={() => {
                                                if (!largeContentPreviewMutableRoot) return;
                                                largeContentPreviewMutableRoot.render(Elements.buildTemplateView(template, TemplateType, { borderRadius: 0, margin: 0, flex: 1, height: "unset" }, contact.parameters, message.attachments));
                                            }}>{contact.address}  <span style={{ color: "rgb(62, 61, 61, 0.5)" }}>({params})</span></span>);
                                        })}
                                    </div>
                                    <Button text={i18nManager.Labels.common.close} scheme={Schemes.RIVTN_QUIRINUS} textOnly onClick={() => subDialog.hide()} />
                                </div>
                                <div className="custom-preview" ref={(r) => {
                                    if (!r) return;
                                    largeContentPreviewMutableRoot = ReactDOM.createRoot(r);
                                    largeContentPreviewMutableRoot.render(Elements.buildTemplateView(template, TemplateType, { borderRadius: 0, margin: 0, flex: 1, height: "unset" }, {}, message.attachments));
                                }}>

                                </div>
                            </div>)
                        });
                        subDialog.show();
                    });
                }, 100);
                return false;
            }
        }).show();
    }

    function onAddAttachmentEvent(attachments: Attachment[], attachment: Partial<Attachment>, caller: Function) {
        const onAddAttachment = (attachment: Partial<Attachment>, caller: Function) => onAddAttachmentEvent(attachments, attachment, caller);
        if ((attachment as any).existing__) {
            delete (attachment as any).existing__;
            updateAttachmentsList(secActiveDialogMutableRoot, attachments);
            return;
        }
        if (attachments.findIndex((a) => a.cid === attachment.cid) > -1) {
            caller(onAddAttachment, attachment, labels.main.content.dialog.add_attachments.cid_taken);
            return;
        }
        attachments.push(attachment as Attachment);
        updateAttachmentsList(secActiveDialogMutableRoot, attachments);
    }

    function updateAttachmentsList(root: ReactDOM.Root | undefined, attachments: Attachment[]) {
        if (!root) return;
        const onAddAttachment = (attachment: Partial<Attachment>, caller: Function) => onAddAttachmentEvent(attachments, attachment, caller);
        const attachmentElements = attachments.map((attachment, index) => {
            const encoding = attachment.encoding;
            attachment.cid = attachment.cid ?? index;

            return (<div key={index}>
                <i className="fa fa-trash" style={{ color: "#EB5959", cursor: "pointer", marginRight: 5 }} onClick={() => {
                    attachments.splice(index, 1);
                    updateAttachmentsList(root, attachments);
                }} />
                <span className="link-hv" onClick={() => {
                    (attachment as any).existing__ = true;
                    if (attachment.path) {
                        addUrlAttachment(onAddAttachment, attachment);
                    } else {
                        addEncodingAttachment(onAddAttachment, attachment);
                    }
                }}>
                    {attachment.cid}:{attachment.filename}
                    <span style={{ color: "rgba(62,61,61,.4)" }}> (
                        {!encoding ? i18nManager.Labels.dashboard.messaging.messages.active_message.dialog.url
                            : `${i18nManager.Labels.dashboard.messaging.messages.active_message.dialog.encoding}:${encoding}`})</span>
                    <i style={{ color: "rgba(62,61,61,.4)", marginLeft: 5 }} className="fa fa-pen" />
                </span>
            </div>);
        });
        root.render(attachmentElements);
    }

    function addAttachements() {
        const attachments = ObjectHelper.clone(message?.attachments ?? []);
        const onAddAttachment = (attachment: Partial<Attachment>, caller: Function) => onAddAttachmentEvent(attachments, attachment, caller);

        const dialog = ConfirmDialog({
            closable: true,
            style: { minWidth: "40%", maxWidth: "70%" },
            title: labels.main.content.dialog.add_attachments.title,
            confirmLabel: labels.main.content.dialog.add_attachments.commit,
            content: (<div className="add-attahments">
                <div className="attachments" ref={(r) => {
                    if (!r) return;
                    secActiveDialogMutableRoot = ReactDOM.createRoot(r);
                    updateAttachmentsList(secActiveDialogMutableRoot, attachments);
                }}>
                </div>
                <div className="controls">
                    <div className="link-hv" style={{ fontSize: 13, marginTop: 15, cursor: "pointer", width: "fit-content" }} onClick={() => addUrlAttachment(onAddAttachment)}>
                        <i className="fa fa-plus" style={{ marginRight: 7, color: "#0FA883" }} />
                        <span>{labels.main.content.dialog.add_attachments.add_new_url_attachment}</span>
                    </div>
                    <div className="link-hv" style={{ fontSize: 13, marginTop: 5, cursor: "pointer", width: "fit-content" }} onClick={() => uploadLocalFileAttachment(onAddAttachment)}>
                        <i className="fa fa-plus" style={{ marginRight: 7, color: "#0FA883" }} />
                        <span>{labels.main.content.dialog.add_attachments.upload_local_attachment}</span>
                    </div>
                    <div className="link-hv" style={{ fontSize: 13, marginTop: 5, cursor: "pointer", width: "fit-content" }} onClick={() => addEncodingAttachment(onAddAttachment)}>
                        <i className="fa fa-plus" style={{ marginRight: 7, color: "#0FA883" }} />
                        <span>{labels.main.content.dialog.add_attachments.add_new_encoding_attachment}</span>
                    </div>
                </div>
                <div ref={(r) => secDialogErrorRef = r} className="error" style={{ marginTop: 20, marginBottom: 0 }}> </div>
            </div>),
            onConfirm: () => {
                message.attachments = attachments;
                contentPreviewMutableRoot?.render(Elements.buildTemplateView(template, TemplateType, { borderRadius: 0, margin: 0, flex: 1, height: "unset" }, {}, message.attachments));
                return true;
            }
        });
        dialog.show();
    }

    function addUrlAttachment(onAdd: (attachment: Partial<Attachment>, caller: Function) => void, exitsingAttachment?: Partial<Attachment>, err?: string) {
        const attachment = exitsingAttachment ?? { cid: DOMHelper.uniqueElementId("cid") };
        const passLabels = {
            key: labels.main.custom_headers.name,
            value: labels.main.custom_headers.primitive_or_json_array,
        }
        attachment.httpHeaders = attachment.httpHeaders ?? {};
        const dialog = ConfirmDialog({
            closable: true,
            style: { minWidth: "40%", maxWidth: "90%" },
            title: labels.main.content.dialog.add_attachments.url_attachment.title,
            confirmLabel: ((attachment as any).existing__ ? labels.main.content.dialog.add_attachments.update : labels.main.content.dialog.add_attachments.add),
            content: (<div style={{ height: "100%", position: "relative" }}>
                {Elements.buildConfirmDialogForm(labels.main.content.dialog.add_attachments.url_attachment.form, (name, value) => {
                    secDialogErrorRef2.innerText = " ";
                    (attachment as any)[name] = value;
                }, attachment)}
                <div style={{ fontSize: 13, margin: "10px 0px" }}>{labels.main.content.dialog.add_attachments.url_attachment.enter_http_headers}</div>
                <div style={{ overflow: "auto", maxHeight: 300 }} ref={(r) => {
                    if (!r) return;
                    secActiveDialogMutableRoot2 = ReactDOM.createRoot(r);
                    updateKeyValueSecondaryRoot(secActiveDialogMutableRoot2, attachment?.httpHeaders!, passLabels, (err) => secDialogErrorRef2.innerText = err);
                }}>
                </div>
                <div className="link-hv" style={{ fontSize: 13, marginTop: 10, cursor: "pointer", width: "fit-content" }} onClick={() => {
                    attachment!.httpHeaders![`Header${DOMHelper.uniqueElementId("")}`] = undefined;
                    updateKeyValueSecondaryRoot(secActiveDialogMutableRoot2, attachment?.httpHeaders!, passLabels, (err) => secDialogErrorRef2.innerText = err);
                }}>
                    <i className="fa fa-plus" style={{ marginRight: 7, color: "#0FA883" }} />
                    <span>{labels.main.content.dialog.add_attachments.url_attachment.add_new_http_headers}</span>
                </div>
                <div ref={(r) => { if (r) secDialogErrorRef2 = r; }} className="error" style={{ marginTop: 20, marginBottom: 0 }}>{err ?? " "}</div>
            </div>),
            onConfirm: () => {
                let errrorMessage;
                if (!attachment.cid) errrorMessage = labels.main.content.dialog.add_attachments.cid_required;
                else if (!attachment.filename) errrorMessage = labels.main.content.dialog.add_attachments.filename_required;
                else if (!attachment.path) errrorMessage = labels.main.content.dialog.add_attachments.url_attachment.path_required;

                Object.keys(attachment.httpHeaders!).forEach((h: string) => {
                    const value = attachment.httpHeaders![h];
                    if (Utils.hasNoValidValue(h) && Utils.hasNoValidValue(value, null)) {
                        delete attachment.httpHeaders![h];
                    }
                });
                for (const header of Object.keys(attachment.httpHeaders!)) {
                    if (Utils.hasNoValidValue(header) || Utils.hasNoValidValue(attachment.httpHeaders![header], null)) {
                        errrorMessage = labels.main.content.dialog.add_attachments.url_attachment.header_missing_key_or_value;
                        break;
                    }
                }

                if (errrorMessage) {
                    secDialogErrorRef2.innerText = errrorMessage;
                    return false;
                }
                onAdd(attachment, addUrlAttachment);
                return true;
            }
        });
        dialog.show();
    }

    function uploadLocalFileAttachment(onAdd: (attachment: Partial<Attachment>, caller: Function) => void, exitsingAttachment?: Partial<Attachment>, err?: string) {
        const attachment = exitsingAttachment ?? { cid: DOMHelper.uniqueElementId("cid"), encoding: "base64" };
        const dialog = ConfirmDialog({
            closable: true,
            style: { minWidth: "40%", maxWidth: "40%" },
            title: labels.main.content.dialog.add_attachments.local_attachment.title,
            confirmLabel: ((attachment as any).existing__ ? labels.main.content.dialog.add_attachments.update : labels.main.content.dialog.add_attachments.add),
            content: (<div style={{ height: "100%", position: "relative" }}>
                {Elements.buildConfirmDialogForm(labels.main.content.dialog.add_attachments.local_attachment.form, (name, value) => {
                    secDialogErrorRef2.innerText = " ";
                    (attachment as any)[name] = value;
                }, attachment)}
                <FileInput className="media-drag-component" accepts={`image/*`} defaultFiles={attachment.content ? [fileInputFileFromUrl(atob(attachment.content), attachment.filename, `image/*`)] : undefined}
                    style={{ height: 250 }} emptyTemplate={(onSelect: any) => (<div onClick={onSelect} style={{ height: "100%", display: "flex", alignItems: "center", justifyContent: "center" }} >
                        <div onClick={onSelect} style={{}}>
                            <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                                <i className="fa fa-upload" style={{ marginBottom: 10, fontSize: 50, color: "#C0C0C0" }} />
                                <span>{labels.main.content.dialog.add_attachments.local_attachment.file_input_control.placeholder}</span>
                            </div>
                        </div>
                    </div>)} onSelectFiles={async (files: File[]) => {
                        attachment.content = ((await Utils.fileToBas64(files[0])) as any).split(",")[1];
                    }} onRemoveFile={() => attachment.content = undefined} />
                <div ref={(r) => { if (r) secDialogErrorRef2 = r; }} className="error" style={{ marginTop: 20, marginBottom: 0 }}>{err ?? " "}</div>
            </div>),
            onConfirm: () => {
                let errrorMessage;
                if (!attachment.cid) errrorMessage = labels.main.content.dialog.add_attachments.cid_required;
                else if (!attachment.filename) errrorMessage = labels.main.content.dialog.add_attachments.filename_required;
                else if (!attachment.content) errrorMessage = labels.main.content.dialog.add_attachments.local_attachment.select_file;
                if (errrorMessage) {
                    secDialogErrorRef2.innerText = errrorMessage;
                    return false;
                }
                onAdd(attachment, uploadLocalFileAttachment);
                return true;
            }
        });
        dialog.show();
    }

    function addEncodingAttachment(onAdd: (attachment: Partial<Attachment>, caller: Function) => void, exitsingAttachment?: Partial<Attachment>, err?: string) {
        const attachment = exitsingAttachment ?? { cid: DOMHelper.uniqueElementId("cid") };
        const dialog = ConfirmDialog({
            closable: true,
            style: { minWidth: "40%", maxWidth: "90%" },
            title: labels.main.content.dialog.add_attachments.encoding_attachment.title,
            confirmLabel: ((attachment as any).existing__ ? labels.main.content.dialog.add_attachments.update : labels.main.content.dialog.add_attachments.add),
            content: (<div style={{ height: "100%", position: "relative" }}>
                {Elements.buildConfirmDialogForm(labels.main.content.dialog.add_attachments.encoding_attachment.form, (name, value) => {
                    secDialogErrorRef2.innerText = " ";
                    (attachment as any)[name] = value;
                }, attachment)}
                <div ref={(r) => { if (r) secDialogErrorRef2 = r; }} className="error" style={{ marginTop: 20, marginBottom: 0 }}>{err ?? " "}</div>
            </div>),
            onConfirm: () => {
                let errrorMessage;
                if (!attachment.cid) errrorMessage = labels.main.content.dialog.add_attachments.cid_required;
                else if (!attachment.filename) errrorMessage = labels.main.content.dialog.add_attachments.filename_required;
                else if (!attachment.content) errrorMessage = labels.main.content.dialog.add_attachments.encoding_attachment.content_required;
                else if (!attachment.encoding) errrorMessage = labels.main.content.dialog.add_attachments.encoding_attachment.encoding_required;
                if (errrorMessage) {
                    secDialogErrorRef2.innerText = errrorMessage;
                    return false;
                }
                onAdd(attachment, addEncodingAttachment);
                return true;
            }
        });
        dialog.show();
    }

}
