
import React from "react";
import ReactDOM from "react-dom/client";
import { i18nManager } from "../../../i18n";
import { NavLink, useSearchParams } from "react-router-dom";
import { Schemes, Subscriber, Utils } from "../../../utils";
import { ConfirmDialog, Elements, SidedContainer, Siren } from "../../shared";
import { ButtonManageRef, Classname, FormControl, FormGroup, ObjectHelper, Scheme } from "@ronuse/noseur";
import { ApiResponseData, BaseService, ContactListService, IChannel, IContact, IContactList, SanitizedResponse } from "../../../services";

function ContactManagement() {
    const dialogErrorRef = React.useRef<HTMLDivElement>(null);
    const contactListService = ContactListService.getInstance();
    const activeDialogMutableRoot = React.useRef<ReactDOM.Root>(null);
    const labels = i18nManager.Labels.dashboard.messaging.contact_management;
    const [searchParams, setSearchParams] = useSearchParams(window.location.search);
    const [activeContactList, setActiveContactList] = React.useState<IContactList>();
    const [contactLists, setContactLists] = React.useState<ApiResponseData<IContactList>>({});

    React.useEffect(fetchContactLists, []);

    return SidedContainer({
        searchParams,
        data: contactLists,
        setSearchParams,
        onAction: manageContactList,
        queryData: fetchContactLists,
        labels: labels.sided_labels,
        activeData: (activeContactList ? {
            data: activeContactList,
            element: (<React.Fragment>
                <div className="header">
                    <i className="fa fa-times" onClick={() => {
                        setActiveContactList(undefined);
                        Utils.updateSearchParams("active_data_id", undefined, setSearchParams);
                    }} />
                    <span className="name">{activeContactList?.name}</span>
                    <span className="id" style={{ fontWeight: "bold" }}>{activeContactList?.contact_list_id}</span>
                    <span className="id">{activeContactList?.type}</span>
                    <span className="desc">{activeContactList?.description}</span>
                </div>
                <div className="middle actions">
                    <NavLink to={`/dashboard/messaging/messages?contact_list_id=${activeContactList?.id}`}>{i18nManager.Labels.dashboard.messaging.see_messages}</NavLink>
                    <span className="link" onClick={() => manageContactList(activeContactList)}>{labels.edit_contact_list}</span>
                    <span className="link danger" onClick={() => deleteContactList(activeContactList)}>{labels.delete_contact_list}</span>
                </div>
                <div className="actions" style={{ overflow: "auto" }}>
                    <span style={{ marginTop: 0, marginBottom: 10, fontWeight: "bold" }}>{labels.contacts}</span>
                    {activeContactList?.contacts.map((contact, index) => {
                        let params = JSON.stringify(contact.parameters);
                        return (<div key={index} style={{ marginTop: 3 }}>{contact.address} <span style={{ color: "rgb(62, 61, 61, 0.5)" }}>({params})</span></div>);
                    })}
                </div>
            </React.Fragment>)
        } : undefined),
        dataTemplate: (contactList: IContactList) => {
            return (<div className={Classname.build("channel", (activeContactList?.contact_list_id === contactList.contact_list_id ? "active" : null))} onClick={() => {
                setActiveContactList(contactList);
                Utils.updateSearchParams("active_data_id", contactList.id, setSearchParams);
            }}>
                <div className="id">
                    <span className="name">{contactList.name}</span>
                    <span className="type"> - {contactList.type}</span>
                </div>
                <span className="desc">{contactList.description}</span>
                <div className="control">
                    <i className="fa fa-edit link gray" onClick={() => manageContactList(contactList)} />
                    <i className="fa fa-trash link danger" onClick={() => deleteContactList(contactList)}/>
                </div>
            </div>);
        }
    });

    function fetchContactLists() {
        const id = parseInt(searchParams.get("active_data_id") || "0");
        contactListService.queryContactList(Utils.normalizeUrlParams(searchParams), true).then(({ apiResponse }: SanitizedResponse<IContactList>) => {
            setContactLists(apiResponse.data!);
            if (id > 0) for (const item of apiResponse.data?.content!) {
                if (item.id === id) {
                    setActiveContactList(item);
                    break;
                }
            }
        }).catch(BaseService.reportError).finally(() => Subscriber.report(Subscriber.KEYS.QUERY_REQUEST_PROMISE_COMPLETED));
    }

    function updateNewContactsControls(contacts: IContact[]) {
        if (!activeDialogMutableRoot.current) return;
        const newData = contacts.map((contact: IContact, index: number) => {
            const keyProps: any = Utils.buildSelectNoseurComponentProps({
                name: "address",
                value: contact.address,
                placeholder: labels.dialog.manage.address_placeholder,
            }, undefined, undefined, undefined, undefined, undefined, (_: string, value: any) => {
                dialogErrorRef.current!.innerText = " ";
                contacts[index].address = value;
            });
            const valueProps: any = Utils.buildSelectNoseurComponentProps({
                name: "parameters",
                value: JSON.stringify(contact.parameters),
                placeholder: labels.dialog.manage.params_placeholder,
            }, undefined, undefined, undefined, undefined, undefined, (_: string, value: any) => {
                dialogErrorRef.current!.innerText = " ";
                try {
                    contacts[index].parameters = JSON.parse(value);
                } catch (err: any) {
                    dialogErrorRef.current!.innerText = err;
                }
            });
            valueProps.style = { background: "rgba(217, 217, 217, 0.2)", borderRadius: 0, flex: 1.2, height: 100 };
            keyProps.style = { background: "rgba(217, 217, 217, 0.2)", borderRadius: 0, flex: 0.8, marginRight: 10, alignSelf: "flex-start" };
            return (<FormControl key={index} style={{ marginTop: 0, marginBottom: 10, background: "transparent" }}
                validStyle={{ borderColor: "transparent" }} contentStyle={{ borderRadius: 0 }} ref={(r: any) => r?.scrollIntoView()}
                rightContent={<i className="fa fa-trash" style={{ color: "#EB5959", cursor: "pointer" }} onClick={() => {
                    dialogErrorRef.current!.innerText = " ";
                    contacts.splice(index, 1); updateNewContactsControls(contacts);
                }} />}>
                {React.createElement(Utils.selectNoseurComponent() as any, keyProps)}
                {React.createElement(Utils.selectNoseurComponent("textarea") as any, valueProps)}
            </FormControl>);
        });
        activeDialogMutableRoot.current.render(newData);
    }

    function manageContactList(contactList?: IContactList) {
        const payload = ObjectHelper.clone(contactList ?? {} as any);
        payload.contacts = contactList?.contacts ?? [];
        const basics = labels.dialog.manage.basic[contactList ? "update" : "create"];
        const dialog = ConfirmDialog({
            ...basics,
            closable: true,
            rightStyle: { flex: 1.5 },
            cancelScheme: Schemes.RIVTN_QUIRINUS,
            style: { minWidth: 600, maxWidth: "80%" },
            desc: basics.desc.replace("${value}", contactList?.name ?? ""),
            content: (<div className="add-new-role">
                {Elements.buildConfirmDialogForm(labels.dialog.manage.form, (name: string, value: string) => {
                    dialogErrorRef.current!.innerText = " ";
                    payload[name] = value;
                }, payload)}
                <div ref={dialogErrorRef} className="error"> </div>
            </div>),
            rightContent: (<div style={{ flex: 1 }} className="add-new-permission add-new-role-permission">
                <div style={{ marginTop: 10, fontSize: 13 }}>{labels.dialog.manage.info} <a href="#" target="_blank">{labels.dialog.manage.download_sample}</a></div>
                <FormGroup scheme={Schemes.RIVTN_QUIRINUS} style={{ marginTop: 20, maxHeight: 260, overflow: "auto" }} className="form" ref={(r: any) => {
                    if (!r) return;
                    ObjectHelper.resolveRef(activeDialogMutableRoot, ReactDOM.createRoot(r));
                    updateNewContactsControls(payload.contacts);
                }}>
                </FormGroup>
                <div className="action link" onClick={() => {
                    payload.contacts.push({});
                    updateNewContactsControls(payload.contacts);
                }}>
                    <i className="fa fa-plus" style={{ marginRight: 7, color: "#0FA883" }} />
                    <span>{labels.dialog.manage.add_new_contact}</span>
                </div>
                <div className="action link" onClick={() => {
                    payload.contacts.push({});
                    updateNewContactsControls(payload.contacts);
                }}>
                    <i className="fa fa-upload" style={{ marginRight: 7, color: "#0FA883" }} />
                    <span>{labels.dialog.manage.add_contacts_from_csv}</span>
                </div>
            </div>),
            onConfirm: (button?: ButtonManageRef) => {
                let errorMessage;
                dialogErrorRef.current!.innerText = " ";
                const formattedPayload = ObjectHelper.clone(payload);
                if (Object.keys(formattedPayload).length < 3) {
                    errorMessage = labels.dialog.manage.fill_required_fields;
                } else {
                    if (!formattedPayload.contacts.length) {
                        errorMessage = labels.dialog.manage.missing_contacts;
                    } else {
                        for (const contact of formattedPayload.contacts) {
                            if (!contact.address || !contact.parameters) {
                                errorMessage = labels.dialog.manage.missing_contact_value;
                                break;
                            }
                        }
                    }
                }
                if (errorMessage) {
                    dialogErrorRef.current!.innerText = errorMessage;
                    return false;
                }
                button?.setLoadingState(true);
                const method = (!!contactList ? contactListService.updateContactList : contactListService.createContactList).bind(contactListService);
                method(formattedPayload)
                    .then(({ }: SanitizedResponse<IChannel>) => {
                        Siren.alert(basics.successful);
                        dialog.hide(fetchContactLists);
                    }).catch(({ errorMessage }: SanitizedResponse<any>) => {
                        dialogErrorRef.current!.innerText = errorMessage;
                    }).finally(() => {
                        button?.setLoadingState(false);
                    });
                return false;
            }
        });
        dialog.show();
    }

    function deleteContactList(contactList: IContactList) {
        const dialog = ConfirmDialog({
            closable: true,
            title: labels.dialog.delete.title,
            desc: labels.dialog.delete.desc.replace("${value}", contactList.name),
            confirmScheme: Scheme.DANGER,
            onConfirm: (button?: ButtonManageRef) => {
                button?.setLoadingState(true);
                contactListService.deleteContactList(contactList).then(() => {
                    Siren.alert(labels.dialog.delete.successful);
                    setActiveContactList(undefined);
                    dialog.hide(fetchContactLists);
                }).catch(BaseService.reportError).finally(() => button?.setLoadingState(false));
                return false;
            }
        });
        dialog.show();
    }

}

export default ContactManagement;

