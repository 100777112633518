
import React from "react";
import { useSearchParams } from "react-router-dom";
import { ConfirmDialog, Elements } from "../../shared";
import { Schemes, Utils, Status } from "../../../utils";
import { FormControls, i18nManager } from "../../../i18n";
import { ButtonManageRef, Column, FormControl, FormGroup, NoseurObject, Popover, Scheme, Table } from "@ronuse/noseur";
import { AdministrationService, ApiResponseData, BaseService, IAdministrator, IAdministratorEmailChangeRequest, SanitizedResponse } from "../../../services";

function ApprovalRequests() {
    const dialogErrorRef = React.useRef<HTMLDivElement>(null);
    const popoverManageRefs = React.useRef<NoseurObject<any>>({});
    const administrationService = AdministrationService.getInstance();
    const labels = i18nManager.Labels.dashboard.administration.approval_requests;
    const [searchParams, setSearchParams] = useSearchParams(window.location.search);
    const selectedRecordMap: NoseurObject<[any, React.Dispatch<React.SetStateAction<any>>]> = {
        ACCOUNT_APPROVAL: React.useState<IAdministrator>(),
        EMAIL_CHANGE_REQUEST: React.useState<IAdministratorEmailChangeRequest>(),
    };
    const resourceData: NoseurObject<[ApiResponseData<any>, React.Dispatch<React.SetStateAction<ApiResponseData<any>>>]> = {
        ACCOUNT_APPROVAL: React.useState<ApiResponseData<IAdministrator>>({}),
        EMAIL_CHANGE_REQUEST: React.useState<ApiResponseData<IAdministratorEmailChangeRequest>>({}),
    };
    const services: NoseurObject<NoseurObject<Function>> = {
        ACCOUNT_APPROVAL: {
            reject: administrationService.approveOrRejectNewAdministrator.bind(administrationService),
            approve: administrationService.approveOrRejectNewAdministrator.bind(administrationService),
            query: administrationService.queryAdministratorPendingApproval.bind(administrationService),
        },
        EMAIL_CHANGE_REQUEST: {
            query: administrationService.queryEmailChangeRequests.bind(administrationService),
            reject: administrationService.approveOrRejectEmailChangeRequest.bind(administrationService),
            approve: administrationService.approveOrRejectEmailChangeRequest.bind(administrationService),
        },
    };
    const queryParameterKeyLookupMaps: NoseurObject<NoseurObject<string>> = {
        ACCOUNT_APPROVAL: {
            status: "acc_appr_status",
        },
        EMAIL_CHANGE_REQUEST: {
            status: "email_change_req_status"
        }
    };
    const queryParameterKeyLookupReverseMaps = Utils.reverseMapOfString(queryParameterKeyLookupMaps);

    return (<div className="approval-requests">
        {labels.groups.map((label, index) => {
            const functionsMap = services[label.key];
            let popoverEntries: NoseurObject<any> = {};
            const [getter, setter] = resourceData[label.key];
            const [selectedRecord, setSelectedRecord] = selectedRecordMap[label.key];
            const queryParameterKeyLookupMap = queryParameterKeyLookupMaps[label.key];
            const queryParameterKeyLookupReverseMap = queryParameterKeyLookupReverseMaps[label.key];
            const queryRecords = () => functionsMap.query(Utils.normalizeUrlParams(searchParams, queryParameterKeyLookupReverseMap)).then(({ apiResponse }: SanitizedResponse<any>) => setter(apiResponse.data!)).catch(BaseService.reportError);

            if (!getter.content) queryRecords();

            return (<div key={index} style={{ display: "flex", flexDirection: "column" }}>
                <span>{label.title}</span>
                <FormGroup scheme={Schemes.RIVTN_QUIRINUS} className="form">
                    {label.search_fields.map((formControl: FormControls, index: number) => {
                        const queryKeyName = queryParameterKeyLookupMap[formControl.name];
                        const inputProps: any = Utils.buildSelectNoseurComponentProps(formControl, searchParams, setSearchParams, queryRecords, queryKeyName);
                        return (<FormControl key={index} leftContent={formControl.icon}
                            validStyle={{ borderColor: "transparent" }} contentStyle={{ borderRadius: 0 }} >
                            {React.createElement(Utils.selectNoseurComponent(formControl.type!) as any, inputProps)}
                        </FormControl>);
                    })}
                </FormGroup>
                <Table className="table" data={getter.content} totalRecords={getter.total_elements} valuedRowProps={selectTableStatusStyling} loadingState={Elements.LOADING_STATE}>
                    {label.table_headers.map(({ key, header }, index) => {
                        if (Array.isArray(header)) {
                            header.forEach((entry) => popoverEntries[entry.value] = setPopoverEvent(label.key, entry.key, functionsMap[entry.key], queryRecords, selectedRecord, {
                                prefix_url: `${window.location.origin}${process.env.REACT_APP_PASSWORD_RESET_PAGE}`
                            }))
                            return (<Column key={index} template={(value: any) => (<React.Fragment>
                                <i className="fa fa-ellipsis-h" style={{ cursor: "pointer" }}
                                    onClick={(e: any) => {
                                        setSelectedRecord(value);
                                        popoverManageRefs.current[label.key].toggle(e);
                                    }} />
                            </React.Fragment>)} />);
                        }
                        return (<Column header={header} template={(value: any) => buildColumnValue(label.key, key, value)} />);
                    })}
                </Table>
                {Elements.buildPaginator(getter)}

                <Popover className="dashboard-popover" manageRef={(e) => popoverManageRefs.current[label.key] = e} style={{ padding: "10px 0px" }} dismissOnClick>
                    {Object.keys(popoverEntries).map((popoverEntry, index) => (<span className="popover-link" key={index} onClick={() => popoverEntries[popoverEntry]()}>{popoverEntry}</span>))}
                </Popover>
            </div>);
        })}
    </div>);

    function selectTableStatusStyling(data: { status: Status }) {
        const props = { style: { borderLeft: "2px solid #A5A80F", borderLeftColor: "#A5A80F" } };
        if (data.status === Status.APPROVED) props.style.borderLeftColor = "#0FA883";
        if (data.status === Status.REJECTED) props.style.borderLeftColor = "#C61818";
        if (data.status === Status.CANCELLED) props.style.borderLeftColor = "#400FA8";
        return props;
    }

    function buildColumnValue(key: string, column: string, _data: Partial<IAdministrator> | Partial<IAdministratorEmailChangeRequest>) {
        if (key === "ACCOUNT_APPROVAL") {
            const data = _data as IAdministrator;
            switch (column) {
                case "date_created":
                    return (<div style={{ display: "flex", flexDirection: "column" }}>
                        <span>{Utils.formatToFineDateOnly(data.created_at)}</span>
                        <span>{Utils.formatToFineTime(data.created_at)}</span>
                    </div>);
                case "administrator":
                    return (<div style={{ display: "flex", flexDirection: "column" }}>
                        <span>{data.first_name} {data.last_name}</span>
                        <span>{data.email}</span>
                    </div>);
                case "created_by":
                    return (<div style={{ display: "flex", flexDirection: "column" }}>
                        <span>{data.created_by?.first_name} {data.created_by?.last_name}</span>
                        <span>{data.created_by?.email}</span>
                    </div>);
            }
        }
        if (key === "EMAIL_CHANGE_REQUEST") {
            const data = _data as IAdministratorEmailChangeRequest;
            switch (column) {
                case "administrator":
                    return (<div style={{ display: "flex", flexDirection: "column" }}>
                        <span>{data.administrator.first_name} {data.administrator.last_name}</span>
                        <span style={{ color: "#EB5959" }}>{labels.old}: {data.old_email}</span>
                        <span style={{ color: "#0FA883" }}>{labels.new}: {data.new_email}</span>
                    </div>);
                case "reason":
                    return (<div style={{ display: "flex", flexDirection: "column" }}>
                        <span>{data.reason}</span>
                    </div>);
            }
        }
        return null;
    }

    function setPopoverEvent(key: string, item: string, action: Function, cb: Function, selectedRecord: any, formValues: NoseurObject<any> = {}) {
        formValues["approve"] = item === "approve";
        if (key === "ACCOUNT_APPROVAL") {
            formValues["administrator_ids"] = [selectedRecord?.external_id];
        } else if (key === "EMAIL_CHANGE_REQUEST") {
            formValues["request_id"] = selectedRecord?.external_id;
        }
        const dialogLabels = (labels.dialog as any)[item];
        const dialog = ConfirmDialog({
            closable: true,
            ...dialogLabels,
            confirmLabel: dialogLabels?.confirm_label,
            confirmScheme: item === "reject" ? Scheme.DANGER : undefined,
            content: (<div className="appr-dialog">
                {Elements.buildConfirmDialogForm(dialogLabels?.form, (name: string, value: string) => {
                    formValues[name] = value;
                    dialogErrorRef.current!.innerText = " ";
                }, formValues)}
                <div ref={dialogErrorRef} className="error"> </div>
            </div>),
            onConfirm: (button?: ButtonManageRef) => {
                if (item === "reject" && !("reason" in formValues)) {
                    dialogErrorRef.current!.innerText = dialogLabels.reason_missing;
                    return false;
                }
                dialogErrorRef.current!.innerText = " ";
                button?.setLoadingState(true);
                action(formValues)
                    .then(({ }: SanitizedResponse<any>) => {
                        cb(); dialog.destroy();
                    }).catch(({ errorMessage }: SanitizedResponse<any>) => {
                        dialogErrorRef.current!.innerText = errorMessage;
                    }).finally(() => {
                        button?.setLoadingState(false);
                    });
                return false;
            }
        });
        return dialog.show;
    }

}

export default ApprovalRequests;
