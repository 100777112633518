
import React from "react";
import ListedIps from "./ListedIps";
import ListedValues from "./ListedValues";
import ListedDomains from "./ListedDomains";
import SuspiciousIps from "./SuspiciousIps";
import { i18nManager } from "../../../i18n";
import { Route, Routes } from "react-router-dom";
import { IProfile, ISecondaryData } from "../../../services";
import { DashboardContainer } from "../../shared/DashboardContainer";

export interface SecurityProps {
    search: string;
    profile: IProfile;
    secondaryData: ISecondaryData;
}

function Security(props: SecurityProps) {
    const labels = i18nManager.Labels.dashboard.security;

    return DashboardContainer({
        ...labels,
        search: props.search,
        secondaryData: props.secondaryData,
        content: (<Routes>
            <Route path="/listed-ips" element={<ListedIps/>} />
            <Route path="/listed-values" element={<ListedValues/>} />
            <Route path="/listed-domains" element={<ListedDomains/>} />
            <Route path="/suspicious-ips" element={<SuspiciousIps/>} />
        </Routes>),
    });
}

export default Security;
