
import React from "react";
import { NavLink } from "react-router-dom";
import { AccountMenuItem } from "../../i18n";
import { ISecondaryData } from "../../services";

export interface DashboardContainerProps {
    search?: string;
    title: React.ReactNode;
    menu: AccountMenuItem[];
    content: React.ReactNode;
    secondaryData?: ISecondaryData;
    rightComponent?: React.ReactNode;
}

export function DashboardContainer(props: DashboardContainerProps) {

    return (<div className="panel">
        <div className="left">
            <div className="header">
                <span>{props.title}</span>
                <div>
                    {props.menu?.map((item: AccountMenuItem, index: number) => (<NavLink key={index} to={{ pathname: item.location, search: item.search || props.search }}>
                        {item.label} {item.badge_key && props.secondaryData?.unreads[item.badge_key] ? <span className="badge">{props.secondaryData?.unreads[item.badge_key]}</span> : null}
                    </NavLink>))}
                </div>
            </div>
            <div className="content">
                {props.content}
            </div>
        </div>
        {props.rightComponent ? <div className="right">{props.rightComponent}</div> : null}
    </div>);

}
