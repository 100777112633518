
import { NoseurObject } from "@ronuse/noseur";
import { GroupSuspicion, SoteriaBaseService } from "./SoteriaBaseService";

export interface ISuspiciousIp {
    trails: string[];
    ip_address: string;
    created_at: string;
    updated_at: string;
    external_id: string;
    platforms_suspicious: NoseurObject<GroupSuspicion>;
}

export class SuspiciousIpService extends SoteriaBaseService {

    protected static instance: SuspiciousIpService;

    private constructor() {
        super();
    }

    static getInstance() {
        if (!SuspiciousIpService.instance) SuspiciousIpService.instance = new SuspiciousIpService();
        return SuspiciousIpService.instance;
    }

    querySuspiciousIps(params: NoseurObject<string | number> = { page: 1, size: 10 }, refresh: boolean = false): Promise<any> {
        return this.report(this.transport.get("/soteria/api/v1/suspicious/ip", { params: this.normalizeParams(params) } as any));
    }

    getSingleSuspiciousIp(externalId: string): Promise<any> {
        return this.report(this.transport.get(`/soteria/api/v1/suspicious/ip/${externalId!}`, this.config));
    }

    deleteSuspiciousIp(externalId: string): Promise<any> {
        return this.report(this.transport.delete(`/soteria/api/v1/suspicious/ip/${externalId!}`, this.config));
    }

    deleteSuspiciousIpPlatform(externalId: string, _: string, platform: string): Promise<any> {
        return this.report(this.transport.patch(`/soteria/api/v1/suspicious/ip/${externalId!}`, {
            platforms_to_reset: [ platform ],
        }, this.config));
    }

    /*createSuspiciousIp(payload: any): Promise<any> {
        return this.report(this.transport.post(`/soteria/api/v1/suspicious/ip`, payload, this.config));
    }

    addSuspiciousIpPlatform(payload: any): Promise<any> {
        return this.report(this.transport.put(`/soteria/api/v1/suspicious/ip`, payload, this.config));
    }

    */

}

