
import { BaseService } from "../../BaseService";
import { NoseurObject } from "@ronuse/noseur";

export class AuthsService extends BaseService {

    protected static instance: AuthsService;

    private constructor() {
        super();
    }

    static getInstance() {
        if (!AuthsService.instance) AuthsService.instance = new AuthsService();
        return AuthsService.instance;
    }

    signup(payload: NoseurObject<any>): Promise<any>  {
        return this.report(this.transport.post("/janus/geminus/api/v1/administrator", payload));
    }

    signin(payload: NoseurObject<any>): Promise<any>  {
        return this.report(this.transport.post("/janus/geminus/api/v1/administrator/auth", payload));
    }

    fa(payload: NoseurObject<any>): Promise<any>  {
        return this.report(this.transport.post("/janus/geminus/api/v1/administrator/auth/2fa", payload));
    }

    requestFaResetTokens(payload: NoseurObject<any>): Promise<any>  {
        return this.report(this.transport.post("/janus/geminus/api/v1/account/2fa/reset/request", payload));
    }

    requestFaReset(payload: NoseurObject<any>, reset: boolean = true): Promise<any>  {
        if (reset) return this.report(this.transport.post("/janus/geminus/api/v1/account/2fa/reset/email", payload));
        return this.report(this.transport.post("/janus/geminus/api/v1/account/2fa/reset", payload, this.config));
    }

    requestPasswordResetEmail(payload: NoseurObject<any>): Promise<any>  {
        return this.report(this.transport.post("/janus/geminus/api/v1/account/password/reset/email", payload));
    }

    resetPassword(payload: NoseurObject<any>): Promise<any>  {
        return this.report(this.transport.post("/janus/geminus/api/v1/account/password/reset", payload));
    }

}
