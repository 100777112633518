
import React from "react";
import { AccountService, AuthsService, BaseService, IProfile, SanitizedResponse } from "../../../services";
import { Schemes, Utils } from "../../../utils";
import { FormControls, i18nManager } from "../../../i18n";
import { Alignment, Button, ButtonManageRef, Checkbox, ComposedPassword, FileInput, FormControl, FormGroup, InputProps, NoseurObject, Scheme } from "@ronuse/noseur";
import { ConfirmDialog, Elements, RequestNewFa, LockAccount } from "../../shared";
import RequestCodeControls from "../../shared/RequestCodeControls";

const Toggle = Checkbox; // create toggle on noseur

export interface AccountSettingsProps {
    profile: IProfile;
}

function AccountSettings(props: AccountSettingsProps) {
    const accountService = AccountService.getInstance();
    const countries = [{ label: "Nigeria" }, { label: "Ghana" },];
    const editProfileErrorRef = React.useRef<HTMLDivElement>(null);
    const changePasswordErrorRef = React.useRef<HTMLDivElement>(null);
    const labels = i18nManager.Labels.dashboard.account.account_settings;
    const changeEmailDialogErrorRef = React.useRef<HTMLDivElement>(null);
    const changePhoneDialogErrorRef = React.useRef<HTMLDivElement>(null);
    const lockAccountDialogErrorRef = React.useRef<HTMLDivElement>(null);
    const changePasswordDialogErrorRef = React.useRef<HTMLDivElement>(null);
    const editProfileSaveButtonManageRef = React.useRef<ButtonManageRef>(null);
    const changePasswordSaveButtonManageRef = React.useRef<ButtonManageRef>(null);
    const [editProfileFormValues, setEditProfileFormvalues] = React.useState<IProfile>(props.profile);
    const [changePasswordIsSubmitable, setChangePasswordIsSubmitable] = React.useState<boolean>(false);
    const [changePasswordFormValues, setChangePasswordFormvalues] = React.useState<NoseurObject<any>>({});

    return (<div className="account-settings">
        <div className="left">
            <span className="t">{labels.edit_profile.title}</span>
            <FormGroup scheme={Schemes.RIVTN_QUIRINUS} className="form" style={{ display: "flex", flexDirection: "column", marginTop: 30 }}>
                <FileInput stickyPreview className="pic" rounded scheme={Schemes.RIVTN_QUIRINUS} accepts="image/*"
                    label={<i className='fa fa-pen'/>} attrsRelay={{ label: { style: { borderRadius: "50%" }, alignment: Alignment.BOTTOM_RIGHT } }}
                    emptyTemplate={(select) => <img onClick={select} style={{ width: "100%", height: "100%" }} src={props.profile.profile_picture_location} />} />
                {labels.edit_profile.form.map(({ disabled, icon, type, name, placeholder }: FormControls, index: number) => {
                    const inputProps: any = {
                        name,
                        fill: true,
                        placeholder,
                        noStyle: true,
                        borderless: true,
                        style: { borderRadius: 0 },
                        disabled: (disabled === true),
                        defaultValue: (editProfileFormValues as any)[name],
                        onInput: (e: any) => onEditProfileFormInput(name, e.target.value),
                    };
                    if (type === "dropdown") {
                        inputProps.options = countries;
                        inputProps.onSelectOption = (option: any) => onEditProfileFormInput(name, option.label);
                        inputProps.selectedOptionIndex = countries.findIndex(c => c.label === editProfileFormValues.country);
                    }
                    return (<FormControl key={index} invalid={name in editProfileFormValues && !(editProfileFormValues as any)[name]} leftContent={icon}
                        validStyle={{ borderColor: "transparent" }} contentStyle={{ borderRadius: 0 }} required>
                        {React.createElement(Utils.selectNoseurComponent(type!) as any, inputProps)}
                    </FormControl>);
                })}
                <div ref={editProfileErrorRef} className="error"> </div>
                <Button text={labels.edit_profile.save} manageRef={editProfileSaveButtonManageRef} onClick={(_: any) => {
                    editProfileErrorRef.current!.innerText = " ";
                    submitEditProfile();
                }} loadingProps={{
                    text: "",
                    disabled: true,
                    leftIcon: "fas fa-spinner fa-pulse"
                }} style={{ width: 100, alignSelf: "flex-end" }} raised />
            </FormGroup>
        </div>
        <div className="right">
            <div className="top">
                <span className="t">{labels.change_password.title}</span>
                <FormGroup scheme={Schemes.RIVTN_QUIRINUS} className="form" style={{ display: "flex", flexDirection: "column", marginTop: 30 }}>
                    {labels.change_password.form!.map(({ strengthy, icon, type, name, placeholder }: FormControls, index: number) => {
                        return (<ComposedPassword key={index} strengthIndicator={strengthy}
                            formControlProps={{
                                leftContent: icon,
                                contentStyle: { borderRadius: 0 },
                                validStyle: { borderColor: "transparent" },
                                invalid: (name in changePasswordFormValues && !changePasswordFormValues[name])
                            }}
                            style={{ width: "100%" }}
                            inputProps={{ placeholder }}
                            progressProps={Elements.COMPOSED_PASSWORD_PROGRESS_PROPS}
                            onInput={(e: any) => onChangePasswordFormInput(name, e.target.value)} />);
                    })}
                    <div ref={changePasswordErrorRef} className="error"> </div>
                    <Button text={labels.change_password.save} manageRef={changePasswordSaveButtonManageRef} onClick={(_: any) => {
                        changePasswordErrorRef.current!.innerText = " ";
                        submitChangePassword();
                    }} loadingProps={{
                        text: "",
                        disabled: true,
                        leftIcon: "fas fa-spinner fa-pulse"
                    }} disabled={!changePasswordIsSubmitable} style={{ width: 100, alignSelf: "flex-end" }} raised />
                </FormGroup>
            </div>
            <div className="bottom acc-toggles-modifiers">
                <div className="tp">
                    {labels.notifications.map((control: FormControls, index: number) => (<div key={index}>
                        <div>
                            <span className="ti">{control.title}</span>
                            <span className="sti">{control.sub_title}</span>
                        </div>
                        <Toggle scheme={Schemes.RIVTN_QUIRINUS} />
                    </div>))}
                </div>
                <div className="bt">
                    <span className="link" onClick={changeMobileNumber}>{labels.change_mobile.label}</span>
                    <span className="link" onClick={changeEmaillAddress}>{labels.change_email.label}</span>
                    <span className="link" onClick={() => LockAccount.lockUnlockAccount(true, labels.lock_account.dialog!, accountService, lockAccountDialogErrorRef)}>{labels.lock_account.label}</span>
                    <span className="link" onClick={requestNewFa}>{labels.new_fa.label}</span>
                </div>
            </div>
        </div>
    </div>);

    function onEditProfileFormInput(name: string, value: string) {
        let newFormValues = { ...editProfileFormValues };
        (newFormValues as any)[name] = value;
        setEditProfileFormvalues(newFormValues);
        if (editProfileErrorRef.current!.innerText) editProfileErrorRef.current!.innerText = " ";
    }

    function submitEditProfile() {
        editProfileSaveButtonManageRef.current!.setLoadingState(true);
        accountService.updateProfile(editProfileFormValues).then(() => {
            ConfirmDialog({
                onConfirm: () => true,
                title: labels.edit_profile.title,
                desc: labels.edit_profile.successful,
                centerIcon: Elements.DIALOG_CHECK_ICON,
            }).show()
        }).catch(({ errorMessage }: SanitizedResponse<IProfile>) => {
            editProfileErrorRef.current!.innerText = errorMessage;
        }).finally(() => editProfileSaveButtonManageRef.current!.setLoadingState(false));
    }

    function onChangePasswordFormInput(name: string, value: string) {
        let newFormValues = { ...changePasswordFormValues };
        (newFormValues as any)[name] = value;
        setChangePasswordFormvalues(newFormValues);
        if (changePasswordErrorRef.current!.innerText) changePasswordErrorRef.current!.innerText = " ";
        setChangePasswordIsSubmitable(Object.keys(newFormValues).length >= labels.change_password.form!.length);
    }

    function submitChangePassword() {
        if (changePasswordFormValues.new_password !== changePasswordFormValues.new_password_confirm) {
            setChangePasswordIsSubmitable(false);
            changePasswordErrorRef.current!.innerText = labels.change_password.new_password_not_match!;
            return;
        }
        changePasswordSaveButtonManageRef.current!.setLoadingState(true);
        const dialog = ConfirmDialog({
            closable: true,
            ...labels.change_password.dialog,
            content: (<div style={{ marginTop: 10, marginBottom: 10 }}>
                <div style={{ marginTop: 15 }}>
                    {Elements.buildConfirmDialogForm(labels.change_password.dialog!.form, (name: string, value: string) => {
                        changePasswordFormValues[name] = value;
                    })}
                </div>
                <div ref={changePasswordDialogErrorRef} className="error"> </div>
            </div>),
            onConfirm: (button?: ButtonManageRef) => {
                button?.setLoadingState(true);
                accountService.changePassword(changePasswordFormValues).then(() => {
                    dialog.hide();
                    ConfirmDialog({
                        title: labels.change_password.title,
                        centerIcon: Elements.DIALOG_CHECK_ICON,
                        desc: labels.change_password.successful,
                        onConfirm: () => {
                            window.location.reload();
                            return true;
                        },
                    }).show()
                }).catch(({ errorMessage }: SanitizedResponse<IProfile>) => {
                    changePasswordDialogErrorRef.current!.innerText = errorMessage;
                }).finally(() => button?.setLoadingState(false));
                return false;
            },
            onCancel: () => changePasswordSaveButtonManageRef.current!.setLoadingState(false),
        });
        dialog.show();
    }

    function changeMobileNumber() {
        const phoneNumberChangeFormValues: NoseurObject<NoseurObject<any>> = {
            request: {},
            change: {
                sms_factor: {}
            },
        };
        const dialog = ConfirmDialog({
            closable: true,
            ...labels.change_mobile.dialog,
            content: (<div style={{ marginTop: 10, marginBottom: 10 }}>
                <div style={{ marginTop: 15 }}>
                    {Elements.buildConfirmDialogForm(labels.change_mobile.dialog!.form.slice(0, 1), (name: string, value: string) => {
                        changePhoneDialogErrorRef.current!.innerText = " ";
                        phoneNumberChangeFormValues.request[name] = value;
                    })}
                    {<RequestCodeControls errorRef={changePhoneDialogErrorRef} params={phoneNumberChangeFormValues}
                        service={accountService} requestCode={requestPhoneToken} />}
                    {Elements.buildConfirmDialogForm(labels.change_mobile.dialog!.form.slice(1, 2), (name: string, value: string) => {
                        changePhoneDialogErrorRef.current!.innerText = " ";
                        phoneNumberChangeFormValues.change.sms_factor[name] = value;
                    })}
                </div>
                <div ref={changePhoneDialogErrorRef} className="error"> </div>
            </div>),
            onConfirm: (button?: ButtonManageRef) => {
                changePhoneDialogErrorRef.current!.innerText = " ";
                if (!phoneNumberChangeFormValues.change.sms_factor.factor_id) {
                    changePhoneDialogErrorRef.current!.innerText = labels.change_mobile.dialog!.request_code!;
                    return false;
                }
                if (!phoneNumberChangeFormValues.change.sms_factor.value) {
                    changePhoneDialogErrorRef.current!.innerText = labels.change_mobile.dialog!.enter_code!;
                    return false;
                }
                button?.setLoadingState(true);
                accountService.changePhone(phoneNumberChangeFormValues.change).then(() => {
                    dialog.hide();
                    ConfirmDialog({
                        centerIcon: Elements.DIALOG_CHECK_ICON,
                        title: labels.change_mobile.dialog!.title,
                        desc: labels.change_mobile.dialog!.successful,
                        onConfirm: () => {
                            accountService.getProfile(true);
                            return true;
                        },
                    }).show()
                }).catch(({ errorMessage }: SanitizedResponse<any>) => {
                    changePhoneDialogErrorRef.current!.innerText = errorMessage;
                }).finally(() => button?.setLoadingState(false));
                return false;
            },
        });
        dialog.show();
    }

    function requestPhoneToken(service: AccountService, phoneNumberChangeFormValues: any, onFail: (errorMessage: string) => void) {
        if (!phoneNumberChangeFormValues.request.new_phone) {
            changePhoneDialogErrorRef.current!.innerText = labels.change_mobile.dialog!.enter_phone!;
            return false;
        }
        if (phoneNumberChangeFormValues.request.new_phone === props.profile.phone) {
            changePhoneDialogErrorRef.current!.innerText = labels.change_mobile.dialog!.old_new_phone_conflict!;
            return false;
        }
        service.requestPhoneChangeCode(phoneNumberChangeFormValues.request).then(({ sanitized }: SanitizedResponse<any>) => {
            const { sms_factor_id } = sanitized;
            phoneNumberChangeFormValues.change.sms_factor = {
                factor_id: sms_factor_id
            };
        }).catch(({ errorMessage }: SanitizedResponse<any>) => onFail(errorMessage));
        return true;
    }

    function changeEmaillAddress() {
        const emailChangeRequestFormValues: NoseurObject<string> = {};
        const dialog = ConfirmDialog({
            closable: true,
            ...labels.change_email.dialog,
            content: (<div style={{ marginTop: 10, marginBottom: 10 }}>
                <div style={{ marginTop: 15 }}>
                    {Elements.buildConfirmDialogForm(labels.change_email.dialog!.form, (name: string, value: string) => {
                        changeEmailDialogErrorRef.current!.innerText = " ";
                        emailChangeRequestFormValues[name] = value;
                    })}
                </div>
                <div ref={changeEmailDialogErrorRef} className="error"> </div>
            </div>),
            onConfirm: (button?: ButtonManageRef) => {
                changeEmailDialogErrorRef.current!.innerText = " ";
                if (Object.keys(emailChangeRequestFormValues).length !== 2) {
                    changeEmailDialogErrorRef.current!.innerText = labels.change_email.dialog!.fill_form!;
                    return false;
                }
                button?.setLoadingState(true);
                accountService.requestEmailChangeCode(emailChangeRequestFormValues).then(() => {
                    dialog.hide();
                    ConfirmDialog({
                        centerIcon: Elements.DIALOG_CHECK_ICON,
                        title: labels.change_email.dialog!.title,
                        desc: labels.change_email.dialog!.successful,
                        onConfirm: () => true,
                    }).show()
                }).catch(({ errorMessage }: SanitizedResponse<IProfile>) => {
                    changeEmailDialogErrorRef.current!.innerText = errorMessage;
                    if (errorMessage.includes("already exist")) {
                        dialog.destroy();
                        ConfirmDialog({
                            confirmScheme: Scheme.DANGER,
                            title: labels.change_email.dialog!.title,
                            cancelLabel: i18nManager.Labels.common.cancel,
                            content: (<div style={{ marginTop: 20 }}></div>),
                            confirmLabel: labels.change_email.dialog!.new_request,
                            desc: labels.change_email.dialog!.conflict_message!.replaceAll("{email}", emailChangeRequestFormValues["new_email"]),
                            onConfirm: () => {
                                changeEmaillAddress();
                                return true;
                            },
                        }).show()
                    }
                }).finally(() => button?.setLoadingState(false));
                return false;
            },
        });
        dialog.show();
    }

    function requestNewFa() {
        RequestNewFa({
            reset: false,
            profile: props.profile,
            service: AuthsService.getInstance(),
            onSuccess: ({ sanitized }: SanitizedResponse<any>) => {
                const dialog = ConfirmDialog({
                    desc: labels.new_fa.dialog?.desc,
                    title: labels.new_fa.dialog?.title,
                    content: (<div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                        <img src={`data:image/png;base64, ${sanitized.qrcode_base64}`} style={{ width: 250, height: 250 }} />
                        <div style={{ wordBreak: "break-all" }}>Plain Text: <b>{sanitized.plain_code}</b></div>
                    </div>),
                    confirmLabel: i18nManager.Labels.common.close,
                });
                dialog.show();

            }
        });
    }


}

export default AccountSettings;
