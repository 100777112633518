
import { Elements } from "./Elements";
import { Schemes, Subscriber } from "../../utils";
import { Attachment, ContentType, TemplateType } from "../../services";
import { NoseurObject, TextAreaInput, Button, ObjectHelper, fileInputFileFromUrl, FileInput } from "@ronuse/noseur";

export const TemplateEditor = {

    buildCreateTemplateEditor: (labels: NoseurObject<any>, formValues: NoseurObject<any>, refs: React.RefObject<NoseurObject<any>>, hideSideBar: boolean = true, attachments: Attachment[] = []) => {
        const lowerCasedType = formValues.type?.toLowerCase() ?? "media";

        if (formValues.required_parameters && !formValues.parameters) {
            formValues.parameters = formValues.required_parameters.reduce((acc: any, v: any) => {
                acc[v] = null;
                return acc;
            }, {});
        }

        // TEXT
        if (formValues.type === TemplateType.TEXT) { // TODO: use rich text input from noseur
            return (<div className="template-editor">
                <span style={{ fontSize: 13 }}>{labels.text_info}</span>
                <TextAreaInput defaultValue={formValues.content} scheme={Schemes.RIVTN_QUIRINUS} style={{ marginTop: 15, height: "100%" }} onInputComplete={(value) => {
                    formValues["content"] = value;
                }} />
            </div>)
        }

        // ANY, HTML
        if (formValues.type === TemplateType.ANY || formValues.type === TemplateType.HTML) {
            if (hideSideBar) Subscriber.report(Subscriber.KEYS.TOGGLE_SIDEBAR_VISIBILITY, false);
            return (<div className="template-editor html">
                <div className="palette">
                    <div className="control">
                        <Button raised fillOnHover scheme={Schemes.RIVTN_QUIRINUS} style={{ borderRadius: "10px 0px 0px 10px", width: "130px", border: "1px solid transparent" }} manageRef={(m) => refs.current!.htmlTemplateDragAndDropButtonRef = m} text={labels.html.dnd} onClick={() => {
                            refs.current?.htmlTemplateHtmlButtonRef?.setLoadingState(true);
                            refs.current?.htmlTemplateDragAndDropButtonRef?.setLoadingState(false);
                            if (refs.current?.htmlTemplateHtmlEditorRef) refs.current.htmlTemplateHtmlEditorRef.style.display = "none";
                            if (refs.current?.htmlTemplateDragAndDropRef) refs.current.htmlTemplateDragAndDropRef.style.display = "grid";
                        }} loadingProps={{ textOnly: true }} />
                        <Button raised fillOnHover scheme={Schemes.RIVTN_QUIRINUS} style={{ borderRadius: "0px 10px 10px 0px", width: "130px", border: "1px solid transparent" }} manageRef={(m) => refs.current!.htmlTemplateHtmlButtonRef = m} text={labels.html.html} onClick={() => {
                            refs.current?.htmlTemplateHtmlButtonRef?.setLoadingState(false);
                            refs.current?.htmlTemplateDragAndDropButtonRef?.setLoadingState(true);
                            if (refs.current?.htmlTemplateHtmlEditorRef) refs.current.htmlTemplateHtmlEditorRef.style.display = "block";
                            if (refs.current?.htmlTemplateDragAndDropRef) refs.current.htmlTemplateDragAndDropRef.style.display = "none";
                        }} loadingProps={{ textOnly: true }} />
                    </div>
                    <span className="desc">{"Drag and drop the element in this section into the view on the right"}</span>
                    <div className="inputs dnd" ref={(r) => refs.current!.htmlTemplateDragAndDropRef = r}>
                        {labels.html.dnd_elements.map((element: { key: string; icon: string; label: string; }, index: number) => {
                            return (<Button key={index} scheme={Schemes.RIVTN_QUIRINUS} textOnly raised fillOnHover>
                                <i className={element.icon} />
                                <span>{element.label}</span>
                            </Button>);
                        })}
                    </div>
                    <TextAreaInput defaultValue={formValues.content} scheme={Schemes.RIVTN_QUIRINUS} className="inputs" ref={(r) => refs.current!.htmlTemplateHtmlEditorRef = r} onInputComplete={(value) => {
                        if (refs.current?.iframeRef) {
                            refs.current.iframeRef.srcdoc = ObjectHelper.expandStringTemplate(Elements.expandTemplateAttachments(value, attachments), formValues.parameters, { chop: "$", relativeExpansion: true });
                        }
                        formValues.content = value;
                    }} style={{ display: "none" }} />
                </div>
                <iframe ref={(r) => refs.current!.iframeRef = r} className="preview">

                </iframe>
                <div style={{ display: "none" }} ref={() => {
                    refs.current?.htmlTemplateHtmlButtonRef?.setLoadingState(true);
                    refs.current?.htmlTemplateDragAndDropButtonRef?.setLoadingState(false);
                    if (refs.current?.htmlTemplateHtmlEditorRef) refs.current.htmlTemplateHtmlEditorRef.style.display = "none";
                    if (refs.current?.htmlTemplateDragAndDropRef) refs.current.htmlTemplateDragAndDropRef.style.display = "grid";
                    if (refs.current?.iframeRef) refs.current.iframeRef.srcdoc = Elements.expandTemplateAttachments(formValues.content ?? "", attachments);
                }} />
            </div>);
        }


        if (formValues.content && !formValues.__files__) {
            formValues.__files__ = [fileInputFileFromUrl(formValues.content, formValues.name, `${lowerCasedType}/*`)];
        }

        // IMAGE, AUDIO, VIDEO
        return (<div className="template-editor">
            <span style={{ fontSize: 13 }}>{labels.media_info.replace("${value}", lowerCasedType)}</span>
            <FileInput className="media-drag-component" accepts={`${lowerCasedType}/*`} defaultFiles={formValues["__files__"]}
                emptyTemplate={(onSelect: any) => (<div onClick={onSelect} style={{ height: "100%", display: "flex", alignItems: "center", justifyContent: "center" }} >
                    <div onClick={onSelect} style={{}}>
                        <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                            <i className="fa fa-upload" style={{ marginBottom: 10, fontSize: 50, color: "#C0C0C0" }} />
                            <span>{labels.choose_file}</span>
                        </div>
                    </div>
                </div>)} onSelectFiles={(files: File[]) => {
                    if (formValues.content) return;
                    formValues["__files__"] = files;
                    formValues["content"] = URL.createObjectURL(files[0]);
                }} />
        </div>);
    },

    templateTypeToContentType: (contentType: TemplateType): ContentType => {
        if (contentType === TemplateType.TEXT) return ContentType.TEXT;
        if (contentType === TemplateType.HTML) return ContentType.HTML;
        if (contentType === TemplateType.AUDIO) return ContentType.AUDIO;
        if (contentType === TemplateType.VIDEO) return ContentType.VIDEO;
        if (contentType === TemplateType.IMAGE) return ContentType.IMAGE;
        return ContentType.ANY;
    },

    contentTypeToTemplateType: (contentType: ContentType): TemplateType => {
        if (contentType === ContentType.TEXT) return TemplateType.TEXT;
        if (contentType === ContentType.HTML) return TemplateType.HTML;
        if (contentType === ContentType.AUDIO) return TemplateType.AUDIO;
        if (contentType === ContentType.VIDEO) return TemplateType.VIDEO;
        if (contentType === ContentType.IMAGE) return TemplateType.IMAGE;
        return TemplateType.ANY;
    }

}
