
import React from "react";
import { NoseurObject } from "@ronuse/noseur";
import { BaseService, IPlatform, PlatformService, SanitizedResponse } from "../../../../services";

export interface CheckHealthProps {
    platform?: IPlatform;
}

function CheckHealth(props: CheckHealthProps) {
    const platformService = PlatformService.getInstance();
    const [healthCheckStatus, setHealthCheckStatus] = React.useState<"loading" | "failed" | "success">("loading");

    React.useEffect(() => {
        if (!props.platform) return;
        setHealthCheckStatus("loading");
        let healthEndpoint = props.platform?.bacuator_data.health_endpoint;
        if (!healthEndpoint || !props.platform?.bacuator_data.api_base_url.startsWith("http")) healthEndpoint = props.platform?.bacuator_route.replace("introspect", "health");
        if (!healthEndpoint.startsWith("http")) healthEndpoint = props.platform?.bacuator_data.api_base_url + (healthEndpoint);
        platformService.checkHealth(healthEndpoint).then(({ apiResponse }: SanitizedResponse<NoseurObject<any>>) => {
            if ((apiResponse.data as any).status === "ok") {
                setHealthCheckStatus("success");
                return;
            }
            throw new Error("service is not ok");
        }).catch((err) => {
            setHealthCheckStatus("failed");
            BaseService.reportError(err);
        });
    }, [props.platform]);

    return <div className="content management-links">
        {healthCheckStatus === "loading" ? (<i className="fa fa-spinner fa-spin" />) : null}
        {healthCheckStatus === "failed" ? (<div style={{ flex: 1, display: "flex", flexDirection: "column", paddingTop: "15%", alignItems: "center" }}>{ServiceNotOkIndicator}</div>) : null}
        {healthCheckStatus === "success" ? (<div style={{ flex: 1, display: "flex", flexDirection: "column", paddingTop: "15%", alignItems: "center" }}>{ServiceOkIndicator}</div>) : null}
    </div>;

}

const ServiceOkIndicator = (<svg width="40" height="40" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M30 60C46.5685 60 60 46.5685 60 30C60 13.4315 46.5685 0 30 0C13.4315 0 0 13.4315 0 30C0 46.5685 13.4315 60 30 60Z" fill="#0FA883" />
    <path d="M52.4738 27.504V27.624C49.8732 29.2515 47.2538 30.8602 44.6419 32.484C44.5924 32.5198 44.5535 32.5684 44.5294 32.6246C45.2269 32.8121 
        45.9488 32.9527 46.6107 33.2565C47.2219 33.5283 47.8482 33.8827 48.1932 34.4771C48.5232 35.0808 48.6057 35.7896 48.4219 36.4515C48.3226 36.9465 
        48.1144 37.4115 47.9269 37.8821C47.1432 39.7608 46.3557 41.634 45.5776 43.5202C45.5398 43.6136 45.4893 43.7013 45.4276 43.7808C45.1707 43.7752 
        44.9251 43.6815 44.6738 43.6233C43.4063 43.3102 42.1313 43.0027 40.8638 42.6858C40.4513 42.5696 40.0276 42.5021 39.6207 42.3671C39.6319 42.1102 
        39.7782 41.8908 39.8644 41.6602L41.6044 37.4958C41.7357 37.2071 41.8126 36.8471 41.6251 36.5658C41.3907 36.1646 40.9201 36.0127 40.5038 
        35.8983C38.9738 35.5046 37.4326 35.1408 35.8988 34.7602C35.6794 34.7133 35.4657 34.614 35.2351 34.644C35.1001 34.8802 35.0213 35.1465 
        34.9126 35.4015C34.2601 36.9315 33.6338 38.4708 32.9851 40.0008C32.8913 40.2146 32.8351 40.4508 32.6926 40.6383C32.4376 40.6383 32.1976 40.539 
        31.9519 40.4808C30.6413 40.1583 29.3307 39.8283 28.0144 39.5096C27.6451 39.4102 27.2588 39.3483 26.8932 39.2227C26.8977 39.1189 26.9205 39.0167 
        26.9607 38.9208L33.5851 23.0396C33.608 22.9864 33.6441 22.9399 33.6901 22.9046C33.9207 22.9196 34.1401 23.0133 34.3651 23.0658C35.4807 23.3321 
        36.5982 23.619 37.7157 23.8908C38.2951 24.0408 38.8894 24.1571 39.4651 24.3296C39.4951 24.4383 39.4426 24.549 39.4013 24.6521C38.9513 25.6758 
        38.5388 26.7146 38.1019 27.7383L36.7501 30.9858C36.5982 31.3665 36.3994 31.7321 36.2944 32.1333C36.4894 32.0658 36.6563 31.9515 
        36.8269 31.8352L45.6507 26.304C45.8119 26.2027 45.9582 26.0677 46.1419 26.0208C46.4119 26.0002 46.6669 26.1033 46.9238 26.1615C47.8238 
        26.3546 48.7088 26.6115 49.6013 26.8177C50.5688 27.0333 51.5176 27.2996 52.4738 27.504ZM31.6538 24.3183C31.7007 25.2633 31.3407 26.1615 
        30.9713 27.0071C29.9738 29.4033 28.9651 31.794 27.9732 34.1902C27.6132 35.0358 27.3001 35.919 26.7469 36.6652C26.3532 37.2202 25.8057 
        37.6477 25.1719 37.8915C24.3731 38.1413 23.5271 38.2023 22.7007 38.0696C21.2757 37.8915 19.8882 37.5071 18.4932 37.1715C16.5994 36.6971 
        14.6926 36.2377 12.7988 35.7615C11.5407 35.4596 10.2713 35.1352 9.10131 34.5615C8.59647 34.3167 8.16748 33.9396 7.86006 33.4702C7.62262 
        33.0829 7.51796 32.6287 7.56193 32.1765C7.66131 31.3458 7.96881 30.5565 8.30256 29.7952L11.3588 22.4546C11.6026 21.8921 11.8163 21.3108 
        12.1294 20.7802C12.3973 20.2894 12.7532 19.8522 13.1794 19.4902C13.6819 19.1077 14.2726 18.8621 14.8969 18.774C15.3563 18.7327 15.8157 18.7552 
        16.2751 18.7646C17.6957 18.9746 19.1025 19.2688 20.4882 19.6458C22.4307 20.1146 24.3676 20.6077 26.3082 21.0765C27.2007 21.2958 28.0894 21.5208 
        28.9613 21.819C29.5444 22.0327 30.1407 22.2502 30.6526 22.6215C31.2338 22.9983 31.6051 23.6265 31.6538 24.3183ZM24.8663 25.989C24.9038 25.7133 
        24.7069 25.4771 24.4763 25.3571C24.0076 25.1021 23.4732 25.0083 22.9632 24.8771C21.6094 24.5396 20.2557 24.2058 18.8944 23.8758C18.5087 
        23.7628 18.1026 23.7372 17.7057 23.8008C17.2463 23.9321 17.0157 24.3971 16.8601 24.804C16.0913 26.6321 15.3413 28.464 14.5688 30.2865C14.4844 
        30.5002 14.4001 30.714 14.3813 30.9427C14.3813 31.2052 14.5632 31.434 14.7863 31.5427C15.1219 31.7152 15.4876 31.8108 15.8419 31.9046L20.0701 
        32.9433C20.5613 33.0577 21.0769 33.1833 21.5794 33.0746C22.0013 32.9377 22.2169 32.5046 22.3726 32.124L24.4444 27.1533C24.6076 26.7783 24.8157 
        26.4071 24.8682 25.9908L24.8663 25.989Z" fill="white" />
</svg>);

const ServiceNotOkIndicator = (<svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M30 5C16.2 5 5 16.2 5 30C5 43.8 16.2 55 30 55C43.8 55 55 43.8 55 30C55 16.2 43.8 5 30 5ZM32.5 42.5H27.5V37.5H32.5V42.5ZM32.5 
    32.5H27.5V17.5H32.5V32.5Z" fill="#EB5959" />
</svg>);

export default CheckHealth;
