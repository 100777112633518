
import React from "react";
import { Elements } from "./Elements";
import { i18nManager } from "../../i18n";
import { ConfirmDialog } from "./ConfirmDialog";
import RequestCodeControls from "./RequestCodeControls";
import { ButtonManageRef, NoseurObject } from "@ronuse/noseur";
import { AuthsService, IProfile, SanitizedResponse } from "../../services";

export interface RequestNewFaProps {
    reset: boolean;
    profile: IProfile;
    service: AuthsService;
    onSuccess: (saniztizedResponse: SanitizedResponse<any>) => void;
}

export function RequestNewFa(props: RequestNewFaProps) {
    let faErrorLabel: any = { current: null };
    const labels = i18nManager.Labels.auths.fa.reset;
    let formValues: NoseurObject<any> = {
        email: props.profile.email,
        sms_factor: {},
        email_factor: {}
    };

    function onFormInput(name: string, value: string) {
        formValues[name]!.value = value;
        faErrorLabel.current!.innerText = " ";
    }

    function requestFaToken(service: AuthsService, _: any, onFail: (errorMessage: string) => void) {
        service.requestFaResetTokens({ email: props.profile.email }).then(({ sanitized }: SanitizedResponse<any>) => {
            const { email_factor_id, sms_factor_id } = sanitized;
            formValues.sms_factor.factor_id = sms_factor_id;
            formValues.email_factor.factor_id = email_factor_id;
        }).catch(({ errorMessage }: SanitizedResponse<any>) => onFail(errorMessage));
        return true;
    }
    
    const dialog = ConfirmDialog({
        closable: true,
        dismissableModal: true,
        desc: labels.desc,
        title: labels.title,
        content: (<div style={{ marginTop: 10, marginBottom: 10 }}>
            {<RequestCodeControls errorRef={faErrorLabel} service={props.service} requestCode={requestFaToken} />}
            {Elements.buildConfirmDialogForm(labels.form, onFormInput)}
            <div ref={(r: any) => faErrorLabel.current = r} className="error"> </div>
        </div>),
        onConfirm: (button?: ButtonManageRef) => {
            faErrorLabel.current.innerText = " ";
            if (!formValues.sms_factor.factor_id || !formValues.email_factor.factor_id) {
                faErrorLabel.current.innerText = labels.required_fa_code;
                return false;
            }
            if (!formValues.sms_factor.value || !formValues.email_factor.value) {
                faErrorLabel.current.innerText = labels.no_fa_value;
                return false;
            }
            button?.setLoadingState(true);
            props.service.requestFaReset(formValues, props.reset).then((sanitizedResponse: SanitizedResponse<any>) => {
                dialog.destroy();
                props.onSuccess(sanitizedResponse)
            }).catch(({ errorMessage }: SanitizedResponse<any>) => {
                if (faErrorLabel.current) faErrorLabel.current.innerText = errorMessage;
            }).finally(() => button?.setLoadingState(false));
            return false;
        }
    });
    dialog.show();
}

