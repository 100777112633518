
import { NoseurObject } from "@ronuse/noseur";
import { IContact } from "./ContactListService";
import { IMessage } from "./MessageService";
import { CharonBaseService, ContentType, MessageStatus, MessageType } from "./CharonBaseService";

export enum IntervalUnit {
    DAY = "DAY",
    HOUR = "HOUR",
    YEAR = "YEAR",
    MONTH = "MONTH",
    MINUTE = "MINUTE",
}

export interface ISchedule {
    id: number;
    name: string;
    end_date: string;
    actor_id: string;
    type: MessageType;
    max_retry: number;
    created_at: string;
    updated_at: string;
    start_date: string;
    ip_address: string;
    schedule_id: string;
    content_url: string;
    description: string;
    platform_id: string;
    event_logs: string[];
    callback_url: string;
    status: MessageStatus;
    interval_value: number;
    last_retry_count: number;
    sent_message_ids: string[];
    message: Partial<IMessage>;
    total_trigger_count: number;
    last_execution_date: string;
    interval_unit: IntervalUnit;
    failed_trigger_count: number;
    success_trigger_count: number;
    send_on_first_schedule: boolean;
}

export class ScheduleService extends CharonBaseService {

    protected static instance: ScheduleService;

    private constructor() {
        super();
    }

    static getInstance() {
        if (!ScheduleService.instance) ScheduleService.instance = new ScheduleService();
        return ScheduleService.instance;
    }

    querySchedule(params: NoseurObject<string | number> = { page: 1, size: 10 }, refresh: boolean = false): Promise<any> {
        return this.report(this.transport.get("/charon/api/v1/schedule", { params: this.normalizeParams(params) } as any));
    }

    createSchedule(schedule: ISchedule): Promise<any> {
        return this.report(this.transport.post(`/charon/api/v1/schedule`, schedule, {
            ...this.config,
            headers: {
                "X-Api-Key": "janus_uysiwutew64863uyeww"
            }
        }));
    }

    getSingleSchedule(id: number): Promise<any> {
        return this.report(this.transport.get(`/charon/api/v1/schedule/${id!}`, this.config));
    }

    updateSchedule(schedule: ISchedule): Promise<any> {
        return this.report(this.transport.patch(`/charon/api/v1/schedule/${schedule.id!}`, schedule, this.config));
    }

    pauseResumeSchedule(schedule: ISchedule, resume: boolean): Promise<any> {
        return this.report(this.transport.put(`/charon/api/v1/schedule/${resume ? "resume" : "pause"}/${schedule.schedule_id!}`, {}, {
            ...this.config,
            headers: {
                "X-Api-Key": "janus_uysiwutew64863uyeww"
            }
        }));
    }

    deleteSchedule(schedule: ISchedule): Promise<any> {
        return this.report(this.transport.delete(`/charon/api/v1/schedule/${schedule.id!}`, this.config));
    }

    cancelSchedule(schedule: ISchedule): Promise<any> {
        return this.report(this.transport.delete(`/charon/api/v1/schedule/cancel/${schedule.schedule_id!}`, {
            ...this.config,
            headers: {
                "X-Api-Key": "janus_uysiwutew64863uyeww"
            }
        }));
    }

    triggerSchedules(scheduleIds: number[]): Promise<any> {
        return this.report(this.transport.post(`/charon/api/v1/schedule/trigger`, { schedule_ids: scheduleIds }, this.config));
    }

    deleteSchedules(scheduleIds: number[]): Promise<any> {
        return this.report(this.transport.delete(`/charon/api/v1/schedule`, {
            ...this.config,
            data: { schedule_ids: scheduleIds },
        }));
    }

}

