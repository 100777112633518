
import React from "react";
import CheckHealth from "./CheckHealth";
import DatabaseQuery from "./DatabaseQuery";
import { i18nManager } from "../../../../i18n";
import { Route, Routes } from "react-router-dom";
import { IPlatform } from "../../../../services";
import { DashboardContainer } from "../../../shared/DashboardContainer";

export interface ServiceDebuggingProps {
    search: string;
    platform?: IPlatform;
}

function ServiceDebugging(props: ServiceDebuggingProps) {
    const loading = !props.platform;
    const labels = i18nManager.Labels.dashboard.platform_management.service_debugging;

    return DashboardContainer({
        ...labels,
        search: props.search,
        title: (<React.Fragment>{labels.title} ({loading ? (<i className="fa fa-spinner fa-spin" />) : (props.platform?.platform_id ?? '')})</React.Fragment>),
        content: (<Routes>
            <Route path="/check-health" element={<CheckHealth platform={props.platform}/>} />
            <Route path="/database-query" element={<DatabaseQuery platform={props.platform} loading={loading}/>} />
        </Routes>),
    });
}

export default ServiceDebugging;
