
import React from "react";
import { Schemes } from "../../utils";
import { i18nManager } from "../../i18n";
import { AlertEvent, AlertInterface, Alignment, ButtonManageRef, LoadingAlertDialog, MessageSchemesIcons, NoseurElement, NoseurIconElement, Scheme, Toaster, alertDialog, loadingAlertDialog, MessageProps } from "@ronuse/noseur";

export interface ConfirmProps {
    desc: string;
    title: string;
    closable: boolean;
    noControl: boolean;
    cancelLabel: string;
    cancelScheme: Scheme;
    confirmScheme: Scheme;
    content: NoseurElement;
    dismissableModal: boolean;
    style: React.CSSProperties;
    confirmLabel: string | null;
    centerIcon: NoseurIconElement;
    rightContent: NoseurElement;
    rightStyle: React.CSSProperties;
    component?: <T>(props: Partial<LoadingAlertDialog<T>>, params?: T | undefined) => AlertInterface;

    onCancel: AlertEvent,
    onConfirm: (confirmButton?: ButtonManageRef) => boolean,
    onLoading: <T>(alert: AlertInterface, params?: T) => Promise<boolean | undefined>
}

export function ConfirmDialog(props: Partial<ConfirmProps>): AlertInterface {
    const confirmButton: any = { current: undefined };

    const alertComponent = props.component || (!props.onLoading ? alertDialog : loadingAlertDialog );
    if (props.closable && !props.cancelLabel) props.cancelLabel = i18nManager.Labels.common.close;
    const cancel = !props.noControl && props.cancelLabel ? {
        action: props.onCancel,
        label: props.cancelLabel,
        scheme: props.cancelScheme || Schemes.RIVTN_QUIRINUS,
        props: { textOnly: true, style: { fontWeight: "bold" } },
    } : null;
    const footerAlignment = (cancel ? Alignment.RIGHT : Alignment.CENTER);
    const alert = alertComponent({
        message: (<React.Fragment>
            <div className="cd-c">
                <span className="title">{props.title}</span>
                <span className="desc" dangerouslySetInnerHTML={{ __html: props.desc || "" }}></span>
                {props.centerIcon ? <div className="ci">{props.centerIcon}</div> : null}
                <div className="content" style={{ height: "100%" }}>{props.content}</div>
            </div>
            {props.rightContent ? (<div className="cd-c-r" style={props.rightStyle}> {props.rightContent} </div>) : null}
        </React.Fragment>),
        cancel,
        confirm: props.noControl ? null : {
            scheme: props.confirmScheme ?? Schemes.RIVTN_QUIRINUS,
            label: props.confirmLabel ?? i18nManager.Labels.common.continue,
            props: {
                raised: true,
                fill: !cancel,
                manageRef: confirmButton,
                style: { padding: "10px 30px", minWidth: 100, display: (props.confirmLabel !== null ? "inline-block" : "none") },
                loadingProps: {
                    disabled: true,
                    leftIcon: "fas fa-spinner fa-pulse"
                }
            },
        },
        className: "cd",
        style: props.style,
        onConfirm: () => {
            if (!props.onConfirm || (props.onConfirm && props.onConfirm(confirmButton.current))) {
                alert.hide();
            }
        },
        onLoading: props.onLoading,
        alignFooter: footerAlignment,
        dismissableModal: props.dismissableModal || false,
    });
    return alert;
}

export const Siren = {

    alert: (message: string, options?: Partial<MessageProps>) => {
        Toaster.toast({
            lifetime: 5000,
            foreScheme: true,
            content: message,
            showProgressbar: true,
            pauseDelayOnHover: true,
            style: { background: "white" },
            scheme: Schemes.RIVTN_QUIRINUS,
            icon: MessageSchemesIcons.SUCCESS,
            ...options
        });
    }

}
