import React from "react";
import { CacheManager } from "../../utils";
import { Navigate } from "react-router-dom";
import { BaseService, SessionService } from "../../services";

function Logout() {
    React.useEffect(() => {
        SessionService.getInstance().revokeSession("active").then(CacheManager.clear).catch(BaseService.reportError);
    });
    
    return (<Navigate to="/"/>);
}

export default Logout;
