
import React from "react";
import { Schemes, Utils } from "../../../utils";
import { CSSTransition } from 'react-transition-group';
import { FormControls, i18nManager } from "../../../i18n";
import { NavLink, useSearchParams } from "react-router-dom";
import { ConfirmDialog, CreateMessage, Elements, Siren } from "../../shared";
import { MessageStatus, TemplateType } from "../../../services/charon/CharonBaseService";
import { AlertInterface, BoolHelper, Button, ButtonManageRef, Checkbox, Classname, Column, DOMHelper, DataManageRef, NoseurObject, ObjectHelper, Scheme, Table, Transition } from "@ronuse/noseur";
import { ApiResponseData, SanitizedResponse, ISchedule, ScheduleService, BaseService, ChannelService, IChannel, ContactListService, IContactList, TemplateService, ITemplate, IMessage } from "../../../services";

function Scheduling() {
    let processing = false;
    const channelService = ChannelService.getInstance();
    const scheduleService = ScheduleService.getInstance();
    const templateService = TemplateService.getInstance();
    const tableManageRef = React.useRef<DataManageRef>(null);
    const dialogErrorRef = React.useRef<HTMLDivElement>(null);
    const contactListService = ContactListService.getInstance();
    const refMapCollection = React.useRef<NoseurObject<any>>({});
    const labels = i18nManager.Labels.dashboard.messaging.schedules;
    const [showFilters, setShowFilters] = React.useState<boolean>();
    const resendButtonManageRef = React.useRef<ButtonManageRef>(null);
    const deleteButtonManageRef = React.useRef<ButtonManageRef>(null);
    const [activeSchedule, setActiveSchedule] = React.useState<ISchedule>();
    const [searchParams, setSearchParams] = useSearchParams(window.location.search);
    const [schedules, setSchedules] = React.useState<ApiResponseData<ISchedule>>({});
    const [selectedSchedulesIds, setSelectedSchedulesIds] = React.useState<number[]>([]);

    React.useEffect(() => {
        if (!activeSchedule && !searchParams.has("active_schedule_id")) {
            fetchSchedules();
        } else {
            fetchActiveSchedule(parseInt(searchParams.get("active_schedule_id") ?? "0"));
        }
    }, []);
    React.useEffect(() => {
        if (window.location.search.includes("active_schedule_id")) {
            if (!activeSchedule) {
                fetchActiveSchedule(parseInt(searchParams.get("active_schedule_id") ?? "0"));
            }
            return;
        }
        searchParams.delete("active_schedule_id");
        setActiveSchedule(undefined);
        if (!schedules.content) fetchSchedules();
    }, [searchParams]);

    return (<div className="charon-messages">
        {!activeSchedule && !searchParams.has("active_schedule_id")
            ? (<div className="management">
                <div className="controls">
                    <div>
                        <Button scheme={Schemes.RIVTN_QUIRINUS} text={labels.create_new_schedule} raised onClick={() => createOrEditSchedule()} />
                        <Button scheme={Schemes.RIVTN_QUIRINUS} text={labels.filter} leftIcon="fa fa-filter" raised outlined={!showFilters}
                            onClick={() => setShowFilters(!showFilters)} />
                    </div>
                    {selectedSchedulesIds.length
                        ? (<div>
                            <Button scheme={Schemes.RIVTN_QUIRINUS} text={labels.trigger} manageRef={resendButtonManageRef} raised loadingProps={{
                                disabled: true,
                                leftIcon: "fas fa-spinner fa-pulse"
                            }} onClick={() => {
                                resendButtonManageRef.current?.setLoadingState(true);
                                triggerSchedules(selectedSchedulesIds, () => resendButtonManageRef.current?.setLoadingState(false));
                            }} />
                            <Button scheme={Scheme.DANGER} text={labels.delete} manageRef={deleteButtonManageRef} raised loadingProps={{
                                disabled: true,
                                leftIcon: "fas fa-spinner fa-pulse"
                            }} onClick={() => {
                                deleteButtonManageRef.current?.setLoadingState(true);
                                deleteSchedules(selectedSchedulesIds, () => {
                                    deleteButtonManageRef.current?.setLoadingState(false);
                                    fetchSchedules();
                                });
                            }} />
                        </div>) : null}
                </div>
                <CSSTransition classNames={Transition.CURTAIN} timeout={500} in={showFilters} unmountOnExit>
                    <div className="filters">
                        {labels.filters.form?.map((control: FormControls, index: number) => {
                            return (<div key={index} className="filter">
                                <span>{control.title}</span>
                                {Elements.buildConfirmDialogForm([control], (name, value) => {
                                    if (name === "channel_id") {
                                        channelService.queryChannel({ name: value.value }, true).then(({ apiResponse }: SanitizedResponse<IChannel>) => {
                                            value.manageRef?.changeOptions(apiResponse.data!.content?.map((channel: IChannel) => {
                                                return {
                                                    "label": channel.name,
                                                    "value": channel.channel_id
                                                };
                                            }));
                                        }).catch(BaseService.reportError);
                                    } else if (name === "contact_list_ids") {
                                        contactListService.queryContactList({ name: value.value }, true).then(({ apiResponse }: SanitizedResponse<IContactList>) => {
                                            value.manageRef?.changeOptions(apiResponse.data!.content?.map((contactList: IContactList) => {
                                                return {
                                                    "label": contactList.name,
                                                    "value": contactList.contact_list_id
                                                };
                                            }));
                                        }).catch(BaseService.reportError);
                                    } else if (name === "template_id") {
                                        templateService.queryTemplate({ name: value.value }, true).then(({ apiResponse }: SanitizedResponse<ITemplate>) => {
                                            value.manageRef?.changeOptions(apiResponse.data!.content?.map((template: ITemplate) => {
                                                return {
                                                    "label": template.name,
                                                    "value": template.template_id
                                                };
                                            }));
                                        }).catch(BaseService.reportError);
                                    }
                                }, searchParams, undefined, { marginTop: 0 }, undefined, { marginTop: 7 }, setSearchParams, undefined, undefined, { platform_id: "schedules_platform_id" })}
                            </div>);
                        })}
                        <Button scheme={Schemes.RIVTN_QUIRINUS} text={labels.filters.reset_filter} outlined onClick={() => {
                            labels.filters.form.map((control: FormControls) => {
                                Utils.updateSearchParams((control.name === "platform_id" ? "schedules_platform_id" : control.name), undefined, setSearchParams);
                            });
                        }} />
                        <Button scheme={Schemes.RIVTN_QUIRINUS} text={labels.filters.apply} style={{ padding: "10px 40px" }} onClick={fetchSchedules} />
                    </div>
                </CSSTransition>
                <Table className="table" data={schedules.content} rowsPerPage={schedules.size ?? 10} totalRecords={schedules.total_elements} dataRefreshKeys={["id"]} manageRef={tableManageRef} loadingState={Elements.LOADING_STATE}>
                    <Column header={() => <Checkbox scheme={Schemes.RIVTN_QUIRINUS} checked={selectedSchedulesIds.length === schedules.content?.length} onChange={(e: any) => {
                        if (!schedules?.content?.length) return false;
                        if (selectedSchedulesIds.length === schedules.content?.length) {
                            setSelectedSchedulesIds([]);
                        } else {
                            setSelectedSchedulesIds(schedules.content?.map(m => m.id)!);
                        }
                    }} />} template={(schedule: ISchedule) => {
                        return (<Checkbox scheme={Schemes.RIVTN_QUIRINUS} checked={selectedSchedulesIds.includes(schedule.id)} onChange={(e: any) => {
                            if (e.checked && !selectedSchedulesIds.includes(schedule.id)) {
                                setSelectedSchedulesIds([...selectedSchedulesIds, schedule.id]);
                            } else if (!e.checked && selectedSchedulesIds.includes(schedule.id)) {
                                selectedSchedulesIds.splice(selectedSchedulesIds.indexOf(schedule.id), 1);
                                setSelectedSchedulesIds([...selectedSchedulesIds]);
                            }
                        }} />);
                    }} />
                    <Column dataKey="platform_id" header={labels.table.platform_id} />
                    <Column dataKey="type" header={labels.table.type} />
                    <Column dataKey="name" header={labels.table.name} />
                    <Column header={labels.table.last_execution_date} template={(schedule: ISchedule) => Utils.formatToFineDate3(schedule.last_execution_date)} />
                    <Column header={labels.table.subject_and_content} template={(schedule: ISchedule) => {
                        const isTemplate = !schedule.message?.content;
                        return (<span className="lined-limit"><b>{labels.table.subject}:</b> {schedule.message?.subject}.
                            <b> {isTemplate ? labels.table.template : labels.table.content}:</b>
                            {isTemplate ? <NavLink to={{
                                pathname: "/dashboard/messaging/templating",
                                search: "active_data_id=" + schedule.message?.template_id
                            }}> {schedule?.message?.template_id}</NavLink> : <span> {schedule.message?.content?.substring(0, 10)}</span>}
                        </span>);
                    }} />
                    <Column header={labels.table.no_of_triggers} template={(schedule: ISchedule) => `${schedule.total_trigger_count ?? 0} ${labels.table.triggers}`} />
                    <Column header={labels.table.status} template={(schedule: ISchedule) => selectStyledStatus(schedule.status)} />
                    <Column template={(schedule: ISchedule) => {
                        return (<i style={{ marginLeft: 15 }} className={`fa fa-eye noseur-cursor-pointer ${Schemes.RIVTN_QUIRINUS}-tx link`} onClick={() => {
                            processing = true;
                            renderContentPreview(schedule, setActiveSchedule);
                            Utils.updateSearchParams("active_schedule_id", schedule.id, setSearchParams);
                        }} />)
                    }} />
                    <Column template={(schedule: ISchedule) => {
                        return (<i style={{ marginLeft: 0 }} className={`fa fa-play-circle noseur-cursor-pointer ${Schemes.RIVTN_QUIRINUS}-tx link fa`} onClick={(e: any) => {
                            DOMHelper.addClass(e.target, "fa-spin");
                            triggerSchedules([schedule.id], () => DOMHelper.removeClass(e.target, "fa-spin"));
                        }} />)
                    }} />
                </Table>
                {Elements.buildPaginator(schedules ?? {}, ({ currentPage }, size) => Utils.updateSearchParamses({ "page": currentPage, id: null, size }, setSearchParams, fetchSchedules), parseInt(searchParams.get("page") ?? "1"))}
            </div>)
            : (<div className="messaging-sided">
                <div className="second override">
                    {!activeSchedule ? Elements.LOADING_STATE : (activeSchedule as any).__borrowed_contentPreview}
                </div>
                <div className="third active-data active-schedule">
                    {!activeSchedule ? Elements.LOADING_STATE : (<React.Fragment>
                        <div className="header">
                            <i className="fa fa-times" onClick={() => {
                                Utils.updateSearchParams("active_schedule_id", undefined, setSearchParams, fetchSchedules);
                            }} />
                            <span className="name">{activeSchedule?.name}</span>
                            <span className="id">{activeSchedule?.platform_id}</span>
                            {selectStyledStatus(activeSchedule?.status ?? "unset", { marginTop: 5 })}
                            <span className="desc">{activeSchedule?.description}</span>
                        </div>
                        <div className="middle">
                            <div><b>{labels.active_schedule.info.type}:</b> {activeSchedule?.type}</div>
                            <div><b>{labels.active_schedule.info.start_date}:</b> {Utils.formatToFineDate(activeSchedule?.start_date)}</div>
                            <div><b>{labels.active_schedule.info.end_date}:</b> {`${Utils.formatToFineDate(activeSchedule?.end_date)}`}</div>
                            <div><b>{labels.active_schedule.info.max_retry}:</b> {activeSchedule?.max_retry}, <b>{labels.active_schedule.info.last_retry_count}:</b> {activeSchedule?.last_retry_count}</div>
                            <div><b>{labels.active_schedule.info.ip_address}:</b> <a href={`https://whatismyipaddress.com/ip/${activeSchedule?.ip_address}`} target="_blank">{activeSchedule?.ip_address}</a></div>
                            <div><b>{labels.active_schedule.info.actor_id}:</b> {activeSchedule?.actor_id}</div>
                            <div><b>{labels.active_schedule.info.last_execution}:</b> {`${Utils.formatToFineDate(activeSchedule?.last_execution_date)}`}</div>
                            <div><b>{labels.active_schedule.info.sent_first_message_immediately}:</b> {activeSchedule?.send_on_first_schedule ? i18nManager.Labels.common.yes : i18nManager.Labels.common.no}</div>
                            <div><b>{labels.active_schedule.info.subject}:</b> {`${activeSchedule?.message?.subject}`}</div>
                            <div><b>{labels.active_schedule.info.send_every_x_interval.replace("${interval_value}", `${activeSchedule?.interval_value}`).replace("${interval_unit}", activeSchedule?.interval_unit?.toLowerCase())}</b></div>
                            <div><b>{`${activeSchedule?.total_trigger_count}`}</b> {labels.active_schedule.info.total_triggers}</div>
                            <div><b>{`${activeSchedule?.failed_trigger_count}`}</b> {labels.active_schedule.info.failed_triggers}</div>
                            <div><b>{`${activeSchedule?.success_trigger_count}`}</b> {labels.active_schedule.info.successful_triggers}</div>
                        </div>
                        <div className="middle" style={{ "borderTop": "none", marginTop: 0 }}>
                            <div><b>{labels.active_schedule.info.channel_requested}:</b> {activeSchedule?.message?.channel_id ? (<a href={`/dashboard/messaging/channels?active_data_id=${activeSchedule?.message?.channel_id}`}>{activeSchedule?.message?.channel_id}</a>) : null}</div>
                            <div><b>{labels.active_schedule.info.last_channel_used}:</b> {activeSchedule?.message?.channel_used_id ? (<a href={`/dashboard/messaging/channels?active_data_id=${activeSchedule?.message?.channel_used_id}`}>{activeSchedule?.message?.channel_used_id}</a>) : null}</div>
                            <div><b>{labels.active_schedule.info.creation_date}:</b> {Utils.formatToFineDate(activeSchedule?.created_at)}</div>
                            <div><b>{labels.active_schedule.info.rotate_channel_on_retry}:</b> {activeSchedule?.message?.rotate_channel_on_retry ? i18nManager.Labels.common.yes : i18nManager.Labels.common.no}</div>
                            <div><b>{labels.active_schedule.info.from_address}:</b> {`${activeSchedule?.message?.from}`}</div>
                        </div>
                        <div className="actions">
                            <span className={Classname.build("link", (BoolHelper.equalsAny(activeSchedule.status, [MessageStatus.ENDED, MessageStatus.CANCELLED]) ? "noseur-disabled" : null))} onClick={() => createOrEditSchedule(activeSchedule)}>{labels.active_schedule.actions.edit_schedule}</span>
                            <span className="link" onClick={() => triggerSchedule(activeSchedule)}>{labels.active_schedule.actions.trigger_schedule}</span>
                            <span className={Classname.build("link", (BoolHelper.equalsAny(activeSchedule.status, [MessageStatus.ENDED, MessageStatus.CANCELLED]) ? "noseur-disabled" : null))} onClick={() => pauseResumeTrigger(activeSchedule)}>{activeSchedule.status === MessageStatus.PAUSED ? labels.active_schedule.actions.resume_schedule : labels.active_schedule.actions.pause_schedule}</span>
                            <span className={Classname.build("link danger", (BoolHelper.equalsAny(activeSchedule.status, [MessageStatus.ENDED, MessageStatus.CANCELLED]) ? "noseur-disabled" : null))} onClick={() => cancelSchedule(activeSchedule)}>{labels.active_schedule.actions.cancel_schedule}</span>
                            <span className="link danger" onClick={() => deleteSchedule(activeSchedule)}>{labels.active_schedule.actions.delete_schedule}</span>
                        </div>
                    </React.Fragment>)}
                </div>
            </div>)}
    </div>);

    function fetchSchedules() {
        if (processing) return; processing = true;
        if (searchParams.has("active_schedule_id")) return;
        setSelectedSchedulesIds([]);
        tableManageRef.current?.setLoadingState(true);
        scheduleService.querySchedule(Utils.normalizeUrlParams(searchParams, { platform_id: "schedules_platform_id" }, undefined, { schedules_platform_id: "platform_id" }), true).then(({ apiResponse }: SanitizedResponse<ISchedule>) => {
            processing = false;
            setSchedules(apiResponse.data!);
        }).catch(BaseService.reportError).finally(() => {
            processing = false;
            tableManageRef.current?.setLoadingState(false);
        });
    }

    function fetchActiveSchedule(id: number) {
        if (processing) return; processing = true;
        scheduleService.getSingleSchedule(id).then(({ apiResponse }: SanitizedResponse<ISchedule>) => {
            processing = false;
            renderContentPreview(apiResponse.data as any as ISchedule, (schedule) => {
                setActiveSchedule(schedule);
            });
        }).catch((err) => {
            BaseService.reportError(err);
            Utils.updateSearchParams("active_schedule_id", undefined, setSearchParams, fetchSchedules);
        }).finally(() => processing = false);
    }

    function selectStyledStatus(status: string, style: any = {}) {
        if (status === MessageStatus.ENDED) return (<span className="pill" style={{ "--backgroundColor": "#000000", ...style } as any}>{labels.table.statuses.ended}</span>);
        if (status === MessageStatus.PAUSED) return (<span className="pill" style={{ "--backgroundColor": "#A10E9B", ...style } as any}>{labels.table.statuses.paused}</span>);
        if (status === MessageStatus.FAILED) return (<span className="pill" style={{ "--backgroundColor": "#EB5959", ...style } as any}>{labels.table.statuses.failed}</span>);
        if (status === MessageStatus.RETRYING) return (<span className="pill" style={{ "--backgroundColor": "#A5A80F", ...style } as any}>{labels.table.statuses.retrying}</span>);
        if (status === MessageStatus.SCHEDULED) return (<span className="pill" style={{ "--backgroundColor": "#0E75A1", ...style } as any}>{labels.table.statuses.scheduled}</span>);
        if (status === MessageStatus.CANCELLED) return (<span className="pill" style={{ "--backgroundColor": "#FFAC71", ...style } as any}>{labels.table.statuses.cancelled}</span>);
        if (status === MessageStatus.MESSAGE_SENT) return (<span className="pill" style={{ "--backgroundColor": "#0F1EA8", ...style } as any}>{labels.table.statuses.message_sent}</span>);
        if (status === MessageStatus.SENDING_MESSAGE) return (<span className="pill" style={{ "--backgroundColor": "#0FA883", ...style } as any}>{labels.table.statuses.sending_message}</span>);
        return (<span className="pill" style={{ "--backgroundColor": "#0FA883", ...style } as any}>{labels.table.statuses.sending_message}</span>);
    }

    function renderContentPreview(schedule: ISchedule, onComplete: (schedule: ISchedule) => void) {
        if (processing) return;
        (schedule.message?.content || !schedule.message?.template_id
            ? new Promise((resolve, _) => resolve({ apiResponse: { data: { type: TemplateType.HTML, content: schedule.message?.content } } }))
            : templateService.getSingleByTemplateId(schedule.message?.template_id)).then(({ apiResponse }: SanitizedResponse<ITemplate> | any) => {
                (schedule as any).__borrowed_contentPreview = Elements.buildTemplateView(apiResponse.data as any as ITemplate, TemplateType);
            }).catch(BaseService.reportError).finally(() => onComplete(schedule));
    }

    function triggerSchedules(scheduleIds: number[], onComplete?: () => void) {
        scheduleService.triggerSchedules(scheduleIds).then(({ }: SanitizedResponse<ISchedule>) => {
            Siren.alert(labels.schedule_trigger_initiated);
        }).catch(BaseService.reportError).finally(onComplete);
    }

    function deleteSchedules(scheduleIds: number[], onComplete: () => void) {
        scheduleService.deleteSchedules(scheduleIds).then(({ }: SanitizedResponse<ISchedule>) => {
            Siren.alert(labels.schedules_deleted);
        }).catch(BaseService.reportError).finally(onComplete);
    }

    function createOrEditSchedule(_schedule?: ISchedule) {
        const schedule = ObjectHelper.clone(_schedule ?? {} as ISchedule);
        const toUpdate = !!schedule.id;

        if (schedule.end_date && schedule.start_date) {
            (schedule as any).start_and_end_date_to = schedule.end_date;
            (schedule as any).start_and_end_date_from = schedule.start_date;
        }
        const dialog = ConfirmDialog({
            closable: true,
            style: { maxWidth: "420px", maxHeight: "95%" },
            desc: labels.create_schedule.desc,
            title: (toUpdate ?labels.create_schedule.update_schedule : labels.create_schedule.title),
            confirmLabel: (toUpdate ? labels.create_schedule.update_message : labels.create_schedule.setup_message),
            onCancel: () => Utils.updateSearchParams("popup", undefined, setSearchParams),
            content: (<div className="create-schedule">
                {Elements.buildConfirmDialogForm(labels.create_schedule.form, (name, value) => {
                    if (name === "start_and_end_date") {
                        (schedule as any).end_date = Utils.formatToApiDate(value.toDate);
                        (schedule as any).start_date = Utils.formatToApiDate(value.fromDate);
                        return;
                    }
                    (schedule as any)[name] = value;
                }, schedule)}
                <div className="interval">
                    {labels.create_schedule.send_every}
                    {Elements.buildConfirmDialogForm([labels.create_schedule.interval_interval_control, labels.create_schedule.interval_unit_control], (name, value) => {
                        (schedule as any)[name] = value;
                    }, schedule)}
                </div>
                {Elements.buildConfirmDialogForm([labels.create_schedule.send_first_message_immediately], (name, value) => {
                    (schedule as any)[name] = value;
                }, schedule)}
                <div ref={dialogErrorRef} className="error"> </div>
            </div>),
            onConfirm: () => {
                let errorMessage;
                if (!schedule.name) errorMessage = labels.create_schedule.name_required;
                else if (!schedule.type) errorMessage = labels.create_schedule.type_required;
                else if (!schedule.description) errorMessage = labels.create_schedule.description_required;
                else if (!schedule.start_date || !schedule.end_date) errorMessage = labels.create_schedule.start_end_date_required;
                else if (!schedule.interval_unit || !schedule.interval_value) errorMessage = labels.create_schedule.interval_unit_and_value_required;
                if (errorMessage) {
                    dialogErrorRef.current!.innerText = errorMessage;
                    return false;
                }

                schedule.message = schedule?.message ?? {};
                schedule.message.type = schedule.type;
                CreateMessage({
                    refMap: refMapCollection,
                    message: schedule?.message as any,
                    cancelLabel: i18nManager.Labels.common.back,
                    title: (toUpdate ? labels.create_schedule.update_schedule : labels.create_schedule.create_schedule),
                    onCancel: () => createOrEditSchedule(schedule),
                    onComplete: (message: IMessage, cb: (error?: string) => void) => {
                        schedule.message = message;
                        schedule.platform_id = message.platform_id;
                        const method = (toUpdate ? scheduleService.updateSchedule : scheduleService.createSchedule).bind(scheduleService);
                        method(schedule).then((_: SanitizedResponse<IMessage>) => {
                            cb(); fetchSchedules();
                            Siren.alert(toUpdate ? labels.create_schedule.schedule_updated : labels.create_schedule.schedule_created);
                        }).catch((err) => {
                            cb(err?.errorMessage ?? `${err}`);
                            BaseService.reportError(err);
                        });
                    }
                }).show();
                return true;
            }
        });
        dialog.show();
    }

    function pauseResumeTrigger(schedule: ISchedule) {
        const paused = schedule.status === MessageStatus.PAUSED;
        const resumePauseLabel = labels.active_schedule.dialog[paused ? "resume" : "pause"];
        const dialog = ConfirmDialog({
            closable: true,
            ...resumePauseLabel,
            confirmLabel: i18nManager.Labels.common.continue,
            onCancel: () => Utils.updateSearchParams("popup", undefined, setSearchParams),
            onConfirm: (button?: ButtonManageRef) => {
                button?.setLoadingState(true);
                scheduleService.pauseResumeSchedule(schedule, paused).then(({ }: SanitizedResponse<ISchedule>) => {
                    Siren.alert(resumePauseLabel.success); fetchActiveSchedule(schedule.id);
                }).catch(BaseService.reportError).finally(() => dialog.hide());
                return false;
            }
        });
        dialog.show();
    }

    function triggerSchedule(schedule: ISchedule) {
        const dialog = ConfirmDialog({
            onLoading: async (alert: AlertInterface) => {
                triggerSchedules([schedule.id ?? 0], () => {
                    alert.hide(); fetchActiveSchedule(schedule.id);
                });
                return false;
            },
        });
        dialog.show();
    }

    function cancelSchedule(schedule: ISchedule) {
        const dialog = ConfirmDialog({
            closable: true,
            confirmScheme: Scheme.DANGER,
            ...labels.active_schedule.dialog.cancel,
            confirmLabel: i18nManager.Labels.common.continue,
            onCancel: () => Utils.updateSearchParams("popup", undefined, setSearchParams),
            onConfirm: (button?: ButtonManageRef) => {
                button?.setLoadingState(true);
                scheduleService.cancelSchedule(schedule).then(({ }: SanitizedResponse<ISchedule>) => {
                    Siren.alert(labels.active_schedule.dialog.cancel.success); fetchActiveSchedule(schedule.id);
                }).catch(BaseService.reportError).finally(() => dialog.hide());
                return false;
            }
        });
        dialog.show();
    }

    function deleteSchedule(schedule: ISchedule) {
        const dialog = ConfirmDialog({
            closable: true,
            confirmScheme: Scheme.DANGER,
            ...labels.active_schedule.dialog.delete,
            confirmLabel: i18nManager.Labels.common.continue,
            onCancel: () => Utils.updateSearchParams("popup", undefined, setSearchParams),
            onConfirm: (button?: ButtonManageRef) => {
                button?.setLoadingState(true);
                scheduleService.deleteSchedule(schedule).then(({ }: SanitizedResponse<ISchedule>) => {
                    Siren.alert(labels.active_schedule.dialog.delete.success);
                    Utils.updateSearchParams("active_schedule_id", undefined, setSearchParams, fetchSchedules);
                }).catch(BaseService.reportError).finally(() => dialog.hide());
                return false;
            }
        });
        dialog.show();
    }

}

export default Scheduling;
