
import { IUser } from "./UserService";
import { NoseurObject } from "@ronuse/noseur";
import { EmailChangeRequestStatus, LanuariusBaseService } from "./LanuariusBaseService";

export interface IEmailChangeRequest {
    user: IUser;
    reason: string;
    old_email: string;
    new_email: string;
    created_at: string;
    updated_at: string;
    external_id: string;
    rejection_reason: string;
    status: EmailChangeRequestStatus;
}

export class EmailChangeRequestService extends LanuariusBaseService {

    protected static instance: EmailChangeRequestService;

    private constructor() {
        super();
    }

    static getInstance() {
        if (!EmailChangeRequestService.instance) EmailChangeRequestService.instance = new EmailChangeRequestService();
        return EmailChangeRequestService.instance;
    }

    queryEmailChangeRequests(params: NoseurObject<string | number> = { page: 1, size: 10 }): Promise<any> {
        return this.report(this.transport.get("/janus/lanuarius/api/v1/email/change", { params: this.normalizeParams(params) } as any));
    }

    approveEmailChangeRequest(externalId: string, payload: NoseurObject<boolean | string>): Promise<any> {
        return this.report(this.transport.post(`/janus/lanuarius/api/v1/email/change/administration/approve/${externalId}`, payload, this.config));
    }

}

