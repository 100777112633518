
import { NoseurObject } from "@ronuse/noseur";
import { CacheManager, Utils } from "../../../utils";
import { BaseService, SanitizedResponse } from "../../BaseService";

export interface IProfile {
    dob: string;
    email: string;
    phone: string;
    title: string;
    country: string;
    last_name: string;
    created_at: string;
    first_name: string;
    external_id: string;
    full_address: string;
    reports_to: IProfile;
    created_by: IProfile;
    profile_picture_location: string;
};

export class AccountService extends BaseService {

    protected static instance: AccountService;

    private constructor() {
        super();
    }

    static getInstance() {
        if (!AccountService.instance) AccountService.instance = new AccountService();
        return AccountService.instance;
    }

    getProfile(refresh: boolean = false): Promise<SanitizedResponse<IProfile>> {
        return this.report(this.transport.get("/janus/geminus/api/v1/account"), ({ sanitized }: SanitizedResponse<IProfile>) => {
            CacheManager.put(CacheManager.PROFILE_KEY, sanitized);
        });
    }

    updateProfile(payload: IProfile): Promise<any> {
        return this.report(this.transport.patch("/janus/geminus/api/v1/account/json", payload, this.config), () => {
            CacheManager.put(CacheManager.PROFILE_KEY, payload);
        });
    }

    changePassword(payload: NoseurObject<any>): Promise<any> {
        return this.report(this.transport.post("/janus/geminus/api/v1/account/password/change", payload, this.config));
    }

    requestPhoneChangeCode(payload: NoseurObject<any>): Promise<any> {
        return this.report(this.transport.post("/janus/geminus/api/v1/account/phone/change/request", payload, this.config));
    }

    changePhone(payload: NoseurObject<any>): Promise<any> {
        return this.report(this.transport.post("/janus/geminus/api/v1/account/phone/change", payload, this.config));
    }

    requestEmailChangeCode(payload: NoseurObject<any>): Promise<any> {
        return this.report(this.transport.post("/janus/geminus/api/v1/email/change", payload, this.config));
    }

    lockAccount(payload: NoseurObject<any>): Promise<any> {
        const route = payload.external_id ? `/janus/geminus/api/v1/account/lock/${payload.external_id}` : "/janus/geminus/api/v1/account/lock";
        return this.report(this.transport.post(route, { ...payload, end_date: Utils.formatToApiDate(payload.end_date), start_date: Utils.formatToApiDate(payload.start_date) }, this.config));
    }

    unlockAccount(payload: { code: string; external_id: string; }): Promise<any> {
        return this.report(this.transport.post(`/janus/geminus/api/v1/account/unlock/${payload.external_id}`, { code: payload.code }, this.config));
    }

}

