
import { BaseService, SanitizedResponse } from "../../BaseService";
import { IProfile } from "./AccountService";
import { NoseurObject } from "@ronuse/noseur";

export interface IAdministrator extends IProfile {
    status: string;
    __internal_relay__: any;
    reports_to: IAdministrator;
    created_by: IAdministrator;
}

export interface IAdministratorEmailChangeRequest {
    reason: string;
    status: string;
    created_at: Date;
    old_email: string;
    new_email: string;
    approved_at: Date;
    approved_by: IAdministrator;
    administrator: IAdministrator;
}

export class AdministrationService extends BaseService {

    protected static instance: AdministrationService;

    private constructor() {
        super();
    }

    static getInstance() {
        if (!AdministrationService.instance) AdministrationService.instance = new AdministrationService();
        return AdministrationService.instance;
    }

    queryAdministrator(params: NoseurObject<string | number> = { page: 1, size: 10 }): Promise<any> {
        const route = params.status === "KICKED" ? "/janus/geminus/api/v1/kick/administrator" : "/janus/geminus/api/v1/administrator";
        return this.report(this.transport.get(route, { params: this.normalizeParams(params) } as any), ({ apiResponse }: SanitizedResponse<any>) => {
            if (params.status !== "KICKED") return;
            if (!apiResponse.data?.content) return;
            for (let index = 0; index < (apiResponse.data?.content?.length ?? 0); index++) {
                apiResponse.data.content[index] = apiResponse.data.content[index].administrator_data;
                apiResponse.data.content[index].status = params.status;
            }
        });
    }

    getSingleAdministrator(externalId: string): Promise<any> {
        return this.report(this.transport.get(`/janus/geminus/api/v1/administrator/${externalId}`));
    }

    createAdministrator(administrator: IAdministrator): Promise<any> {
        return this.report(this.transport.post(`/janus/geminus/api/v1/administrator/onboard`, administrator, this.config));
    }

    kickAdministrator(externalId: string, code: string): Promise<any> {
        return this.report(this.transport.delete(`/janus/geminus/api/v1/kick/administrator/boot/${externalId}`, { params: { code } } as any));
    }

    activateDeactivateAdministrator(externalId: string, dectivate: boolean): Promise<any> {
        return this.report(this.transport.post(`/janus/geminus/api/v1/account/active/${externalId}`, { active: !dectivate, reason: "UI" }, this.config));
    }

    queryAdministratorPendingApproval(params: NoseurObject<string | number> = { page: 1, size: 10 }): Promise<any> {
        return this.report(this.transport.get("/janus/geminus/api/v1/administrator/pending/approval", { params: this.normalizeParams(params) } as any));
    }

    approveOrRejectNewAdministrator(payload: { approve: boolean, administrator_ids: number[], code?: string, prefix_url?: string, message?: string }) {
        return this.report(this.transport.post(`/janus/geminus/api/v1/administrator/pending/approval`, payload, this.config));
    }

    queryEmailChangeRequests(params: NoseurObject<string | number> = { page: 1, size: 10 }): Promise<any> {
        return this.report(this.transport.get("/janus/geminus/api/v1/email/change", { params: this.normalizeParams(params) } as any));
    }

    approveOrRejectEmailChangeRequest(payload: { request_id?: string, approve: boolean, administrator_ids: number[], code?: string, prefix_url?: string, message?: string }) {
        return this.report(this.transport.post(`/janus/geminus/api/v1/email/change/approve/${payload.request_id}`, payload, this.config));
    }

    sendPasswordResetEmail(externalId: string, payload: NoseurObject<any>) {
        return this.report(this.transport.post(`/janus/geminus/api/v1/account/password/reset/email/${externalId}`, payload, this.config));
    }

    changeAdminEmail(externalId: string, payload: { code: string; email: string; }) {
        return this.report(this.transport.post(`/janus/geminus/api/v1/email/change/admin/${externalId}`, { reason: "Chnaged from addmin service", ...payload }, this.config));
    }

    updateAdminProfile(administrator: IAdministrator) {
        return this.report(this.transport.patch(`/janus/geminus/api/v1/account/${administrator.external_id}/json`, administrator, this.config));
    }

}

