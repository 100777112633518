
import ffs, { FFS } from "kyofuuc";
import { i18nManager } from "../i18n";
import { LzEncryptor } from "../utils/Encryptor";
import { CacheManager, Subscriber } from "../utils";
import { ConfirmDialog, Elements } from "../components/shared";
import { AlertInterface, MessageSchemesIcons, NoseurObject, Scheme, Toaster, TypeChecker } from "@ronuse/noseur";

export interface ApiResponseData<T> {
    data?: T;
    content?: T[];
    size?: number;
    transformed?: any;
    total_pages?: number;
    total_elements?: number;
    number_of_elements?: number;
    pageable?: {
        paged: boolean;
        offset: number;
        page_size: number;
        page_number: number;
    };
};

export interface ApiResponse<T> {
    message: string;
    success: boolean;
    data?: ApiResponseData<T>;
    secondary_data: NoseurObject<any>;
};

export type SanitizedResponse<T> = {
    sanitized: T;
    status: number;
    errors?: any[];
    errorMessage: string;
    apiResponse: ApiResponse<T>;
};

export interface ISecondaryData {
    unreads: NoseurObject<number>;
};

export class BaseService {

    // remove /json route after add formData submission on kyofuuc, and remove the headers this.config
    config: any = {
        headers: {
            Authorization: this.getAccessToken()
        }
    };
    transport: FFS;
    baseUrl: string = process.env.REACT_APP_BASE_API_URL ?? "http://127.0.0.1:9000";
    cacheManager = new (ffs as any).cachemanagers.LocalStorageFFSCacheManager({
        bucket: sessionStorage,
        encryptor: LzEncryptor.encrypt,
        decryptor: LzEncryptor.decrypt,
    });

    constructor(baseUrl?: string) {
        this.transport = ffs.init({
            httpConfig: {
                baseURL: baseUrl ?? this.baseUrl,
                ...this.config
            }
        } as any);
        (this.transport as any).httpInterceptor.registerPostResponse((_: any, __: any, response: any) => {
            let { apiResponse }: SanitizedResponse<ISecondaryData> = this.buildResponse(response);
            if (apiResponse && !!apiResponse.secondary_data) Subscriber.report(Subscriber.KEYS.SECONDARY_DATA, apiResponse.secondary_data);
        });
    }

    getAccessToken() {
        const accessToken = CacheManager.get(CacheManager.ACCESS_TOKEN_KEY);
        if (!accessToken) return null;
        return `Bearer ${accessToken}`;
    }

    normalizeParams(params: NoseurObject<any>) {
        if (!params.page) params.page = 1;
        if (!params.size) params.size = 10;
        if (!params.sort) params.sort = "id,DESC";
        Object.keys(params).forEach((key: string) => {
            const value = params[key];
            if (!TypeChecker.isArray(value)) return;
            params[key] = value.reduce((acc: string, v: string, index: number) => {
                if (index > 0) return `${acc},${key}=${v}`;
                return `${v}`;
            }, "");
        });
        return params;
    }

    report<T>(promise: Promise<any>, postResolve?: Function, postReject?: Function): Promise<SanitizedResponse<any>> {
        return new Promise((resolve, reject) => {
            promise.then((response: T) => {
                let transformedRespomse = this.buildResponse(response);
                if (postResolve) postResolve(transformedRespomse);
                resolve(transformedRespomse);
            }).catch((error: any) => {
                let transformedError = this.buildError(error);
                if (postReject) postReject(transformedError);
                reject(transformedError);
                if (error.response?.status === 401 && !(this.constructor.name === "AuthsService" || window.location.pathname === "/")) {
                    let errorSpan = { current: undefined as any };
                    const accessToken = CacheManager.get(CacheManager.ACCESS_TOKEN_KEY);
                    const sessionToken = CacheManager.get(CacheManager.SESSION_TOKEN_KEY);
                    if (!accessToken || !sessionToken) {
                        window.location = "/" as any; return;
                    }
                    const refreshTokenPayload: NoseurObject<any> = {
                        access_token: accessToken,
                        session_token: sessionToken,
                    };
                    const dialog = ConfirmDialog({
                        cancelScheme: Scheme.DANGER,
                        ...i18nManager.Labels.dashboard.refresh_access_token,
                        content: Elements.refreshSessionDialogContent(i18nManager.Labels.dashboard.refresh_access_token.form, errorSpan, (name: string, value: string) => {
                            refreshTokenPayload[name] = value;
                            if (errorSpan!.current!.innerText.length > 1) errorSpan!.current!.innerText = ' ';
                        }),
                        onCancel: () => this.backToLogin(dialog),
                        onConfirm: (button: any) => {
                            button.setLoadingState(true);
                            if (errorSpan!.current!.innerText.length > 1) errorSpan!.current!.innerText = ' ';
                            this.transport.post(`${process.env.REACT_APP_BASE_API_URL ?? "http://127.0.0.1:9000"}/janus/geminus/api/v1/administrator/auth/refresh`, refreshTokenPayload).then((response: any) => {
                                CacheManager.insecurePut(CacheManager.ACCESS_TOKEN_KEY, response.data?.data?.access_token);
                                window.location.reload();
                            }).catch((error: any) => {
                                if (error.response?.status === 400) return this.backToLogin(dialog);
                                errorSpan!.current!.innerText = error?.response?.data?.message ?? error.message;
                            }).finally(() => button.setLoadingState(false));
                            return false;
                        }
                    });
                    dialog.show();
                }
            });
        });
    }

    buildResponse<T>(response: any): SanitizedResponse<T> {
        if (!response.data || !response.data.data) return response;
        response.status = response?.status;
        response.apiResponse = response.data;
        response.sanitized = response.apiResponse.data;
        return response;
    }

    buildError<T>(error: any): SanitizedResponse<T> {
        if (!error) error = {};
        error.message = error?.response?.data?.message ?? error?.response?.message ?? error?.message ?? "An error occur please try again later";
        error.errors = error?.response?.data?.errors;
        error.errorMessage = error.message;
        error.status = error.response?.status ?? -1;
        return error;
    }

    backToLogin(dialog?: AlertInterface) {
        dialog?.destroy();
        CacheManager.clear();
        window.location = "/" as any;
    }

    static reportError<T>(response: SanitizedResponse<T>) {
        Toaster.toast({
            lifetime: 5000,
            foreScheme: true,
            scheme: Scheme.DANGER,
            showProgressbar: true,
            pauseDelayOnHover: true,
            style: { background: "white" },
            icon: MessageSchemesIcons.INFO,
            content: response?.errorMessage ?? `${response}`,
        });
    }

}
