
import { BaseService } from "../BaseService";

export enum ActorType {
    USER = "USER",
    BUSINESS = "BUSINESS",
    PLATFORM = "PLATFORM",
    ADMINISTRATOR = "ADMINISTRATOR",
}

export class ThothBaseService extends BaseService {

    BASE_URL: string = "";

    constructor() {
        let baseUrl = process.env.REACT_APP_THOTH_BASE_API_URL ?? "http://127.0.0.1:9300";
        super(baseUrl);
        this.BASE_URL = baseUrl;
    }

}

