
import React from "react";
import { Elements } from "./Elements";
import { SetURLSearchParams } from "react-router-dom";
import { Schemes, Subscriber, Utils } from "../../utils";
import { SidedContainerLabels, SidedContainerLabelsSearchControl, i18nManager } from "../../i18n";
import { Button, DataManageRef, List, NoseurElement, DataInternalElementProps } from "@ronuse/noseur";
import { ApiResponseData, BaseService, IPlatform, PlatformService, SanitizedResponse } from "../../services";

export interface SidedContainerProps<T> {
    onAction?: () => void;
    queryData: () => void;
    data?: ApiResponseData<T>;
    leftContent?: NoseurElement;
    labels: SidedContainerLabels;
    searchParams: URLSearchParams;
    mainContent?: (data?: T[]) => NoseurElement;
    dataTemplate: (value: any) => NoseurElement;
    internalElementProps?: DataInternalElementProps;
    setSearchParams: SetURLSearchParams | URLSearchParams;
    activeData?: { override?: boolean; data: T; element: NoseurElement; preview?: NoseurElement };
}
let __sided_container__internal__data__manage__ref: DataManageRef | null;

export function SidedContainer<T>(props: SidedContainerProps<T>) {
    
    const platformService = PlatformService.getInstance();

    return (<div className="messaging-sided">
        {!props.activeData ? <div className="first">
            {props.leftContent
                ? props.leftContent : (<React.Fragment>
                    <span className="title">{props.labels.title}</span>
                    {Elements.buildConfirmDialogForm(props.labels.textfield_form, undefined, props.searchParams, undefined, { marginTop: 0 }, undefined, undefined, props.setSearchParams, props.queryData, onSearch)}
                    {props.labels.dropdown_form?.map((searchControl: SidedContainerLabelsSearchControl, index: number) => {
                        return (<div key={index} className="sub-filter">
                            <span>{searchControl.title}</span>
                            {Elements.buildConfirmDialogForm([searchControl.control], (name, value) => {
                                if (name.includes("platform")) {
                                    platformService.queryPlatforms({ name__like: value.value }, true).then(({ apiResponse }: SanitizedResponse<IPlatform>) => {
                                        value.manageRef?.changeOptions(apiResponse.data!.content?.map((platform: IPlatform) => {
                                            return {
                                                "label": platform.name,
                                                "value": platform.platform_id
                                            };
                                        }));
                                    }).catch(BaseService.reportError);
                                }
                            }, props.searchParams, undefined, { marginTop: 0 }, undefined, { marginTop: 7 }, props.setSearchParams, props.queryData, onSearch)}
                        </div>);
                    })}
                    {props.labels.date_range_form?.map((searchControl: SidedContainerLabelsSearchControl, index: number) => {
                        return (<div key={index} className="sub-filter">
                            <span>{searchControl.title}</span>
                            {Elements.buildConfirmDialogForm([searchControl.control], undefined, props.searchParams, undefined, { marginTop: 0 }, undefined, { marginTop: 7 }, props.setSearchParams, props.queryData, onSearch)}
                        </div>);
                    })}
                    <div style={{ flex: 1, marginBottom: 15 }} />
                    {props.onAction ? <Button scheme={Schemes.RIVTN_QUIRINUS} text={props.labels.create_label} onClick={() => props.onAction && props.onAction()} fill raised /> : null}
                </React.Fragment>)}
        </div> : null}
        {props.activeData?.override
            ? <div className="second override">
                {props.activeData?.preview}
            </div>
            : <div className="second">
                {props.mainContent
                    ? props.mainContent(props.data?.content) : (<React.Fragment>
                        <List data={props.data?.content as any} template={props.dataTemplate} loadingState={Elements.LOADING_STATE} manageRef={resolveManageRef}
                            style={{ marginTop: 10 }} emptyState={<div className="es" style={{ marginTop: 20 }}>
                                <i className="fa fa-bullseye" />
                                <span style={{ maxWidth: 150 }}>{i18nManager.Labels.common.no} {props.labels.fine_name} {i18nManager.Labels.common.found} <span onClick={props.onAction}>{props.labels.create ?? i18nManager.Labels.common.create}</span> {props.labels.fine_name}</span>
                            </div>} internalElementProps={props.internalElementProps} />
                        {Elements.buildPaginator(props.data || {}, ({ currentPage }) => Utils.updateSearchParamses({ "page": currentPage, id: null }, props.setSearchParams, props.queryData),
                            parseInt(props.searchParams.get("page") ?? "1"))}
                    </React.Fragment>)}
            </div>}
        {!!props.activeData ? <div className="third active-data">
            {props.activeData.element}
        </div> : null}
    </div>);

    function resolveManageRef(r: any) {
        if (r) {
            __sided_container__internal__data__manage__ref = r;
            Subscriber.subscribe(Subscriber.KEYS.QUERY_REQUEST_PROMISE_COMPLETED, () => onSearch([]));
        } else {
            __sided_container__internal__data__manage__ref = null;
            Subscriber.clearSubscriptions(Subscriber.KEYS.QUERY_REQUEST_PROMISE_COMPLETED);
        }
    }

    function onSearch(newData?: any[]) {
        __sided_container__internal__data__manage__ref?.setData(newData);
    }

}
