
import React from "react";
import Auth from "./Auth";
import { i18nManager } from "../../i18n";
import { NoseurObject } from "@ronuse/noseur";
import { CacheManager, LzEncryptor } from "../../utils";
import { useNavigate, useSearchParams } from "react-router-dom";
import { SanitizedResponse, AuthsService } from "../../services/";

function Login() {
    const navigate = useNavigate();
    const [ error, setError ] = React.useState<string>();
    const [ loading, setLoading ] = React.useState<boolean>(false);
    const [searchParams, _] = useSearchParams(window.location.search);
    
    return Auth({
        ...(i18nManager.Labels.auths.login),
        error,
        loading,
        onSubmit: (service: AuthsService, formValues: NoseurObject<any>) => {
            setError(""); setLoading(true);
            service.signin(formValues).then(({ sanitized }: SanitizedResponse<any>) => {
                if (searchParams.has("rloc") && searchParams.has("rtype") && searchParams.get("rtype") === "auth") {
                    const redirectLocation = searchParams.get("rloc");
                    const separator = redirectLocation?.includes("?") ? "&" : "?";
                    const encryptedAccessToken = LzEncryptor.encrypt(sanitized.access_token, true);
                    window.location.replace(redirectLocation + separator + `atok=${encodeURIComponent(encryptedAccessToken)}`);
                    return;
                }
                CacheManager.clear();
                CacheManager.insecurePut(CacheManager.PROFILE_KEY, { email: formValues.email });
                CacheManager.insecurePut(CacheManager.SESSION_TOKEN_KEY, sanitized.session_token);
                if (!sanitized.requires2fa) CacheManager.insecurePut(CacheManager.ACCESS_TOKEN_KEY, sanitized.access_token);
                return navigate(sanitized.requires2fa ? "/fa" : "/dashboard");
            }).catch(({ errorMessage }: SanitizedResponse<any>) => {
                setLoading(false);
                setError(errorMessage);
            });
        }
    });
}

export default Login;
