
import { ButtonManageRef, Classname, Column, DataManageRef, NoseurObject, Popover, PopoverManageRef, Scheme, Table } from "@ronuse/noseur";
import React from "react";
import { FormControls, i18nManager } from "../../../i18n";
import { ConfirmDialog, Elements, Siren } from "../../shared";
import { useSearchParams } from "react-router-dom";
import { ApiResponseData, BaseService, EmailChangeRequestService, EmailChangeRequestStatus, IEmailChangeRequest, IUser, SanitizedResponse } from "../../../services";
import { Utils } from "../../../utils";

function EmailChangeRequest() {
    const tableManageRef = React.useRef<DataManageRef>(null);
    const dialogErrorRef = React.useRef<HTMLDivElement>(null);
    const popoverManageRef = React.useRef<PopoverManageRef>(null);
    const emailChangeRequestService = EmailChangeRequestService.getInstance();
    const [searchParams, setSearchParams] = useSearchParams(window.location.search);
    const labels = i18nManager.Labels.dashboard.users_and_businesses.email_change_requests;
    const [activeEmailChangeRequest, setActiveEmailChangeRequest] = React.useState<IEmailChangeRequest>();
    const [emailChangeRequests, setEmailChangeRequests] = React.useState<ApiResponseData<IEmailChangeRequest>>({});

    React.useEffect(() => {
        fetchEmailChangeRequests();
    }, [searchParams]);

    return (<div className="charon-messages">
        <div className="management" style={{ padding: 10 }}>
            <div className="filters flexi">
                {labels.filters.form?.map((control: FormControls) => {
                    return (<div key={control.name} className={Classname.build("filter", (control.type === "checkbox" ? "ckb" : null))} style={{ maxWidth: "unset" }}>
                        {Elements.buildConfirmDialogForm([control], (name, value) => {
                        }, searchParams, undefined, { marginTop: 0 }, undefined, { marginTop: 7 }, setSearchParams, undefined, undefined, { platform_id: "users_platform_id" })}
                    </div>);
                })}
            </div>
            <Table className="table" data={emailChangeRequests.content} rowsPerPage={emailChangeRequests.size ?? 10} totalRecords={emailChangeRequests.total_elements} dataRefreshKeys={["id", "status"]} manageRef={tableManageRef} loadingState={Elements.LOADING_STATE} rowExpansionTemplate={(request: IEmailChangeRequest) => {
                return (<div className="email-change-content">
                    <span><b>{labels.table.reason_for_change}:</b> {request.reason}</span>
                    <span style={{ marginTop: 5 }}><b>{labels.table.reason_for_rejection}:</b> {request.rejection_reason}</span>
                </div>)
            }}>
                <Column template={(_, rowControlOptions) => <i style={{ cursor: "pointer" }} className="fa fa-angle-right" onClick={rowControlOptions?.toggleContent}/>}/>
                <Column dataKey="user" header={labels.table.name} template={(user: IUser) => `${user.first_name} ${user.last_name}`} />
                <Column dataKey="old_email" header={labels.table.old_email} />
                <Column dataKey="new_email" header={labels.table.new_email} />
                <Column dataKey="created_at" header={labels.table.date_requested} template={(createdAt: string) => Utils.formatToFineDate4(createdAt)} />
                <Column dataKey="status" header={labels.table.status} template={selectStyledStatus} />
                <Column template={(request: IEmailChangeRequest) => (<React.Fragment>
                    <i className="fa fa-ellipsis-h" style={{ margin: "5px 10px", cursor: "pointer" }}
                        onClick={(e) => {
                            setActiveEmailChangeRequest(request);
                            popoverManageRef.current?.show(e as any);
                        }} />
                </React.Fragment>)} />
            </Table>
            {Elements.buildPaginator(emailChangeRequests ?? {}, ({ currentPage }, size) => Utils.updateSearchParamses({ "page": currentPage, id: null, size }, setSearchParams, fetchEmailChangeRequests), parseInt(searchParams.get("page") ?? "1"))}
        </div>

        <Popover className="dashboard-popover" manageRef={popoverManageRef} style={{ padding: "10px 0px" }} dismissOnClick>
            <span className={Classname.build("popover-link g", (activeEmailChangeRequest?.status !== EmailChangeRequestStatus.PENDING ? "noseur-disabled" : null))} onClick={() => approveRejectEmailRequest(true)}>{labels.table.popover.approve}</span>
            <span className={Classname.build("popover-link r", (activeEmailChangeRequest?.status !== EmailChangeRequestStatus.PENDING ? "noseur-disabled" : null))} onClick={() => approveRejectEmailRequest(false)}>{labels.table.popover.reject}</span>
        </Popover>
    </div>);

    function fetchEmailChangeRequests() {
        emailChangeRequestService.queryEmailChangeRequests(Utils.normalizeUrlParams(searchParams, {}, undefined, {})).then(({ apiResponse }: SanitizedResponse<IEmailChangeRequest>) => {
            setEmailChangeRequests(apiResponse.data!);
        }).catch(BaseService.reportError).finally(() => {
            tableManageRef.current?.setLoadingState(false);
        });
    }

    function selectStyledStatus(status: string, style: any = {}) {
        if (status === EmailChangeRequestStatus.PENDING) return (<span className="pill" style={{ "--backgroundColor": "#A5A80F", ...style } as any}>{EmailChangeRequestStatus.PENDING}</span>);
        if (status === EmailChangeRequestStatus.REJECTED) return (<span className="pill" style={{ "--backgroundColor": "#EB5959", ...style } as any}>{EmailChangeRequestStatus.REJECTED}</span>);
        return (<span className="pill" style={{ "--backgroundColor": "#0FA883", ...style } as any}>{EmailChangeRequestStatus.APPROVED}</span>);
    }

    function approveRejectEmailRequest(approve: boolean) {
        if (!activeEmailChangeRequest) return;
        const approveRejectPayload: NoseurObject<boolean | string> = { approve };
        const dialogLabel = labels.table.popover.dialogs[approve ? "approve" : "reject"];
        const dialog = ConfirmDialog({
            closable: true,
            desc: dialogLabel.desc,
            title: dialogLabel.title,
            confirmLabel: dialogLabel.confirm_label,
            confirmScheme: !approve ? Scheme.DANGER : undefined,
            content: (<div style={{ marginTop: 10, marginBottom: 10 }}>
                <div style={{ marginTop: 15 }}>
                    {Elements.buildConfirmDialogForm(dialogLabel.form, (name: string, value: string) => {
                        approveRejectPayload[name] = value;
                    })}
                </div>
                <div ref={dialogErrorRef} className="error"> </div>
            </div>),
            onConfirm: (button?: ButtonManageRef) => {
                let errorMessage;
                dialogErrorRef.current!.innerText = " ";
                if (!approve && !approveRejectPayload.reason) errorMessage = labels.table.popover.reason_required;
                if (!approveRejectPayload.code) errorMessage = labels.table.popover.auth_code_required;
                if (errorMessage) {
                    dialogErrorRef.current!.innerText = errorMessage;
                    return false;
                }
                button?.setLoadingState(true);
                emailChangeRequestService.approveEmailChangeRequest(activeEmailChangeRequest.external_id, approveRejectPayload).then((_: SanitizedResponse<IUser>) => {
                    dialog.hide(fetchEmailChangeRequests);
                    Siren.alert(dialogLabel.success);
                }).catch(({ errorMessage }: SanitizedResponse<any>) => {
                    dialogErrorRef.current!.innerText = errorMessage;
                }).finally(() => {
                    button?.setLoadingState(false);
                });
                return false;
            },
        });
        dialog.show();
    }

}

export default EmailChangeRequest;
