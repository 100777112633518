
import React from "react";
import Channels from "./Channels";
import Messages from "./Messages";
import Templating from "./Templating";
import Scheduling from "./Scheduling";
import { i18nManager } from "../../../i18n";
import { Route, Routes } from "react-router-dom";
import ContactManagement from "./ContactManagement";
import { IProfile, ISecondaryData } from "../../../services";
import { DashboardContainer } from "../../shared/DashboardContainer";

export interface MessagingProps {
    search: string;
    profile: IProfile;
    secondaryData: ISecondaryData;
}

function Messaging(props: MessagingProps) {
    const labels = i18nManager.Labels.dashboard.messaging;

    return DashboardContainer({
        ...labels,
        search: props.search,
        secondaryData: props.secondaryData,
        content: (<Routes>
            <Route path="/channels" element={<Channels/>} />
            <Route path="/messages" element={<Messages/>} />
            <Route path="/templating" element={<Templating/>} />
            <Route path="/scheduling" element={<Scheduling/>} />
            <Route path="/contact-management" element={<ContactManagement/>} />
        </Routes>),
    });
}

export default Messaging;
