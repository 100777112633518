
import { Elements } from "./Elements";
import { ConfirmDialog } from "./ConfirmDialog";
import { FormControls, i18nManager } from "../../i18n";
import { NoseurObject, ButtonManageRef } from "@ronuse/noseur";
import { SanitizedResponse, IProfile, AccountService, IAdministrator } from "../../services";
import React from "react";

export interface LocaAccountLabel {
    desc: string;
    title: string;
    desc2?: string;
    confirm?: string;
    fill_form?: string;
    successful?: string;
    form_error?: string;
    form: FormControls[];
    sub_form?: FormControls[];
}

function lockUnlockAccount(lock: boolean, labels: LocaAccountLabel, accountService: AccountService, errorRef: React.RefObject<HTMLDivElement>, lockAccountFormValues: any = {}, administrator?: IAdministrator, cb?: Function) {
    if (lock) {
        if (!lockAccountFormValues.external_id) lockAccountFormValues.external_id = undefined;
        lockAccount(labels, accountService, errorRef, lockAccountFormValues, administrator, cb);
        return;
    }
    const dialog = ConfirmDialog({
        ...labels,
        closable: true,
        confirmLabel: labels.confirm,
        content: (<div style={{ marginTop: 10, marginBottom: 10 }}>
            <div style={{ marginTop: 15 }}>
                {administrator ? (<div className="participant" style={{ marginTop: 20, marginBottom: 30 }}>
                    {Elements.buildAdministratorPreview(administrator)}
                </div>) : null}
                {Elements.buildConfirmDialogForm(labels.form, (name: string, value: any) => {
                    errorRef.current!.innerText = " ";
                    lockAccountFormValues[name] = value;
                })}
            </div>
            <div ref={errorRef} className="error"> </div>
        </div>),
        onConfirm: (button?: ButtonManageRef) => {
            accountService.unlockAccount(lockAccountFormValues).then(() => {
                dialog.destroy(cb);
            }).catch(({ errorMessage }: SanitizedResponse<any>) => {
                errorRef.current!.innerText = errorMessage;
            }).finally(() => button?.setLoadingState(false));
            return false;
        },
    });
    dialog.show();

}

function lockAccount(labels: LocaAccountLabel, accountService: AccountService, errorRef: React.RefObject<HTMLDivElement>, lockAccountFormValues: any = {}, administrator?: IAdministrator, cb?: Function) {
    const lockAccountFormElements: NoseurObject<any> = {};
    const dialog = ConfirmDialog({
        ...labels,
        closable: true,
        content: (<div style={{ marginTop: 10, marginBottom: 10 }}>
            <div style={{ marginTop: 15 }}>
                {administrator ? (<div className="participant" style={{ marginTop: 20, marginBottom: 30 }}>
                    {Elements.buildAdministratorPreview(administrator)}
                </div>) : null}
                {Elements.buildConfirmDialogForm(labels.form, (name: string, value: any) => {
                    errorRef.current!.innerText = " ";
                    if (name.indexOf("date") > -1) value = value.selectedDate;
                    if (name === "indefinite") {
                        if (value) delete lockAccountFormValues["end_date"];
                        lockAccountFormElements["end_date"].style.display = value ? "none" : "flex";
                        return;
                    }
                    lockAccountFormValues[name] = value;
                }, lockAccountFormValues, (name: string, element: any) => {
                    lockAccountFormElements[name] = element;
                })}
            </div>
            <div ref={errorRef} className="error"> </div>
        </div>),
        onConfirm: () => {
            errorRef.current!.innerText = " ";
            if (Object.keys(lockAccountFormValues).length < 3) {
                errorRef.current!.innerText = labels.fill_form!;
                return false;
            }
            dialog.destroy(() => lockAccountWithFa(labels, accountService, errorRef, lockAccountFormValues, administrator, cb));
            return false;
        },
    });
    dialog.show();
}

function lockAccountWithFa(labels: LocaAccountLabel, accountService: AccountService, errorRef: React.RefObject<HTMLDivElement>, lockAccountFormValues: NoseurObject<string>, administrator?: IAdministrator, cb?: Function) {
    const dialog = ConfirmDialog({
        closable: true,
        desc: labels.desc2,
        title: labels.title,
        content: (<div style={{ marginTop: 10, marginBottom: 10 }}>
            <div style={{ marginTop: 15 }}>
                {administrator ? (<div className="participant" style={{ marginTop: 20, marginBottom: 30 }}>
                    {Elements.buildAdministratorPreview(administrator)}
                </div>) : null}
                {Elements.buildConfirmDialogForm(labels.sub_form!, (name: string, value: any) => {
                    errorRef.current!.innerText = " ";
                    lockAccountFormValues[name] = value;
                })}
            </div>
            <div ref={errorRef} className="error"> </div>
        </div>),
        cancelLabel: i18nManager.Labels.common.back,
        onConfirm: (button?: ButtonManageRef) => {
            errorRef.current!.innerText = " ";
            if (Object.keys(lockAccountFormValues).length < 3) {
                errorRef.current!.innerText = labels.form_error!;
                return false;
            }
            button?.setLoadingState(true);
            accountService.lockAccount(lockAccountFormValues).then(() => {
                dialog.hide();
                ConfirmDialog({
                    centerIcon: Elements.DIALOG_CHECK_ICON,
                    title: labels.title,
                    desc: labels.successful,
                    onConfirm: () => {
                        cb && cb();
                        return true;
                    },
                }).show()
            }).catch(({ errorMessage }: SanitizedResponse<any>) => {
                errorRef.current!.innerText = errorMessage;
            }).finally(() => button?.setLoadingState(false));
            return false;
        },
        onCancel: () => {
            dialog.destroy(() => lockAccount(labels, accountService, errorRef, lockAccountFormValues, administrator, cb));
        },
    });
    dialog.show();
}

export const LockAccount = {
    lockUnlockAccount
}

