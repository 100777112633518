
import React from "react";
import { Elements, Siren } from "../../shared";
import { Schemes, Utils } from "../../../utils";
import { useSearchParams } from "react-router-dom";
import { FormControls, i18nManager } from "../../../i18n";
import { Dialog, Classname, Button, NoseurObject, Alignment, FileInput, ButtonManageRef, Checkbox } from "@ronuse/noseur";
import { ApiResponseData, IUserSession, IUser, SanitizedResponse, BaseService, UserSessionService, UserService, UserStatus, UserPhoneNumberService, IUserPhoneNumber, UserAddressService, IUserAddress } from "../../../services";

const Toggle = Checkbox; // create toggle on noseur

export enum ManageUserFragment {
    PROFILE = "profile",
    SESSIONS = "sessions",
    ADDRESSES = "addresses",
    PHONE_NUMBERS = "phone_numbers",
    SECURITY_SETTINGS = "security_settings",
    CONNECTED_BUSINESSES = "connected_businesses",
}

interface ManageUserProps {
    user: IUser;
    fragment: ManageUserFragment;
    sendPasswordReset: (onDone: () => void) => void;
}

function ManageUser(props: ManageUserProps) {
    const userService = UserService.getInstance();
    const sessionService = UserSessionService.getInstance();
    const dialogErrorRef = React.useRef<HTMLDivElement>(null);
    const userAddressService = UserAddressService.getInstance();
    const [visible, setVisible] = React.useState<boolean>(true);
    const countries = [{ label: "Nigeria" }, { label: "Ghana" },];
    const userPhoneNumberService = UserPhoneNumberService.getInstance();
    const [actionOngoing, setActionOngoing] = React.useState<boolean>(false);
    const actionButtonManageRef = React.useRef<NoseurObject<ButtonManageRef>>({});
    const [searchParams, setSearchParams] = useSearchParams(window.location.search);
    const [sessions, setSessions] = React.useState<ApiResponseData<IUserSession>>({});
    const [userAddresses, setUserAddresses] = React.useState<ApiResponseData<IUserAddress>>({});
    const labels = i18nManager.Labels.dashboard.users_and_businesses.users.dialogs.manage_account;
    const [userPhoneNumbers, setUserPhoneNumbers] = React.useState<ApiResponseData<IUserPhoneNumber>>({});
    const [fragment, setFragment] = React.useState<ManageUserFragment>((props.fragment ?? searchParams.get("fragment")) as ManageUserFragment);
    const fragmentLabels = (labels.fragments as any)[fragment as any];
    const fragmentQueryMap: NoseurObject<Function> = {
        "sessions": fetchSession,
        "addresses": fetchAddresses,
        "phone_numbers": fetchPhoneNumbers,
    };

    React.useEffect(() => {
        if (!searchParams.has("fragment")) Utils.updateSearchParams("fragment", fragment, setSearchParams);
        if (!(fragment in fragmentQueryMap)) return;
        fragmentQueryMap[fragment]();
    }, [fragment]);

    return (<Dialog contentProps={{ className: "manage-admin" }} style={{ minWidth: "85%", height: "90%" }} visible={visible} onHide={onClose} notClosable dismissableModal={false}>
        <div className="left">
            <span className="title">{labels.title}</span>
            <div className="participant">
                {Elements.buildAdministratorPreview(props.user)}
            </div>
            <div className="actions">
                <span className={Classname.build(fragment === ManageUserFragment.PROFILE ? "active" : null)} onClick={() => switchManageUserFragment(ManageUserFragment.PROFILE)}>{labels.actions.profile}</span>
                <span className={Classname.build(fragment === ManageUserFragment.SECURITY_SETTINGS ? "active" : null)} onClick={() => switchManageUserFragment(ManageUserFragment.SECURITY_SETTINGS)}>{labels.actions.security_settings}</span>
                <span className={Classname.build(fragment === ManageUserFragment.PHONE_NUMBERS ? "active" : null)} onClick={() => switchManageUserFragment(ManageUserFragment.PHONE_NUMBERS)}>{labels.actions.phone_numbers}</span>
                <span className={Classname.build(fragment === ManageUserFragment.ADDRESSES ? "active" : null)} onClick={() => switchManageUserFragment(ManageUserFragment.ADDRESSES)}>{labels.actions.addresses}</span>
                <span className={Classname.build(fragment === ManageUserFragment.SESSIONS ? "active" : null)} onClick={() => switchManageUserFragment(ManageUserFragment.SESSIONS)}>{labels.actions.sessions}</span>
                <span className={Classname.build(fragment === ManageUserFragment.CONNECTED_BUSINESSES ? "active" : null)} onClick={() => switchManageUserFragment(ManageUserFragment.CONNECTED_BUSINESSES)}>{labels.actions.connected_businesses}</span>
            </div>
            <Button text={i18nManager.Labels.common.close} scheme={Schemes.RIVTN_QUIRINUS} onClick={onClose} textOnly />
        </div>
        <div className="right">
            <span>{fragmentLabels.title}</span>
            <div className="fragment">
                {fragment === ManageUserFragment.PROFILE ? profileView() : null}
                {fragment === ManageUserFragment.ADDRESSES ? addressesView() : null}
                {fragment === ManageUserFragment.PHONE_NUMBERS ? phoneNumbersView() : null}
                {fragment === ManageUserFragment.SECURITY_SETTINGS ? securitySettingsView() : null}
                {fragment === ManageUserFragment.CONNECTED_BUSINESSES ? connectedBusinessView() : null}
                {fragment === ManageUserFragment.SESSIONS ? Elements.buildSessionView(sessions, fragmentLabels.desc, searchParams, setSearchParams, fetchSession, (session: IUserSession, onDone: () => void) => {
                    sessionService.revokeSession(session.id).then(fetchSession).catch(BaseService.reportError).finally(onDone);
                }) : null}
            </div>
        </div>
    </Dialog>);

    function switchManageUserFragment(fragmentation: ManageUserFragment) {
        if (fragmentation === fragment) return;
        setFragment(fragmentation);
        Utils.updateSearchParams("fragment", fragmentation, setSearchParams);
    }

    function profileView() {
        return (<div className="profile-view">
            <FileInput stickyPreview className="pic" rounded scheme={Schemes.RIVTN_QUIRINUS} accepts="image/*"
                label={<i className='fa fa-pen' />} attrsRelay={{ label: { style: { borderRadius: "50%" }, alignment: Alignment.TOP_LEFT } }}
                emptyTemplate={(select) => <img onClick={select} style={{ width: "100%", height: "100%" }} src={props.user.profile_picture_location} />} />
            <div className="inputs">
                {Elements.buildConfirmDialogForm(labels.fragments.profile.form, (name: string, value: any) => {
                    dialogErrorRef.current!.innerText = " ";
                    if (name === "dob") value = Utils.formatToApiDate(value.selectedDate, true);
                    (props.user as any)[name] = value;
                }, props.user, undefined, { marginTop: 0 }, { country: countries })}
                <span style={{ marginTop: 15 }}>{labels.status}: <span style={{ fontWeight: "bold" }} className={Classname.build(props.user.status === UserStatus.ACTIVE ? "rivtn-quirinus-tx" : "noseur-danger-tx")}>{props.user.status}</span></span>
                <span style={{ marginTop: 15 }}>{labels.email_verified}: <span style={{ fontWeight: "bold" }} className={Classname.build(props.user.email_verified ? "rivtn-quirinus-tx" : "noseur-danger-tx")}>{props.user.email_verified ? i18nManager.Labels.common.yes : i18nManager.Labels.common.no}</span></span>
                <div ref={dialogErrorRef} className="error"> </div>
                <div style={{ flex: 0.6 }} />
                <Button manageRef={(m) => { if (m) actionButtonManageRef.current["change-email"] = m }} scheme={Schemes.RIVTN_QUIRINUS} text={i18nManager.Labels.common.save} loadingProps={{
                    disabled: true,
                    leftIcon: "fas fa-spinner fa-pulse"
                }} onClick={() => {
                    actionButtonManageRef.current["change-email"]?.setLoadingState(true);
                    userService.updateUserProfile(props.user).then(({ }: SanitizedResponse<any>) => {
                        Siren.alert(fragmentLabels.success);
                    }).catch(({ errorMessage }: SanitizedResponse<any>) => {
                        dialogErrorRef.current!.innerText = errorMessage;
                    }).finally(() => actionButtonManageRef.current["change-email"]?.setLoadingState(false));
                }} style={{ alignSelf: "flex-end", minWidth: 120 }} />
            </div>
        </div>);
    }

    function phoneNumbersView() {
        return (<div className="phone-numbers">
            <div className="numbers">
                {userPhoneNumbers.content?.map((userPhoneNumber: IUserPhoneNumber) => {
                    let deleteManageRef: ButtonManageRef | null;
                    let setPrimaryManageRef: ButtonManageRef | null;

                    return (<div key={userPhoneNumber.external_id} className="number">
                        <Button manageRef={(m) => deleteManageRef = m} className="delete" leftIcon="fa fa-trash" iconOnly textOnly onClick={() => {
                            deleteManageRef?.setLoadingState(true);
                            userPhoneNumberService.deleteUserPhoneNumber(userPhoneNumber.external_id)
                                .then(fetchPhoneNumbers).catch(BaseService.reportError)
                                .finally(() => deleteManageRef?.setLoadingState(false));
                        }} loadingProps={{ disabled: true, leftIcon: "fa fa-spinner fa-spin" }} />
                        <div className="details">
                            <span className="no">{userPhoneNumber.phone} {userPhoneNumber.primary ? (<span className="pill" style={{ "--backgroundColor": "#0FA883" } as any}>{labels.fragments.default}</span>) : null}</span>
                            <span className="info">{fragmentLabels.verified_on} {userPhoneNumber.updated_at}</span>
                        </div>
                        {!userPhoneNumber.primary ? (<span className="controls">
                            <Button scheme={Schemes.RIVTN_QUIRINUS} manageRef={(m) => setPrimaryManageRef = m} text={labels.fragments.mark_as_default} textOnly onClick={(e: any) => {
                                setPrimaryManageRef?.setLoadingState(true);
                                userPhoneNumberService.markUserPhoneNumberAsDefault(userPhoneNumber.external_id)
                                    .then(fetchPhoneNumbers).catch(BaseService.reportError)
                                    .finally(() => setPrimaryManageRef?.setLoadingState(false));
                            }} loadingProps={{ disabled: true, rightIcon: "fa fa-spinner fa-spin" }} />
                        </span>) : null}
                    </div>);
                })}
            </div>
            {Elements.buildPaginator(userPhoneNumbers ?? {}, ({ currentPage }, size) => Utils.updateSearchParamses({ "phone_numbers_page": currentPage, id: null, phone_numbers_size: size }, setSearchParams, fetchSession), parseInt(searchParams.get("phone_numbers_page") ?? "1"), { marginBottom: 20 })}
        </div>)
    }

    function addressesView() {
        return (<div className="user-addresses">
            <div className="addresses">
                {userAddresses.content?.map((userAddress: IUserAddress) => {
                    let deleteManageRef: ButtonManageRef | null;

                    return (<div key={userAddress.external_id} className="address">
                        <Button manageRef={(m) => deleteManageRef = m} className="delete" leftIcon="fa fa-trash" iconOnly textOnly onClick={() => {
                            deleteManageRef?.setLoadingState(true);
                            userAddressService.deleteUserAddress(userAddress.external_id)
                                .then(fetchAddresses).catch(BaseService.reportError)
                                .finally(() => deleteManageRef?.setLoadingState(false));
                        }} loadingProps={{ disabled: true, leftIcon: "fa fa-spinner fa-spin" }} />
                        <div className="details">
                            <span className="no">{userAddress.zipcode} - {userAddress.address}</span>
                            <span className="info"><b>{fragmentLabels.state}:</b> {userAddress.state}</span>
                            <span className="info"><b>{fragmentLabels.city}:</b> {userAddress.city}</span>
                            <span className="info"><b>{fragmentLabels.country}:</b> {userAddress.country}</span>
                        </div>
                    </div>);
                })}
            </div>
            {Elements.buildPaginator(sessions ?? {}, ({ currentPage }, size) => Utils.updateSearchParamses({ "sessions_page": currentPage, id: null, sessions_size: size }, setSearchParams, fetchSession), parseInt(searchParams.get("addresses") ?? "1"), { marginBottom: 20 })}
        </div>)
    }

    function securitySettingsView() {
        return (<div className="acc-settings">
            <div className="acc-toggles-modifiers" style={{ padding: 0, marginTop: 30, fontSize: 13, height: "auto" }}>
                <div className="tp">
                    {fragmentLabels.settings.map((control: FormControls, index: number) => (<div key={index}>
                        <div style={{ marginRight: 50 }}>
                            <span className="ti">{control.title}</span>
                            <span className="sti">{control.sub_title}</span>
                        </div>
                        <Toggle scheme={Schemes.RIVTN_QUIRINUS} />
                    </div>))}
                </div>
            </div>
            <p className={Classname.build("link", (actionOngoing ? "noseur-disabled" : null))} onClick={() => {
                setActionOngoing(true); props.sendPasswordReset(() => setActionOngoing(false));
            }} style={{ width: "fit-content" }}>{fragmentLabels.send_password_reset.replace("${email}", props.user.email)}</p>
        </div>);
    }

    function connectedBusinessView() {
        return (<div className="connected-businesses">
        </div>);
    }

    function fetchSession() {
        sessionService.queryAllSession({
            actor_id: props.user.external_id,
            page: parseInt(searchParams.get("phone_numbers_page") ?? "1"),
            size: parseInt(searchParams.get("phone_numbers_size") ?? "10"),
        }).then(({ apiResponse }: SanitizedResponse<IUserSession>) => {
            setSessions(apiResponse.data!);
        }).catch(BaseService.reportError);
    }

    function fetchPhoneNumbers() {
        userPhoneNumberService.queryUserPhoneNumbers({
            "user.external_id": props.user.external_id,
            page: parseInt(searchParams.get("phone_numbers_page") ?? "1")
        }).then(({ apiResponse }: SanitizedResponse<IUserPhoneNumber>) => {
            setUserPhoneNumbers(apiResponse.data!);
        }).catch(BaseService.reportError);
    }

    function fetchAddresses() {
        userAddressService.queryUserAddresss({
            "user.external_id": props.user.external_id,
            page: parseInt(searchParams.get("addresses") ?? "1"),
            size: parseInt(searchParams.get("addresses") ?? "1"),
        }).then(({ apiResponse }: SanitizedResponse<IUserAddress>) => {
            setUserAddresses(apiResponse.data!);
        }).catch(BaseService.reportError);
    }

    function onClose() {
        setVisible(false);
        Utils.updateSearchParams("fragment", undefined, setSearchParams);
        Utils.updateSearchParams("addresses", undefined, setSearchParams);
        Utils.updateSearchParams("sessions_page", undefined, setSearchParams);
        Utils.updateSearchParams("active_user_id", undefined, setSearchParams);
        Utils.updateSearchParams("platforms_page", undefined, setSearchParams);
        Utils.updateSearchParams("phone_numbers_page", undefined, setSearchParams);
    }

}

export default ManageUser;
