
import { NoseurObject } from "@ronuse/noseur";
import { LanuariusBaseService, UserStatus } from "./LanuariusBaseService";

export interface IUser {
    email: string;
    last_name: string;
    status: UserStatus;
    first_name: string;
    created_at: string;
    updated_at: string;
    other_names: string;
    external_id: string;
    email_verified: boolean;
    profile_picture_location: string;
}

export class UserService extends LanuariusBaseService {

    protected static instance: UserService;

    private constructor() {
        super();
    }

    static getInstance() {
        if (!UserService.instance) UserService.instance = new UserService();
        return UserService.instance;
    }

    queryUsers(params: NoseurObject<string | number> = { page: 1, size: 10 }, refresh: boolean = false): Promise<any> {
        return this.report(this.transport.get("/janus/lanuarius/api/v1/user", { params: this.normalizeParams(params) } as any));
    }

    getSingleUser(externalId: string): Promise<any> {
        return this.report(this.transport.get(`/janus/lanuarius/api/v1/user/${externalId!}`, this.config));
    }

    sendPasswordResetEmail(externalId: string): Promise<any> {
        return this.report(this.transport.post(`/janus/lanuarius/api/v1/account/password/reset/email/${externalId!}`, {
            prefix_url: process.env.REACT_APP_LANUARIUS_PASSWORD_RESET_PREFIX_URL ?? "http://localhost:3002/reset-password"
        }, this.config));
    }

    updateUserStatus(externalId: string, payload: NoseurObject<string>): Promise<any> {
        return this.report(this.transport.put(`/janus/lanuarius/api/v1/account/status/${externalId!}`, payload, this.config));
    }

    updateUserProfile(administrator: IUser) {
        return this.report(this.transport.patch(`/janus/lanuarius/api/v1/account/${administrator.external_id}/json`, administrator, this.config));
    }

}

