
import { NoseurObject } from "@ronuse/noseur";
import { BaseService, SanitizedResponse } from "../../BaseService";

export interface ISession {
    id: number;
    status: string;
    ip_address: string;
    created_at: string;
    updated_at: string;
    session_token: string;
    device: {
        os_name: string;
        device_class: string;
        browser_name: string;
    };
    location: {
        state: string;
        country: string;
    };
}

export class SessionService extends BaseService {

    protected static instance: SessionService;

    private constructor() {
        super();
    }

    static getInstance() {
        if (!SessionService.instance) SessionService.instance = new SessionService();
        return SessionService.instance;
    }

    querySession(params: NoseurObject<string | number> = { page: 1, size: 10 }): Promise<any> {
        return this.report(this.transport.get("/janus/geminus/api/v1/session/self", { params: this.normalizeParams(params) } as any));
    }

    revokeSession(id: number | string): Promise<any> {
        return this.report(this.transport.delete(`/janus/geminus/api/v1/session/self/${id}`, this.config));
    }

    queryAllSession(params: NoseurObject<string | number> = { page: 1, size: 10 }): Promise<any> {
        return this.report(this.transport.get("/janus/geminus/api/v1/session", { params: this.normalizeParams(params) } as any));
    }

    superRevokeSession(id: number | string): Promise<any> {
        return this.report(this.transport.delete(`/janus/geminus/api/v1/session/${id}`, this.config));
    }

}

