
import { Utils } from "../../../utils";
import { NoseurObject } from "@ronuse/noseur";
import { ApiResponse, BaseService, SanitizedResponse } from "../../BaseService";

export interface INotification {
    logo: string;
    read: boolean;
    title: string;
    group: string;
    message: string;
    extra_data: any;
    created_at: string;
    updated_at: string;
    external_id: string;
}

export class NotificationService extends BaseService {

    protected static instance: NotificationService;

    private constructor() {
        super();
    }

    static getInstance() {
        if (!NotificationService.instance) NotificationService.instance = new NotificationService();
        return NotificationService.instance;
    }

    queryNotification(params: NoseurObject<any> = { page: 1, size: 10 }, labels: NoseurObject<any> = {}, cb?: (apiResponse: ApiResponse<INotification>) => void): Promise<any> {
        return this.report(this.transport.get("/janus/geminus/api/v1/notification", { params: this.normalizeParams(params) } as any), ({ apiResponse }: SanitizedResponse<INotification>) => {
            if (cb && cb instanceof Function) cb(apiResponse);
            apiResponse.data!.transformed = {};
            apiResponse.data!.transformed[labels.today] = [];
            apiResponse.data!.transformed[labels.yesterday] = [];
            apiResponse.data!.transformed[labels.earlier] = [];
            for (const notification of apiResponse.data?.content!) {
                if (Utils.dateIsToday(new Date(notification.created_at))) {
                    apiResponse.data!.transformed[labels.today].push(notification);
                } else if (Utils.dateIsYesterday(new Date(notification.created_at))) {
                    apiResponse.data!.transformed[labels.yesterday].push(notification);
                } else {
                    apiResponse.data!.transformed[labels.earlier].push(notification);
                }
            }
        });
    }

    readNotification(externalId: string): Promise<any> {
        return this.report(this.transport.patch(`/janus/geminus/api/v1/notification/${externalId}`, { read: true }, this.config));
    }

    deleteNotification(externalId: string): Promise<any> {
        return this.report(this.transport.delete(`/janus/geminus/api/v1/notification/${externalId}`, this.config));
    }

}

