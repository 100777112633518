
import Fa from './components/auths/Fa';
import ReactDOM from 'react-dom/client';
import Login from './components/auths/Login';
import Logout from './components/auths/Logout';
import Register from './components/auths/Register';
import Dashboard from './components/dashboard/Dashboard';
import ResetPassword from './components/auths/ResetPassword';
import ForgotPassword from './components/auths/ForgotPassword';
import { createBrowserRouter, Navigate, RouterProvider } from "react-router-dom";
import './assets/css/index.css';
import './assets/css/scheme.css';

const router = createBrowserRouter([
    { path: "/fa", element: <Fa/> },
    { path: "/", element: <Login/> },
    { path: "/logout", element: <Logout/> },
    { path: "/register", element: <Register/> },
    { path: "/dashboard/*", element: <Dashboard/> },
    { path: "/reset-password", element: <ResetPassword/> },
    { path: "/forgot-password", element: <ForgotPassword/> },
    { path: "/dashboard", element: <Navigate to="/dashboard/home"/> },
]);

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
    //<React.StrictMode>
    <RouterProvider router={router} />
    //</React.StrictMode>
);

