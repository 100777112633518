
import React from "react";
import ReactDOM from "react-dom/client";
import { i18nManager } from "../../../i18n";
import { Schemes, Subscriber, Utils } from "../../../utils";
import { NavLink, useSearchParams } from "react-router-dom";
import { ConfirmDialog, Elements, SidedContainer, Siren } from "../../shared";
import { ApiResponseData, BaseService, ChannelService, IChannel, SanitizedResponse } from "../../../services";
import { ButtonManageRef, Classname, FormControl, FormGroup, NoseurObject, ObjectHelper, Scheme, TypeChecker } from "@ronuse/noseur";

function Channels() {
    const channelService = ChannelService.getInstance();
    const dialogErrorRef = React.useRef<HTMLDivElement>(null);
    const labels = i18nManager.Labels.dashboard.messaging.channels;
    const activeDialogMutableRoot = React.useRef<ReactDOM.Root>(null);
    const [activeChannel, setActiveChannel] = React.useState<IChannel>();
    const [channels, setChannels] = React.useState<ApiResponseData<IChannel>>({});
    const [searchParams, setSearchParams] = useSearchParams(window.location.search);

    React.useEffect(fetchChannels, []);

    return SidedContainer({
        searchParams,
        data: channels,
        setSearchParams,
        onAction: manageChannel,
        queryData: fetchChannels,
        labels: labels.sided_labels,
        activeData: (activeChannel ? {
            data: activeChannel,
            element: (<React.Fragment>
                <div className="header">
                    <i className="fa fa-times" onClick={() => {
                        setActiveChannel(undefined);
                        Utils.updateSearchParams("active_data_id", undefined, setSearchParams);
                    }} />
                    <span className="name">{activeChannel?.name}</span>
                    <span className="id" style={{ fontWeight: "bold" }}>{activeChannel?.channel_id}</span>
                    <span className="id">{activeChannel?.type}</span>
                    <span className="desc">{activeChannel?.description}</span>
                </div>
                <div className="middle">
                    {Object.keys(activeChannel?.data).map((key, index) => {
                        let value = key === "auth" ? "************" : activeChannel?.data[key];
                        if (TypeChecker.isObject(value)) value = JSON.stringify(value);
                        return (<div key={index}><b>{key}:</b> {value}</div>);
                    })}
                </div>
                <div className="actions">
                    <NavLink to={`/dashboard/messaging/messages?channel_id=${activeChannel?.id}`}>{i18nManager.Labels.dashboard.messaging.see_messages}</NavLink>
                    <span className="link" onClick={() => manageChannel(activeChannel)}>{labels.edit_channel}</span>
                    <span className={Classname.build("link", (activeChannel?.enabled ? "danger" : null))} onClick={() => enabledDisableChannel(activeChannel?.enabled)}>{activeChannel?.enabled ? labels.disable_channel : labels.enable_channel}</span>
                    <span className="link danger" onClick={() => deleteChannel(activeChannel)}>{labels.delete_channel}</span>
                </div>
            </React.Fragment>)
        } : undefined),
        dataTemplate: (channel: IChannel) => {
            return (<div className={Classname.build("channel", (activeChannel?.channel_id === channel.channel_id ? "active" : null))} onClick={() => {
                setActiveChannel(channel);
                Utils.updateSearchParams("active_data_id", channel.id, setSearchParams);
            }}>
                <div className="id">
                    <span className="name">{channel.name}</span>
                    <span className="type"> - {channel.type}</span>
                </div>
                <span className="desc">{channel.description}</span>
                <div className="control">
                    <i className="fa fa-edit link gray" onClick={() => manageChannel(channel)} />
                    <i className="fa fa-trash link danger" onClick={() => deleteChannel(channel)} />
                </div>
            </div>);
        }
    });

    function fetchChannels() {
        const id = parseInt(searchParams.get("active_data_id") || "0");
        channelService.queryChannel(Utils.normalizeUrlParams(searchParams), true).then(({ apiResponse }: SanitizedResponse<IChannel>) => {
            setChannels(apiResponse.data!);
            if (id > 0) for (const item of apiResponse.data?.content!) {
                if (item.id === id) {
                    setActiveChannel(item);
                    break;
                }
            }
        }).catch(BaseService.reportError).finally(() => Subscriber.report(Subscriber.KEYS.QUERY_REQUEST_PROMISE_COMPLETED));
    }

    function updateNewDataControls(data: NoseurObject<any>[]) {
        if (!activeDialogMutableRoot.current) return;
        const newData = data.map((entry: NoseurObject<any>, index: number) => {
            const keyProps: any = Utils.buildSelectNoseurComponentProps({
                name: "key",
                value: entry.key,
                placeholder: labels.dialog.manage.key,
            }, undefined, undefined, undefined, undefined, undefined, (_: string, value: any) => {
                dialogErrorRef.current!.innerText = " ";
                data[index].key = value;
            });
            const valueProps: any = Utils.buildSelectNoseurComponentProps({
                name: "value",
                value: (entry.type === "primitive" ? entry.value : JSON.stringify(entry.value)),
                placeholder: (entry.type === "primitive" ? labels.dialog.manage.primitive : labels.dialog.manage.json),
            }, undefined, undefined, undefined, undefined, undefined, (_: string, value: any) => {
                dialogErrorRef.current!.innerText = " ";
                try {
                    data[index].value = (entry.type === "primitive" ? value : JSON.parse(value));
                } catch (err: any) {
                    dialogErrorRef.current!.innerText = err;
                }
            });
            keyProps.style = { background: "rgba(217, 217, 217, 0.2)", borderRadius: 0, flex: 0.7, marginRight: 10, alignSelf: "flex-start" };
            valueProps.style = { background: "rgba(217, 217, 217, 0.2)", borderRadius: 0, flex: 1.5, height: (entry.type === "primitive" ? undefined : 100) };
            return (<FormControl key={index} style={{ marginTop: 0, marginBottom: 10, background: "transparent" }}
                validStyle={{ borderColor: "transparent" }} contentStyle={{ borderRadius: 0 }} ref={(r: any) => r?.scrollIntoView()}
                rightContent={<i className="fa fa-trash" style={{ color: "#EB5959", cursor: "pointer" }} onClick={() => {
                    dialogErrorRef.current!.innerText = " ";
                    delete data[index]; updateNewDataControls(data);
                }} />}>
                {React.createElement(Utils.selectNoseurComponent() as any, keyProps)}
                {React.createElement(Utils.selectNoseurComponent(entry.type === "primitive" ? "text" : "textarea") as any, valueProps)}
            </FormControl>);
        });
        activeDialogMutableRoot.current.render(newData);
    }

    function manageChannel(channel?: IChannel) {
        const payload = ObjectHelper.clone(channel ?? {} as any);
        payload.data = (channel?.data ? Object.keys(channel?.data).reduce((acc: any, key: string) => {
            const value = channel?.data[key];
            acc.push({
                key,
                value,
                type: typeof value === "object" ? "json" : "primitive",
            });
            return acc;
        }, []) : []);
        const basics = labels.dialog.manage.basic[channel ? "update" : "create"];
        const dialog = ConfirmDialog({
            ...basics,
            closable: true,
            rightStyle: { flex: 1.2 },
            cancelScheme: Schemes.RIVTN_QUIRINUS,
            style: { minWidth: 600, maxWidth: "80%" },
            desc: basics.desc.replace("${value}", channel?.name ?? ""),
            content: (<div className="add-new-role">
                {Elements.buildConfirmDialogForm(labels.dialog.manage.form, (name: string, value: string) => {
                    dialogErrorRef.current!.innerText = " ";
                    payload[name] = value;
                }, payload)}
                <div ref={dialogErrorRef} className="error"> </div>
            </div>),
            rightContent: (<div style={{ flex: 1 }} className="add-new-permission add-new-role-permission">
                <div style={{ marginTop: 10, fontSize: 13 }}>{labels.dialog.manage.info}</div>
                <FormGroup scheme={Schemes.RIVTN_QUIRINUS} style={{ marginTop: 20, maxHeight: 260, overflow: "auto" }} className="form" ref={(r: any) => {
                    if (!r) return;
                    ObjectHelper.resolveRef(activeDialogMutableRoot, ReactDOM.createRoot(r));
                    updateNewDataControls(payload.data);
                }}>
                </FormGroup>
                <div className="action link" onClick={() => {
                    payload.data.push({ type: "json" });
                    updateNewDataControls(payload.data);
                }}>
                    <i className="fa fa-plus" style={{ marginRight: 7, color: "#0FA883" }} />
                    <span>{labels.dialog.manage.add_json_data}</span>
                </div>
                <div className="action link" onClick={() => {
                    payload.data.push({ type: "primitive" });
                    updateNewDataControls(payload.data);
                }}>
                    <i className="fa fa-plus" style={{ marginRight: 7, color: "#0FA883" }} />
                    <span>{labels.dialog.manage.add_primitive_data}</span>
                </div>
            </div>),
            onConfirm: (button?: ButtonManageRef) => {
                let errorMessage;
                dialogErrorRef.current!.innerText = " ";
                const formattedPayload = ObjectHelper.clone(payload);
                if (Object.keys(formattedPayload).length < 3) {
                    errorMessage = labels.dialog.manage.fill_required_fields;
                } else {
                    formattedPayload.data = formattedPayload.data.reduce((acc: NoseurObject<any>, entry: any) => {
                        if (!entry || !entry.key || !entry.value) {
                            errorMessage = labels.dialog.manage.missing_value;
                            return acc;
                        }
                        acc[entry.key] = entry.value;
                        return acc;
                    }, {});
                }
                if (errorMessage) {
                    dialogErrorRef.current!.innerText = errorMessage;
                    return false;
                }
                button?.setLoadingState(true);
                const method = (!!channel ? channelService.updateChannel : channelService.createChannel).bind(channelService);
                method(formattedPayload)
                    .then(({ }: SanitizedResponse<IChannel>) => {
                        Siren.alert((basics as any).successful);
                        dialog.hide(fetchChannels);
                    }).catch(({ errorMessage }: SanitizedResponse<any>) => {
                        dialogErrorRef.current!.innerText = errorMessage;
                    }).finally(() => {
                        button?.setLoadingState(false);
                    });
                return false;
            }
        });
        dialog.show();
    }

    function enabledDisableChannel(disable: boolean) {
        const dialogLabel = labels.dialog[disable ? "disable" : "enable"];
        const dialog = ConfirmDialog({
            ...dialogLabel,
            closable: true,
            confirmScheme: (disable ? Scheme.DANGER : undefined),
            onConfirm: (button?: ButtonManageRef) => {
                button?.setLoadingState(true);
                channelService.enableDisableChannel(activeChannel!, !disable).then(() => {
                    Siren.alert(dialogLabel.successful);
                    dialog.hide(fetchChannels);
                }).catch(BaseService.reportError).finally(() => button?.setLoadingState(false));
                return false;
            }
        });
        dialog.show();
    }

    function deleteChannel(channel: IChannel) {
        const dialog = ConfirmDialog({
            closable: true,
            title: labels.dialog.delete.title,
            desc: labels.dialog.delete.desc.replace("${value}", channel.name),
            confirmScheme: Scheme.DANGER,
            onConfirm: (button?: ButtonManageRef) => {
                button?.setLoadingState(true);
                channelService.deleteChannel(channel).then(() => {
                    Siren.alert(labels.dialog.delete.successful);
                    setActiveChannel(undefined);
                    dialog.hide(fetchChannels);
                }).catch(BaseService.reportError).finally(() => button?.setLoadingState(false));
                return false;
            }
        });
        dialog.show();
    }

}

export default Channels;
