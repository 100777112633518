
import { NoseurObject } from "@ronuse/noseur";
import { LanuariusBaseService } from "./LanuariusBaseService";

export interface IUserAddress {
    city: string;
    state: string;
    address: string;
    zipcode: string;
    country: string;
    primary: boolean;
    created_at: string;
    updated_at: string;
    external_id: string;
}

export class UserAddressService extends LanuariusBaseService {

    protected static instance: UserAddressService;

    private constructor() {
        super();
    }

    static getInstance() {
        if (!UserAddressService.instance) UserAddressService.instance = new UserAddressService();
        return UserAddressService.instance;
    }

    queryUserAddresss(params: NoseurObject<string | number> = { page: 1, size: 10 }): Promise<any> {
        return this.report(this.transport.get("/janus/lanuarius/api/v1/administration/user/address", { params: this.normalizeParams(params) } as any));
    }

    deleteUserAddress(externalId: string): Promise<any> {
        return this.report(this.transport.delete("/janus/lanuarius/api/v1/administration/user/address/multiple", {
            ...this.config,
            data: [
                externalId
            ]
        }));
    }

}

